import React, { useEffect, useState, useCallback } from 'react'
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { Portlet, PortletHeader, PortletBody, PortletHeaderToolbar } from "../../partials/content/Portlet";
import { Link } from "react-router-dom";
import FilterComponent from '../../partials/layout/FilterComponent';
import { commonServices } from '../../commonServices/commonServices';
import * as constants from '../../constants';
import { formatMultipleUserRoles, formatMultipleCities } from '../../../_metronic/utils/utils';
export default function PMSUsers(props) {
  const [employees, setEmployees] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [formattedCities, setFormattedCities] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchEmployeeData = useCallback(async () => {
    const data = {
      userRoleCodes: [
        'PROPERTY_MANAGEMENT_HEAD',
        'PROPERTY_MANAGER',
        'SALES_MANAGER',
        'MOVE_IN_MANAGER',
        'PROPERTY_SUPERVISOR'
      ],
      include: ['PHONE_NUMBERS', 'CITIES'],
      cityIds: cityIds
    };

    setIsFetching(true);
    try {
      const result = await commonServices.getUserListData(data);
      if (result && result.status === 200) {
        setEmployees(result.data.users);
      }
    } catch (error) {
      console.error('Error fetching employee data:', error.message);
    } finally {
      setIsFetching(false);
    }
  }, [cityIds]);

  useEffect(() => {
    getCities();
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  useEffect(() => {
    fetchEmployeeData();
  }, [cityIds, fetchEmployeeData]);


  const getCities = async () => {
    const data = {
      pmsOperationStatus: 'YES',
    };

    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      setFormattedCities(formattedCities);
    }
  };

  const handleFilterChildData = async (childData) => {
    console.log(childData);
    if (childData) {
      setCityIds(childData.cityIds);
    }
  }

  return (
    <>
      <Container fluid key="c1">
        <Portlet fluidHeight={true}>
          <PortletHeader
            title="PMS Dashboard Users"
            toolbar={
              <PortletHeaderToolbar>
                <Link to="/new-pms-user">
                  <Button className='ml-auto' variant="primary" size="sm" >Add New User</Button>
                </Link>
              </PortletHeaderToolbar>

            }
          />
          <PortletBody>
            <FilterComponent
              formattedCities={formattedCities}
              filterParentCallback={handleFilterChildData}
            />
            <Card.Header>
              <Row className="azuro-table-header">
                <Col md={3}><strong>Name</strong></Col>
                <Col md={2}><strong>Employee Code</strong></Col>
                <Col md={2}><strong>Role</strong></Col>
                <Col md={3}><strong>Cities</strong></Col>
                {/* <Col md={2}><strong>Action</strong></Col> */}

              </Row>
            </Card.Header>
            {employees && employees.map(employee => <Card.Body key={employee.id} className="p-0">
              <React.Fragment>
                <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                  <Col md={3}><span className='text-primary'>{employee.fullName}</span><br />
                    <span>{employee.phoneNumbers[0]?.number + " | " + employee.email}</span>
                  </Col>
                  <Col md={2}>{employee.employeeId}</Col>
                  <Col md={2}><div style={{ whiteSpace: "pre-line" }}>{formatMultipleUserRoles(employee.userRoleCodes).join(',\n')}</div></Col>
                  <Col md={2}>{formatMultipleCities((employee.city) || [])}</Col>
                  <Col md={12}>
                    <hr className="my-1" />
                  </Col>
                </Row>
              </React.Fragment>
            </Card.Body>)}
          </PortletBody>
        </Portlet>
      </Container >
    </>
  )
}