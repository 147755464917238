import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Button, Modal, Form, Card } from 'react-bootstrap';
import { MoveInReportServices } from './MoveInMoveOutReport.services';
import { commonServices } from '../../../commonServices/commonServices';
import { Formik, FieldArray } from 'formik';
import * as constants from '../../../constants';
import { formatDate, toAbsoluteUrl, formatMedia } from '../../../../_metronic/utils/utils';
import { store } from 'react-notifications-component';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import PhotoGridComponent from '../../../partials/layout/PhotoGridComponent.js';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'

class MoveInMoveOutReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leaseId: props.match.params.leaseId,
      isFetching: false,
      mode: props.match.params.mode,
      showAddSectionsModal: false,
      showEditSectionModal: false,
      showDeleteSectionModal: false,
      showDeleteItemModal: false,
      showAddPhotosModal: false,
      showDeletePhotoModal: false,
      showPublishMoveInReportModal: false,
      showPublishMoveOutReportModal: false,
      reportItemId: null,
      doc: null,
      identifier: '',
      reportSectionId: null,
      showChangeMoveInExecutionDetailsModal: false,
      showChangeMoveOutExecutionDetailsModal: false,
    };
  }

  /**
   * Component did mount method
   */
  async componentDidMount() {
    await this.getMoveInReportById(this.state.leaseId);
  }

  /**
   * Method to get item status badge
   * @param {String} status 
   */
  getItemStatusBadge = (status) => {
    if (status === 'RED') return <i className="flaticon2-hexagonal text-danger"></i>
    if (status === 'YELLOW') return <i className="flaticon2-hexagonal text-warning"></i>
    if (status === 'GREEN') return <i className="flaticon2-hexagonal text-success"></i>
  }

  /**
   * method to handle 'add sections' button click
   */
  handleAddSectionsButtonClick = () => {
    this.setState(state => ({ showAddSectionsModal: !state.showAddSectionsModal }));
  }

  /**
   * Method to handle 'edit' in report section
   * @param {Number} sectionId
   */
  handleEditSectionButtonClick = async (sectionId = null) => {
    if (sectionId !== null) {
      const elementToBeEdited = this.state.moveInReport && this.state.moveInReport.reportSections.find(repSecId => repSecId.id === sectionId);
      await this.setState({ toBeEdited: elementToBeEdited });
    }
    this.setState(state => ({ showEditSectionModal: !state.showEditSectionModal }));
  }

  /**
   * Method to handle 'delete' on report section
   * @param {Number} sectionId
   */
  handleDeleteSectionButtonClick = async (sectionId = null) => {
    if (sectionId !== null) {
      await this.setState(state => ({
        idToBeDeleted: sectionId
      }))
    }
    this.setState(state => ({ showDeleteSectionModal: !state.showDeleteSectionModal }));
  }

  /**
   * Method to handle approval of delete section
   */
  handleApproveDeleteSectionButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await commonServices.deleteReportSection(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Section deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteSectionModal: !state.showDeleteSectionModal }));
        await this.getMoveInReportById(this.state.leaseId);
      }
    }
  }

  /**
   * Method to hanlde the delete of section item
   * @param {Number} itemId
   */
  handleDeleteItemButtonClick = async (itemId) => {
    if (itemId !== null) {
      await this.setState(state => ({
        itemToBeDeleted: itemId
      }))
    }
    this.setState(state => ({ showDeleteItemModal: !state.showDeleteItemModal }));
  }

  /**
   * Method to handle approval of delete button on section items
   */
  handleApproveDeleteItemButtonClick = async () => {
    if (this.state.itemToBeDeleted) {
      const result = await commonServices.deleteReportSectionItem(this.state.itemToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Item deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteItemModal: !state.showDeleteItemModal }));
        await this.getMoveInReportById(this.state.leaseId);
      }
    }
  }

  /**
   * Method to handle approval of photo button click
   */
  handleApproveDeletePhotoButtonClick = async () => {
    if (this.state.photoIdToBeDeleted) {
      const result = await commonServices.deleteFile(this.state.photoIdToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Photo deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeletePhotoModal: !state.showDeletePhotoModal }));
        await this.getMoveInReportById(this.state.leaseId);
      }
    }
  }


  /**
 * Method to handle publish report button click
 * @param {*} confirmPublish
 */
  handlePublishMoveInReportButtonClick = async (confirmPublish = false) => {
    this.setState(state => ({ showPublishMoveInReportModal: !state.showPublishMoveInReportModal }));
  }

  handlePublishMoveOutReportButtonClick = async (confirmPublish = false) => {
    this.setState(state => ({ showPublishMoveOutReportModal: !state.showPublishMoveOutReportModal }));
  }
  /**
   * Method to handle publish report button click
   */
  handleConfirmPublishMoveInReportButtonClick = async () => {
    const result = await MoveInReportServices.publishMoveInReport(this.state.leaseId);
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: `Move-in Report published!`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
      this.setState(state => ({ showPublishMoveInReportModal: !state.showPublishMoveInReportModal }));
      await this.getMoveInReportById(this.state.leaseId);
    }
  }

  handleConfirmPublishMoveOutReportButtonClick = async () => {
    const result = await MoveInReportServices.publishMoveOutReport(this.state.leaseId);
    if (result && result.status === 200) {
      store.addNotification({
        title: "Success!",
        message: `Move-out Report published!`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
      this.setState(state => ({ showPublishMoveOutReportModal: !state.showPublishMoveOutReportModal }));
      await this.getMoveInReportById(this.state.leaseId);
    }

  }
  /**
   * Method to handle 'chcange execution details' button click
   * @param {Boolean} isApiCall
   */
  handleChangeMoveInExecutionDetailsButtonClick = async (isApiCall = false) => {
    (isApiCall === true) && await this.getPropertySupervisors();
    this.setState(state => ({ showChangeMoveInExecutionDetailsModal: !state.showChangeMoveInExecutionDetailsModal }));
  }

  handleChangeMoveOutExecutionDetailsButtonClick = async (isApiCall = false) => {
    (isApiCall === true) && await this.getPropertySupervisors();
    this.setState(state => ({ showChangeMoveOutExecutionDetailsModal: !state.showChangeMoveOutExecutionDetailsModal }));
  }

  /**
   * Method to get inspection report by Id
   * @param {*} id
   */
  getMoveInReportById = async (id) => {
    this.setState({ isFetching: true })
    const result = await MoveInReportServices.getMoveInReportById(id);
    if (result && result.status === 200) {
      await this.setState({ moveInReport: result.data.moveInMoveOutReport })
    } else {
      console.error('Something went wrong');
    }
    this.setState({ isFetching: false })
  }

  /**
   * Method to get property supervisors
   */
  getPropertySupervisors = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      result.data.users.push(this.props.userDetails);
      await this.setState({ propertySuperVisors: result.data.users })
    }
  }

  /**
   * Method to handle data from child components
   * @param {Boolean} childData
   */
  handlePhotoGridComponentData = async (photoData) => {
    (photoData === true) && await this.getMoveInReportById(this.state.leaseId);
  }

  render() {
    const { moveInReport, toBeEdited, mode, propertySuperVisors, isFetching } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    // let reportItemsNew = [];
    // if(toBeEdited && toBeEdited.reportItems){
    //   reportItemsNew = toBeEdited.reportItems;
    //   reportItemsNew.forEach(element => {
    //     delete element.reportItemFiles;
    //   });
    //   // delete reportItemsNew.reportItemFiles;
    //   console.log('reportItemFilesreportItemFilesreportItemFilesreportItemFiles -------', reportItemsNew)

    // }
    return (
      <>
        <Container fluid key={'move-in-report-' + 0}>
          <Portlet fluidHeight={true}>
            <Card>
            <Link to={`/lease-profile/${moveInReport && moveInReport.leaseId}`}>
              <Card.Header>
              <Row>
                <Col xs={1}>
              <Button variant="clean" size="lg" title="back to lease"><i size="lg" className="flaticon2-back pt-2"></i></Button>
              </Col>
              <Col xs={11}>
              <h4 className="mb-0">{moveInReport && moveInReport.house && moveInReport.house.formattedHouseName} <br/>
                <small>{moveInReport && moveInReport.house && moveInReport.house.society?.locality?.name}, {moveInReport && moveInReport.house && moveInReport.house.society.locality.city.name}</small></h4>
              <p className='small mt-2 pt-0 mb-0'>Property Manager: {moveInReport && moveInReport.house && moveInReport.house.propertyManager!==null? moveInReport.house.propertyManager?.fullName: "Not Assigned"}</p>     
              </Col>
              </Row>
              </Card.Header>
              </Link>
            </Card>
            {
              mode === 'edit' ?
                <PortletHeader
                  title={moveInReport && moveInReport.moveOutInitiationStatus==="YES"?"Edit Move-out Report":"Edit Move-in Report"}
                  toolbar={
                    <PortletHeaderToolbar>
                    {moveInReport && moveInReport.moveOutInitiationStatus===null && !moveInReport.isMoveInReportPublished &&
                      <Button variant="primary" className='mr-2' size="sm" onClick={this.handleAddSectionsButtonClick}>
                        <i className="flaticon2-plus"></i> Add Sections
                      </Button>}
                    {moveInReport && moveInReport.isMoveInReportPublished !== true && moveInReport.moveOutInitiationStatus===null && 
                      <Button variant="primary" size="sm" onClick={this.handlePublishMoveInReportButtonClick}>
                        <i className="flaticon2-google-drive-file"></i> Publish Move-in Report
                      </Button>}
                    {moveInReport && moveInReport.isMoveInReportPublished === true && moveInReport.moveOutInitiationStatus===null && 
                      <Button variant="primary" size="sm" disabled>
                        <i className="flaticon2-google-drive-file"></i> Move-in Report Published 
                      </Button>}
                    {moveInReport && moveInReport.moveOutInitiationStatus!==null && moveInReport.isMoveOutReportPublished !== true &&
                      <Button variant="primary" size="sm" onClick={this.handlePublishMoveOutReportButtonClick}>
                        <i className="flaticon2-google-drive-file"></i> Publish Move-out Report
                      </Button>}
                    {moveInReport && moveInReport.moveOutInitiationStatus!==null && moveInReport.isMoveOutReportPublished===true &&
                      <Button variant="primary" size="sm" disabled>
                        <i className="flaticon2-google-drive-file"></i> Move-out Report Published
                      </Button>}
                    </PortletHeaderToolbar>
                  }
                /> :
                <PortletHeader
                  title={moveInReport && moveInReport.moveOutInitiationStatus==="YES"?"View Move-out Report":"View Move-in Report"}
                />
            }
            <PortletBody>
              {moveInReport &&
                <Container fluid>
                <Row>
                <Col xs={moveInReport && moveInReport.moveOutInitiationStatus=== "YES" ? 6 : 12} style={moveInReport.moveOutInitiationStatus=== "YES" ? { borderRight: "1px dashed rgba(0, 0, 0, 0.1)" }: null}>
                  <h5>Move-in Report Details</h5>
                    <Row>
                      <Col xs={8}>
                        <h6>Execution Details</h6>
                        <Row className="mb-3">
                          <Col xs={12} className="d-flex align-items-center justify-content-between">
                            {moveInReport.moveInExecutor && moveInReport.moveInExecutor?.fullName &&
                              <div className="kt-widget4">
                                <div className="kt-widget4__item ">
                                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                    <img alt="" src={
                                      toAbsoluteUrl((moveInReport.moveInExecutor.imgUrl) || '/media/users/default.jpg')
                                    } />
                                  </div>
                                  <div className="kt-widget4__info ">
                                    <p className="mb-0 kt-widget4__username">
                                      {moveInReport.moveInExecutor && moveInReport.moveInExecutor.fullName}
                                    </p>
                                    <p className="kt-widget4__text">
                                      on {formatDate(moveInReport?.moveInReportExecutionDate)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            }
                            {moveInReport.executor === null && <span className="text-warning"><i className="flaticon-warning mr-2"></i>Details not entered! Fill in the details before publishing.</span>}
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        {!moveInReport.isMoveInReportPublished && mode==='edit' &&
                        <Button variant="info" title="Update Report Execution Details" className='mr-2 pull-right' size="sm" onClick={() => this.handleChangeMoveInExecutionDetailsButtonClick(true)}>
                          {/* <i className="flaticon2-edit"></i> */}
                          Edit Details
                        </Button>}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {/* <p className='mb-0'><strong>Report Created:</strong> {formatDate(moveInReport.moveInReportExecutionDate)}</p> */}
                        <h6 className='mb-0'>Comments:</h6>
                        <ul>
                          {
                            moveInReport.moveInReportComments && moveInReport.moveInReportComments.map((comment, idx) =>
                              <li key={idx + 'PUNS'}>
                                <span key={idx + 'PUN'}>
                                  {(idx > 0 && idx < (moveInReport.moveInReportComments.length)) ? ', ' : ''}
                                </span>
                                <span key={idx}>
                                  {comment}
                                </span>
                              </li>
                            )
                          }
                        </ul>
                      </Col>
                      <hr />
                    </Row>
                    </Col>

                    {moveInReport && moveInReport.moveOutInitiationStatus=== "YES" && <Col xs={6}>
                    {moveInReport && moveInReport.moveOutInitiationStatus=== "YES" && <>
                      <h5>Move-out Report Details</h5>
                      <Row>
                        <Col xs={8}>
                          <h6>Execution Details</h6>
                          <Row className="mb-3">
                            <Col xs={12} className="d-flex align-items-center justify-content-between">
                              {moveInReport.moveOutExecutor && moveInReport.moveOutExecutor?.fullName &&
                                <div className="kt-widget4">
                                  <div className="kt-widget4__item ">
                                    <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                      <img alt="" src={
                                        toAbsoluteUrl((moveInReport.moveOutExecutor.imgUrl) || '/media/users/default.jpg')
                                      } />
                                    </div>
                                    <div className="kt-widget4__info ">
                                      <p className="mb-0 kt-widget4__username">
                                        {moveInReport.moveOutExecutor && moveInReport.moveOutExecutor.fullName}
                                      </p>
                                      <p className="kt-widget4__text">
                                        on {formatDate(moveInReport?.moveOutReportExecutionDate)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              }   
                              {moveInReport.moveOutExecutor === null && <span className="text-warning"><i className="flaticon-warning mr-2"></i>Details not entered! Fill in the details before publishing.</span>}
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          {!moveInReport.isMoveOutReportPublished && mode==='edit' &&
                            <Button variant="info" title="Update Report Execution Details" className='mr-2 pull-right' size="sm" onClick={() => this.handleChangeMoveOutExecutionDetailsButtonClick(true)}>
                              {/* <i className="flaticon2-edit"></i> */}
                              Edit Details
                            </Button>}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {/* <p className='mb-0'><strong>Report Created:</strong> {formatDate(moveInReport.moveInReportExecutionDate)}</p> */}
                          <h6 className='mb-0'>Comments:</h6>
                          <ul>
                            {
                              moveInReport.moveOutReportComments && moveInReport.moveOutReportComments.map((comment, idx) =>
                                <li key={idx + 'PUNS'}>
                                  <span key={idx + 'PUN'}>
                                    {(idx > 0 && idx < (moveInReport.moveOutReportComments.length)) ? ', ' : ''}
                                  </span>
                                  <span key={idx}>
                                    {comment}
                                  </span>
                                </li>
                              )
                            }  
                          </ul>
                        </Col>
                        <hr />
                      </Row>
                    </>
                    }
                    </Col>}
                  </Row>

                  <Row>
                    {moveInReport.reportSections.length === 0 && <Col xs={12}>
                      <h5 className="text-warning"><i className="flaticon-warning mr-3"></i>No Report Sections created.</h5>
                      <p className="text-warning">Add atleast one Report Section with one Report Item &amp; some photos before publishing the report.</p>
                    </Col>}
                    {moveInReport.reportSections.map(section => (
                      <>
                        <Col xs={12} key={section.id}>
                          <Card className="my-3">
                            <Card.Header>
                              <Row className="d-flex align-items-center">
                                <Col xs={6}>
                                  <h5 className="mb-0">{section.name}</h5>
                                </Col>
                                <Col xs={6} className="text-right">
                                  {mode === 'edit' &&<>
                                  {moveInReport && ((moveInReport.moveOutInitiationStatus===null && !moveInReport.isMoveInReportPublished) || (moveInReport.moveOutInitiationStatus==="YES" && !moveInReport.isMoveOutReportPublished))&&
                                    <Button className='mr-2' variant="clean" size="sm" title="Edit" onClick={() => this.handleEditSectionButtonClick(section.id)}>
                                      <i className="flaticon2-edit"></i>
                                    </Button>
                                  }
                                  {!moveInReport.isMoveInReportPublished &&
                                    <Button variant="clean" size="sm" title="Delete" onClick={() => this.handleDeleteSectionButtonClick(section.id)}>
                                      <i className="flaticon2-trash"></i>
                                    </Button>
                                  }
                                  </>}
                                </Col>
                              </Row>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col xs={6}>
                                  <p><strong>Move-in Comments:</strong></p>
                                  <ul>
                                    {
                                      section.comments && section.comments.map((comment, idx)  =>
                                        <li key={idx + 'PUNS'}>
                                          <span key={idx + 'PUN'}>
                                            {(idx > 0 && idx < (section.comments.length)) ? ', ' : ''}
                                          </span>
                                          <span key={idx}>
                                            {comment}
                                          </span>
                                        </li>
                                      )
                                    }
                                  </ul>
                                </Col>
                                {moveInReport?.moveOutInitiationStatus !== null && <Col xs={6}>
                                  <p><strong>Move-out Comments:</strong></p>
                                  <ul>
                                    {
                                      section.moveOutComments && section.moveOutComments.map((moveOutComment, idx) =>
                                        <li key={idx + 'PUNS'}>
                                          <span key={idx + 'PUN'}>
                                            {(idx > 0 && idx < (section.moveOutComments.length)) ? ', ' : ''}
                                          </span>
                                          <span key={idx}>
                                            {moveOutComment}
                                          </span>
                                        </li>
                                      )
                                    }
                                  </ul>
                                </Col>}
                                {section.reportItems.length === 0 && <Col xs={12}>
                                  <h5 className="text-warning"><i className="flaticon-warning mr-3"></i>No Report Items created.</h5>
                                  <p className="text-warning">Add atleast one Report Item to this section before publishing the report.</p>
                                </Col>}
                                {section.reportItems.map((item, i) => (
                                  <>
                                    <Col xs={11} key={item.id}>
                                      <h4 className='mb-3'>{item.name} {item.count > 0 && '(' + item.count + ')'}</h4>
                                    </Col>
                                    <Col xs={1}>
                                      {mode === 'edit' && (moveInReport && !moveInReport.isMoveInReportPublished) && <Button variant="clean" size="sm" title="Delete" onClick={() => this.handleDeleteItemButtonClick(item.id)} >
                                        <i className="flaticon2-trash"></i>
                                      </Button>}
                                    </Col>
                                    <Col xs={3}>
                                      <Row className='ml-2'>
                                        <Col xs={12}>
                                          <p className="mb-0"><strong>Move-in Status:</strong>
                                          <span className="mb-0 ml-2">
                                            {this.getItemStatusBadge(item.status)}
                                          </span></p>
                                          </Col>
                                        {moveInReport.moveOutInitiationStatus!==null && <Col xs={12}>
                                          <p className="mb-0"><strong>Move-out Status: </strong>
                                          <span className="mb-0 ml-2">
                                            {this.getItemStatusBadge(item.moveOutStatus)}
                                          </span></p></Col>
                                        }
                                      </Row>
                                    </Col>
                                    <Col xs={4}>
                                    <p className="mb-0">
                                        <strong>Move-in Comments:</strong><br/> {item.comment}
                                      </p>
                                      {(moveInReport.moveOutInitiationStatus !== null) && <p className="mb-0">
                                        <strong>Move-out Comments:</strong><br/> {item.moveOutComment}
                                      </p>}
                                    </Col>
                                    <Col xs={5}>
                                      <PhotoGridComponent
                                        medias={formatMedia(item.reportItemFiles)?.MOVE_IN || []}
                                        mode={mode}
                                        reportItemId={item.id}
                                        title={`Move-in Photos`}
                                        isItemMedia={true}
                                        photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                        identifier={`MOVE_IN`}
                                        disabled={moveInReport?.moveOutInitiationStatus !== null || undefined}
                                        isPublished={moveInReport.isMoveInReportPublished || undefined}
                                      />
                                      {(moveInReport?.moveOutInitiationStatus !== null) &&
                                        <PhotoGridComponent
                                          medias={formatMedia(item.reportItemFiles)?.MOVE_OUT || []}
                                          mode={mode}
                                          reportItemId={item.id}
                                          title={`Move-out Photos`}
                                          isItemMedia={true}
                                          photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                          identifier={`MOVE_OUT`}
                                          isPublished={moveInReport.isMoveOutReportPublished || undefined}
                                        />}
                                    </Col>
                                    
                                    
                                    <Col xs={12}>
                                      { (
                                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                      )}
                                    </Col>


                                  </>
                                ))}
                                <Col xs={12}>

                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  {/* images, mode, serviceReqeustId, reportSectionId, reportItemId, title */}
                                  <PhotoGridComponent
                                    medias={section.collatedReportSectionFiles}
                                    mode={mode}
                                    reportSectionId={section.id}
                                    title={`Move-in Section Photos`}
                                    photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                    identifier={`MOVE_IN`}
                                    disabled={(moveInReport.moveOutInitiationStatus !== null) || undefined}
                                    isPublished={moveInReport.isMoveInReportPublished || undefined}
                                  />
                                </Col>
                                {(moveInReport?.moveOutInitiationStatus !== null) && <Col xs={6}>
                                  <PhotoGridComponent
                                    medias={section.collatedMoveOutReportSectionFiles}
                                    mode={mode}
                                    reportSectionId={section.id}
                                    title={`Move-out Section Photos`}
                                    photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                    identifier={`MOVE_OUT`}
                                    isPublished={moveInReport.isMoveOutReportPublished || undefined}
                                  />
                                </Col>}
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Container>
              }
            </PortletBody>
          </Portlet>
        </Container>

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddSectionsModal} onHide={this.handleAddSectionsButtonClick} >
          <Modal.Header closeButton>
            <Modal.Title>Add Sections</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                sections: [{
                  leaseId: this.state.leaseId,
                  name: ""
                }]
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await commonServices.createReportSections(values.sections);
                  if (result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.sections.length + ` Sections added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddSectionsModal: !state.showAddSectionsModal }));
                    this.getMoveInReportById(this.state.leaseId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                (

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <FieldArray
                          name="sections"
                          render={arrayHelpers => (
                            <div>
                              {values.sections.map((section, index) =>
                              (<Row key={index}>
                                <Col xs={10}>
                                  <Form.Group controlId={"section" + index}>
                                    <Form.Label>Section Name</Form.Label>
                                    <Form.Control type="text" name={`sections[${index}].name`} required="required" onChange={handleChange} onBlur={handleBlur} value={section.name} list="sectionNamesList" />
                                    <datalist id="sectionNamesList">
                                      <option value="Living Room">Living Room</option>
                                      <option value="Hall">Hall</option>
                                      <option value="Kitchen">Kitchen</option>
                                      <option value="Master Bedroom">Master Bedroom</option>
                                      <option value="Children Bedroom">Children Bedroom</option>
                                      <option value="Guest Bedroom">Guest Bedroom</option>
                                      <option value="Common Bedroom">Common Bedroom</option>
                                      <option value="Master Bathroom">Master Bathroom</option>
                                      <option value="Children Bathroom">Children Bathroom</option>
                                      <option value="Common Bathroom">Common Bathroom</option>
                                      <option value="Guest Bathroom">Guest Bathroom</option>
                                      <option value="Flat Photos">Flat Photos</option>
                                      <option value="Passage">Passage</option>
                                      <option value="Store Room">Store Room</option>
                                      <option value="Hall Balcony">Hall Balcony</option>
                                      <option value="Master Bedroom Balcony">Master Bedroom Balcony</option>
                                      <option value="Children Bedroom Balcony">Children Bedroom Balcony</option>
                                      <option value="Guest Bedroom Balcony">Guest Bedroom Balcony</option>
                                    </datalist>
                                  </Form.Group>
                                </Col>
                                <Col xs={2} className="d-flex align-items-center">
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>
                              </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({ leaseId: this.state.leaseId, name: "" })}>Add Another Section</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddSectionsButtonClick}>
                          Cancel
                            </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
        }

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showEditSectionModal} onHide={this.handleEditSectionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: toBeEdited ? toBeEdited.name : undefined,
                comments: toBeEdited?.comments || [""],
                moveOutComments: toBeEdited?.moveOutComments || [""],
                reportItems:
                  toBeEdited ?
                    toBeEdited.reportItems : [
                      {
                        id: undefined,
                        count: undefined,
                        name: undefined,
                        status: undefined,
                        comment: undefined,
                        moveOutComment: undefined,
                        moveOutStatus: undefined
                      }
                    ]
              }}
              validate={values => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Name is required';
                }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  // TODO : check if this step can be avoided
                  (values?.reportItems).forEach((reportItem, index) => {
                    if (reportItem.count === '') { reportItem.count = undefined; }
                  });
                  const result = await commonServices.updateReportSection(this.state.toBeEdited.id, values);
                  if (result.status === 200 || result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.name + ` Section updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showEditSectionModal: !state.showEditSectionModal }));
                    this.getMoveInReportById(this.state.leaseId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        {moveInReport?.moveOutInitiationStatus === null && <Form.Group controlId="sectionName">
                          <Form.Label>{(moveInReport?.moveOutInitiationStatus === null && `Section Name`) || (moveInReport?.moveOutInitiationStatus !== null && <strong>Section Name: </strong>)}</Form.Label>
                           <Form.Control type="text" name='name' onChange={handleChange} onBlur={handleBlur} value={values.name} />
                            </Form.Group>
                          }
                          {moveInReport?.moveOutInitiationStatus !== null && <h3 className='mb-3'>{values.name}</h3>}
                        
                      </Col>
                      <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                      {/* TODO: Array of Comments & Array of Items */}
                      <Col xs={12}>{(moveInReport?.moveOutInitiationStatus === null && `Move-in Comments`) || (moveInReport?.moveOutInitiationStatus !== null && <strong>Move-in Comments: </strong>)}</Col>
                      <Col xs={12} className="mb-3">
                        <FieldArray
                          name="comments"
                          render={arrayHelpers => (
                            <div>
                              
                              {values.comments && values.comments.length > 0 && values.comments.map((item, index) =>
                              (<Row key={index}>
                                <Col xs={10}>
                                  {moveInReport?.moveOutInitiationStatus === null && <Form.Group controlId={"comment" + index}>
                                    <Form.Control type="text" name={`comments[${index}]`} required="required" onChange={handleChange} onBlur={handleBlur} value={item}
                                    /> </Form.Group>}
                                    {moveInReport?.moveOutInitiationStatus !== null && <li className='mb-0 pb-0'>{item}</li>}
                                    
                                  
                                </Col>
                                
                                {moveInReport?.moveOutInitiationStatus === null && <Col xs={2}>
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>}
                              </Row>)
                              )}
                              {
                                moveInReport?.moveOutInitiationStatus === null && <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Comment</Button>
                              }
                            </div>
                          )}
                        />
                      </Col>

                      {/* Section for Move-out comments */}

                      {(moveInReport?.moveOutInitiationStatus !== null) &&
                        <>
                          <Col xs={12}><strong>Move-out Comments: </strong></Col>
                          <Col xs={12} className="mb-2">
                            <FieldArray
                              name="moveOutComments"
                              render={arrayHelpers => (
                                <div>
                                  {/* <Row>
                                  <Col xs={5}>
                                    <Form.Label>Item Name</Form.Label>
                                  </Col>
                                  <Col xs={5}>
                                    <Form.Label>Amount</Form.Label>
                                  </Col>
                                </Row> */}
                                  {values.moveOutComments.map((item, index) =>
                                  (<Row key={index}>
                                    <Col xs={10}>
                                      <Form.Group controlId={"moveOutComment" + index} className='mb-2'>
                                        <Form.Control type="text" name={`moveOutComments[${index}]`} required="required" onChange={handleChange} onBlur={handleBlur} value={item}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                      <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                    </Col>
                                  </Row>)
                                  )}
                                  <Button className='mb-3' variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Move-out Comment</Button>
                                </div>
                              )}
                            />
                          </Col>
                        </>
                      }
                      <Col xs={12}>
                        <FieldArray
                          name="reportItems"
                          render={arrayHelpers => (
                            <div>
                              {values.reportItems.map((item, index) =>
                              (
                                <Row key={index}>
                                  <Col xs={6}>
                                    <h6>Item #{index + 1}</h6>
                                  </Col>
                                  <Col xs={6} className="text-right">
                                    {
                                      !item.id &&
                                      <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                    }
                                  </Col>
                                  {moveInReport.moveOutInitiationStatus === null && <>
                                    <Col xs={3}>
                                      <Form.Group controlId={"itemName" + index}>
                                        <Form.Label>Item Name</Form.Label>
                                        <Form.Control type="text" name={`reportItems[${index}].name`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.name} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group controlId={"itemStatus" + index}>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" name={`reportItems[${index}].status`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.status}>
                                          {
                                            !item.id &&
                                            <option key={'' + index} value={''}>{constants.DEFAULT_OPTION}</option>
                                          }
                                          <option key={"RED" + index} value={"RED"}>Red</option>
                                          <option key={"YELLOW" + index} value={"YELLOW"} >Yellow</option>
                                          <option key={"GREEN" + index} value={"GREEN"}>Green</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group controlId={"itemCount" + index}>
                                        <Form.Label>Count</Form.Label>
                                        <Form.Control type="number" name={`reportItems[${index}].count`} onChange={handleChange} onBlur={handleBlur} value={item.count || undefined} />
                                      </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Group controlId={"itemComment" + index}>
                                        <Form.Label>Move-in Comment</Form.Label>
                                        {moveInReport.moveOutInitiationStatus === null && <Form.Control as="textarea" rows="3" name={`reportItems[${index}].comment`} onChange={handleChange} onBlur={handleBlur} value={item.comment} />}
                                      </Form.Group>
                                    </Col> 
                                  </>}
                                    {moveInReport.moveOutInitiationStatus !== null &&<><Row className="ml-2 mr-2">
                                      <Col xs={12}><h5>{item.name}({item.count})</h5></Col>
                                      <Col xs={4}><p>Move-in Status: {this.getItemStatusBadge(item.status)}</p></Col>
                                      <Col xs={8}><p>Move-in Comment: {item.comment}</p></Col>
                                      <Col xs={4}>
                                        <Form.Group controlId={"itemMoveOutStatus" + index}>
                                          <Form.Label>Move-out Status:</Form.Label>
                                          <Form.Control as="select" name={`reportItems[${index}].moveOutStatus`} required="required" onChange={handleChange} onBlur={handleBlur} >
                                          {/* {
                                            !item.id &&
                                          }                        */}                    
                                          <option key={'' + index} value={''}>{constants.DEFAULT_OPTION}</option> 

                                          <option key={"RED" + index} value={"RED"}>Red</option>
                                          <option key={"YELLOW" + index} value={"YELLOW"}>Yellow</option>
                                          <option key={"GREEN" + index} value={"GREEN"}>Green</option>
                                        </Form.Control>
                                        </Form.Group>
                                      </Col>
                                      <Col xs={8}><Form.Group controlId={"itemMoveOutComment" + index}>
                                        <Form.Label>Move-out Comment:</Form.Label>
                                        <Form.Control as="textarea" rows="3" name={`reportItems[${index}].moveOutComment`} onChange={handleChange} onBlur={handleBlur} value={item.moveOutComment} />
                                        </Form.Group>
                                      </Col>

                                        </Row> </>
                                  }
                                  <Col xs={12}>
                                    <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                  </Col>
                                </Row>
                              )
                              )}{moveInReport && moveInReport.moveOutInitiationStatus === null &&
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push(
                                {
                                  count: undefined,
                                  name: undefined,
                                  status: undefined,
                                  comment: undefined
                                }
                              )}>Add Item</Button>}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditSectionButtonClick}>
                          Cancel
                            </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 3} show={this.state.showDeleteSectionModal} onHide={this.handleDeleteSectionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete all the linked section items, comments, item status and photos.</p>
            <p><strong>Confirm deletion of this section?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteSectionButtonClick}>
              Cancel
              </Button>
            <Button variant="danger" onClick={this.handleApproveDeleteSectionButtonClick}>
              Delete
              </Button>
          </Modal.Footer>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 4} show={this.state.showDeleteItemModal} onHide={this.handleDeleteItemButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete the item comments, item status and item photos.</p>
            <p><strong>Confirm deletion of this item?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteItemButtonClick}>
              Cancel
              </Button>
            <Button variant="danger" onClick={this.handleApproveDeleteItemButtonClick}>
              Delete
              </Button>
          </Modal.Footer>
        </Modal>}




        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 7} show={this.state.showPublishMoveInReportModal} onHide={this.handlePublishMoveInReportButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Publish Move-in Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Do you want to publish this Move-in report?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handlePublishMoveInReportButtonClick}>
              Cancel
              </Button>
            <Button variant="success" onClick={() =>(moveInReport.reportSections.length>0 && this.handleConfirmPublishMoveInReportButtonClick())}>
              Publish
              </Button>
          </Modal.Footer>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 7} show={this.state.showPublishMoveOutReportModal} onHide={this.handlePublishMoveOutReportButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Publish Move-out Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Do you want to publish this Move-out report?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handlePublishMoveOutReportButtonClick}>
              Cancel
              </Button>
            <Button variant="success" onClick={() => (moveInReport.reportSections.length>0 && this.handleConfirmPublishMoveOutReportButtonClick())}>
              Publish
              </Button>
          </Modal.Footer>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 8} show={this.state.showChangeMoveInExecutionDetailsModal} onHide={this.handleChangeMoveInExecutionDetailsButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Move-in Execution Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                moveInExecutorId: (this.state.moveInReport && this.state.moveInReport.moveInExecutor && this.state.moveInReport.moveInExecutor.id) || undefined,
                moveInReportComments: (this.state.moveInReport && this.state.moveInReport.moveInReportComments) || [""],
                moveInReportExecutionDate: (this.state.moveInReport && this.state.moveInReport.moveInReportExecutionDate) || undefined,

              }}
              validate={values => {
                const errors = {};
                if (!values.moveInExecutorId) {
                  errors.moveInExecutorId = 'Executor is required';
                }
                if (!values.moveInReportExecutionDate) {
                  errors.moveInReportExecutionDate = 'Date is required';
                }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.moveInExecutorId = parseInt(values.moveInExecutorId, 10);
                  const result = await MoveInReportServices.changeExecutionDetails(this.state.leaseId, values);
                  if (result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Execution details recorded!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showChangeMoveInExecutionDetailsModal: !state.showChangeMoveInExecutionDetailsModal }));
                    this.getMoveInReportById(this.state.leaseId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="moveInExecutorId">
                          <Form.Label>Move-in Executor</Form.Label>
                          {/* TODO: Add Dropdown list of PS to select Executor */}

                          <Form.Control as="select" name="moveInExecutorId" onChange={handleChange} onBlur={handleBlur} value={values.moveInExecutorId}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              propertySuperVisors && propertySuperVisors.map((psData =>
                                <option value={psData.id}>{psData.fullName}</option>
                              ))
                            }
                          </Form.Control>
                          <span className="text-danger my-2">
                            {errors.moveInExecutorId && touched.moveInExecutorId && errors.moveInExecutorId}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="moveInReportExecutionDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Execution Date'
                            dateFormat='MMMM d, yyyy'
                            name='moveInReportExecutionDate'
                            value={values.moveInReportExecutionDate}
                            onChange={setFieldValue}
                          />
                          {errors.moveInReportExecutionDate && touched.moveInReportExecutionDate && <Form.Text className="text-danger">
                            {errors.moveInReportExecutionDate}
                          </Form.Text>}
                        </Form.Group>



                      </Col>

                      <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                      <Col xs={12}>Add Comments</Col>
                      <Col xs={12}>
                        <FieldArray
                          name="moveInReportComments"
                          render={arrayHelpers => (
                            <div>
                              {values.moveInReportComments.map((item, index) =>
                              (
                                <Row key={index}>
                                  <Col xs={10}>
                                    <Form.Group controlId={"comment" + index}>
                                      <Form.Control type="text" name={`moveInReportComments[${index}]`} onChange={handleChange} onBlur={handleBlur} value={item} />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={2}>
                                    <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                  </Col>
                                </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Comment</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleChangeMoveInExecutionDetailsButtonClick}>
                          Cancel
                            </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>}
        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 9} show={this.state.showChangeMoveOutExecutionDetailsModal} onHide={this.handleChangeMoveOutExecutionDetailsButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Move-out Execution Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                moveOutExecutorId: (this.state.moveInReport && this.state.moveInReport.moveOutExecutor && this.state.moveInReport.moveOutExecutor.id) || undefined,
                moveOutReportComments: (this.state.moveInReport && this.state.moveInReport.moveOutReportComments) || [""],
                moveOutReportExecutionDate: (this.state.moveInReport && this.state.moveInReport.moveOutReportExecutionDate) || undefined,
              }}
              validate={values => {
                const errors = {};
                if (!values.moveOutExecutorId) {
                  errors.moveOutExecutorId = 'Executor is required';
                }
                if (!values.moveOutReportExecutionDate) {
                  errors.moveOutReportExecutionDate = 'Date is required';
                }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.moveOutExecutorId = parseInt(values.moveOutExecutorId, 10);
                  const result = await MoveInReportServices.changeExecutionDetails(this.state.leaseId, values);
                  if (result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Move-out Execution details recorded!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showChangeMoveOutExecutionDetailsModal: !state.showChangeMoveOutExecutionDetailsModal }));
                    this.getMoveInReportById(this.state.leaseId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="moveInExecutorId">
                          <Form.Label>Move-out Executor</Form.Label>
                          {/* TODO: Add Dropdown list of PS to select Executor */}

                          <Form.Control as="select" name="moveOutExecutorId" onChange={handleChange} onBlur={handleBlur} value={values.moveOutExecutorId}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              propertySuperVisors && propertySuperVisors.map((psData =>
                                <option value={psData.id}>{psData.fullName}</option>
                              ))
                            }
                          </Form.Control>
                          <span className="text-danger my-2">
                            {errors.moveOutExecutorId && touched.moveOutExecutorId && errors.moveOutExecutorId}
                          </span>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="moveOutReportExecutionDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Execution Date'
                            dateFormat='MMMM d, yyyy'
                            name='moveOutReportExecutionDate'
                            value={values.moveOutReportExecutionDate}
                            onChange={setFieldValue}
                          />
                          {errors.moveOutReportExecutionDate && touched.moveOutReportExecutionDate && <Form.Text className="text-danger">
                            {errors.moveOutReportExecutionDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <p>Move-in Comments</p>
                        {moveInReport && moveInReport.moveInReportComments && moveInReport.moveInReportComments.map((item, index) => (
                          <p>{item}</p>
                        ))}
                      </Col>
                      <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                      <Col xs={12}>Move-out Comments</Col>
                      <Col xs={12}>
                        <FieldArray
                          name="moveOutReportComments"
                          render={arrayHelpers => (
                            <div>
                              {values.moveOutReportComments && values.moveOutReportComments.map((item, index) =>
                              (
                                <Row key={index}>
                                  <Col xs={10}>
                                    <Form.Group controlId={"comment" + index}>
                                      <Form.Control type="text" name={`moveOutReportComments[${index}]`} onChange={handleChange} onBlur={handleBlur} value={item} />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={2}>
                                    <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                  </Col>
                                </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Comment</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleChangeMoveOutExecutionDetailsButtonClick}>
                          Cancel
                            </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>}
      </>
    );
  }

}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(MoveInMoveOutReport);
