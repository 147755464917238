import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { LeaseExtensionServices } from './LeaseExtensions.services';
import * as constants from '../../../constants';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { Link } from "react-router-dom";
import { Formik, FieldArray } from 'formik';
import { store } from 'react-notifications-component';
import { formatDate, formatWorks } from '../../../../_metronic/utils/utils';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import { commonServices } from '../../../../app/commonServices/commonServices';
import * as firebase from 'firebase/app';
import FilterComponent from '../../../partials/layout/FilterComponent';

export default class LeaseExtensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      showMarkTerminalStatusModal: false,
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.listLeaseExtensions();
    await this.getCities();
    await this.getPropertyManagers();
  }


  /**
   * Method to get all the cities
   */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
   * Method to get all the payments data
   */
  listLeaseExtensions = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, cityIds, propertyManagerId, maximumPreviousLeaseEndDate,
      houseId } = this.state;
    const data = {
      cityIds,
      houseId,
      maximumPreviousLeaseEndDate,
      state: 'OPEN',
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      include: ['TERMINATED_WORKS', 'NON_TERMINATED_WORKS'],
      offset,
      limit,
    }
    const result = await LeaseExtensionServices.listLeaseExtensions(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        leaseExtensions: result.data.leaseExtensions,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false })
  }


  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.listReports();
  }

  /**
   * Method to handle 'Initiate Move-out' button click
   */
  handleInitiateMoveOutButtonClick = async (leaseExtensionId = undefined) => {
    await this.setState(state => ({ showMarkTerminalStatusModal: !state.showMarkTerminalStatusModal, leaseExtensionId }));
  }

  /**
   * Method to handle data of filter component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      await this.setState({ ...childData });
      await this.resetPage();
      await this.listLeaseExtensions();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Lease Extensions' });
    }
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  render() {
    const { isFetching, leaseExtensions, page, total, totalCount,
      showMarkTerminalStatusModal, leaseExtensionId, formattedCities,
      preSelectedCities, propertyManagers, propertyManagerId, maximumPreviousLeaseEndDate,
      houseName, houseId
    } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Lease Extensions (${totalCount || 0})`}
            />
            <PortletBody>
              <FilterComponent
                propertyManagers={propertyManagers}
                propertyManagerId={propertyManagerId}
                formattedCities={formattedCities}
                preSelectedCities={preSelectedCities}
                maximumPreviousLeaseEndDate={maximumPreviousLeaseEndDate}
                houseName={houseName}
                houseId={houseId}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Card>
                {leaseExtensions && leaseExtensions.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {leaseExtensions && leaseExtensions.length > 0 && <Card.Header>
                  <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                    <Col md={3}><strong>Property</strong></Col>
                    <Col md={2}><strong>Previous Lease End Date</strong></Col>
                    <Col md={2}><strong>Terminated Works</strong></Col>
                    <Col md={2}><strong>Non Terminated Works</strong></Col>
                    <Col md={3}><strong>Actions </strong></Col>
                  </Row>
                </Card.Header>}
                <Card.Body className="p-0">
                  {leaseExtensions && leaseExtensions.map(leaseExtension =>
                    <>
                      <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                        <Col md={3} className="d-flex flex-row">
                          {leaseExtension.lease && leaseExtension.lease.houseOfferings && leaseExtension.lease.houseOfferings.house && <span>{leaseExtension.lease.houseOfferings.house.formattedHouseName} <br />
                            <span className="small text-muted">{leaseExtension.lease.houseOfferings.house.propertyManager ? `PM: ${(leaseExtension.lease.houseOfferings.house.propertyManager && leaseExtension.lease.houseOfferings.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                          </span>}
                        </Col>
                        <Col md={2}>{<span>{leaseExtension.previousLeaseEndDate ? formatDate(leaseExtension.previousLeaseEndDate) : 'Not Available'} </span>}</Col>
                        <Col md={2}>{formatWorks(leaseExtension.terminatedWorks)}</Col>
                        <Col md={2}>{formatWorks(leaseExtension.nonTerminatedWorks)}</Col>
                        <Col md={3}>
                          <>
                            <Button variant="link" className='mr-2' size="sm" onClick={() => this.handleInitiateMoveOutButtonClick(leaseExtension.id)}>Mark Terminal Status</Button>
                            <Link to={`/lease-profile/${leaseExtension.lease.id}`}>
                              <Button variant="link" className='mr-2' size="sm">View Lease</Button>
                            </Link>
                          </>
                        </Col>

                      </Row>
                      <hr className="my-1" />
                    </>
                  )
                  }
                </Card.Body>
              </Card>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key={2} show={showMarkTerminalStatusModal} onHide={() => this.handleInitiateMoveOutButtonClick()}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Mark Terminal Status`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                leaseExtension: {
                  terminalStatus: 'YES',
                  lease: {
                    leaseEndDate: undefined,
                    lockInEndDate: undefined,
                    rentPeriods: [
                      {
                        startDate: undefined,
                        endDate: undefined,
                        rent: undefined
                      }
                    ]
                  }
                }

              }}
              onSubmit={async (values) => {
                const result = await LeaseExtensionServices.markLeaseExtensionTerminalStatus(leaseExtensionId && leaseExtensionId, values);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: "Terminal Status Marked for Lease Extension",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showMarkTerminalStatusModal: !state.showMarkTerminalStatusModal }));
                  await this.getLeasesList();
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      <Col xs={6}>
                        <Form.Group controlId="leaseExtension.lease.leaseEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Lease End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='leaseExtension.lease.leaseEndDate'
                            value={values.leaseExtension.lease.leaseEndDate}
                            onChange={setFieldValue}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group controlId="leaseExtension.lease.lockInEndDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Lock-In End Date'
                            dateFormat='MMMM d, yyyy'
                            name='leaseExtension.lease.lockInEndDate'
                            value={values.leaseExtension.lease.lockInEndDate}
                            onChange={setFieldValue}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <h5>Rent Periods</h5>
                        <p>
                          <span className='text-danger'>*</span>
                          <span>Multiple rent periods can be added, atleast one rent period is required</span>
                        </p>
                      </Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs={4}>
                            <Form.Label>Start Date</Form.Label>
                          </Col>
                          <Col xs={4}>
                            <Form.Label>End Date</Form.Label>
                          </Col>
                          <Col xs={3}>
                            <Form.Label>Rent</Form.Label>
                          </Col>
                        </Row>
                        <FieldArray
                          name="leaseExtension.lease.rentPeriods"
                          render={arrayHelpers => (
                            <div>
                              {values.leaseExtension.lease.rentPeriods.map((rentPeriod, index) =>
                              (<Row key={index}>
                                <Col xs={4}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}startDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='MMMM d, yyyy'
                                      name={`leaseExtension.lease.rentPeriods[${index}].startDate`}
                                      value={rentPeriod.startDate}
                                      onChange={setFieldValue}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={4}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}endDate`}>
                                    <DatePickerField
                                      className='form-control'
                                      autoComplete='off'
                                      dateFormat='MMMM d, yyyy'
                                      name={`leaseExtension.lease.rentPeriods[${index}].endDate`}
                                      value={rentPeriod.endDate}
                                      onChange={setFieldValue}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col xs={3}>
                                  <Form.Group controlId={`leaseExtension.lease.rentPeriods${index}rent`}>
                                    <Form.Control type="number" name={`leaseExtension.lease.rentPeriods[${index}].rent`} onChange={handleChange} onBlur={handleBlur}
                                      value={rentPeriod.rent} />
                                  </Form.Group>
                                </Col>

                                {index > 0 && <Col xs={1}>
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>}
                              </Row>)
                              )}
                              <Button className='mb-3' variant="outline-primary" size="sm" onClick={() => arrayHelpers.push({ startDate: undefined, endDate: undefined, rent: undefined })}>Add Another Rent Period</Button>
                            </div>
                          )}
                        />
                      </Col>


                    </Row>
                    <Row>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleInitiateMoveOutButtonClick()}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

      </>
    )
  }

}

