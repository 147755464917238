import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

async function getAllTenantRentPaymentBills(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/tenantRentPaymentBills`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllTenantRentPaymentBills ', err);
  }

}

async function getRentPaymentBillAdjustments(data){
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/tenantRentPaymentBills/new?leaseId=${data}`);
    return  result;
  } catch (err) {
    console.log('error in getRentPaymentBillAdjustments' , err)
  }
}

async function updateRentPaymentBill(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/tenantRentPaymentBills/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in updateRentPaymentBill', err);
  }
}

async function getTenantRentPaymentBillsById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/tenantRentPaymentBills/${id}`);
    return result;
  } catch (err) {
    console.error('error in getTenantRentPaymentBillsById ', err);
  }
}

async function rentPaymentBillsMarkTerminalStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/tenantRentPaymentBills/${id}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in rentPaymentBillsMarkTerminalStatus ', err);
  }
}

async function markPaymentReceiptStatus(id, status) {
  try {
    const data = {
      "tenantRentPaymentConfirmation": {
        "paymentReceiptStatus": status
      }
    };
    const result = await axiosInstance.patch(`${baseApiUrl}admin/tenantRentPaymentConfirmations/${id}/markPaymentReceiptStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markPaymentReceiptStatus ', err);
  }
}

async function createRentPaymentBill(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/tenantRentPaymentBills`, data);
    return result;
  } catch (err) {
    console.error('error in createSettlement ', err);
  }
}

async function markDepositionStatus(chequeId, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/cheques/${chequeId}/markDepositionStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markDepositionStatus ', err);
  }
}

async function markChequeTerminalStatus(chequeId, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/cheques/${chequeId}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markChequeTerminalStatus ', err);
  }
}

async function deleteRentPaymentBill(id) {
  try {
      const result = await axiosInstance.delete(`${baseApiUrl}admin/tenantRentPaymentBills/${id}`);
      return result;
  } catch (err) {
      console.error('error in deleteRentPaymentBill ', err);
  }
}

export const rentPaymentBillsServices = {
  getAllTenantRentPaymentBills,
  getTenantRentPaymentBillsById,
  rentPaymentBillsMarkTerminalStatus,
  markPaymentReceiptStatus,
  createRentPaymentBill,
  updateRentPaymentBill,
  markDepositionStatus,
  markChequeTerminalStatus,
  getRentPaymentBillAdjustments,
  deleteRentPaymentBill
};
