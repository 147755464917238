import axiosInstance from "../myAxiosInstance";

const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;



/**
 * Service Method to get the user list
 * @param {Object} data 
 * Where it is used:
 * getUserListData():
 *  - rename from getRequestorData()
 *  - homepages, service request, inspection report, inspections, invoices, leaseProfilePage, moveInReport, Payments, RentPaymentBills,
 *  
 */
async function getUserListData(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/users`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getUserListDate ', err);
    }
}


/**
 * Service method to create payment
 * @param {Object} data 
 * Where it is used:
 *  - common to Payments, RentPaymentBills, AddPayment
 */
async function createPayment(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/payments`, data);
        return result;
    } catch (err) {
        console.error('error in createPayment ', err);
    }
}

/**
 * Service method to create Invoice
 * @param {Object} data
 * Where it is used:
 *  - common to Invoices, AddInvoice
 */
async function createInvoice(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/invoices`, data);
        return result;
    } catch (err) {
        console.error('error in createInvoice ', err);
    }
}


/**
 * Method to upload files
 * @param {Object} values
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function uploadFiles(values) {
    try {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        const result = await axiosInstance.post(`${baseApiUrl}admin/files/batchCreate`, values, headers);
        return result;
    } catch (err) {
        console.error('error in uploadFiles ', err);
    }
}


/**
 * Method to delete file by id
 * @param {Number} id
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function deleteFile(id) {
    try {
        const result = await axiosInstance.delete(`${baseApiUrl}admin/files/${id}`);
        return result;
    } catch (err) {
        console.error('error in deleteFile ', err);
    }
}

/**
 * Method to batch create report sections
 * @param {Object} values
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function createReportSections(values) {
    try {
        const data = {
            'reportSections': {
                ...values
            }
        }
        const result = await axiosInstance.post(`${baseApiUrl}admin/reportSections/batchCreate`, data);
        return result;
    } catch (err) {
        console.error('error in createReportSections ', err);
    }
}

/**
 * Method to update a report section
 * @param {Number} id
 * @param {Object} values
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function updateReportSection(id, values) {
    try {
        const data = {
            'reportSection':
                { ...values }
        }
        const result = await axiosInstance.patch(`${baseApiUrl}admin/reportSections/${id}`, data);
        return result;
    } catch (err) {
        console.error('error in updateReportSection ', err);
    }
}


/**
 * Method to delete a report section by id
 * @param {Number} id
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function deleteReportSection(id) {
    try {
        const result = await axiosInstance.delete(`${baseApiUrl}admin/reportSections/${id}`);
        return result;
    } catch (err) {
        console.error('error in deleteReportSection ', err);
    }
}

/**
 * Method to delete a report section item by id
 * @param {Number} id
 * Where it is used:
 *  - InspectionReportServices, MoveInReportServices
 */
async function deleteReportSectionItem(id) {
    try {
        const result = await axiosInstance.delete(`${baseApiUrl}admin/reportItems/${id}`);
        return result;
    } catch (err) {
        console.error('error in deleteReportSectionItem ', err);
    }
}

/**
 * Method to create user documents
 * @param {Object} values 
 * Where it is used:
 *  leaseProfilePage and PropertyProflePage
 */
async function createUserDocuments(values) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/documents`, values);
        return result;
    } catch (err) {
        console.error('error in createUserDocuments ', err);
    }

}


/**
 * Method to delete document
 * @param {Number} id 
 * Where it is used:
 *  leaseProfilePage and PropertyProflePage
 */
async function deleteDocument(id) {
    try {
        const result = await axiosInstance.delete(`${baseApiUrl}admin/documents/${id}`);
        return result;
    } catch (err) {
        console.error('error in deleteDocument ', err);
    }
}

/**
 * Method to get property data
 */
async function getPropertyData(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/houses`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getPropertyData ', err);
    }
}

/**
 * Method to assign service requests
 * @param {Object} data 
 */
async function adminAssignments(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/assignments`, data);
        return result;
    } catch (err) {
        console.error('error in adminAssignments ', err);
    }
}

/**
 * Method to create users
 * @param {Object} values
 */
async function createUser(values) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/users`, values);
        return result;
    } catch (err) {
        console.error('error in createUser ', err);
    }

}


/*
*Method to mark Bank Account verification
*
*/
async function verifyBankAccount(values){
    try{
        const result = await axiosInstance.post(`${baseApiUrl}admin/bankAccountVerificationRequests`, values);
        return result;
    } catch (err){
        console.log('error in createBankAccountVerification', err);
    }
}

/**
* Method to create bank account
* @param {Object} values
*/
async function createBankAccount(values) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/bankAccounts`, values);
        return result;
    } catch (err) {
        console.error('error in createBankAccount ', err);
    }

}

/**
 * Method to update bank account
 * @param {Object} values
 */
async function updateBankAccountLinkedAccount(id, values) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/bankAccounts/${id}/linkedAccount`, values);
        return result;
    } catch (err) {
        console.error('error in updateBankAccountLinkedAccount ', err);
    }

}

/**
 * Method to mark Primary bank account
 * @param {Object} values
 */
async function markPrimaryBankAccount(id) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/bankAccounts/${id}/markPrimary`);
        return result;
    } catch (err) {
        console.error('error in markPrimary ', err);
    }

}

/**
 * Method to create lease
 * @param {Object} values
 */
async function createLease(values) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/leases`, values);
        return result;
    } catch (err) {
        console.error('error in createLease ', err);
    }

}

/**
 * Service method to create house ownerships
 * @param {Object} data 
 * Where it is used:
 *  - common to Payments, RentPaymentBills, AddPayment
 */
async function createHouseOwnerships(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/houseOwnerships`, data);
        return result;
    } catch (err) {
        console.error('error in createHouseOwnerships ', err);
    }
}


/**
 * Service method to create lease tenant participations
 * @param {Object} data 
 * Where it is used:
 *  - common to Payments, RentPaymentBills, AddPayment
 */
async function createLeaseTenantParticipations(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/leaseTenantParticipations`, data);
        return result;
    } catch (err) {
        console.error('error in createLeaseTenantParticipations ', err);
    }
}


/**
 * Service method to get cities
 * @param {Object} data 
 */
async function getCities(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/cities`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getCities', err);
    }
}

/**
 * Helper method to format the cities
 */
function formatCities(rawCities) {
    return rawCities.map(city => {
        return {
            ...city,
            label: city.name,
            value: city.id
        };
    });
}

/**
 * Helper method to get the pre-selected cities
 */
function preSelectCities(formattedCities, cityIds) {
    // const { cityIds } = this.state;
    return formattedCities.filter((element, index) =>
        element.id === cityIds[index]
    );
}

/**
 * Method to create Settlement Account
 * @param {Number} id
 */
async function createSettlementAccount(id) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/bankAccounts/${id}/createSettlementAccount`);
        return result;
    } catch (err) {
        console.error('error in createSettlementAccount ', err);
    }

}

/**
 * Method ot mark onboarding status
 * @param {Number} id 
 * @param {Object} data 
 */
async function markOnboardingStatus(id, data) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/houseOfferings/${id}/markOnboardingStatus`, data);
        return result;
    } catch (err) {
        console.error('error in markOnboardingStatus ', err);
    }
}

/**
 * Method ot mark onboarding terminal status
 * @param {Number} id 
 * @param {Object} data 
 */
 async function markOnboardingTerminalStatus(id,data) {
    try {
  
      const result = await axiosInstance.patch(`${baseApiUrl}admin/houseOfferings/${id}/markOnboardingTerminalStatus`, data);
      return result;
    } catch (err) {
      console.error('error in markOnboardingTerminalStatus   ', err);
    }
  }


/**
 * Method to handle new transfer
 * @param {Object} data
 */
 async function getNewTransferBankAccountsAndPaymentAdjustments(data) {
    try {
      const result = await axiosInstance.get(`${baseApiUrl}admin/payments/transfers/new`, { params:data });
      return result;
    } catch (err) {
        console.error('error in getNewTransferBankAccountsAndPaymentAdjustments ', err);
    }
  
  }

  /**
 * Method to create transfer
 * @param {Object} data
 */
async function createTransferToOwner(data) {
    try {
      const result = await axiosInstance.post(`${baseApiUrl}admin/payments/transfers`, data);
      return result;
    } catch (err) {
      console.error('error in createTransferToOwner ', err);
    }
  }

export const commonServices = {
    getUserListData,
    createPayment,
    createInvoice,
    uploadFiles,
    deleteFile,
    createReportSections,
    updateReportSection,
    deleteReportSection,
    deleteReportSectionItem,
    createUserDocuments,
    deleteDocument,
    getPropertyData,
    adminAssignments,
    createBankAccount,
    updateBankAccountLinkedAccount,
    markPrimaryBankAccount,
    createUser,
    verifyBankAccount,
    createLease,
    createHouseOwnerships,
    createLeaseTenantParticipations,
    getCities,
    formatCities,
    preSelectCities,
    createSettlementAccount,
    markOnboardingStatus,
    markOnboardingTerminalStatus,
    getNewTransferBankAccountsAndPaymentAdjustments,
    createTransferToOwner
};