import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import { Formik } from 'formik';
import { formatDateTime, formatProperCase } from '../../../_metronic/utils/utils';
import * as Yup from 'yup'; // for everything
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
import * as constants from '../../constants';
import * as firebase from 'firebase/app';
import AdminAssignments from './AdminAssignments';

const addScheduleSchema = Yup.object().shape({
  startDatetime: Yup.date().required('Start Date Time is required').min(new Date(), 'Date Time should be of future, greater than ' + new Date().toLocaleString()),
  endDatetime: Yup.date().min(Yup.ref('startDatetime'), 'End Date Time should be greater than Start Date Time').required('End Date Time is required'),
  title: Yup.string().required('Title is required'),
  place: Yup.string().required('Place is required')
});

class SchedulesListComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showAddScheduleModal: false,
      // showDeleteScheduleModal: false,
      showRescheduleModal: false,
      showScheduleMarkTerminalStatusModal: false,
      showAssignScheduleModal: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    let isDifferent = false;

    if (props.leaseWorks?.id !== state.leaseWorks?.id) {
      result.leaseWorks.id = props.leaseWorks?.id;
    }

    (props.schedules.length === state.schedules.length) && props.schedules && props.schedules.forEach((sch, index) => {
      if (
        sch.startDatetime !== state.schedules[index].startDatetime ||
        sch.endDatetime !== state.schedules[index].endDatetime ||
        sch.executedAt !== state.schedules[index].executedAt ||
        sch.assignments?.length !== state.schedules[index].assignments?.length ||
        sch.assignments?.status !== state.schedules[index].status
      ) {
        isDifferent = true;
      }
    }
    )

    if (state.schedules.length !== props.schedules.length || isDifferent) {
      result.schedules = props.schedules
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  handleAddScheduleButtonClick = () => {
    this.setState(state => ({ showAddScheduleModal: !state.showAddScheduleModal }));
  }

  handleAdminAssignmentsData = async (isReassign, page) => {
    isReassign && (page === 'scheduleList') && this.state.serviceRequestId && await this.props.parentCallback(this.props.serviceRequestId);
    !isReassign && (page === 'scheduleList') && this.state.serviceRequestId && await this.props.parentCallback(this.props.serviceRequestId);
    isReassign && (page === 'scheduleList') && this.state.workId && await this.props.parentCallback(this.props.workId);
    !isReassign && (page === 'scheduleList') && this.state.workId && await this.props.parentCallback(this.props.workId);
    isReassign && (page === 'scheduleList') && this.state.preMoveInWorks && await this.props.parentCallback(
      (this.state.isDocumentation && 'DOCUMENTATION') ||
      (this.state.isFurnishing && 'FURNISHING') ||
      (this.state.isReport && 'MOVEINREPORT') ||
      (this.state.isReport && 'MOVEOUTREPORT')
    );
    !isReassign && (page === 'scheduleList') && this.state.preMoveInWorks && await this.props.parentCallback(
      (this.state.isDocumentation && 'DOCUMENTATION') ||
      (this.state.isFurnishing && 'FURNISHING') ||
      (this.state.isReport && 'MOVEINREPORT') ||
      (this.state.isReport && 'MOVEOUTREPORT')
    );
  }

  handleRescheduleButtonClick = async (id = null) => {
    if (id !== null) {
      const rescheduleElement = this.state.schedules.find(schedule => schedule.id === id);
      await this.setState({ toBeRescheduled: rescheduleElement });
    }
    this.setState(state => ({ showRescheduleModal: !state.showRescheduleModal }));

  }

  handleScheduleMarkTerminalStatusButtonClick = (id = null) => {
    if (id !== null) {
      this.setState({ idToBeTerminated: id })
    }
    this.setState(state => ({ showScheduleMarkTerminalStatusModal: !state.showScheduleMarkTerminalStatusModal }));
  }

  // handleDeleteScheduleButtonClick = (id = null) => {

  //   if (id !== null) {
  //     this.setState({ idToBeDeleted: id })
  //   }
  //   this.setState(state => ({ showDeleteScheduleModal: !state.showDeleteScheduleModal }));

  // }

  // handleConfirmDeleteScheduleButtonClick = async () => {
  //   if (this.state.idToBeDeleted) {
  //     const result = await ServiceRequestsServices.deleteSchedules(this.state.idToBeDeleted);
  //     if (result.status === 200) {
  //       store.addNotification({
  //         title: "Success!",
  //         message: `Schedule deleted!`,
  //         type: "success",
  //         insert: "top",
  //         container: "top-right",
  //         animationIn: ["animated", "fadeIn"],
  //         animationOut: ["animated", "fadeOut"],
  //         dismiss: {
  //           duration: 5000,
  //           onScreen: true,
  //           showIcon: true,
  //           pauseOnHover: true
  //         }
  //       });
  //       if (this.state.serviceRequestId) {
  //         this.props.parentCallback(this.props.serviceRequestId);
  //       } else if (this.state.isDocumentation) {
  //         this.props.parentCallback('DOCUMENTATION')
  //       } else if (this.state.isReport) {
  //         this.props.parentCallback(this.state.isReport)
  //       } else if (this.state.workId) {
  //         this.props.parentCallback(this.props.workId);
  //       }
  //       this.setState(state => ({ showDeleteScheduleModal: !state.showDeleteScheduleModal }));
  //     }
  //   }
  // }


  // handleConfirmExecuteScheduleButtonClick = async () => {
  //   if (this.state.idToBeExecuted) {
  //     const result = await ServiceRequestsServices.markExecuted(this.state.idToBeExecuted);
  //     if (result.status === 200) {
  //       store.addNotification({
  //         title: "Success!",
  //         message: `Schedule executed!`,
  //         type: "success",
  //         insert: "top",
  //         container: "top-right",
  //         animationIn: ["animated", "fadeIn"],
  //         animationOut: ["animated", "fadeOut"],
  //         dismiss: {
  //           duration: 5000,
  //           onScreen: true,
  //           showIcon: true,
  //           pauseOnHover: true
  //         }
  //       });
  //       if (this.state.serviceRequestId) {
  //         this.props.parentCallback(this.state.serviceRequestId);
  //       } else if (this.state.isDocumentation) {
  //         this.props.parentCallback('DOCUMENTATION')
  //       } else if (this.state.isFurnishing) {
  //         this.props.parentCallback('FURNISHING')
  //       } else if (this.state.isReport) {
  //         this.props.parentCallback(this.state.isReport)
  //       } else if (this.state.workId) {
  //         this.props.parentCallback(this.state.workId);
  //       }
  //       this.setState(state => ({ showExecuteScheduleModal: !state.showExecuteScheduleModal }));
  //     }
  //   }
  // }


  async componentDidMount() {

  }

  render() {
    const {
      allowAddSchedules, schedules, showAddScheduleModal, serviceRequestId,
      preMoveInWorks, workId, isDocumentation, isFurnishing, isReport, showRescheduleModal,
      toBeRescheduled, latestTenantAndOnwers, idToBeTerminated, showScheduleMarkTerminalStatusModal,
      // showDeleteScheduleModal, showExecuteScheduleModal,
      house, isMoveInReportPublished, isMoveOutReportPublished
    } = this.state;
    var typeOfWork = '';
    if(isDocumentation) { typeOfWork = 'Perform '+preMoveInWorks.name; }
    else if(isFurnishing) { typeOfWork = 'Get '+preMoveInWorks.name+' done'; }
    else if(isReport === 'MOVEINREPORT') {typeOfWork = 'Create Move-in Report';}
    else if(isReport === 'MOVEOUTREPORT') {typeOfWork = 'Create Move-out Report';}
    // TODO: Check for actual usecase for leaseExtension and update accordingly
    // else if(preMoveInWorks.leaseExtensionId !== null) {typeOfWork = 'Lease Extension Work';}
    return (
      <>
        <Row className="mb-4" key={0}>
          <Col xs={10}>
            <h5>Schedule</h5>
          </Col>
          <Col xs={2} className="text-right">
            {allowAddSchedules && !isReport && <Button variant="clean" size="sm" onClick={this.handleAddScheduleButtonClick}>Add Schedule</Button>}
            {allowAddSchedules && isReport === "MOVEINREPORT" && isMoveInReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddScheduleButtonClick}>Add Schedule</Button>}
            {allowAddSchedules && isReport === "MOVEOUTREPORT" && isMoveOutReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddScheduleButtonClick}>Add Schedule</Button>}
          </Col>
          {schedules.length === 0 && <Col className="text-center" xs={12}><p>No Schedules</p></Col>}
        </Row>
        {schedules && schedules.map(sch =>
          <Row className="mb-4 d-flex align-items-center" key={sch.id} >
            <Col xs={3} className="d-flex align-items-center">
              <h4 className="mb-0 mr-3">
                {sch.status === 'CREATED' && <i className="flaticon-star text-primary" title="Created"></i>}
                {sch.status === 'ASSIGNED' && <i className="flaticon2-user-1 text-primary" title="Assigned"></i>}
                {sch.status === 'ACCEPTED' && <i className="flaticon2-check-mark text-success" title="Accepted"></i>}
                {sch.status === 'REJECTED' && <i className="flaticon2-cancel text-danger" title="Rejected"></i>}
                {sch.status === 'EXECUTION_IN_PROGRESS' && <i className="flaticon2-hourglass-1 text-warning" title="Executing"></i>}
                {sch.status === 'CANCELLED' && <i className="flaticon2-exclamation text-danger" title="Cancelled"></i>}
                {sch.status === 'COMPLETED' && <i className="flaticon2-correct text-success" title="Completed"></i>}
              </h4>
              <span><strong>From</strong> {formatDateTime(sch.startDatetime)}<br /><strong>to</strong> {formatDateTime(sch.endDatetime)}</span>
            </Col>
            <Col xs={3}>
              <span><strong>Title:</strong> {sch.title}<br /><strong>Place:</strong> {sch.place}{sch.user && <span><br /><strong>With:</strong> {sch.user.fullName}</span>}</span>
            </Col>
            <Col xs={3}>
              {sch.assignments.length > 0 && sch.assignments.map((asg, index) => (
                <span><strong>Assigned to:</strong> {asg.assignee?.fullName} {asg.assigneeCategory === 'VENDOR' ? '(Vendor)' : '' } {asg.acknowledgementStatus === 'NO' && <i className="flaticon2-cancel" title={'Reject Reason: ' + asg.acknowledgementStatusRemarks}></i>}<br/></span>
              ))}
              {sch.assignments.length === 0 && <span>Not Assigned</span>}
            </Col>
            <Col xs={3} className="text-right">
              {
                allowAddSchedules && sch.terminalStatus === null &&
                <>
                  <AdminAssignments scheduleId={sch.id} isReassign={false} adminAssignmentsParent={this.handleAdminAssignmentsData} title={'Assign'} />
                  <Button key={'b' + 2} variant="clean" size="sm" onClick={() => this.handleRescheduleButtonClick(sch.id)}><i className="flaticon2-time"></i></Button>
                  <Button key={'b' + 3} variant="clean" size="sm" onClick={() => this.handleScheduleMarkTerminalStatusButtonClick(sch.id)}>Close</Button>
                  {/* <Button key={'b' + 3} variant="clean" size="sm" onClick={() => this.handleDeleteScheduleButtonClick(sch.id)}><i className="flaticon2-trash"></i></Button> */}
                </>
              }
            </Col>
            <Col xs={12}>
              {(sch.rescehdulesCount !== null && sch.reschedulesCount > 0) && <span className="small text-muted">Rescheduled {sch.reschedulesCount} {sch.reschedulesCount === 1 ? 'time' : 'times'}. Last Reason: {sch.latestRescheduleRemarksCategory} - {sch.latestRescheduleRemarks}</span>}
            </Col>
          </Row>
        )
        }

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={showAddScheduleModal} onHide={this.handleAddScheduleButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                serviceRequestId: serviceRequestId || undefined,
                workId: preMoveInWorks?.id || workId || undefined,
                title: workId ? 'Perform Inspection' : preMoveInWorks?.id ? typeOfWork : undefined,
                place: 'Property',
                cachedHouseId: house?.id || undefined,
                startDatetime: undefined,
                endDatetime: undefined,
                userId: undefined,
              }}
              validationSchema={addScheduleSchema}
              onSubmit={async (values) => {
                const data = {
                  "schedule": { ...values }
                }
                const result = await ServiceRequestsServices.addSchedules(data);
                if (result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: `Schedule added!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  if (serviceRequestId) {
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Service Requests' });
                    this.props.parentCallback(values.serviceRequestId);
                  } else if (isDocumentation) {
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Documentation in Lease Profile' });
                    this.props.parentCallback('DOCUMENTATION')
                  } else if (isFurnishing) {
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Furnishing in Lease Profile' });
                    this.props.parentCallback('FURNISHING')
                  } else if (isReport === 'MOVEINREPORT') {
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Move-in Work in Lease Profile' });
                    this.props.parentCallback('MOVEINREPORT')
                  } else if (isReport === 'MOVEOUTREPORT') {
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Move-out Work in Lease Profile' });
                    this.props.parentCallback('MOVEOUTREPORT')
                  } else if (workId) {
                    // For Inspections Work
                    firebase.analytics().logEvent("add_schedule_button_click", { description: "Add Schedule Button Clicked", addScheduleFrom: 'Inspections' });
                    this.props.parentCallback(values.workId);
                  }
                  this.setState(state => ({ showAddScheduleModal: !state.showAddScheduleModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="title">
                          <Form.Label>Title</Form.Label>
                          <Form.Control type="text" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                          {errors.title && touched.title && <Form.Text className="text-danger">
                            {errors.title}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="place">
                          <Form.Label>Place</Form.Label>
                          <Form.Control type="text" name="place" onChange={handleChange} onBlur={handleBlur} value={values.place} />
                          {errors.place && touched.place && <Form.Text className="text-danger">
                            {errors.place}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      {latestTenantAndOnwers && <Col xs={12}>
                        <Form.Group controlId="userId">
                          <Form.Label>Schedule With (optional)</Form.Label>
                          <Form.Control as="select" name="userId" onChange={handleChange} onBlur={handleBlur} value={values.tenantVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              latestTenantAndOnwers && latestTenantAndOnwers.map((tenantOwner =>
                                <option key={tenantOwner.id} value={tenantOwner.id}>{tenantOwner.fullName}
                                  {tenantOwner.userSubCategory && ' (' + formatProperCase(tenantOwner.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>}

                      <Col xs={12}>
                        <Form.Group controlId="startDatetime">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start time'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='startDatetime'
                            value={values.startDatetime}
                            onChange={setFieldValue}
                          />
                          {errors.startDatetime && touched.startDatetime && <Form.Text className="text-danger">{errors.startDatetime}</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="endDatetime">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='End time'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='endDatetime'
                            value={values.endDatetime}
                            onChange={setFieldValue}
                          />
                          {errors.endDatetime && touched.endDatetime && <Form.Text className="text-danger">{errors.endDatetime}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddScheduleButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={showRescheduleModal} onHide={this.handleRescheduleButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Reschedule Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                // serviceRequestId: serviceRequestId || undefined,
                // workId: preMoveInWorks?.id || workId || undefined,
                jobId: toBeRescheduled && toBeRescheduled.id,
                // place: "",
                startDatetime: toBeRescheduled && formatDateTime(toBeRescheduled.startDatetime),
                // endDatetime: toBeRescheduled && formatDateTime(toBeRescheduled.endDatetime),
                // title: toBeRescheduled?.title || undefined,
                // place: toBeRescheduled?.place || undefined,
                remarksCategory: undefined,
                remarks: undefined
              }}
              onSubmit={async (values) => {
                const result = await ServiceRequestsServices.reschedule(values);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: `Schedule rescheduled!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  if (serviceRequestId) {
                    this.props.parentCallback(serviceRequestId);
                  } else if (isDocumentation) {
                    this.props.parentCallback('DOCUMENTATION')
                  } else if (isFurnishing) {
                    this.props.parentCallback('FURNISHING')
                  } else if (isReport === 'MOVEINREPORT') {
                    this.props.parentCallback('MOVEINREPORT')
                  } else if (isReport === 'MOVEOUTREPORT') {
                    this.props.parentCallback('MOVEOUTREPORT')
                  } else if (workId) {
                    this.props.parentCallback(workId);
                  }
                  this.setState(state => ({ showRescheduleModal: !state.showRescheduleModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p className="mb-0"><strong>Title:</strong> {toBeRescheduled?.title}</p>
                        <p><strong>Place:</strong> {toBeRescheduled?.place}</p>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="startDatetime">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start Date &amp; Time'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='startDatetime'
                            value={values.startDatetime}
                            onChange={setFieldValue}
                          />
                          {errors.startDatetime && touched.startDatetime && errors.startDatetime}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <p>Note: End Date &amp; Time will be calculated based on previous duration and new Start Date &amp; Time.</p>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="remarksCategory">
                          <Form.Label>Reason Category for Rescheduling:</Form.Label>
                          {/* <Form.Control type="text" name="remarksCategory" required="required" onChange={handleChange} onBlur={handleBlur} value={values.remarksCategory} /> */}
                          <Form.Control as="select" name="remarksCategory" onChange={handleChange} onBlur={handleBlur} value={values.remarksCategory}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            <option key={'rrc1'} value="Vendor not available">Vendor not available</option>
                            <option key={'rrc2'} value="Customer not available">Customer not available</option>
                            <option key={'rrc3'} value="I'm occupied with another job">PS is occupied with another job</option>
                            <option key={'rrc4'} value="I'm not available due to personal reason">PS is not available due to personal reason</option>
                            <option key={'rrc5'} value="External factor beyond control">External factor beyond control</option>
                            <option key={'rrc6'} value="Other">Other</option>
                          </Form.Control>
                          {errors.remarksCategory && touched.remarksCategory && <Form.Text className="text-danger">
                            {errors.remarksCategory}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="remarks">
                          <Form.Label>Reason for Rescheduling:</Form.Label>
                          <Form.Control type="text" name="remarks" required="required" onChange={handleChange} onBlur={handleBlur} value={values.remarks} />
                          {errors.remarks && touched.remarks && <Form.Text className="text-danger">
                            {errors.remarks}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleRescheduleButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 3} show={showScheduleMarkTerminalStatusModal} onHide={this.handleScheduleMarkTerminalStatusButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Close Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                jobId: idToBeTerminated,
                terminalStatus: undefined,
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await ServiceRequestsServices.markScheduleTerminalStatus(values);
                  if(result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Schedule closed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    if (serviceRequestId) {
                      this.props.parentCallback(serviceRequestId);
                    } else if (isDocumentation) {
                      this.props.parentCallback('DOCUMENTATION')
                    } else if (isFurnishing) {
                      this.props.parentCallback('FURNISHING')
                    } else if (isReport === 'MOVEINREPORT') {
                      this.props.parentCallback('MOVEINREPORT')
                    } else if (isReport === 'MOVEOUTREPORT') {
                      this.props.parentCallback('MOVEOUTREPORT')
                    } else if (workId) {
                      this.props.parentCallback(workId);
                    }
                    this.setState(state => ({ showScheduleMarkTerminalStatusModal: !state.showScheduleMarkTerminalStatusModal}));
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Form.Group controlId="terminalStatusSelect">
                          <Form.Label>Schedule Terminal Status</Form.Label>
                          <Form.Control as="select" name="terminalStatus" onChange={handleChange} onBlur={handleBlur} value={values.terminalStatus}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            <option key="sts1" value="YES">Completed</option>
                            <option key="sts2" value="NO">Cancelled</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleScheduleMarkTerminalStatusButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        {/* <Modal backdrop="static" keyboard={false} key={'m' + 4} show={showDeleteScheduleModal} onHide={this.handleDeleteScheduleButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Schedule?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteScheduleButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteScheduleButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal> */}

      </>
    );
  }
}



export default SchedulesListComponent;
