import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST+process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get lease list
 * @param {Object} data 
 */
async function getLeaseListData(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leases`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getLeaseListData ', err);
  }

}

/**
 * Method to get house list
 * @param {Object} data
 */
async function getHouseListData(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houses`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getHouseListData ', err);
  }
}

/**
 * Method to get task list
 * @param {Obejct} data 
 * @returns tasklist from admin/tasks
 */
async function getTaskList(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/tasks`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getTaskList ', err);
  }
}

/**
 * Method to get the user data
 * @param {Object} data 
 */
async function getRequestorData(data) {
  try {

    const result = await axiosInstance.get(`${baseApiUrl}admin/users`, { params:data });
    return result;
  } catch (err) {
    console.error('error in getRequestorData ', err);
  }
}




export const HomePageServices = {
  getLeaseListData,
  getHouseListData,
  getTaskList,
  getRequestorData,
};