import axiosInstance from "../../../../app/myAxiosInstance";

const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all the service requests
 * @param {Object} data 
 */
async function getServiceRequestsData(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/serviceRequests`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getServiceRequestsData ', err);
  }

}

/**
 * Method to get service request by id
 * @param {Number} id 
 */
async function getServiceRequestById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/serviceRequests/${id}`);
    return result;
  } catch (err) {
    console.error('error in getServiceRequestById ', err);
  }

}

/**
 * Method to create service requests
 * @param {Object} data 
 */
async function createServiceRequests(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/serviceRequests`, data);
    return result;
  } catch (err) {
    console.error('error in createServiceRequests ', err);
  }
}

/**
 * Method to delete service request
 * @param {Number} id 
 */
async function deleteServiceRequests(id) {
  try {
    const result = await axiosInstance.delete(`${baseApiUrl}admin/serviceRequests/${id}`);
    return result;
  } catch (err) {
    console.error('error in deleteServiceRequests ', err);
  }
}

/**
 * Method to update service request
 * @param {Number} id 
 * @param {Object} data 
 */
async function updateServiceRequests(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/serviceRequests/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in updateServiceRequests ', err);
  }
}

/**
 * Method to close service request
 * @param {Number} id
 * @param {Object} formData
 */
async function closeServiceRequests(id, formData) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/serviceRequests/${id}/close`, formData);
    return result;
  } catch (err) {
    console.error('error in closeServiceRequests ', err);
  }
}


/**
 * Method to get service request timelint
 * @param {Number} id 
 */
async function getTimeline(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/serviceRequests/${id}/timeline`);
    return result;
  } catch (err) {
    console.error('error in getTimeline ', err);
  }
}

/**
 * Method to mark acceptance status of a service request
 * @param {Object} data 
 */
async function acceptanceStatus(data) {
  try {
    const requestData = {
      'acceptanceStatus': data.acceptanceStatus,
      'ownerVisibility': data.ownerVisibility,
      'tenantVisibility': data.tenantVisibility
    }
    const result = await axiosInstance.patch(`${baseApiUrl}admin/serviceRequests/${data.serviceRequestId}/markAcceptanceStatus`, requestData);
    return result;
  } catch (err) {
    console.error('error in acceptanceStatus ', err);
  }
}

/**
 * Method to add notes
 * @param {Object} data 
 */
async function addNotes(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/notes`, data);
    return result;
  } catch (err) {
    console.error('error in addNotes ', err);
  }
}


/**
 * Method to add estimates
 * @param {Object} data 
 */
async function addEstimates(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/costEstimates`, data);
    return result;
  } catch (err) {
    console.error('error in addEstimates ', err);
  }
}

/**
 * Method to delete estimates
 * @param {Number} id 
 */
async function deleteEstimates(id) {
  try {
    const result = await axiosInstance.delete(`${baseApiUrl}admin/costEstimates/${id}`);
    return result;
  } catch (err) {
    console.error('error in deleteEstimates ', err);
  }
}

/**
 * Method to add tasks
 * @param {Object} data 
 */
async function addTasks(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/tasks`, data);
    return result;
  } catch (err) {
    console.error('error in addTasks ', err);
  }
}

/**
 * Method to delete tasks
 * @param {Number} id 
 */
async function deleteTasks(id) {
  try {
    const result = await axiosInstance.delete(`${baseApiUrl}admin/tasks/${id}`);
    return result;
  } catch (err) {
    console.error('error in deleteTasks ', err);
  }
}

/**
 * Method to mark task as done
 * @param {Number} id 
 */
async function markTaskAsDone(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/tasks/${id}/complete`);
    return result;
  } catch (err) {
    console.error('error in markTaskAsDone ', err);
  }
}

/**
 * Method to reschedule tasks
 * @param {Object} data 
 */
async function editTasks(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/tasks/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in editTasks ', err);
  }
}

/**
 * Method to add schedules
 * @param {Object} data 
 */
async function addSchedules(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/schedules`, data);
    return result;
  } catch (err) {
    console.error('error in addSchedules ', err);
  }
}

/**
 * Method to delete schedules
 * @param {Number} id 
 */
async function deleteSchedules(id) {
  try {
    const result = await axiosInstance.delete(`${baseApiUrl}admin/schedules/${id}`);
    return result;
  } catch (err) {
    console.error('error in deleteSchedules ', err);
  }
}

/**
 * Method to reschdule a schedule
 * @param {Object} data 
 */
async function reschedule(values) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/jobs/reschedule`, values);
    return result;
  } catch (err) {
    console.error('error in reschedule ', err);
  }
}

/**
 * Method to mark schedule as executed
 * @param {Nubmer} id 
 */
async function markExecuted(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/schedules/${id}/markExecuted`);
    return result;
  } catch (err) {
    console.error('error in markExecuted ', err);
  }
}

/**
 * Method to mark schedule terminal status
 * @param {Nubmer} id
 */
async function markScheduleTerminalStatus(values) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/jobs/markTerminalStatus`, values);
    return result;
  } catch (err) {
    console.error('error in markScheduleTerminalStatus ', err);
  }
}

export const ServiceRequestsServices = {
  getServiceRequestsData,
  getServiceRequestById,
  createServiceRequests,
  deleteServiceRequests,
  updateServiceRequests,
  closeServiceRequests,
  getTimeline,
  acceptanceStatus,
  addNotes,
  addEstimates,
  deleteEstimates,
  addTasks,
  deleteTasks,
  markTaskAsDone,
  editTasks,
  addSchedules,
  deleteSchedules,
  reschedule,
  markExecuted,
  markScheduleTerminalStatus
};
