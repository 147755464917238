import React, { Component } from 'react';
import { Row, Col, Button, Modal, Card, Badge, Accordion, Form } from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import * as constants from '../../constants';
import { formatAmount, formatProperCase, formatDateTime } from '../../../_metronic/utils/utils';
import AddInvoiceComponent from './AddInvoiceComponent';
import * as Yup from 'yup'; // for everything
import { store } from 'react-notifications-component';
import * as firebase from 'firebase/app';
import { commonServices } from '../../commonServices/commonServices';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import AdditionalPaymentDetails from './AdditionalPaymentDetails';

const addEstimateSchema = Yup.object().shape({
  // vendorName: Yup.string().required('Vendor Name is required'),
  approverId: Yup.number().moreThan(0, 'Please select Approver'),
  costEstimateItems: Yup.array().of(Yup.object().shape({
    name: Yup.string().required('Item name is a required field'),
    amount: Yup.number().min(1, 'The amount must be greater than 0')
  })).min(1, 'There should be at least one Estimate Item'),
});

class EstimatesComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      // currency: props?.estimates[0]?.currency,
      showAddEstimateModal: false,
      showDeleteEstimateModal: false,
      isLoading: false,
      options: [],
      query: '',
      offset: 0,
      limit: 50,
    };
  }
  cache = {};

  static getDerivedStateFromProps(props, state) {
    let result = {}
    let isDifferent = false;

    (state.estimates.length === props.estimates.length) && props.estimates && props.estimates.forEach((estimate, index) => {
      if (
        (estimate.invoices && estimate.invoices.length !== 0) ||
        (estimate.payments && estimate.payments.length !== 0)
      ) {
        isDifferent = true;
      }
    }
    )
    if (isDifferent) {
      result.estimates = props.estimates
    }

    if ((props.preMoveInWorksId !== state.preMoveInWorksId)) {
      result.preMoveInWorksId = props.preMoveInWorksId;
    }

    if (!state.approverData || ((state.approverData && state.approverData.length) !== (props.approverData && props.approverData.length))) {
      result.approverData = props.approverData;
    }

    if (state.estimates.length !== props.estimates.length) {
      // this.setState({ currency: props?.estimates[0]?.currency })
      result.estimates = props.estimates
      const total = EstimatesComponent.calculateTotal(props.estimates);
      result.totalAmount = total;
    }

    return Object.keys(result).length > 0 ? result : null;
  }

  async componentDidMount() {
    const total = EstimatesComponent.calculateTotal(this.state.estimates);
    await this.setState({ totalAmount: total })
  }

  handleInputChange = query => {
    this.setState({ query });
  };

  handlePagination = async (e, shownResults) => {
    const { query } = this.state;
    const cachedQuery = this.cache[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults ||
      cachedQuery.options.length === cachedQuery.total_count
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const page = cachedQuery.page + 1;

    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });

    const response = await this.getAllVendors();
    const options = cachedQuery.options.concat(response.options);
    this.cache[query] = { ...cachedQuery, options, page, offset };
    this.setState({
      isLoading: false,
      options,
    });
  };


  handleSearch = async (query) => {
    if (this.cache[query]) {
      this.setState({ options: this.cache[query].options });
      return;
    }
    this.setState({ isLoading: true });
    const response = await this.getAllVendors();
    this.cache[query] = { ...response, page: 1 };
    this.setState({
      isLoading: false,
      options: response && response.options,
    });
  };

  getAllVendors = async () => {
    this.setState({ isLoading: true });
    const { offset, limit, query } = this.state;
    const data = {
      searchQuery: query,
      isVendor: 'TRUE',
      offset,
      limit
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      const total_count = result.headers['x-total-count'];
      const options = result.data.users.map(userData => ({
        id: userData.id,
        vendor: userData.fullName,
      }));
      return { options, total_count };
    }
    this.setState({ isLoading: false });
  }

  static calculateTotal = (estimates) => {
    let total = {};
    estimates.forEach((est, index) => {
      let individualTotal = 0;
      let estId = est.id;
      est.costEstimateItems.map(item =>
        individualTotal += item.amount
      );
      total[estId] = individualTotal;
      // total['currenty'] = est.currency
    }
    )
    return total;
  }

  handleAddEstimateButtonClick = () => {
    this.setState(state => ({ showAddEstimateModal: !state.showAddEstimateModal }));
  }

  handleDeleteEstimateButtonClick = (id = null) => {

    if (id !== null) {
      this.setState({ idToBeDeleted: id })
    }
    this.setState(state => ({ showDeleteEstimateModal: !state.showDeleteEstimateModal }));

  }

  handleConfirmDeleteEstimateButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await ServiceRequestsServices.deleteEstimates(this.state.idToBeDeleted);
      if (result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Estimate deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteEstimateModal: !state.showDeleteEstimateModal }));
        this.state.serviceRequestId && this.props.parentCallback(this.state.serviceRequestId);
        this.state.isFurnishing && this.props.parentCallback('FURNISHING')
      }
    }


  }

  getEstStatusBadge(approvalStatus) {
    if (approvalStatus === 'ACCEPTED') return <Badge variant="success" title="Accepted">A</Badge>
    if (approvalStatus === 'PENDING' || approvalStatus === null) return <Badge variant="warning" title="Pending">P</Badge>
    if (approvalStatus === 'REJECTED') return <Badge variant="danger" title="Rejected">R</Badge>
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleAddPaymentData = (childData) => {
    if (childData === 'LeaseProfilePage') {
      this.props.parentCallback('FURNISHING')
      firebase.analytics().logEvent("save_new_payment_button_click", { description: "Save New Payment Button Clicked", addPaymentFrom: 'Estimates in Furnishing' });
    }
    if (childData === 'ServiceRequests') {
      this.props.parentCallback(this.props.serviceRequestId)
      firebase.analytics().logEvent("save_new_payment_button_click", { description: "Save New Payment Button Clicked", addPaymentFrom: 'Estimates in Service Requests' });
    }

  }

  render() {
    return (
      <React.Fragment>
        <Row key={0} className="mb-4">
          <Col xs={10}>
            <h5>Estimates</h5>
          </Col>
          <Col xs={2} className="text-right">
            {this.state.allowAddEstimates && <Button variant="clean" size="sm" onClick={this.handleAddEstimateButtonClick}>Add Estimate</Button>}
          </Col>
          <Col xs={12}>
            <Accordion>
              {this.state.estimates.length === 0 && <Col className="text-center" xs={12}><p>No Estimates</p></Col>}
              {this.state.estimates.map(est =>
                <Card key={est.id}>
                  <Card.Header>
                    <div className="d-flex flex-row">
                      <Accordion.Toggle key={est.id} eventKey={est.id} className="flex-grow-1 d-flex flex-row py-2" style={{ backgroundColor: 'transparent', border: 0 }}>
                        <Col className="text-left" md={1}>{this.getEstStatusBadge(est.approvalStatus || 'PENDING')}</Col>
                        <Col className="text-left" md={5}>{est.vendorName}</Col>
                        <Col className="text-left" md={4}>{this.state.totalAmount && formatAmount(this.state.totalAmount[est.id], this.state.estimates[0]?.currency)}</Col>
                      </Accordion.Toggle>
                      <div className="text-right">
                        {
                          est.approvalStatus === 'ACCEPTED' &&
                          est.invoices && est.invoices.length === 0 &&
                          <AddInvoiceComponent isEstimateInvoice={est.id}
                            houseId={this.props.houseId}
                            approverData={this.props.approverData}
                            isLeaseProfile={this.props.isLeaseProfile}
                            estimatesComponentCallback={this.handleAddPaymentData}
                          />
                        }
                        {/* {this.state.allowAddEstimates && <Button variant="clean" size="sm" onClick={() => this.handleDeleteEstimateButtonClick(est.id)}><i className="flaticon2-trash"></i></Button>} */}
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey={est.id}>
                    <Card.Body>
                      {est.costEstimateItems.map(item =>
                        <Row key={item.id}>
                          <Col md={{ span: 5, offset: 1 }}>
                            <p>{item.name}</p>
                          </Col>
                          <Col md={2} className="text-right">
                            <p>{formatAmount(item.amount, item.currency)}</p>
                          </Col>
                        </Row>
                      )}
                      <Row key={"estTotalRow" + est.id}>
                        <Col md={{ span: 5, offset: 1 }}>
                          <p><strong>Total</strong></p>
                        </Col>
                        <Col md={2} className="text-right">
                          <p><strong>{this.state.totalAmount && formatAmount(this.state.totalAmount[est.id], this.state.estimates[0]?.currency)}</strong></p>
                        </Col>
                      </Row>
                      <Row key={"estAdvancePaymentAmountRow" + est.id}>
                        <Col md={{ span: 5, offset: 1 }}>
                          <p><strong>Advance Payment</strong></p>
                        </Col>
                        <Col md={2} className="text-right">
                          <p><strong>{formatAmount(est.advancePaymentAmount, est.currency)}</strong></p>
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col xs={12}>
                          <h5>Payments</h5>
                        </Col>
                        <Col xs={12}>
                          {(!est.payments || (est.payments && est.payments.length === 0)) && <Col className="text-center" xs={12}><p>No Payments</p></Col>}
                          {est.payments && est.payments.map(item =>
                            <Row key={item.id}>
                              <Col md={12} className="d-flex justify-content-between align-items-center">
                                <p>
                                  {item.direction} <strong>{formatAmount(item.amount, item.currency)}</strong> in {item.mode} on {formatDateTime(item.datetime)} {' (Ref:' + item.reference + ')'} from <strong>{item.user && item.user.fullName}</strong> {item.mode === 'ONLINE' && item.paymentGatewayProperties && (' : ' + (item.paymentGatewayProperties.description || item.paymentGatewayProperties.productinfo))}
                                </p>
                                <p>
                                  {item.paymentGatewayProperties && <AdditionalPaymentDetails paymentDetails={item.paymentGatewayProperties} />}
                                </p>
                              </Col>
                            </Row>
                          )
                          }
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col xs={12}>
                          <h5>Invoices</h5>
                        </Col>
                        <Col xs={12}>
                          {(!est.invoices || (est.invoices && est.invoices.length === 0)) && <Col className="text-center" xs={12}><p>No Invoices</p></Col>}
                          {est.invoices && est.invoices.map(item =>
                            <Row>
                              <Col md={5}>
                                <p>
                                  {item.raisedBy}:
                                      {formatDateTime(item.invoiceDate)} {' (' + formatAmount(item.totalAmount, item.currency) + ')'}
                                </p>
                              </Col>
                            </Row>
                          )
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
              }
            </Accordion>
          </Col>
        </Row >

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddEstimateModal} onHide={this.handleAddEstimateButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Estimate</Modal.Title>
          </Modal.Header>
          <Card className="mb-2" variant="secondary">
            <Card.Body>
              <h5><i className="flaticon2-warning"></i>&nbsp;Please Note</h5>
              <p className="mb-0">{`If the estimate is being created by Azuro, you do not need to fill Vendor Name. Please leave it blank!`}</p>
            </Card.Body>
          </Card>
          <Modal.Body>
            <Formik
              initialValues={{
                serviceRequestId: this.state.serviceRequestId ? this.state.serviceRequestId : undefined,
                workId: this.state.preMoveInWorksId || undefined,
                advancePaymentAmount: 0,
                raiserId: undefined,
                // vendorId: undefined,
                approverId: 0,
                costEstimateItems: [
                  {
                    name: "",
                    amount: 0
                  }
                ]
              }}
              validationSchema={addEstimateSchema}
              onSubmit={async (values) => {
                values.approverId = parseInt(values.approverId, 10);
                const result = await ServiceRequestsServices.addEstimates(values);
                if (result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: `Estimate created!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showAddEstimateModal: !state.showAddEstimateModal }));
                  if (this.state.serviceRequestId) {
                    firebase.analytics().logEvent("add estimate_button_click", { description: "Add Estimate Button Clicked", addEstimateFrom: 'Service Requests' });
                    this.props.parentCallback(values.serviceRequestId);
                  } else if (this.state.isFurnishing) {
                    firebase.analytics().logEvent("add estimate_button_click", { description: "Add Estimate Button Clicked", addEstimateFrom: 'Furnishing' });
                    this.props.parentCallback('FURNISHING')
                  }
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {/* <Col xs={12}>
                        <Form.Group controlId="vendorName">
                          <Form.Label>Vendor Name</Form.Label>
                          <Form.Control type="text" name="vendorName" required="required" onChange={handleChange} onBlur={handleBlur} value={values.vendorName} />
                          {errors.vendorName && touched.vendorName && <Form.Text className="text-danger">
                            {errors.vendorName}
                          </Form.Text>}
                        </Form.Group>
                      </Col> */}

                      <Col xs={12} className="mb-3">
                        <label>Vendor Name</label>
                        <AsyncTypeahead
                          // {...this.state}
                          name="raiserId"
                          isLoading={this.state.isLoading}
                          options={this.state.options}
                          query={this.state.query}
                          id="async-pagination-example"
                          labelKey="vendor"
                          maxResults={this.state.limit - 1}
                          minLength={3}
                          onChange={(selected) => {
                            const vendorId = (selected.length > 0) ? selected[0].id : undefined;
                            setFieldValue('raiserId', vendorId);
                          }}
                          onInputChange={this.handleInputChange}
                          onPaginate={this.handlePagination}
                          onSearch={this.handleSearch}
                          paginate
                          placeholder={"Search for vendor here..."}
                          renderMenuItemChildren={option => (
                            <div key={option.id}>
                              <span>{option.vendor}</span>
                            </div>
                          )}
                          useCache={false}
                        />
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="approverId">
                          <Form.Label>Approver</Form.Label>
                          <Form.Control as="select" name="approverId" onChange={handleChange} onBlur={handleBlur} value={values.approverId}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {/* <option value='1'>Manish Kumar (Owner)</option>
                                <option value='2'>Ajay Shastri (Tenant)</option>
                                <option value='3'>Rakesh Shastri (Tenant)</option> */}
                            {
                              this.state.approverData && this.state.approverData.map((approverData =>
                                <option key={approverData.id} value={approverData.id}>{approverData.fullName}
                                  {approverData.userSubCategory && ' (' + formatProperCase(approverData.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors.approverId && touched.approverId && <Form.Text className="text-danger">
                            {errors.approverId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={5}>
                        <Form.Label>Advance Payment</Form.Label>
                        <Form.Group controlId={`advancePaymentAmount`}>
                          <Form.Control type="number" min="0" name={`advancePaymentAmount`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.advancePaymentAmount} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>Add Details</Col>
                      <Col xs={12}>
                        <Row>
                          <Col xs={5}>
                            <Form.Label>Item Name</Form.Label>
                          </Col>
                          {/* <Col xs={3}>
                                  <Form.Label>Currency</Form.Label>
                                </Col> */}
                          <Col xs={5}>
                            <Form.Label>Amount</Form.Label>
                          </Col>
                        </Row>
                        <FieldArray
                          name="costEstimateItems"
                          render={arrayHelpers => (
                            <div>
                              {values.costEstimateItems.map((item, index) => (
                                <Row key={index}>
                                  <Col xs={5}>
                                    <Form.Group controlId={`costEstimateItems[${index}].name`}>
                                      <Form.Control type="text" name={`costEstimateItems[${index}].name`} onChange={handleChange} onBlur={handleBlur} value={item.name} />
                                      {errors.costEstimateItems && touched.costEstimateItems && errors.costEstimateItems[index] && touched.costEstimateItems[index] && <Form.Text className="text-danger">
                                        {errors.costEstimateItems[index].name && touched.costEstimateItems[index].name && errors.costEstimateItems[index].name}
                                      </Form.Text>}
                                    </Form.Group>
                                  </Col>
                                  <Col xs={5}>
                                    <Form.Group controlId={`costEstimateItems[${index}].name`}>
                                      <Form.Control type="number" min="0" name={`costEstimateItems[${index}].amount`} onChange={handleChange} onBlur={handleBlur} value={item.amount} />
                                      {errors.costEstimateItems && touched.costEstimateItems && errors.costEstimateItems[index] && touched.costEstimateItems[index] && <Form.Text className="text-danger">
                                        {errors.costEstimateItems[index].amount && touched.costEstimateItems[index].amount && errors.costEstimateItems[index].amount}
                                      </Form.Text>}
                                    </Form.Group>
                                  </Col>
                                  <Col xs={2}>
                                    <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                  </Col>
                                </Row>)
                              )}
                              {typeof errors.costEstimateItems === 'string' ? <p className="text-danger mb-2">{errors.costEstimateItems}</p> : null}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({ name: '', amount: 0 })}>Add Item</Button>
                            </div>
                          )}
                        />
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button className="mr-2" variant="secondary" onClick={this.handleAddEstimateButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showDeleteEstimateModal} onHide={this.handleDeleteEstimateButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Estimate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete all the list items in this estimate.</p>
            <p><strong>Confirm deletion of this Estimate?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteEstimateButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteEstimateButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}
export default EstimatesComponent;
