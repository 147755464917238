import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
/* import { FormattedMessage } from "react-intl"; */
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { Row, Col } from "react-bootstrap";
import { changePassword } from "../../crud/auth.crud";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const { intl } = this.props;
    // const { isRequested } = this.state;

    return (
      <Row>
        <Col xs={12} md={4}>
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                {/*<FormattedMessage id="AUTH.FORGOT.TITLE" />*/}
                Change Password
              </h3>
            </div>

            <Formik
              initialValues={{ oldPassword: "", newPassword: "", confirmNewPassword: "" }}
              validate={values => {
                const errors = {};

                if (!values.oldPassword) {
                  errors.oldPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                if (!values.newPassword) {
                  errors.newPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (values.newPassword.length < 6) {
                  errors.newPassword = "Password is too short. Minimum length required is 6 characters.";
                }

                if (!values.confirmNewPassword) {
                  errors.confirmNewPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                if (values.confirmNewPassword !== values.newPassword) {
                  errors.confirmNewPassword = "Passwords do not match. Please make sure to match the passwords";
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                const result = await changePassword(values);
                if (result.status === 201) {
                  setStatus(
                    { isSuccess: true, message: 'Password Changes successfully' }
                  )
                  // TODO: trigger logout after some time 
                  setTimeout(this.props.logout(), 5000);
                } else {
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage(
                      { id: "AUTH.VALIDATION.NOT_FOUND" },
                      { name: 'Change password was not successful!' }
                    )
                  );
                }

              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                  <form onSubmit={handleSubmit} className="kt-form">
                    {status && !status.isSuccess && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}
                    {status && status.isSuccess && (
                      <div role="alert" className="alert alert-success">
                        <div className="alert-text">{status.message}</div>
                      </div>
                    )}

                    <div className="form-group">
                      <TextField
                        type="password"
                        label="Current Password"
                        margin="normal"
                        fullWidth={false}
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.oldPassword}
                        helperText={touched.oldPassword && errors.oldPassword}
                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                      />
                      <TextField
                        type="password"
                        label="New Password"
                        margin="normal"
                        fullWidth={false}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        helperText={touched.newPassword && errors.newPassword}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                      />
                      <TextField
                        type="password"
                        label="Confirm New Password"
                        margin="normal"
                        fullWidth={false}
                        name="confirmNewPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmNewPassword}
                        helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                        error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                      />
                    </div>

                    <div className="kt-login__actions text-right">
                      <button
                        type="submit"
                        className="btn btn-primary btn-elevate kt-login__btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                    </button>
                    </div>
                  </form>
                )}
            </Formik>
          </div>
        </Col>
      </Row>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
