import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
/* import { FormattdMessage } from "react-intl"; */
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { verifyToken, resetPassword } from "../../crud/auth.crud";
import { Link, Redirect } from "react-router-dom";
// import { requestPassword } from "../../crud/auth.crud";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaseListData: [],
      houseListData: [],
      token: new URLSearchParams(this.props.location.search).get("prt"),
      tokenVerified: false,
      tokenMissing: false,
    };
  }

  async componentDidMount() {
    const data = { 'prt': this.state.token };
    if (data.prt === null) {
      this.setState({ tokenMissing: true });
    } else {
      const result = await verifyToken(data);
      if (result?.status === 200) {
        this.setState({ tokenVerified: true })
      }
    }

  }

  render() {
    const { intl } = this.props;
    // const { isRequested } = this.state;

    if (this.state.tokenMissing) {
      return <Redirect to="/login" />;
    }

    if (this.state.tokenVerified === false) {
      return (
        <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
          <div className="kt-login__body">
            <div className="kt-login__title text-center">
              <h3 className="mb-5">
                The token is incorrect or has expired.
              </h3>

              <h5>
                Click here if you have <Link
                  to="/auth/forgot-password"
                  className="kt-link kt-login__link-forgot"
                >
                  Forgot Password
                </Link> to request a new token.
              </h5>
            </div>
          </div>
        </div>
      )
    }

    // if (isRequested) {
    //   return <Redirect to="/auth" />;
    // }

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                {/*<FormattedMessage id="AUTH.FORGOT.TITLE" />*/}
                Reset Your Password
              </h3>
            </div>

            <Formik
              initialValues={{ prt: this.state.token, newPassword: "", confirmNewPassword: "" }}
              validate={values => {
                const errors = {};
                if (!values.newPassword) {
                  errors.newPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (values.newPassword.length < 6) {
                  errors.newPassword = "Password is too short. Minimum length required is 6 characters.";
                }


                if (!values.confirmNewPassword) {
                  errors.confirmNewPassword = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                if (values.confirmNewPassword !== values.newPassword) {
                  errors.confirmNewPassword = "Passwords do not match. Please make sure to match the passwords";
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                // TODO: add reset password call function and show confirmation message with link to login page.
                const result = await resetPassword(values);
                if (result?.status === 201 || result?.status === 200) {
                  // this.setState({ isRequested: true });
                  setStatus(
                    { isSuccess: true, message: `Password has been successfully reset` }
                  )
                  values.newPassword = '';
                  values.confirmNewPassword = '';
                } else {
                  setSubmitting(false);
                  setStatus(
                    intl.formatMessage(
                      { id: "AUTH.VALIDATION.NOT_FOUND" },
                      { name: 'Reset unsuccessful!' }
                    )
                  );
                }
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                  <form onSubmit={handleSubmit} className="kt-form">
                    {status && !status.isSuccess &&  (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    {status && status.isSuccess && (
                      <div role="alert" className="alert alert-success">
                        <div className="alert-text">{status.message}</div>
                      </div>
                    )}

                    <div className="form-group">
                      <TextField
                        type="password"
                        label="New Password"
                        margin="normal"
                        fullWidth={true}
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        helperText={touched.newPassword && errors.newPassword}
                        error={Boolean(touched.newPassword && errors.newPassword)}
                      />
                      <TextField
                        type="password"
                        label="Confirm New Password"
                        margin="normal"
                        fullWidth={true}
                        name="confirmNewPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmNewPassword}
                        helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                        error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                      />
                    </div>

                    <div className="kt-login__actions">
                      <button
                        type="submit"
                        className="btn btn-primary btn-elevate kt-login__btn-primary"
                        disabled={isSubmitting}
                      >
                        Submit
                    </button>
                    </div>
                  </form>
                )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
