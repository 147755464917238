import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import * as constants from '../../constants';
import { Formik } from 'formik';
import { commonServices } from '../../../app/commonServices/commonServices';
import { store } from 'react-notifications-component';

// const markOnboardingTerminalStatusSchema = Yup.object().shape({
//     houseOffering: Yup.object().shape({
//         onboardingTerminalStatus: Yup.string().required('Onboarding Terminal status is a required field')
//     })
// });

class CloseOnboarding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isLoading: false,
            showMarkOnboardingTerminalStatusModal: false,
        }
    }

    

    /**
     * Method to handle Mark Onboarding Terminal Status button click
     * @param {Object} currentHouseOffering
     */
     handleMarkOnboardingTerminalStatusButtonClick = async () => {
        await this.setState(state => ({ showMarkOnboardingTerminalStatusModal: !state.showMarkOnboardingTerminalStatusModal }));
    }

    render() {
        const {showMarkOnboardingTerminalStatusModal} = this.state;
        return (
            <>
                <Button variant={this.props.dropdownItem ? "dropdown-item" : "link"} size={this.props.dropdownItem ? "" : "sm"} className={this.props.dropdownItem ? "dropdown-item" : ""} onClick={() => this.handleMarkOnboardingTerminalStatusButtonClick()}>Close Onboarding</Button>

                <Modal backdrop="static" keyboard={false} key={'closeOnboarding' + 1} show={showMarkOnboardingTerminalStatusModal} onHide={this.handleMarkOnboardingTerminalStatusButtonClick} >
                    <Modal.Header closeButton>
                        <Modal.Title>Close Onboarding</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                houseOffering: {
                                    onboardingTerminalStatus: null,
                                    onboardingRequirementStatus: undefined
                                }
                            }}
                            // validationSchema={markOnboardingTerminalStatusSchema}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.markOnboardingTerminalStatus(this.props.houseOffering.id, values);
                                    if (result?.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Onboarding closed!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showMarkOnboardingTerminalStatusModal: !state.showMarkOnboardingTerminalStatusModal }));
                                        setSubmitting(false);          
                                        if(this.props.pendingOnboardingsCallback){
                                            this.props.pendingOnboardingsCallback();
                                        }else {
                                            this.props.unassignedOnboardingsCallback();
                                        }                  

                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group controlId="houseOffering.onboardingTerminalStatus">
                                                    <Form.Label>Select Onboarding Final Status</Form.Label>
                                                    <Form.Control as="select" name="houseOffering.onboardingTerminalStatus" onChange={handleChange} onBlur={handleBlur} value={values?.houseOffering?.onboardingTerminalStatus}>
                                                        <option key="ots0" value={null}>{constants.DEFAULT_OPTION}</option>
                                                        <option key="ots1" value="YES">Completed</option>
                                                        <option key="ots2" value="NO">Cancelled</option>
                                                    </Form.Control>
                                                    {/* {errors?.houseOffering?.onboardingStatusRemarks && touched?.houseOffering?.onboardingStatusRemarks && <Form.Text className="text-danger">
                                                            {errors?.houseOffering?.onboardingStatusRemarks}
                                                        </Form.Text>} */}
                                                </Form.Group>
                                            </Col>
                                            {values.houseOffering.onboardingTerminalStatus === 'NO' && <Col xs={12}>
                                                <Form.Group controlId="houseOffering.onboardingRequirementStatus">
                                                    <Form.Label>Was Onboarding Required?</Form.Label>
                                                    <Form.Control as="select" name="houseOffering.onboardingRequirementStatus" onChange={handleChange} onBlur={handleBlur} value={values?.houseOffering?.onboardingRequirementStatus}>
                                                        <option key="ors0" value={null}>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            constants.YES_NO.map((yesNo, index) =>
                                                                (<option key={'ors' + (index + 1)} value={yesNo}>{yesNo}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {/* {errors?.houseOffering?.onboardingStatusRemarks && touched?.houseOffering?.onboardingStatusRemarks && <Form.Text className="text-danger">
                                                            {errors?.houseOffering?.onboardingStatusRemarks}
                                                        </Form.Text>} */}
                                                </Form.Group>
                                            </Col>}

                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={this.handleMarkOnboardingTerminalStatusButtonClick}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
export default CloseOnboarding;