export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "Pre-Move-In",
      //   desc: "Some description here",
      //   root: true,
      //   alignment: "left",
      //   page: "pre-move-in",
      // },
      // {
      //   title: "Post-Move-In",
      //   desc: "Some description here",
      //   root: true,
      //   alignment: "left",
      //   page: "post-move-in",
      // },
      // {
      //   title: "PM Head",
      //   desc: "Some description here",
      //   root: true,
      //   alignment: "left",
      //   page: "pm-head",
      // },
      // {
      //   title: "Bootstrap",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   submenu: {
      //     type: "mega",
      //     width: "800px",
      //     alignment: "left",
      //     columns: [
      //       {
      //         items: [
      //           {
      //             title: "Alerts",
      //             page: "react-bootstrap/alert"
      //           },
      //           {
      //             title: "Accordion",
      //             page: "react-bootstrap/accordion"
      //           },
      //           {
      //             title: "Badge",
      //             page: "react-bootstrap/badge"
      //           },
      //           {
      //             title: "Breadcrumb",
      //             page: "react-bootstrap/breadcrumb"
      //           },
      //           {
      //             title: "Buttons",
      //             page: "react-bootstrap/buttons"
      //           },
      //           {
      //             title: "Button Group",
      //             page: "react-bootstrap/button-group"
      //           },
      //           {
      //             title: "Cards",
      //             page: "react-bootstrap/cards"
      //           }
      //         ]
      //       },
      //       {
      //         items: [
      //           {
      //             title: "Carousel",
      //             page: "react-bootstrap/carousel",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Dropdowns",
      //             page: "react-bootstrap/dropdowns",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Forms",
      //             page: "react-bootstrap/forms",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Input Group",
      //             page: "react-bootstrap/input-group",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Images",
      //             page: "react-bootstrap/images",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Figures",
      //             page: "react-bootstrap/figures",
      //             bullet: "line"
      //           },
      //           {
      //             title: "Jumbotron",
      //             page: "react-bootstrap/jumbotron",
      //             bullet: "line"
      //           }
      //         ]
      //       },
      //       {
      //         items: [
      //           {
      //             title: "List group",
      //             page: "react-bootstrap/list-group",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Modal",
      //             page: "react-bootstrap/modal",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Navs",
      //             page: "react-bootstrap/navs",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Navbar",
      //             page: "react-bootstrap/navbar",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Overlays",
      //             page: "react-bootstrap/overlays",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Pagination",
      //             page: "react-bootstrap/pagination",
      //             bullet: "dot"
      //           },
      //           {
      //             title: "Popovers",
      //             page: "react-bootstrap/popovers",
      //             bullet: "dot"
      //           }
      //         ]
      //       },
      //       {
      //         items: [
      //           {
      //             title: "Progress",
      //             page: "react-bootstrap/progress"
      //           },
      //           {
      //             title: "Spinners",
      //             page: "react-bootstrap/spinners"
      //           },
      //           {
      //             title: "Table",
      //             page: "react-bootstrap/table"
      //           },
      //           {
      //             title: "Tabs",
      //             page: "react-bootstrap/tabs"
      //           },
      //           {
      //             title: "Tooltips",
      //             page: "react-bootstrap/tooltips"
      //           },
      //           {
      //             title: "Toasts",
      //             page: "react-bootstrap/toasts"
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // },
      // {
      //   title: "Components",
      //   root: true,
      //   alignment: "left",
      //   toggle: "click",
      //   submenu: [
      //     {
      //       title: "React Bootstrap",
      //       bullet: "dot",
      //       icon: "flaticon-web",
      //       submenu: [
      //
      //
      //       ]
      //     }
      //   ]
      // },
    ]
  },
  aside: {
    self: {},
    items: [
      { section: "Dashboard" },
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-box-1",
        page: "dashboard",
      },
      {
        title: "My Properties",
        root: true,
        icon: "flaticon-black",
        page: "my-properties",
      },
      {
        title: "My Onboardings",
        root: true,
        icon: "flaticon-black",
        page: "pending-onboardings",
      },
      { section: "Daily Works" },
      {
        title: "Service Requests",
        root: true,
        icon: "flaticon-support",
        page: "service-requests",
      },
      {
        title: "Rent Payment Bills",
        root: true,
        icon: "flaticon-price-tag",
        page: "rent-payments",
      },
      {
        title: "PMS Renewals",
        root: true,
        icon: "flaticon2-shield",
        page: "pms-renewals",
      },
      {
        title: "Inspections",
        root: true,
        icon: "flaticon2-protection",
        page: "inspections",
      },
      {
        title: "Payments",
        root: true,
        icon: "flaticon-coins",
        page: "payments",
      },
      {
        title: "Invoices",
        root: true,
        icon: "flaticon-price-tag",
        page: "invoices",
      },
      {
        title: "Rent Escalations",
        root: true,
        icon: "flaticon-price-tag",
        page: "rent-escalations",
      },
      {
        title: "Rent Receipts",
        root: true,
        icon: "flaticon-notes",
        page: "rent-receipts",
      },
      { section: "Other Works" },
      {
        title: "Move-out Leases",
        root: true,
        icon: "flaticon2-warning",
        page: "lease-terminations",
      },
      {
        title: "Scheduled Jobs",
        root: true,
        icon: "flaticon-calendar",
        page: "scheduled-jobs",
      },
      {
        title: "Inspection Reports",
        root: true,
        icon: "flaticon2-protection",
        page: "inspection-reports",
      },
      {
        title: "PMS Subscriptions",
        root: true,
        icon: "flaticon2-shield",
        page: "pms-subscriptions",
      },
      {
        title: "Lease Extensions",
        root: true,
        icon: "flaticon2-paper",
        page: "lease-extensions",
      },
      {
        title: "Lease Terminations (Old)",
        root: true,
        icon: "flaticon2-warning",
        page: "lease-terminations-old",
      },
    ]
  }
};
