import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import * as constants from '../../constants';
import { Formik, FieldArray } from 'formik';
import { addDays } from 'date-fns';
import { commonServices } from '../../../app/commonServices/commonServices';
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
import * as firebase from 'firebase/app';
import * as Yup from 'yup'; // for everything

const addLeaseSchema = Yup.object().shape({
    lease: Yup.object().shape({
        // always required
        beatsListingIdentifier: Yup.number().required('Beats Listing ID is required'),
        houseId: Yup.number().required('House ID is required'),
        // these are always optional
        leaseType: Yup.string().optional(),
        tenantFoundByAzuro: Yup.string().optional(),
        tenantRentPaymentBillDurationLength: Yup.string().optional(),
        tenantRentPaymentMode: Yup.string().optional(),
        tenantType: Yup.string().optional(),
        // to determine moveInDate based validation
        movedInType: Yup.string().required(),
        expectedMoveInDate: Yup.date().when("movedInType", { is: "expectedMoveInDate", then: Yup.date().required('Expected Move-in date is required') }),
        isRentFlowing: Yup.string().when("movedInType", { is:"moveInDate", then: Yup.string().required('Rent Flowing through Azuro is required field') }),
        leaseEndDate: Yup.date().when("movedInType", { is:"moveInDate", then: Yup.date().required('End Date is required') }),
        leaseStartDate: Yup.date().when("movedInType", { is: "moveInDate", then: Yup.date().required('Start Date is required') }),
        lockInEndDate: Yup.date().when("movedInType", { is: "moveInDate", then: Yup.date().required('Lock in End Date is required') }),
        moveInDate: Yup.date().when("movedInType", { is: "moveInDate", then: Yup.date().required('Actual Move in date is required') }),
        noticePeriodQuantity: Yup.string().when("movedInType", { is: "moveInDate", then: Yup.string().required('Notice Period is required') }),
        noticePeriodUnit: Yup.string().when("movedInType", { is: "moveInDate", then: Yup.string().required('Notice Period Unit is required') }),
        rentPaymentTiming: Yup.string().when("movedInType", { is: "moveInDate", then: Yup.string().required('Pre Paid or Post Paid is required') }),
        rentPeriods: Yup.array().when("movedInType", { is: "moveInDate", then: Yup.array(
            Yup.object().shape({ startDate: Yup.date().required('Start Date is required'), endDate: Yup.date().required('End Date is required'), rent: Yup.number().required('Rent is required') })
        ) }),
        securityDepositType: Yup.string().when("movedInType", { is: "moveInDate", then: Yup.string().required('Deposit Scheme is required') }),
        tenantRentPaymentDay: Yup.string().when("movedInType", { is:"moveInDate", then: Yup.string().required('Rent Cycle is required') }),
        // these are sub-dependent on securityDepositType
        isSecurityDepositFlowing: Yup.string().when("securityDepositType", { is: 'PARTIAL' || 'FULL', then: Yup.string().required('Security Deposit Flowing through Azuro is required') }).nullable(),
        securityDeposit: Yup.number().when("securityDepositType", { is: 'PARTIAL' || 'FULL', then: Yup.number().required('Amount is required').moreThan(0,'Amount cannot be zero or negative') }).nullable(),
    }
    )
});


class AddLeaseComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAddLeaseModal: false,
            isLoading: false,
        }
    }

    async componentDidMount() {
        await this.getMoveInManagers();
    }

    getMoveInManagers = async () => {
        const data = {
            userRoleCodes: ['MOVE_IN_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ moveInManagers: result.data.users })
        }
    }

    handleAddLeaseButtonClick = async () => {
        this.setState(state => ({ showAddLeaseModal: !state.showAddLeaseModal }))
    }

    render() {
        const { moveInManagers } = this.state;
        return (
            <>
                <Button variant={this.props.dropdownItem ? "dropdown-item" : "link"} size={this.props.dropdownItem ? "" : "sm"} className={this.props.dropdownItem ? "dropdown-item" : ""} onClick={() => this.handleAddLeaseButtonClick()}>Add Lease</Button>

                <Modal size="lg" backdrop="static" keyboard={false} key={'addLease' + 1} show={this.state.showAddLeaseModal} onHide={this.handleAddLeaseButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Lease</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                lease: {
                                    houseId: this.props.houseId || this.props.houseIdFromPropertyProfile || null,
                                    beatsListingIdentifier: "",
                                    tenantFoundByAzuro: undefined,
                                    leaseStartDate: undefined,
                                    leaseEndDate: undefined,
                                    lockInEndDate: undefined,
                                    moveInDate: undefined,
                                    moveInTerminalStatus: undefined,
                                    isRentFlowing: true,
                                    securityDeposit: undefined,
                                    noticePeriodQuantity: 1,
                                    noticePeriodUnit: "MONTH",
                                    tenantRentPaymentDay: undefined,
                                    tenantRentPaymentMode: 'ONLINE',
                                    tenantRentPaymentBillDurationLength: 'MONTH',
                                    rentPeriods: [
                                        {
                                            startDate: undefined,
                                            endDate: undefined,
                                            rent: undefined,
                                        },
                                        // {<another rent period object>}
                                    ],
                                    assignment: undefined,
                                    securityDepositType: undefined,
                                    isSecurityDepositFlowing: undefined,
                                    expectedMoveInDate: undefined,
                                    leaseType: undefined,
                                    tenantType: undefined,
                                    rentPaymentTiming: undefined,
                                    movedInType: undefined,
                                }
                            }}
                            validationSchema={addLeaseSchema}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    values.lease && values.lease.tenantFoundByAzuro
                                        && (values.lease.tenantFoundByAzuro === 'true' ? (values.lease.tenantFoundByAzuro = true) : (values.lease.tenantFoundByAzuro === 'false' ? values.lease.tenantFoundByAzuro = false : values.lease.tenantFoundByAzuro = undefined))

                                    if (values.lease.isRentFlowing === 'true') {
                                        values.lease.isRentFlowing = true
                                    }
                                    else if (values.lease.isRentFlowing === 'false') {
                                        values.lease.isRentFlowing = false
                                    }

                                    values.lease && values.lease.isPmsFeeDeductedFromRentCollection
                                        && (values.lease.isPmsFeeDeductedFromRentCollection === 'true' ? (values.lease.isPmsFeeDeductedFromRentCollection = true) : (values.lease.isPmsFeeDeductedFromRentCollection === 'false' ? values.lease.isPmsFeeDeductedFromRentCollection = false : values.lease.isPmsFeeDeductedFromRentCollection = undefined));

                                    values.lease && values.lease.isSecurityDepositFlowing
                                        && (values.lease.isSecurityDepositFlowing === 'true' ? (values.lease.isSecurityDepositFlowing = true) : (values.lease.isSecurityDepositFlowing === 'false' ? values.lease.isSecurityDepositFlowing = false : values.lease.isSecurityDepositFlowing = undefined))
                                    if (values.lease && values.lease.securityDepositType === 'ZERO') {
                                        values.lease.securityDeposit = null;
                                        values.lease.isSecurityDepositFlowing = null;
                                    }

                                    if (this.props.houseId) {
                                        delete values.lease.houseOfferingsId;
                                    } else if (this.props.houseOfferingId) {
                                        delete values.lease.houseId;
                                    }
                                    const result = await commonServices.createLease(values);
                                    if (result && result.status === 201) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `New Lease created!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        if (this.props.houseIdFromPropertyProfile) {
                                            firebase.analytics().logEvent("save_new_lease_button_click", { description: "Save New Lease Button Clicked", addLeaseFrom: 'Property Profile' });
                                            this.props.propertyProfilePageCallback();
                                        } else {
                                            firebase.analytics().logEvent("save_new_lease_button_click", { description: "Save New Lease Button Clicked", addLeaseFrom: 'Assigned Pending Onboardings' });
                                            this.props.pendingOnboardingsCallback();
                                        }
                                        this.setState(state => ({ showAddLeaseModal: !state.showAddLeaseModal }));
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Lease Details</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.movedInType">
                                                    <Form.Label>Has Tenant Moved-in? <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control as="select" name="lease.movedInType" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.movedInType}>
                                                        {
                                                            <>
                                                                <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                <option value={'moveInDate'}>{'YES'}</option>
                                                                <option value={'expectedMoveInDate'}>{'NO'}</option>
                                                            </>
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {values.lease.movedInType === 'moveInDate' &&
                                                <Col xs={4}>
                                                    <Form.Group controlId="lease.moveInDate">
                                                        <DatePickerField
                                                            className='form-control'
                                                            autoComplete='off'
                                                            label='Actual Move-in Date'
                                                            dateFormat='MMMM d, yyyy'
                                                            name='lease.moveInDate'
                                                            isMandatory={true}
                                                            value={values.lease.moveInDate}
                                                            onChange={setFieldValue}
                                                            maxDate={addDays(new Date(), 1)}
                                                        />
                                                        <Form.Text className="text-muted small">Enter the date only when Move-in has happened, else leave it blank</Form.Text>
                                                        {errors.lease && errors.lease.moveInDate && touched.lease && touched.lease.moveInDate && <Form.Text className="text-danger">
                                                            {errors.lease.moveInDate}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>
                                            }
                                            {values.lease.movedInType === 'expectedMoveInDate' &&
                                                <Col xs={4}>
                                                    <Form.Group controlId="lease.expectedMoveInDate">
                                                        <DatePickerField
                                                            className="form-control"
                                                            autoComplete='off'
                                                            label='Expected Move-in Date'
                                                            dateFormat='MMMM d, yyyy'
                                                            name='lease.expectedMoveInDate'
                                                            isMandatory={true}
                                                            value={values.lease.expectedMoveInDate}
                                                            onChange={setFieldValue}
                                                            minDate={new Date()}
                                                        />
                                                        {errors.lease && errors.lease.expectedMoveInDate && touched.lease && touched.lease.expectedMoveInDate && <Form.Text className="text-danger">
                                                            {errors.lease.expectedMoveInDate}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>
                                            }
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.beatsListingIdentifier">
                                                    <Form.Label>Beats Listing ID <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" name="lease.beatsListingIdentifier" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.beatsListingIdentifier} />
                                                    {errors.lease && errors.lease.beatsListingIdentifier && touched.lease && touched.lease.beatsListingIdentifier && <Form.Text className="text-danger">
                                                        {errors.lease.beatsListingIdentifier}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.leaseStartDate">
                                                    <DatePickerField
                                                        className='form-control'
                                                        autoComplete='off'
                                                        label='Start Date'
                                                        isMandatory={values.lease.movedInType === 'moveInDate' && true}
                                                        dateFormat='MMMM d, yyyy'
                                                        name='lease.leaseStartDate'
                                                        value={values.lease.leaseStartDate}
                                                        onChange={setFieldValue}
                                                    />
                                                    {errors.lease && errors.lease.leaseStartDate && touched.lease && touched.lease.leaseStartDate && <Form.Text className="text-danger">
                                                        {errors.lease.leaseStartDate}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.leaseEndDate">
                                                    <DatePickerField
                                                        className='form-control'
                                                        autoComplete='off'
                                                        label='End Date'
                                                        isMandatory={values.lease.movedInType === 'moveInDate' && true}
                                                        dateFormat='MMMM d, yyyy'
                                                        name='lease.leaseEndDate'
                                                        value={values.lease.leaseEndDate}
                                                        onChange={setFieldValue}
                                                    />
                                                    {errors.lease && errors.lease.leaseEndDate && touched.lease && touched.lease.leaseEndDate && <Form.Text className="text-danger">
                                                        {errors.lease.leaseEndDate}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.lockInEndDate">
                                                    <DatePickerField
                                                        className='form-control'
                                                        autoComplete='off'
                                                        label='Lock-In End Date'
                                                        isMandatory={values.lease.movedInType === 'moveInDate' && true}
                                                        dateFormat='MMMM d, yyyy'
                                                        name='lease.lockInEndDate'
                                                        value={values.lease.lockInEndDate}
                                                        onChange={setFieldValue}
                                                    />
                                                    {errors.lease && errors.lease.lockInEndDate && touched.lease && touched.lease.lockInEndDate && <Form.Text className="text-danger">
                                                        {errors.lease.lockInEndDate}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.noticePeriodQuantity">
                                                    <Form.Label>Notice Period {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control type="text" name="lease.noticePeriodQuantity" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.noticePeriodQuantity} />
                                                    {errors.lease && errors.lease.noticePeriodQuantity && touched.lease && touched.lease.noticePeriodQuantity && <Form.Text className="text-danger">
                                                        {errors.lease.noticePeriodQuantity}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.noticePeriodUnit">
                                                    <Form.Label>Notice Period Unit {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control as="select" name="lease.noticePeriodUnit" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.noticePeriodUnit}>
                                                        {
                                                            constants.NOTICE_PERIOD_UNITS.map((unit, index) =>
                                                                (<option key={index} value={unit}>{unit}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.noticePeriodUnit && touched.lease && touched.lease.noticePeriodUnit && <Form.Text className="text-danger">
                                                        {errors.lease.noticePeriodUnit}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group controlId="lease.leaseType">
                                                    <Form.Label>Lease Type</Form.Label>
                                                    <Form.Control as="select" name="lease.leaseType" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.leaseType}>
                                                        <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            constants.LEASE_TYPE.map((lease, index) =>
                                                                (<option key={index} value={lease}>{lease}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.leaseType && touched.lease && touched.lease.leaseType && <Form.Text className="text-danger">
                                                        {errors.lease.leaseType}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group controlId="lease.tenantType">
                                                    <Form.Label>Tenant Type</Form.Label>
                                                    <Form.Control as="select" name="lease.tenantType" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.tenantType}>
                                                        <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            constants.TENANT_TYPE.map((tenant, index) =>
                                                                (<option key={index} value={tenant}>{tenant}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.tenantType && touched.lease && touched.lease.tenantType && <Form.Text className="text-danger">
                                                        {errors.lease.tenantType}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group key={`lease.tenantFoundByAzuro`} controlId={`lease.tenantFoundByAzuro`}>
                                                    <Form.Label>Tenant Found By Azuro?</Form.Label>
                                                    {
                                                        constants.YES_NO.map((yesNo, index) =>
                                                        (<Form.Check type="radio" key={`lease.tenantFoundByAzuro${index}`}>
                                                            <Form.Check.Input type="radio" onChange={handleChange}
                                                                name="lease.tenantFoundByAzuro"
                                                                value={yesNo === 'YES' ? true : false}
                                                                checked={
                                                                    (yesNo === 'YES' && (values.lease.tenantFoundByAzuro && values.lease.tenantFoundByAzuro.toString() === 'true')) ||
                                                                    (yesNo === 'NO' && (values.lease.tenantFoundByAzuro && values.lease.tenantFoundByAzuro.toString() === 'false'))
                                                                }
                                                            />
                                                            <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                                                        </Form.Check>)
                                                        )
                                                    }
                                                    {errors.lease && errors.lease.tenantFoundByAzuro && touched.lease && touched.lease.tenantFoundByAzuro && <Form.Text className="text-danger">
                                                        {errors.lease.tenantFoundByAzuro}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Rent Payment Details</h5>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.tenantRentPaymentDay">
                                                    <Form.Label>Rent Cycle {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control as="select" name="lease.tenantRentPaymentDay" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.tenantRentPaymentDay}>
                                                        <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                        <option value='1'>{'1 to Month-end'}</option>
                                                        {
                                                            constants.RENT_PAYMENT_DAY.map((rentPaymentDay, index) =>
                                                                (<option key={index} value={rentPaymentDay}> {rentPaymentDay + ' to ' + (rentPaymentDay - 1)}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.tenantRentPaymentDay && touched.lease && touched.lease.tenantRentPaymentDay && <Form.Text className="text-danger">
                                                        {errors.lease.tenantRentPaymentDay}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group controlId="lease.rentPaymentTiming">
                                                    <Form.Label>Payment Timing {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control as="select" name="lease.rentPaymentTiming" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.rentPaymentTiming}>
                                                        {
                                                            <>
                                                                <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                                <option value={'PRE'}>Pre-paid</option>
                                                                <option value={'POST'}>Post-paid</option>
                                                            </>
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.rentPaymentTiming && touched.lease && touched.lease.rentPaymentTiming && <Form.Text className="text-danger">
                                                        {errors.lease.rentPaymentTiming}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group key={`lease.isRentFlowing`} controlId={`lease.isRentFlowing`}>
                                                    <Form.Label>Rent Flowing through Azuro? {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    {
                                                        constants.YES_NO.map((yesNo, index) =>
                                                        (<Form.Check type="radio" key={`lease.isRentFlowing${index}`} >
                                                            <Form.Check.Input type="radio" onChange={handleChange}
                                                                name="lease.isRentFlowing"
                                                                value={yesNo === 'YES' ? true : false}
                                                                checked={
                                                                    (yesNo === 'YES' && (values.lease.isRentFlowing && values.lease.isRentFlowing.toString() === 'true')) ||
                                                                    (yesNo === 'NO' && (values.lease.isRentFlowing && values.lease.isRentFlowing.toString() === 'false'))
                                                                }
                                                            />
                                                            <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                                                        </Form.Check>)
                                                        )
                                                    }
                                                    {errors.lease && errors.lease.isRentFlowing && touched.lease && touched.lease.isRentFlowing && <Form.Text className="text-danger">
                                                        {errors.lease.isRentFlowing}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={4}>
                                                <Form.Group controlId="lease.tenantRentPaymentMode">
                                                    <Form.Label>Mode of Rent Payment {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control as="select" name="lease.tenantRentPaymentMode" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.tenantRentPaymentMode}>
                                                        <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            constants.PAYMENT_MODES.map((modes, index) =>
                                                                (<option key={index} value={modes}>{modes}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.tenantRentPaymentMode && touched.lease && touched.lease.tenantRentPaymentMode && <Form.Text className="text-danger">
                                                        {errors.lease.tenantRentPaymentMode}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.tenantRentPaymentBillDurationLength">
                                                    <Form.Label>Rent Payment Frequency {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    <Form.Control as="select" name="lease.tenantRentPaymentBillDurationLength" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.tenantRentPaymentBillDurationLength}>
                                                        {
                                                            <>
                                                                <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                                <option value={'MONTH'}>MONTHLY</option>
                                                                <option value={'QUARTER'}>QUARTERLY</option>
                                                            </>
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.tenantRentPaymentBillDurationLength && touched.lease && touched.lease.tenantRentPaymentBillDurationLength && <Form.Text className="text-danger">
                                                        {errors.lease.tenantRentPaymentBillDurationLength}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Rent Periods {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</h5>
                                                <p>Multiple rent periods can be added, atleast one rent period is required</p>
                                            </Col>
                                            <Col xs={12}>
                                                <Row>
                                                    <Col xs={4}>
                                                        <Form.Label>Start Date {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Label>End Date {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Label>Rent (in {this.props.currency}) {values.lease.movedInType === 'moveInDate' && <span className="text-danger">*</span>}</Form.Label>
                                                    </Col>
                                                </Row>
                                                <FieldArray
                                                    name="lease.rentPeriods"
                                                    render={arrayHelpers => (
                                                        <div>
                                                            {values.lease?.rentPeriods.map((rentPeriod, index) =>
                                                            (<Row key={index}>
                                                                <Col xs={4}>
                                                                    <Form.Group controlId={`lease.rentPeriods${index}startDate`}>
                                                                        <DatePickerField
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            dateFormat='MMMM d, yyyy'
                                                                            name={`lease.rentPeriods[${index}].startDate`}
                                                                            value={rentPeriod.startDate}
                                                                            onChange={setFieldValue}
                                                                            minDate={index === 0 ? new Date(values.lease.leaseStartDate) : addDays(new Date(values.lease.rentPeriods[index - 1].endDate), 1)}
                                                                            maxDate={index === 0 ? new Date(values.lease.leaseStartDate) : addDays(new Date(values.lease.rentPeriods[index - 1].endDate), 1)}
                                                                        />
                                                                        {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].startDate && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].startDate && <Form.Text className="text-danger">
                                                                            {errors.lease.rentPeriods[index].startDate}
                                                                        </Form.Text>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <Form.Group controlId={`lease.rentPeriods${index}endDate`}>
                                                                        <DatePickerField
                                                                            className='form-control'
                                                                            autoComplete='off'
                                                                            dateFormat='MMMM d, yyyy'
                                                                            name={`lease.rentPeriods[${index}].endDate`}
                                                                            value={rentPeriod.endDate}
                                                                            onChange={setFieldValue}
                                                                            minDate={new Date(values.lease.rentPeriods[index].startDate)}
                                                                            maxDate={new Date(values.lease.leaseEndDate)}
                                                                        />
                                                                        {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].endDate && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].endDate && <Form.Text className="text-danger">
                                                                            {errors.lease.rentPeriods[index].endDate}
                                                                        </Form.Text>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={3}>
                                                                    <Form.Group controlId={`lease.rentPeriods${index}rent`}>
                                                                        <Form.Control type="number" name={`lease.rentPeriods[${index}].rent`} onChange={handleChange} onBlur={handleBlur}
                                                                            value={rentPeriod.rent} />
                                                                        {errors.lease && errors.lease.rentPeriods && errors.lease.rentPeriods.length > 0 && errors.lease.rentPeriods[index].rent && touched.lease && touched.lease.rentPeriods && touched.lease.rentPeriods.length > 0 && touched.lease.rentPeriods[index].rent && <Form.Text className="text-danger">
                                                                            {errors.lease.rentPeriods[index].rent}
                                                                        </Form.Text>}
                                                                    </Form.Group>
                                                                </Col>

                                                                {index > 0 && <Col xs={1}>
                                                                    <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                                                </Col>}
                                                            </Row>)
                                                            )}
                                                            {values.lease.rentPeriods[values.lease.rentPeriods.length - 1].endDate !== values.lease.leaseEndDate &&
                                                                <Button className='mb-3' variant="outline-primary" size="sm" onClick={() => arrayHelpers.push({ startDate: undefined, endDate: undefined, rent: undefined })}>Add Another Rent Period</Button>
                                                            }
                                                        </div>
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Security Deposit</h5>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.securityDepositType">
                                                    <Form.Label>Deposit Scheme <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control as="select" name="lease.securityDepositType" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.lease.securityDepositType}>
                                                        {
                                                            <>
                                                                <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                <option value={'ZERO'}>{'ZERO'}</option>
                                                                <option value={'PARTIAL'}>{'PARTIAL'}</option>
                                                                <option value={'FULL'}>{'FULL'}</option>
                                                            </>
                                                        }
                                                    </Form.Control>
                                                    {errors.lease && errors.lease.securityDepositType && touched.lease && touched.lease.securityDepositType && <Form.Text className="text-danger">
                                                        {errors.lease.securityDepositType}
                                                    </Form.Text>}
                                                </Form.Group>
                                            </Col>

                                            {(values.lease.securityDepositType === 'PARTIAL' || values.lease.securityDepositType === 'FULL') &&
                                                <>
                                                    <Col xs={4}>
                                                        <Form.Group controlId="lease.securityDeposit">
                                                            <Form.Label>Amount <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="text" name="lease.securityDeposit" onChange={handleChange} onBlur={handleBlur}
                                                                value={values.lease.securityDeposit} />
                                                            {errors.lease && errors.lease.securityDeposit && touched.lease && touched.lease.securityDeposit && <Form.Text className="text-danger">
                                                        {errors.lease.securityDeposit}
                                                    </Form.Text>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Form.Group controlId="lease.isSecurityDepositFlowing">
                                                            <Form.Label>Flowing through Azuro <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control as="select" name="lease.isSecurityDepositFlowing" onChange={handleChange} onBlur={handleBlur}
                                                                value={values.lease.isSecurityDepositFlowing}>
                                                                {
                                                                    <>
                                                                        <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                        <option value={true}>{'YES'}</option>
                                                                        <option value={false}>{'NO'}</option>
                                                                    </>
                                                                }
                                                            </Form.Control>
                                                            {errors.lease && errors.lease.isSecurityDepositFlowing && touched.lease && touched.lease.isSecurityDepositFlowing && <Form.Text className="text-danger">
                                                        {errors.lease.isSecurityDepositFlowing}
                                                         </Form.Text>}
                                                     </Form.Group>
                                                    </Col>
                                                </>
                                            }

                                        </Row>
                                        <Row>
                                            <Col xs={12} className="mt-3">
                                                <h5>Assignment (Optional)</h5>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="lease.assignment.assigneeId">
                                                    <Form.Label>Move-in Manager </Form.Label>
                                                    {/* <Form.Control type="text" name="house.houseOffering.lease.assignment.assigneeId" onChange={handleChange} onBlur={handleBlur}
                                                                value={values.house.houseOffering.lease.assignment.assigneeId} /> */}

                                                    <Form.Control as="select" name="lease.assignment.assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.lease.assignment && values.lease.assignment.assigneeId}>
                                                        <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            moveInManagers && moveInManagers.map((mim =>
                                                                <option key={mim.id} value={mim.id}>{mim.fullName}</option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleAddLeaseButtonClick()}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default AddLeaseComponent;