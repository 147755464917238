import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Badge, Modal, Button } from 'react-bootstrap';
import { PaymentsServices } from './Payments.services';
import FilterComponent from '../../../partials/layout/FilterComponent';
import * as constants from '../../../constants';
import { formatDateTime, formatAmount, datePlusOneDay } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as firebase from 'firebase/app';
import AdditionalPaymentDetails from '../../../partials/layout/AdditionalPaymentDetails';
import { Link } from 'react-router-dom';
import { commonServices } from '../../../../app/commonServices/commonServices';

export default class Payments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showDeletePaymentModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      requestedFilters: [
        // 'mode',
        'direction',
        'purpose'
      ]
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.getAllPayments();
    await this.getCities();
    await this.getPropertyManagers();
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
    * Method to get all the cities
    */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get all the payments data
   */
  getAllPayments = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, direction, cityIds,
      minimumDatetime, maximumDatetime, propertyManagerId, purpose
      // mode
    } = this.state;
    let newMaximumDatetime = maximumDatetime && datePlusOneDay(maximumDatetime);
    const data = {
      cityIds,
      minimumDatetime,
      maximumDatetime: newMaximumDatetime,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId: houseIdFromPropertyProfile,
      direction: (direction && direction[0]) || undefined,
      paymentPurpose: purpose!== "0" ? purpose : undefined,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
      // mode,
    }
 
    const result = await PaymentsServices.getAllPayments(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        payments: result.data.payments,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false })
  }

  /**
   * Method to get the status badge
   * @param {*} payment_status 
   * @param {*} payment_direction 
   */
  getPaymentDirectionBadge(payment_direction ,creationMechanism) {
    if (creationMechanism === 'IMPLICIT') {
      if (payment_direction === 'DEBIT') return <Badge variant="secondary"><i className="flaticon2-arrow-up"></i></Badge>
      if (payment_direction === 'CREDIT') return <Badge variant="secondary"><i className="flaticon2-arrow-down"></i></Badge>
    }
    if (payment_direction === 'DEBIT') return <Badge variant="danger"><i className="flaticon2-arrow-up"></i></Badge>
    if (payment_direction === 'CREDIT') return <Badge variant="success"><i className="flaticon2-arrow-down"></i></Badge>
  }

  /**
   * Method to handle delete payment button click
   * @param {*} id
   */
  handleDeletePaymentButtonClick = async (id = null) => {
    if (id !== null) {
      this.setState({ idToBeDeleted: id })
    }
    this.setState(state => ({ showDeletePaymentModal: !state.showDeletePaymentModal }));
  }

  /**
   * Method to handle confirm of delete payment
   */
  handleConfirmDeletePaymentButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await PaymentsServices.deletePayment(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Payment deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeletePaymentModal: !state.showDeletePaymentModal }));
        await this.getAllPayments();
      }
    }
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllPayments();
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleAddPaymentData = async (childData) => {
    if (childData === 'PaymentsPage') {
      await this.getAllPayments();
      firebase.analytics().logEvent("save_new_payment_button_click", { description: "Save New Payment Button Clicked", addPaymentFrom: 'Payments' });
    }
  }


  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData })
      await this.resetPage();
      await this.getAllPayments();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Payments' });
    }
  }

  /**
   * Method to reset Pagination
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    })
  }


  render() {
    const { isFetching, payments = [], page, total, totalCount, houseIdFromPropertyProfile,
      requestedFilters, mode, direction, formattedCities, preSelectedCities, purpose,
      minimumDatetime, maximumDatetime, propertyManagers, propertyManagerId
    } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid key="c1">
          <Portlet fluidHeight={true}>
            <PortletHeader title={`Payments (${totalCount || 0})`} />
            <PortletBody>
              <FilterComponent
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                minimumDatetime={minimumDatetime}
                maximumDatetime={maximumDatetime}
                requestedFilters={requestedFilters}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
                mode={mode}
                purpose={purpose}
                direction={direction}
              />
              <Card>
                {payments && payments.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {payments && payments.length > 0 && <Card.Header>
                  <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                    {!houseIdFromPropertyProfile && <Col md={2}><strong>Property</strong></Col>}
                    <Col md={2}><strong>Title</strong></Col>
                    <Col md={2}><strong>Customer</strong></Col>
                    <Col md={houseIdFromPropertyProfile ? 2 : 1}><strong>Amount</strong></Col>
                    <Col md={houseIdFromPropertyProfile ? 2 : 1}><strong>Mode</strong></Col>
                    <Col md={3}><strong>Other Details</strong></Col>
                  </Row>
                </Card.Header>}
                <Card.Body className="p-0">
                  {payments && payments.map(payment =>
                    <React.Fragment key={payment.id}>
                      <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                        {!houseIdFromPropertyProfile && <Col md={2} className="d-flex flex-row">
                          <span className="pr-3">{this.getPaymentDirectionBadge(payment.direction, payment.creationMechanism)}</span>
                          {payment.house && <span>{payment.house.formattedHouseName} <br />
                            <span className="small text-muted">{payment.house.propertyManager ? `PM: ${(payment.house.propertyManager && payment.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                          </span>}
                        </Col>}
                        <Col md={2}>{houseIdFromPropertyProfile && <span className="pr-3">{this.getPaymentDirectionBadge(payment.direction,payment.creationMechanism)}</span>}{payment.title}</Col>
                        <Col md={2}>{payment.user && payment.user.fullName}</Col>
                        <Col md={houseIdFromPropertyProfile ? 2 : 1}>{formatAmount(payment.amount, payment.currency)}</Col>
                        <Col md={houseIdFromPropertyProfile ? 2 : 1}>{payment.mode}</Col>
                        <Col md={3} className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">{formatDateTime(payment.datetime)}<br /><span className="small text-muted">{payment.reference}</span></p>
                          {payment.paymentGatewayProperties && payment.bankAccount && <p className="mb-0"><AdditionalPaymentDetails paymentDetails={payment.paymentGatewayProperties} bankAccount={payment.bankAccount}/></p>}
                        </Col>
                        {/* <Col md={1}>
                          <Button variant="clean" size="sm" onClick={() => this.handleDeletePaymentButtonClick(payment.id)}><i className="flaticon2-trash"></i></Button>
                        </Col> */}
                        {payment.house && <Col md={1}>
                          <Link to={`/property-profile/${payment.house.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                          </Link>
                        </Col>}
                      </Row>
                      <hr className="my-1" />
                    </React.Fragment>
                  )
                  }
                </Card.Body>
              </Card>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key="m1" show={this.state.showDeletePaymentModal} onHide={this.handleDeletePaymentButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Payment?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeletePaymentButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeletePaymentButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}

