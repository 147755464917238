import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as constants from '../../constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { HouseOnboardingServices } from '../../pages/home/HouseOnboarding/HouseOnboarding.services';
import { store } from 'react-notifications-component';
class EditHouseDetailsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showEditHouseDetailsModal: false,
            isLoading: false,
            options: [],
            query: '',
            offset: 0,
            limit: 50,
            showAddSocietyModal: false,
            showAddLocalityModal: false,
            isSocietyLoading: false,
            societyOptions: [],
            societyQuery: '',
            societyOffset: 0,
            societyLimit: 50,
            isLocalityLoading: false,
            localityOptions: [],
            localityQuery: '',
            localityOffset: 0,
            localityLimit: 50,
            selectedHouse: {}
        }
    }
    societyCache = {};

    handleEditButtonClick = async (isApiCall = false) => {
        this.setState({ showEditHouseDetailsModal: !this.state.showEditHouseDetailsModal });
    }

    handleCustomChange = async (e, setFieldValue) => {
        if (e.target.validity.valid) {
            if (e.target.name === 'house.propertyType') {
                setFieldValue('house.bhk', undefined);
            }

        }
    };

    handleLocalityInputChange = localityQuery => {
        this.setState({ localityQuery });
    };

    handleLocalitySearch = async (localityQuery) => {
        if (this.localityCache[localityQuery]) {
            this.setState({ localityOptions: this.localityCache[localityQuery].options });
            return;
        }
        this.setState({ isLocalityLoading: true });
        const response = await this.getAllLocalities();
        this.localityCache[localityQuery] = { ...response, localityPage: 1 };
        this.setState({
            isLocalityLoading: false,
            localityOptions: response && response.options,
        });
    };

    handleSocietyInputChange = societyQuery => {
        this.setState({ societyQuery });
    };


    handleSocietySearch = async (societyQuery) => {
        if (this.societyCache[societyQuery]) {
            this.setState({ societyOptions: this.societyCache[societyQuery].options });
            return;
        }
        this.setState({ selectedCity: this.state.houseDetails ? this.state.houseDetails.society?.locality?.city : undefined })
        this.setState({ isSocietyLoading: true });
        const response = await this.getAllSocieties();
        this.societyCache[societyQuery] = { ...response, societyPage: 1 };
        this.setState({
            isSocietyLoading: false,
            societyOptions: response && response.options,
        });
    }

    getAllSocieties = async () => {
        this.setState({ isSocietyLoading: true });
        const {
            // societyOffset, societyLimit,
            societyQuery, selectedCity } = this.state;
        const data = {
            countryId: selectedCity?.country?.beatsDatabaseId,
            SearchText: societyQuery,
            cityId: selectedCity?.connectDatabaseId,
            // offset: societyOffset,
            // limit: societyLimit
        }
        const result = await HouseOnboardingServices.getAllSocietiesAndLocalitiesBeats(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const societiesToBeMapped = result.data.locationDetail;
            const options = societiesToBeMapped?.map(societyData => (
                societyData.type === 'Project' ? {
                    id: societyData.refId,
                    society: `${societyData.refName}`,
                    localityId: societyData.localityid,
                    localityName: `${societyData.localityName}`
                } : (societyData.type === 'Society' || societyData.type === 'Locality') ? {
                    id: societyData.localityId,
                    society: `${societyData.localityName}`,
                    localityId: societyData.localityId,
                    localityName: `${societyData.localityName}`,
                } : undefined
            ));
            return { options, total_count };
        }
        await this.setState({ isSocietyLoading: false });
    }

    getAllLocalities = async () => {
        this.setState({ isLocalityLoading: true });
        const {
            // localityOffset, localityLimit,
            localityQuery, selectedCity } = this.state;
        const data = {
            countryId: selectedCity?.country?.beatsDatabaseId,
            SearchText: localityQuery,
            cityId: selectedCity?.connectDatabaseId,
            // offset: localityOffset,
            // limit: localityLimit
        }
        const result = await HouseOnboardingServices.getAllSocietiesAndLocalitiesBeats(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const localitiesToBeMapped = result.data.locationDetail;
            const options = localitiesToBeMapped?.map(localityData => (
                localityData.type === 'Locality' ? {
                    id: localityData.localityId,
                    locality: `${localityData.localityName}`
                } : (localityData.type === 'Society' || localityData.type === 'Project') ? {
                    id: localityData.localityId,
                    society: `${localityData.localityName}`,
                    localityId: localityData.localityId,
                    localityName: `${localityData.localityName}`,
                } : undefined
            ));
            //TODO: Resolve issue of undefined also getting mapped unnecessarily. Scope for performance improvement.
            return { options, total_count };
        }
        await this.setState({ isLocalityLoading: false });
    }

    render() {
        const { houseDetails } = this.state;
        const { selectedCity } = this.state;
        return (
            <React.Fragment>
                <Button className='mr-2' variant="link" size="sm" style={{ 'right': '50px', 'position': 'absolute', 'top': '34px' }} onClick={() => this.handleEditButtonClick(true)}>Edit</Button>

                <Modal backdrop="static" keyboard={false} show={this.state.showEditHouseDetailsModal} onHide={() => this.handleEditButtonClick()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit House Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                house: {
                                    id: houseDetails ? houseDetails.id : undefined,
                                    societyId: houseDetails ? houseDetails.society?.id : undefined,
                                    societyName: houseDetails ? houseDetails.society?.name : undefined,
                                    buildingName: houseDetails ? houseDetails.buildingName : undefined,
                                    unitNumber: houseDetails ? houseDetails.unitNumber : undefined,
                                    propertyType: houseDetails ? houseDetails.propertyType : undefined,
                                    bhk: houseDetails ? houseDetails.bhk : undefined,
                                    cityId: houseDetails ? houseDetails.society?.locality?.city?.id : undefined,
                                    cityName: houseDetails ? houseDetails.society?.locality?.city?.name : undefined,
                                    selectedCity: houseDetails ? houseDetails.society?.locality?.city : undefined,
                                }
                            }}
                            onSubmit={async (values) => {
                                values.house.bhk = values.house.bhk === undefined ? this.state.houseDetails.bhk : values.house.bhk;
                                values.house.propertyType = values.house.propertyType === undefined ? this.state.houseDetails.propertyType : values.house.propertyType
                                const result = await HouseOnboardingServices.updateHouse(this.state.houseDetails.id, values.house);
                                if (result.status === 200) {
                                    store.addNotification({
                                        title: "Success!",
                                        message: `House Updated Successfully`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true,
                                            showIcon: true,
                                            pauseOnHover: true
                                        }
                                    });
                                    window.location = `/property-profile/${this.state.houseDetails.id}`;
                                }
                            }}
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group controlId="house.buildingName">
                                                    <Form.Label>Building/Wing/Tower  <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" name="house.buildingName" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.house.buildingName} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="house.unitNumber">
                                                    <Form.Label>Unit Number <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" name="house.unitNumber" onChange={handleChange} onBlur={handleBlur}
                                                        value={values.house.unitNumber} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="house.propertyType">
                                                    <Form.Label>Property Type <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control as="select" name="house.propertyType"
                                                        // onChange={handleChange}
                                                        onChange={
                                                            async (e) => {
                                                                handleChange(e);
                                                                await this.handleCustomChange(e, setFieldValue)
                                                            }
                                                        }
                                                        onBlur={handleBlur}
                                                        value={values.house.propertyType}>
                                                        <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            constants.PROPERTY_TYPES.map((propertyType, index) =>
                                                                (<option key={index} value={propertyType}>{propertyType}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="house.bhk">
                                                    <Form.Label>BHK <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control as="select" name="house.bhk"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.house.bhk}>
                                                        <option value={undefined}>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            values?.house?.propertyType === 'APARTMENT/CONDO' && constants.BHK_FOR_APARTMENT.map((bhksForApartment, index) =>
                                                                (<option key={index} value={bhksForApartment}>{bhksForApartment}</option>)
                                                            )
                                                        }
                                                        {
                                                            values?.house?.propertyType === 'INDEPENDENT HOUSE/VILLA' && constants.BHK_FOR_VILLA.map((bhksForVilla, index) =>
                                                                (<option key={index} value={bhksForVilla}>{bhksForVilla}</option>)
                                                            )
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="house.cityId">
                                                    <Form.Label>City <span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" name="house.cityId" readOnly
                                                        value={values.house.cityName} />
                                                </Form.Group>
                                            </Col>
                                            {selectedCity?.id === 449 && <Col xs={6} className='mb-3'>
                                                <label>Select Locality <span className="text-danger">*</span></label>
                                                <AsyncTypeahead
                                                    // {...this.state}
                                                    isLoading={this.state.isLocalityLoading}
                                                    options={this.state.localityOptions}
                                                    query={this.state.localityQuery}
                                                    id="async-pagination-example"
                                                    labelKey="locality"
                                                    disabled={values.house?.cityId === undefined || values.house?.cityId === 'None'}
                                                    onChange={(selected) => {
                                                        const identifier = (selected.length > 0) ? selected[0].id : undefined;
                                                        const name = (selected.length > 0) ? selected[0].locality : undefined;
                                                        setFieldValue('house.locality.identifier', identifier);
                                                        setFieldValue('house.locality.name', name);
                                                        this.setState({ selectedLocalityId: identifier })
                                                    }}
                                                    maxResults={this.state.localityLimit - 1}
                                                    minLength={3}
                                                    onInputChange={this.handleLocalityInputChange}
                                                    // onPaginate={this.handleLocalityPagination}
                                                    onSearch={this.handleLocalitySearch}
                                                    paginate
                                                    placeholder={values.house.society?.locality?.name ? values.house.society?.locality?.name : "Type to search Locality..."}
                                                    renderMenuItemChildren={option => (
                                                        <div key={option.id}>
                                                            <span>{option.locality}</span>
                                                        </div>
                                                    )}
                                                    useCache={false}
                                                />
                                            </Col>}
                                            <Col xs={selectedCity?.id === 449 ? 6 : 12} className='mb-3'>
                                                <label>Select Society <span className="text-danger">*</span></label>
                                                <AsyncTypeahead
                                                    // {...this.state}
                                                    isLoading={this.state.isSocietyLoading}
                                                    options={this.state.societyOptions}
                                                    query={this.state.societyQuery}
                                                    id="async-pagination-example"
                                                    labelKey="society"
                                                    value={values.house?.societyName}
                                                    disabled={values.house?.cityId === undefined || values.house?.cityId === 'None'}
                                                    onChange={(selected) => {
                                                        const societyId = (selected.length > 0) ? selected[0].id : undefined;
                                                        let localityOriginalName = (selected.length > 0) ? selected[0].localityName : undefined;
                                                        let societyName = (selected.length > 0) ? selected[0].society : undefined;
                                                        if (societyName) {
                                                            let societyNewName = societyName.substring(0, societyName.lastIndexOf(localityOriginalName) === -1 ? undefined : societyName.lastIndexOf(localityOriginalName)).trim()
                                                            societyName = societyNewName
                                                        }

                                                        setFieldValue('house.society.identifier', societyId);
                                                        setFieldValue('house.society.name', societyName);
                                                        if (selectedCity?.id !== 449) {
                                                            const localityId = (selected.length > 0) ? selected[0].localityId : undefined;
                                                            const localityName = (selected.length > 0) ? selected[0].localityName : undefined;
                                                            setFieldValue('house.locality.identifier', localityId);
                                                            setFieldValue('house.locality.name', localityName);
                                                            this.setState({ selectedLocalityId: localityId })
                                                        }
                                                    }}
                                                    maxResults={this.state.societyLimit - 1}
                                                    minLength={3}
                                                    onInputChange={this.handleSocietyInputChange}
                                                    // onPaginate={this.handleSocietyPagination}
                                                    onSearch={this.handleSocietySearch}
                                                    paginate
                                                    placeholder={values.house.societyName ? values.house?.societyName : "Type to search Society..."}
                                                    renderMenuItemChildren={option => (
                                                        <div key={option.id}>
                                                            <span>{option.society}</span>
                                                        </div>
                                                    )}
                                                    useCache={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default EditHouseDetailsComponent;