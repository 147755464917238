import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { store } from 'react-notifications-component';
import { commonServices } from '../../../app/commonServices/commonServices';

class ToggleHoldOnboarding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isFetching: false,
            page: 1,
            total: undefined,
            offset: 0,
            limit: 10,
            showToggleHoldStatusModal: false,
        }
    }

    /**
     * Method to handle Onboarding Status = HOLD button click
     */
    handleToggleHoldStatusButtonClick = () => {
        this.setState(state => ({ showToggleHoldStatusModal: !state.showToggleHoldStatusModal }));
    }

    render() {
        return (
            <>
                <Button variant={this.props.dropdownItem ? "dropdown-item" : "link"} size={this.props.dropdownItem ? "" : "sm"} className={this.props.dropdownItem ? "dropdown-item" : ""} onClick={() => this.handleToggleHoldStatusButtonClick()}>{this.props.houseOffering?.onboardingStatus === 'HOLD' ? 'Unhold Property' : 'Put on Hold'}</Button>
                <Modal backdrop="static" keyboard={false} key={'ToggleHold' + 1} show={this.state.showToggleHoldStatusModal} onHide={this.handleToggleHoldStatusButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.houseOffering?.onboardingStatus === 'HOLD' ? 'Unhold Property' : 'Put on Hold'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                houseOffering: {
                                    onboardingStatus: this.props.houseOffering?.onboardingStatus === 'HOLD' ? null : 'HOLD',
                                    onboardingStatusRemarks: undefined
                                }
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.markOnboardingStatus(this.props.houseOffering?.id, values);
                                    if (result?.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Onboarding Status updated!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        setSubmitting(false);
                                        this.setState(state => ({ showToggleHoldStatusModal: !state.showToggleHoldStatusModal }));
                                        if (this.props.pendingOnboardingsCallback) {
                                            this.props.pendingOnboardingsCallback();
                                        }else if (this.props.unassignedOnboardingsCallback){
                                            this.props.unassignedOnboardingsCallback();
                                        }
                                    }
                                }

                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group controlId="houseOffering.onboardingStatusRemarks">
                                                    <Form.Label>Onboarding Status Remarks</Form.Label>
                                                    <Form.Control name="houseOffering.onboardingStatusRemarks" type="text" onChange={handleChange} onBlur={handleBlur} value={values?.houseOffering?.onboardingStatusRemarks} />
                                                    {/* {errors?.houseOffering?.onboardingStatusRemarks && touched?.houseOffering?.onboardingStatusRemarks && <Form.Text className="text-danger">
                                                            {errors?.houseOffering?.onboardingStatusRemarks}
                                                        </Form.Text>} */}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={this.handleToggleHoldStatusButtonClick}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
export default ToggleHoldOnboarding;