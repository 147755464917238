import React, { Component } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { store } from 'react-notifications-component';
import { commonServices } from '../../../app/commonServices/commonServices';
import { Formik } from 'formik';
// import * as Yup from 'yup'; // for everything
// import * as constants from '../../constants';
// var mime = require('mime-types')
import mime from 'mime-types';
import * as firebase from 'firebase/app';

// const addPhotosSchema = Yup.object().shape({
//     file: Yup
//         .array()
//         .required("A file is required")
//         // .test(
//         //     "fileSize",
//         //     "File too large",
//         //     value => value && value.size <= FILE_SIZE
//         // )
//         .test(
//             "fileFormat",
//             "Please upload one of these extensions: .jpg, .jpeg, .gif or .png",
//             value => value && constants.SUPPORTED_IMAGE_FORMATS.includes(value.type)
//         )
// });

// const addVideosSchema = Yup.object().shape({
//     file: Yup
//         .array()
//         .required("A file is required")
//         // .test(
//         //     "fileSize",
//         //     "File too large",
//         //     value => value && value.size <= FILE_SIZE
//         // )
//         .test(
//             "fileFormat",
//             "Unsupported Format. Please upload one of these extensions: .mp4, .mov, .wmv, .flv or .mkv",
//             value => value && constants.SUPPORTED_VIDEO_FORMATS.includes(value.type)
//         )
// });

class PhotoGridComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAddPhotosModal: false,
            showDeletePhotoModal: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        let result = {}
        if (props.medias && props.medias.length) {
            result.medias = props.medias
        }
        return Object.keys(result).length > 0 ? result : null;
    }

    handleDeletePhotoButtonClick = async (id = null) => {
        if (id !== null) {
            await this.setState({ photoIdToBeDeleted: id })
        }
        await this.setState(state => ({ showDeletePhotoModal: !state.showDeletePhotoModal }));
    }

    handleApproveDeletePhotoButtonClick = async () => {
        if (this.state.photoIdToBeDeleted) {
            const result = await commonServices.deleteFile(this.state.photoIdToBeDeleted);
            if (result && result.status === 200) {
                //   ----------- USE CALLBACK  -----------------------
                // this.getInspectionReportById(this.state.workId);
                if (this.state.serviceRequestId) {
                    await this.state.photoGridComponentParentCallback(this.state.serviceRequestId);
                } else {
                    await this.state.photoGridComponentParentCallback(true);
                }
            }
        }
        await this.setState(state => ({ showDeletePhotoModal: !state.showDeletePhotoModal }));
    }

    handleAddPhotosButtonClick = async (sectionItemId = null, isSectionId = false, isVideo) => {
        this.setState(state => ({ showAddPhotosModal: !state.showAddPhotosModal, isVideo: isVideo }));
    }

    render() {
        const { medias, mode, serviceRequestId, reportSectionId, reportItemId, title,
            identifier, isVideo, isItemMedia, disabled, isPublished
        } = this.state;
        // section.collatedReportSectionFiles = medias
        //  mode = mode
        let containsVideos = false;
        medias && (medias.length > 0) && medias.forEach(image => {
            if (mime.lookup(image.url) && mime.lookup(image.url).startsWith('video')) {
                containsVideos = true
            }
        });

        const parentId = serviceRequestId || reportSectionId || reportItemId || undefined;
        return (
            <>
                {(((medias && medias.length === 0 && (mode === 'edit' || !mode))) || (medias && medias.length > 0)) &&
                    <h6 className=""><strong>{title}</strong></h6>
                }
                <div className="d-flex align-items-center justify-content-start flex-wrap">
                    {medias && medias.length > 0 &&
                        <>
                        {medias.map(media => (
                            <div key={media.id} className="d-inline-flex flex-shrink-0 align-items-center m-2" style={{ borderWidth: '1px', borderColor: 'lightgray', borderStyle: 'solid' }}>
                                {mime.lookup(media.url) && mime.lookup(media.url).startsWith('image') &&
                                    <img className="img-fluid pr-1" alt="" src={media.url} style={isItemMedia ? { height: 50 + "px" } : { height: 144 + "px" }} title="Right-click & View Image" />
                                }
                                {containsVideos && mime.lookup(media.url) && mime.lookup(media.url).startsWith('video') &&
                                    <video width={isItemMedia ? '50' : '256'} height={isItemMedia ? '50' : '144'} controls title="Right-click & View Video">
                                        <source src={`${media.url}`} type={`${mime.lookup(media.url)}`} />
                                        <source src="movie.ogg" type="video/ogg" />
                                        {`Your browser does not support the video tag.`}
                                    </video>
                                }
                                {(mode === 'edit' || !mode) && !isPublished &&
                                    <Button variant="clean" size="sm" title="Delete" onClick={() => this.handleDeletePhotoButtonClick(media.id)} >
                                        <i className="flaticon2-trash"></i>
                                    </Button>
                                }
                            </div>
                        ))}
                        </>
                    }
                    {/* Fallback for No Photos & Videos + Add buttons */}
                    {/* {medias && medias.length === 0 && (mode === 'edit' || !mode) && <p className="small text-muted">No Photos & Videos added.</p>} */}
                    {(mode === 'edit' || !mode) && !disabled && !isPublished && <Button variant="clean" size="sm" title="Add Photo" onClick={() => this.handleAddPhotosButtonClick(parentId, true)}>
                        <i className="flaticon2-plus"></i> Photo
                    </Button>}
                    {(mode === 'edit' || !mode) && !disabled &&  !isPublished && <Button variant="clean" size="sm" title="Add Video" onClick={() => this.handleAddPhotosButtonClick(parentId, true, 'yes')}>
                        <i className="flaticon2-plus"></i> Video
                    </Button>}
                </div>
                {(mode === 'edit' || !mode) &&
                    <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddPhotosModal} onHide={this.handleAddPhotosButtonClick} >
                        <Modal.Header closeButton>
                            <Modal.Title>{!isVideo ? `Add Photo` : `Add Video`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                initialValues={{
                                    reportItemId: reportItemId || undefined,
                                    reportSectionId: reportSectionId || undefined,
                                    serviceRequestacessId: serviceRequestId || undefined,
                                    file: undefined,
                                    identifier: identifier || undefined
                                }}
                                // validationSchema={!isVideo ? addPhotosSchema : addVideosSchema}
                                onSubmit={
                                    async (values, { setSubmitting }) => {
                                        let formData = new FormData();
                                        values.file && (values.file).forEach((file, index) => {
                                            reportItemId && formData.append(`files[${index}][reportItemId]`, reportItemId);
                                            reportSectionId && formData.append(`files[${index}][reportSectionId]`, reportSectionId);
                                            serviceRequestId && formData.append(`files[${index}][serviceRequestId]`, serviceRequestId);
                                            formData.append(`files[${index}][doc]`, values.file[index]);
                                            values.identifier && formData.append(`files[${index}][identifier]`, values.identifier);
                                        });
                                        const result = await commonServices.uploadFiles(formData);
                                        if (result && result.status === 201) {
                                            store.addNotification({
                                                title: "Success!",
                                                message: `Media added!`,
                                                type: "success",
                                                insert: "top",
                                                container: "top-right",
                                                animationIn: ["animated", "fadeIn"],
                                                animationOut: ["animated", "fadeOut"],
                                                dismiss: {
                                                    duration: 5000,
                                                    onScreen: true,
                                                    showIcon: true,
                                                    pauseOnHover: true
                                                }
                                            });
                                            if (serviceRequestId) {
                                                firebase.analytics().logEvent("media_upload", { description: "Media Uploaded", addMediaFrom: 'Service Requests' });
                                                await this.state.photoGridComponentParentCallback(serviceRequestId);
                                            } else {
                                                firebase.analytics().logEvent("media_upload", { description: "Media Uploaded", addMediaFrom: 'Inspection Reports' });
                                                await this.state.photoGridComponentParentCallback(true);
                                            }
                                            this.setState(state => ({ showAddPhotosModal: !state.showAddPhotosModal }));

                                        }
                                    }
                                }
                            >
                                { ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                    (<Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Label>Select File (Multiple Files can be uploaded)</Form.Label>
                                            </Col>
                                            <Col xs={12}>
                                                <input id="file" name="file" type="file"
                                                    // accept= {!isVideo ? `image/*` : `video/*`} capture="environment"
                                                    multiple onChange={(event) => {
                                                    setFieldValue("file", event.currentTarget.files);
                                                }} />
                                                {errors.file && touched.file && <Form.Text className="text-danger">
                                                    {errors.file}
                                                </Form.Text>}
                                            </Col>
                                            {/* {!identifier && <Col xs={12} className='mt-2'>
                                                    <Form.Group controlId="identifier">
                                                        <Form.Label>Identifier</Form.Label>
                                                        <Form.Control type="text" name='identifier' onChange={handleChange} />
                                                    </Form.Group>
                                                    <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                                </Col>} */}
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={this.handleAddPhotosButtonClick}>
                                                    Cancel
                                                    </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>)
                                }
                            </Formik>
                        </Modal.Body>
                    </Modal>
                }
                {(mode === 'edit' || !mode) &&
                    <Modal backdrop="static" keyboard={false} key={'m' + 6} show={this.state.showDeletePhotoModal} onHide={this.handleDeletePhotoButtonClick}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Photo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
                            <p><strong>Confirm deletion of this photo?</strong></p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleDeletePhotoButtonClick}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={this.handleApproveDeletePhotoButtonClick}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }
            </>
        )
    }
}

export default PhotoGridComponent;
