import React, { Component } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { commonServices } from '../../../app/commonServices/commonServices';
import * as constants from '../../constants';
import { store } from 'react-notifications-component';
import MultiSelect from 'react-multi-select-component';

class NewPMSUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      selectedUserRoles: [],
      selectedCities: props.preSelectedCities || [],
    }
  }

  componentDidMount = async () => {
    await this.getCities();
  }

  setSelectedUserRoles = async(selectedUserRoles) => {
    await this.setState({ selectedUserRoles });
  }

  /**
  * Method to get all the cities
  */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  setSelectedCities = (selectedCities) => {
    this.setState({ selectedCities });
  }

  /**
   * Helper method to format the userRoles
   */
  formatUserRoles = (rawUserRoles) => {
    return rawUserRoles?.map(rawUserRole => {
      return {
        label: rawUserRole.name,
        value: rawUserRole.id
      };
    });
  }

  /**
   * Custom method to ser users in formik
   * @param {Object} setFieldValue
   */
  setUsers = async (setFieldValue) => {
    const {selectedUserRoles} = this.state;
    selectedUserRoles && await setFieldValue('selectedUserRoles', selectedUserRoles)
  };


  render() {
    // const { intl } = this.props;
    const { userRoles, formattedCities, selectedCities
      //formattedUserRolesOptions,  selectedUserRoles
    } = this.state;
    // const { isRequested } = this.state;
    return (
      <Row>
        <Col xs={12} className="mb-3">
          <h3>Add New User</h3>
        </Col>
        <Col xs={12}>
          <Formik
            initialValues={{
              file: undefined,
              fullName: '',
              email: '',
              employeeId: '',
              phoneNumbers: [{
                countryCode: '',
                number: '',
                isPrimary: 'YES'
              }],
              userRoleAssignments: [],
              cityIds: [],
              selectedUserRoles: [],
              formattedUserRolesOptions: this.formatUserRoles(userRoles)
            }}
            validate={values => {
              const errors = {};
              // if (!values.preMoveInWorks.length === 0) {
              //   errors.preMoveInWorks = 'Atlessast one Furnishing Name is required';
              // }
              return errors;
            }}
            onSubmit={
              async (values, { setSubmitting, resetForm }) => {

                let formData = new FormData();
                values.file && formData.append('file', values.file);
                values.email && formData.append('user[email]', values.email);
                values.fullName && formData.append('user[fullName]', values.fullName);
                values.employeeId && formData.append('user[employeeId]', values.employeeId);
                values.phoneNumbers && (values.phoneNumbers).forEach((phone, index) => {
                  formData.append(`user[phoneNumbers][${index}][countryCode]`, phone.countryCode);
                  formData.append(`user[phoneNumbers][${index}][number]`, phone.number);
                  formData.append(`user[phoneNumbers][${index}][isPrimary]`, phone.isPrimary === 'true');
                });
                selectedCities && selectedCities.forEach((selectedCity, index) => {
                  formData.append(`user[cityIds][${index}][id]`, selectedCity.id);
                });
                // values.selectedUserRoles && console.log(values.selectedUserRoles);
                values.selectedUserRoles && (values.selectedUserRoles).forEach((role, index) => {
                  formData.append(`user[userRoleAssignments][${index}][userRoleId]`, role.value);
                });
                // for (var pair of formData.entries()) {
                //   console.log(pair[0] + ', ' + pair[1]);
                // }
                const result = await commonServices.createUser(formData);
                if (result && result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: `New PMS User Added!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                }
                resetForm({values:undefined});
                document.getElementById('profilePhotoFile').value = "";
              }
            }
          >
            {
              ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={4} className='mt-2'>
                        <Form.Group controlId="fullName">
                          <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="text" name='fullName' onChange={handleChange} onBlur={handleBlur} value={values.fullName} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4} className='mt-2'>
                        <Form.Group controlId="email">
                          <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="text" name='email' onChange={handleChange} onBlur={handleBlur} value={values.email} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group>
                          <label>Select Profile Picture</label><br />
                          <input id="profilePhotoFile" name="file" type="file" onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }} />
                        </Form.Group>
                      </Col>
                      {formattedCities &&
                        <Col xs={12} md={4} className="mt-2">
                          <p className="mb-0">Select User's Operation Cities <span className="text-danger">*</span></p>
                          <MultiSelect
                            options={formattedCities}
                            value={selectedCities}
                            onChange={this.setSelectedCities}
                            labelledBy={"Select User's Operation Cities"}
                          />
                        </Col>
                      }
                      <Col xs={12} md={4} className='mt-2'>
                        <Form.Group controlId="employeeId">
                          <Form.Label>Beats Employee Code <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="text" name='employeeId' onChange={handleChange} onBlur={handleBlur} value={values.employeeId} />
                        </Form.Group>
                      </Col>
                      {values.formattedUserRolesOptions && <Col xs={12} md={4} className='mt-2'>
                        <Form.Group controlId={"userRoleAssignments"}>
                          <Form.Label>Select All User Roles to Assign <span className="text-danger">*</span></Form.Label>
                          {/* <Form.Control as="select" multiple name={`userRolesAssignment`} onChange={handleChange} onBlur={handleBlur} value={values.userRolesAssignment}>
                            {userRoles && userRoles.map((roleCode, index) =>
                              <option key={"roleCode" + index} value={roleCode.id}>{roleCode.name}</option>)}
                          </Form.Control> */}
                          <MultiSelect
                            options={values.formattedUserRolesOptions}
                            value={values.selectedUserRoles}
                            onChange={async(e) => {
                                await this.setSelectedUserRoles(e);
                                await this.setUsers(setFieldValue);
                              }
                            }
                            labelledBy={"Select User Roles to Assign"}
                            hasSelectAll={false}
                            disableSearch={true}
                          />
                        </Form.Group>
                      </Col>}
                      <Col xs={12}>
                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                        <FieldArray
                          name="phoneNumbers"
                          render={arrayHelpers => (
                            <div>
                              {values.phoneNumbers.map((phoneNumber, index) =>
                                (<Row key={index}>
                                  <Col xs={3}>
                                    <Form.Group controlId={"countryCode" + index}>
                                      <Form.Label>Country Code (without +) <span className="text-danger">*</span></Form.Label>
                                      <Form.Control type="text" name={`phoneNumbers[${index}].countryCode`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.countryCode} />
                                    </Form.Group>
                                  </Col>

                                  <Col xs={5}>
                                    <Form.Group controlId={"number" + index}>
                                      <Form.Label>Number <span className="text-danger">*</span></Form.Label>
                                      <Form.Control type="text" name={`phoneNumbers[${index}].number`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.number} />
                                    </Form.Group>
                                  </Col>

                                  <Col xs={3}>
                                    <Form.Group controlId={"isPrimary" + index}>
                                      <Form.Label>Primary <span className="text-danger">*</span></Form.Label>
                                      <Form.Control as="select" name={`phoneNumbers[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.isPrimary}>
                                        <option value={null}>{constants.DEFAULT_OPTION}</option>
                                        <option value={true}>YES</option>
                                        <option value={false}>NO</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>

                                  <Col xs={1} className='d-flex align-self-center justify-content-right'>
                                    {index !== 0 && <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>}
                                  </Col>
                                </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({
                                countryCode: null,
                                number: null,
                                isPrimary: 'NO'
                              })}>Add Phone Number</Button>
                            </div>
                          )}
                        />
                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                      </Col>
                      {!values.showEmailOnly && <Col xs={12} className="text-right">
                        {/* <Button variant="secondary" className="mr-2" onClick={() => this.handleAddPMSUserButtonClick(null)}>
                          Cancel
                        </Button> */}
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>}
                    </Row>
                  </Form>
                )
            }
          </Formik>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  userRoles: state.auth?.staticData?.users?.userRoles
})

export default
  connect(
    mapStateToProps,
    null
  )(NewPMSUser);
