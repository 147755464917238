import { Button } from 'react-bootstrap';

var React = require('react');
var ReactUltimatePagination = require('react-ultimate-pagination');

function Page(props) {
  return (
    <Button size='sm' className='m-1 mt-3'
      // style={props.isActive ? { fontWeight: 'bold' } : null}
      onClick={props.onClick}
      variant={props.isActive ? 'success' : `outline-success`}
    >{props.value}</Button>
  );
}

function Ellipsis(props) {
  return <Button size='sm' variant={`outline-success`} className='m-1 mt-3' onClick={props.onClick} disabled={props.disabled}>...</Button>
}

function FirstPageLink(props) {
  return <Button size='sm' variant={`outline-success`} className='m-1 mt-3' onClick={props.onClick} disabled={props.disabled}>First</Button>
}

function PreviousPageLink(props) {
  return <Button size='sm' variant={`outline-success`} className='m-1 mt-3' onClick={props.onClick} disabled={props.disabled}>Previous</Button>
}

function NextPageLink(props) {
  return <Button size='sm' variant={`outline-success`} className='m-1 mt-3' onClick={props.onClick} disabled={props.disabled}>Next</Button>
}

function LastPageLink(props) {
  return <Button size='sm' variant={`outline-success`} className='m-1 mt-3' onClick={props.onClick} disabled={props.disabled}>Last</Button>
}

function Wrapper(props) {
  return <div className="pagination justify-content-center">{props.children}</div>
}

var itemTypeToComponent = {
  'PAGE': Page,
  'ELLIPSIS': Ellipsis,
  'FIRST_PAGE_LINK': FirstPageLink,
  'PREVIOUS_PAGE_LINK': PreviousPageLink,
  'NEXT_PAGE_LINK': NextPageLink,
  'LAST_PAGE_LINK': LastPageLink
};

var UltimatePagination = ReactUltimatePagination.createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
});

export default UltimatePagination;