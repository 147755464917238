import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

async function getAllSecurityDepositPaymentBills(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/securityDepositPaymentBills`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllSecurityDepositPaymentBills ', err);
  }
}

async function getSecurityDepositBillById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/securityDepositPaymentBills/${id}`);
    return result;
  } catch (err) {
    console.error('error in getSecurityDepositBillById ', err);
  }
}

async function getSecurityDepositBillAdjustments(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/paymentBills/new?leaseId=${data}`);
    return result;
  } catch (err) {
    console.log('error in getRentPaymentBillAdjustments', err)
  }

}

async function createSecurityDepositBill(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/paymentBills`, data);
    return result;
  } catch (err) {
    console.error('error in createSecurityDepositBill ', err);
  }
}

async function updateSecurityDepositBill(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/paymentBills/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in updateSecurityDepositBill', err);
  }
}

async function securityDepositBillsMarkTerminalStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/paymentBills/${id}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in securityDepositBillsMarkTerminalStatus ', err);
  }
}
async function deleteSecurityDepositBill(id) {
  try {
      const result = await axiosInstance.delete(`${baseApiUrl}admin/paymentBills/${id}`);
      return result;
  } catch (err) {
      console.error('error in deleteSecurityDepositBill ', err);
  }
}

export const securityDepositBillsServices = {
  getAllSecurityDepositPaymentBills,
  getSecurityDepositBillById,
  createSecurityDepositBill,
  updateSecurityDepositBill,
  securityDepositBillsMarkTerminalStatus,
  getSecurityDepositBillAdjustments,
  deleteSecurityDepositBill
};
