import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Badge, Button, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import AddLeaseComponent from "../../../partials/layout/AddLeaseComponent";
import ToggleHoldOnboarding from "../../../partials/layout/ToggleHoldOnboarding";
import RequestMoveIn from "../../../partials/layout/RequestMoveIn";
import CloseOnboarding from "../../../partials/layout/CloseOnboarding";
import { HouseOnboarding } from "../HouseOnboarding/HouseOnboarding";
import { PendingOnboardingServices } from './PendingOnboarding.services';
import { commonServices } from '../../../commonServices/commonServices';
import * as constants from '../../../constants';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { Link } from 'react-router-dom';
import { formatMultipleUsers, findPrimary } from '../../../../_metronic/utils/utils';
import AdminAssignments from '../../../partials/layout/AdminAssignments';

class PendingOnboardings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isFetching: false,
            page: 1,
            total: undefined,
            showNewOnboardingHouseModal:false,
            offset: 0,
            // terminalStatusForOnboarding: undefined,
            requestedFilters: ['state',
                // 'terminalStatusForOnboarding'
            ],
            limit: 10,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let result = {}
        // if (state.accordionData?.terminalStatusForOnboarding !== props.houseInspectionWork?.terminalStatusForOnboarding) {
        //     result.houseInspectionWork = props.accordionData
        // }
        return Object.keys(result).length > 0 ? result : null;
    }

    async componentDidMount() {
        await this.getHouseOfferings();
        await this.getMoveInManagers();
        await this.getCities();
        await this.getPropertyManagers();
        await this.getMoveInManagers()
    }

    getPropertyManagers = async () => {
        const data = {
            userRoleCodes: ['PROPERTY_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ propertyManagers: result.data.users })
        }
    }

    getCities = async () => {
        const { cityIds } = this.state;
        const data = {
            pmsOperationStatus: 'YES'
        }
        const result = await commonServices.getCities(data);
        if (result && result.status === 200) {
            const cities = result?.data?.cities;
            const formattedCities = commonServices.formatCities(cities);
            let preSelectedCities = null;
            if (this.state.cityIds?.length > 0) {
                preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
            }
            this.setState({ formattedCities });
            preSelectedCities && this.setState({ preSelectedCities });
        }
    }

    handleNewOnboardHouseButtonClick = async () => {
        await this.setState(state => ({ showNewOnboardHouseModal: !state.showNewOnboardHouseModal }));
      }

      getHouseOfferings = async () => {
        this.setState({ isFetching: true });
        const { offset, limit, state, propertyManagerId,
            // terminalStatusForOnboarding,
            moveInManagerId, onboardingStatus, minimumOnboardingRequirementStatusMarkedAt, maximumOnboardingRequirementStatusMarkedAt, beatsListingIdentifier, cityIds } = this.state;
        const data = {
            cityIds,
            offset,
            limit:10,
            state: (state && state[0]) || undefined,
            // terminalStatus: (terminalStatusForOnboarding && terminalStatusForOnboarding[0]) || undefined,
            propertyManagerId,
            moveInManagerId,
            propertyManagerAssigned: 'TRUE',
            onboardingRequirementStatus: 'YES',
            include: ['OWNERS', 'PROPERTY_MANAGER', 'MOVE_IN_MANAGER',],
            onboardingStatus,
            minimumOnboardingRequirementStatusMarkedAt,
            maximumOnboardingRequirementStatusMarkedAt,
            beatsListingIdentifier,
        }
        const result = await PendingOnboardingServices.getHouseOfferings(data);
        if (result && result.status === 200) {
            const totalCount = result.headers['x-total-count'];
            const totalPageNo = Math.ceil(totalCount / limit);
            await this.setState({
                houseOfferings: result.data.houseOfferings,
                total: totalPageNo === 0 ? 1 : totalPageNo,
                totalCount
            });
        }
        this.setState({ isFetching: false })
    }

    getHouseOfferingStatusBadge = (houseOfferingStatus) => {
        return houseOfferingStatus ? <Badge variant="info" title="Completed">C</Badge> : <Badge variant="success" title="Open">O</Badge>
    }

    getMoveInManagers = async () => {
        const data = {
            userRoleCodes: ['MOVE_IN_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ moveInManagers: result.data.users })
        }
    }

    onPageChange = async (page) => {
        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });
        await this.getHouseOfferings();
    }

    handleFilterChildDataForUnassignedLeaseList = async (childData) => {
        if (childData) {
            this.setState({ ...childData });
            await this.getHouseOfferings();
        }
    }

    render() {
        const { isFetching, houseOfferings = [],
            // terminalStatusForOnboarding,
            page, total, totalCount, state, requestedFilters,
            moveInManagers, moveINManagerId, formattedCities, preSelectedCities, propertyManagers, propertyManagerId, minimumOnboardingRequirementStatusMarkedAt, maximumOnboardingRequirementStatusMarkedAt, beatsListingIdentifier, onboardingStatus } = this.state;
        if (isFetching) {
            return <p>{constants.LOADING_DATA}</p>
        }
        let showPagination = false;
        if (total) { showPagination = total !== 0 && total !== 1; }
        return (
            <>
                <Container fluid key="c1">
                    <Portlet fluidHeight={true}>
                        <PortletHeader
                            title={`My Onboardings (${totalCount || 0})`}
                            toolbar={
                                <PortletHeaderToolbar>
                                    {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <Button className="mr-2" variant="primary" size="sm" onClick={this.handleNewOnboardHouseButtonClick}>Onboard New House</Button>}
                                </PortletHeaderToolbar>
                            }
                        />
                        <PortletBody>
                            <FilterComponent
                                requestedFilters={requestedFilters}
                                state={state}
                                // terminalStatusForOnboarding={terminalStatusForOnboarding}
                                formattedCities={formattedCities}
                                preSelectedCities={preSelectedCities}
                                propertyManagers={propertyManagers}
                                propertyManagerId={propertyManagerId}
                                moveInManagers={moveInManagers}
                                moveINManagerId={moveINManagerId}
                                onboardingStatus={onboardingStatus}
                                minimumOnboardingRequirementStatusMarkedAt={minimumOnboardingRequirementStatusMarkedAt}
                                maximumOnboardingRequirementStatusMarkedAt={maximumOnboardingRequirementStatusMarkedAt}
                                beatsListingIdentifier={beatsListingIdentifier}
                                filterParentCallback={this.handleFilterChildDataForUnassignedLeaseList}
                            />
                            <Card>
                                <Card.Header>
                                    <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                                        <Col md={3}><strong>Property</strong></Col>
                                        <Col md={1}><strong>City</strong></Col>
                                        <Col md={2}><strong>Owners</strong></Col>
                                        <Col md={2}><strong>Onboarding Status</strong></Col>
                                        <Col md={2}><strong>Lease Status</strong></Col>
                                        <Col md={2}><strong>Actions</strong></Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    {houseOfferings && houseOfferings.length === 0 && <p className="p-3 m-0">{constants.ZERO_DATA}</p>}
                                    {houseOfferings && houseOfferings.length > 0 && houseOfferings.map((houseOffering, index) =>
                                        <React.Fragment key={"ho"+index}>
                                            <Row className="azuro-table-row d-flex align-items-center px-3 py-2" key={houseOffering.house?.id}>
                                                <Col md={3} className="d-flex flex-row">
                                                    <span className="pr-3" title={houseOffering?.onboardingTerminalStatus ? 'Closed' : 'Open'}>{this.getHouseOfferingStatusBadge(houseOffering?.onboardingTerminalStatus)}</span>
                                                    <div>
                                                        <Link to={`/property-profile/${houseOffering.house?.id}`}>{houseOffering.house && <span>{houseOffering.house.formattedHouseName}</span>}</Link>
                                                        {houseOffering.beatsListingId && <p className="small mt-2 pt-0 mb-0 text-muted">Beats Listing ID: {houseOffering.beatsListingId}</p>}
                                                        {this.state.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <p className="small mt-2 pt-0 mb-0 text-muted">PM: {houseOffering.propertyManager?.fullName ? houseOffering.propertyManager.fullName : '-'}</p>}

                                                    </div>
                                                </Col>
                                                <Col md={1}>{houseOffering.house && houseOffering.house.society && houseOffering.house.society.locality.city.name}</Col>
                                                <Col md={2}>{houseOffering.house && formatMultipleUsers(houseOffering.house?.owners || [])}
                                                    {houseOffering?.house?.owners && findPrimary(houseOffering.house.owners) && findPrimary(findPrimary(houseOffering.house.owners).phoneNumbers) &&
                                                        ` (+${findPrimary(findPrimary(houseOffering.house.owners).phoneNumbers).countryCode} ${findPrimary(findPrimary(houseOffering.house.owners).phoneNumbers).number})`
                                                    }</Col>
                                                <Col md={2}><div>
                                                    <p>{houseOffering?.onboardingStatus === "HOLD" ? 'On hold' : 'Assigned'}</p>
                                                    {/* <p className="small mt-2 pt-0 mb-0 text-muted">{ `on {"dd/mm/yyyy"}`}</p> */}
                                                </div>
                                                </Col>
                                                <Col md={2}>
                                                    <p className="mt-2 pt-0 mb-0"> {houseOffering.lease !== null ? "Added" : "Not Added"}</p>
                                                    {houseOffering.lease !== null && <><p className="small mt-2 pt-0 mb-0 text-muted">tenant found by azuro: {houseOffering.lease?.tenantFoundByAzuro === true ? "Yes" : "No"}</p>
                                                        <p className="small mt-2 pt-0 mb-0 text-muted"> MIM: {houseOffering.moveInManager?.fullName}</p></>}
                                                </Col>
                                                <Col md={2}>
                                                    <DropdownButton id="dropdown-item-button" variant="clean" title="Choose Action">
                                                        {houseOffering?.onboardingStatus === null && <Dropdown.Item as={ToggleHoldOnboarding} dropdownItem={true} houseOffering={houseOffering} pendingOnboardingsCallback={this.getHouseOfferings}></Dropdown.Item>}
                                                        {houseOffering?.onboardingStatus === 'HOLD' && <Dropdown.Item as={ToggleHoldOnboarding} dropdownItem={true} houseOffering={houseOffering} pendingOnboardingsCallback={this.getHouseOfferings}></Dropdown.Item>}
                                                        {houseOffering?.onboardingStatus === null && houseOffering?.lease === null && <Dropdown.Item as={AddLeaseComponent} dropdownItem={true} houseId={houseOffering.house.id} pendingOnboardingsCallback={this.getHouseOfferings} ></Dropdown.Item>}
                                                        {houseOffering?.onboardingStatus === null && houseOffering?.lease === null && <Dropdown.Item as={RequestMoveIn} dropdownItem={true} houseId={houseOffering.house.id} pendingOnboardingsCallback={this.getHouseOfferings}></Dropdown.Item>}
                                                        {houseOffering?.onboardingStatus === null && houseOffering?.lease !== null && !houseOffering.moveInManager && <Dropdown.Item as={AdminAssignments} dropdownItem={true} leaseId={houseOffering.lease?.id} isReassign={false} adminAssignmentsParent={this.getHouseOfferings} title={'Assign MIM'}></Dropdown.Item>}
                                                        {houseOffering?.onboardingStatus === null && <Dropdown.Item as={CloseOnboarding} dropdownItem={true} houseOffering={houseOffering} pendingOnboardingsCallback={this.getHouseOfferings}></Dropdown.Item>}
                                                    </DropdownButton>
                                                </Col>
                                            </Row>
                                            <hr className="my-1" />
                                        </React.Fragment>
                                    )}
                                </Card.Body>
                            </Card>
                            {/* Code for pagination */}
                            {showPagination && showPagination && <UltimatePagination
                                currentPage={page}
                                totalPages={total}
                                onChange={this.onPageChange}
                                boundaryPagesRange={0}
                                hidePreviousAndNextPageLinks={true}
                                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                            />}
                        </PortletBody>
                    </Portlet>
                </Container>

                <Modal backdrop="static" size="lg" keyboard={false} key={'m' + 1} show={this.state.showNewOnboardHouseModal} onHide={this.handleNewOnboardHouseButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>Onboard New House</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <HouseOnboarding/>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
const mapStateToProps = state => ({
    userDetails: state.auth && state.auth.user && state.auth.user
});
export default
    connect(
        mapStateToProps,
        null
    )(PendingOnboardings);
