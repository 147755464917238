import axiosInstance from "../../../../app/myAxiosInstance";

const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

// TODO: Look into combining getLeaseDocumentation, getLeaseFurnishing, getLeaseWorks as they call the same endpoint
// TODO: Organize calls alphabetically, check if they need to be renamed for clarity, here, and wherever they are called
// TODO: data, value, values, formData - follow one naming strategy!
// Note: Similar changes across all services can be possible.

/**
 * Method to get the Lease Details
 * @param {Number} id
 * @param {Object} data
 */
async function getLeaseDetails(id, data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/leases/${id}`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getLeaseDetails ', err);
    }
}

/**
 * Method to get the Lease Documentations
 * @param {Object} data
 */
async function getLeaseDocumentation(data) {
    try {
        // const result = await axiosInstance.get(`${baseApiUrl}admin/preMoveInWorks`, { params: data });
        const result = await axiosInstance.get(`${baseApiUrl}admin/works/leaseWorks`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getLeaseDocumentation ', err);
    }
}

/**
 * Method to generate Escrow Agreement
 * @param {Object} data
 */
async function generateEscrowAgreement(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/works/generateEscrowAgreement`, data);
        return result;
    } catch (err) {
        console.error('error in generateEscrowAgreement ', err);
    }
}

/**
 * Method to get the Lease Furnishing
 * @param {Object} data
 */
async function getLeaseFurnishing(data) {
    try {
        // const result = await axiosInstance.get(`${baseApiUrl}admin/preMoveInWorks`, { params: data });
        const result = await axiosInstance.get(`${baseApiUrl}admin/works/leaseWorks`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getLeaseFurnishing ', err);
    }
}

/**
 * Method to get Lease Tenant Move-in
 * @param {Object} data
 */
async function getLeaseWorks(data) {
    try {
        // const result = await axiosInstance.get(`${baseApiUrl}admin/preMoveInWorks`, { params: data });
        const result = await axiosInstance.get(`${baseApiUrl}admin/works/leaseWorks`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getLeaseWorks ', err);
    }
}

/**
 * Method to get Lease Parties
 * @param {Object} data
 */
async function getLeaseParties(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/users`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getLeaseParties ', err);
    }
}

/**
 * Method to create Lease Works
 * @param {Object} values
 */
async function createLeaseWorks(values) {
    try {
        // const result = await axiosInstance.post(`${baseApiUrl}admin/preMoveInWorks/batchCreate`, values);
        const result = await axiosInstance.post(`${baseApiUrl}admin/works/batchCreate`, values);
        return result;
    } catch (err) {
        console.error('error in createLeaseWorks ', err);
    }
}

/**
 * Method to create Notice Period
 * @param {Object} values
*/
async function createNoticePeriod(values){
    try{
        const result = await axiosInstance.post(`${baseApiUrl}admin/noticePeriods`, values);
        return result;
    } catch (err) {
        console.error('error in createNoticePeriod ', err);
    }
}

/**
 * Method to create Extend Lease
 * @param {Object} values
*/
async function createExtendLease(values){
    try{
        const result = await axiosInstance.post(`${baseApiUrl}admin/leaseExtensions`, values);
        return result;
    } catch (err) {
        console.error('error in createExtendLease ', err);
    }
}

/**
 * Method to Mark Move-out Initiation Status
 * @param {Number} id
 * @param {Object} data
*/
async function markMoveOutInitiationStatus(id, values){
    try{
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/markMoveOutInitiationStatus`, values);
        return result;
    } catch (err) {
        console.error('error in createMoveOutInitiation ', err);
    }
}

/**
 * Method to edit Users
 * @param {Number} id
 * @param {Object} formData
 */
async function editUsers(id, formData) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/users/${id}`, formData);
        return result;
    } catch (err) {
        console.error('error in editUsers ', err);
    }
}

/**
 * Method to mark the Terminal Status
 * @param {Number} id
 * @param {Object} formData
 */
async function markTerminalStatus(id, formData) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/preMoveInWorks/${id}/markTerminalStatus`, formData);
        return result;
    } catch (err) {
        console.error('error in markTerminalStatus ', err);
    }
}

/**
 * Method to mark Move-in Terminal Status
 * @param {Number} id
 * @param {Object} formData
 */
async function markMoveInTerminalStatus(id, formData) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/markMoveInTerminalStatus`, formData);
        return result;
    } catch (err) {
        console.error('error in markMoveInTerminalStatus ', err);
    }
}

/**
 * Method to mark Move-in Initiation Status
 * @param {Number} id
 * @param {Object} data
 */
async function markMoveInInitiationStatus(id, data) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/markMoveInInitiationStatus`, data);
        return result;
    } catch (err) {
        console.error('error in markMoveInInitiationStatus ', err);
    }
}

/**
 * Method to edit Lease
 * @param {Number} leaseId
 * @param {Object} data
 */
async function editLease(leaseId, data) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${leaseId}`, data);
        return result;
    } catch (err) {
        console.error('error in editLease ', err);
    }
}

/**
 * Method to generate User Credentials
 * @param {Number} userId
 */
async function generateUserCredentials(userId) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}/admin/users/${userId}/createPassword`);
        return result;
    } catch (err) {
        console.error('error in generateUserCredentials', err);
    }
}

/**
 * Method to create Background Verification Request
 * @param {Object} data
 */
async function createBackgroundVerificationRequest(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/backgroundVerificationRequests`, data);
        return result;
    } catch (err) {
        console.error('error in createBackgroundVerificationRequest ', err);
    }
}

/**
* Method to confirm Move-Out
* @param {object} data
* @param {Number} id
*/
async function markMoveOutTerminalStatus(id, values) {
    try{
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/markMoveOutTerminalStatus`, values);
        return result;
    } catch (err) {
        console.error("error in MoveOut Terminal Status")
    }
}

/**
* Method to mark Notice Period Terminal Status
* @param {object} data
* @param {Number} id
*/
async function markNoticePeriodTerminalStatus(id, values) {
    try{
        const result = await axiosInstance.patch(`${baseApiUrl}admin/noticePeriods/${id}/markTerminalStatus`, values);
        return result;
    }catch(err){
        console.error("error in Notice Period Terminal Status")
    }
}

/**
* Method to mark Lease Extension Terminal Status
* @param {object} data
* @param {Number} id
*/
async function markLeaseExtensionTerminalStatus(id, value){
    try{
        const result = await axiosInstance.patch(`${baseApiUrl}admin/leaseExtensions/${id}/markTerminalStatus`, value);
        return result;
    }
    catch(err){
        console.error("error in Lease Extension Terminal")
    }
    
}

async function getLeaseTeanants(data){
    try{
      const result = await axiosInstance.get(`${baseApiUrl}admin/leaseTenantParticipations`,{ params: data })
      return result;
    } catch (err) {
      console.error('error in getLeaseTeanants', err);
    }
  }
  
  /**
   * Method to delete leaseTenantParticipations
   * @param {Number} id
   * Where it is used:
   *  leaseProfilePage
   */
  async function deleteLeaseTeanantParticipants(id) {
    try {
        const result = await axiosInstance.delete(`${baseApiUrl}admin/leaseTenantParticipations/${id}`);
        return result;
    } catch (err) {
        console.error('error in delete LeaseTeanantParticipants ', err);
    }
  }
  
  async function markPrimaryLeaseTeanantParticipants(id) {
    try{
      const result = await axiosInstance.patch(`${baseApiUrl}admin/leaseTenantParticipations/${id}/markPrimary`);
      return result;
    }
    catch (err) {
      console.error('error in markPrimary LeaseTeanantParticipants', err);
    }
  }

export const leaseProfileServices = {
    createBackgroundVerificationRequest,
    createExtendLease,
    createLeaseWorks,
    createNoticePeriod,
    editLease,
    editUsers,
    generateEscrowAgreement,
    generateUserCredentials,
    getLeaseDetails,
    getLeaseDocumentation,
    getLeaseFurnishing,
    getLeaseParties,
    getLeaseWorks,
    markLeaseExtensionTerminalStatus,
    markMoveInInitiationStatus,
    markMoveInTerminalStatus,
    markMoveOutInitiationStatus,
    markMoveOutTerminalStatus,
    markNoticePeriodTerminalStatus,
    markTerminalStatus,
    markPrimaryLeaseTeanantParticipants,
    deleteLeaseTeanantParticipants,
    getLeaseTeanants
};