import React from "react";
import { Container, Alert } from 'react-bootstrap';

export default function NoUserRolesDashboard() {
    return (
        <>
            <Container className="d-flex justify-content-center align-items-center align-self-center h-100">
                <Alert variant="warning">
                    <p className="mb-0">You have not been assigned any user roles yet. Please get in touch with your manager to get started with the PMS Dashboard.</p>
                </Alert>
            </Container>
        </>
    );
}
