import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login, getStaticData } from "../../crud/auth.crud";
import * as firebase from 'firebase/app';

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  /**
 * Method to get property supervisors
 */
  const getStaticDataValue = async () => {
    const result = await getStaticData();
    if (result && result.status === 200) {
      // await this.setState({ staticData: result.data.works })
      props.loadStaticData(result.data);
    } else {
      console.error('Something went wrong');
    }
  }

  return (
    <>
      {/*<div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link>
      </div>*/}

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
              userCategory: "EMPLOYEE"
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              enableLoading();
              // setTimeout(() => {
              //   login(values.email, values.password, values.userCategory)
              //     .then(({ headers: { _hash }, data: { user } }) => {
              //       disableLoading();
              //       props.login(_hash);
              //       props.fulfillUser(user);
              //     })
              //     .catch(() => {
              //       disableLoading();
              //       setSubmitting(false);
              //       setStatus(
              //         intl.formatMessage({
              //           id: "AUTH.VALIDATION.INVALID_LOGIN"
              //         })
              //       );
              //     });
              // }, 1000);

              const result = await login(values.email, values.password, values.userCategory);
              if(result && result.status === 201){
                // const headers: { _hash } data: { user }
                const {_hash} = result.headers;
                const {user} = result.data
                firebase.analytics().setUserId(user.email);
                firebase.analytics().logEvent("login", {user_id: user.id, user_email: user.email, description: "User logged in successfully!"});
                props.login(_hash);
                props.fulfillUser(user);
                getStaticDataValue();

              }else{
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              }
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {status ? (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  ) : (
                    //   <div role="alert" className="alert alert-info">
                    //     <div className="alert-text">
                    //       Use account <strong>admin@demo.com</strong> and password{" "}
                    //       <strong>demo</strong> to continue.
                    // </div>
                    //   </div>
                    <></>
                    )}

                  <div className="form-group">
                    <TextField
                      type="email"
                      label="Email"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    <Link
                      to="/auth/forgot-password"
                      className="kt-link kt-login__link-forgot"
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                    </Link>

                    {/* <Link
                    to="/auth/reset-password/:token"
                    className="kt-link kt-login__link-forgot"
                  >
                    Reset Password (TEMP)
                  </Link> */}

                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Sign In
                  </button>
                  </div>
                </form>
              )}
          </Formik>

          {/*<div className="kt-login__divider">
            <div className="kt-divider">
              <span />
              <span>OR</span>
              <span />
            </div>
          </div>

          <div className="kt-login__options">
            <Link to="http://facebook.com" className="btn btn-primary kt-btn">
              <i className="socicon-facebook" />
              Facebook
            </Link>
            <Link to="http://twitter.com" className="btn btn-info kt-btn">
              <i className="socicon-twitter" />
              Twitter
            </Link>
            <Link to="google.com" className="btn btn-danger kt-btn">
              <i className="socicon-google" />
              Google
            </Link>
          </div>*/}
        </div>
      </div>
    </>
  );
}


export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
