import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Badge, Accordion, Form, Modal } from 'react-bootstrap';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import TasksListComponent from "../../../partials/layout/TasksListComponent.js";
import SchedulesListComponent from '../../../partials/layout/SchedulesListComponent';
import { InspectionServices } from './Inspections.services';
import { InspectionReportServices } from '../InspectionReport/InspectionReport.services';
import { commonServices } from '../../../../app/commonServices/commonServices';
import { Link } from "react-router-dom";
import { Formik } from 'formik';
import * as constants from '../../../constants';
import { connect } from 'react-redux';
import {
  formatDate, formatDateTime, formatMultipleUserRoles,
  datePlusOneDay
} from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import * as firebase from 'firebase/app';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { DatePickerField } from '../../../partials/layout/DatePickerField';

const createHouseInspectionSchema = Yup.object().shape({
  workId: Yup.number().required('Remarks is a mandatory field')
})

const assignInspectionSchema = Yup.object().shape({
  workId: Yup.number().required('Service Request Id is a mandatory'),
  assigneeId: Yup.number().required('Assignee is a mandatory field'),
  assignmentRemarks: Yup.string().required('Remarks is required field'),
});

class Inspections extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showCancelInspectionModal: false,
      showCreateHouseInspectionModal: false,
      showAssignInspectionModal: false,
      showPublishReportModal: false,
      showNewHouseInspectionWorkModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      state: !props.houseIdFromPropertyProfile ? ['OPEN'] : undefined,
      terminalStatusForInspection: undefined,
      propertyManagementStatus: ['YES'],
      requestedFilters: ['state', 'terminalStatusForInspection','propertyManagementStatus'],
    }
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    if (state.accordionData?.terminalStatus !== props.houseInspectionWork?.terminalStatus) {
      result.houseInspectionWork = props.accordionData
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  /**
   * Component did mount method
   */
  async componentDidMount() {
    await this.getAllInspections();
    await this.getPropertyManagers();
    await this.getInspectionAssigneeData();
    await this.getCities();
  }

  /**
   * Method to get all the cities
   */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
   * method to get inspection status badge
   * @param {String} inspection_status 
   */
  getInspectionStatusBadge = (inspectionStatus) => {
    if (inspectionStatus === 'OPEN') return <Badge variant="success" title="Open">O</Badge>
    if (inspectionStatus === 'CLOSED') return <Badge variant="info" title="Closed">C</Badge>
    if (inspectionStatus === 'DONE') return <Badge variant="info" title="Completed">C</Badge>
    if (inspectionStatus === 'CANCELLED') return <Badge variant="danger" title="Cancelled">C</Badge>
  }

  /**
   * method for get all inspections
   * @param {*} state
   */
  getAllInspections = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, minimumDueDate, maximumDueDate, maximumScheduleStartDatetime,
      propertyManagerId, assigneeId, cityIds, state, terminalStatusForInspection, propertyManagementStatus } = this.state;
    let newMaximumScheduleStartDatetime = maximumScheduleStartDatetime && datePlusOneDay(maximumScheduleStartDatetime);
    const data = {
      state: (state && state[0]) || undefined,
      terminalStatus: (terminalStatusForInspection && terminalStatusForInspection[0]) || undefined,
      cityIds,
      minimumDueDate,
      maximumDueDate,
      propertyManagementStatus: (propertyManagementStatus && propertyManagementStatus[0]) || undefined,
      maximumScheduleStartDatetime: newMaximumScheduleStartDatetime,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      assigneeId: assigneeId !== '0' ? assigneeId : undefined,
      houseId: houseIdFromPropertyProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
    }
    const result = await InspectionServices.getAllInspections(data)
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        inspectionProperties: result.data.houseInspectionWorks,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      })
    }
    this.setState({ isFetching: false });
  }

  /**
   * Method to get inspection assignee data for inspections
   */
  getInspectionAssigneeData = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      (result.data.users).push(this.props.userDetails);
      await this.setState({ propertySupervisors: result.data.users })
    }
  }

  /**
   * method for show inspections
   * @param {*} id
   */
  getInspectionById = async (id) => {
    const result = await InspectionServices.getInspectionById(id)
    if (result && result.status === 200) {
      await this.setState({ accordionData: result.data.houseInspectionWork })
    }
  }

  /**
   * Method to handle accordion click
   * @param {Number} id
   */
  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getInspectionById(id);
      firebase.analytics().logEvent("inspections_details_view_click", { description: "Inspections Details View Clicked", inspectionId: id });
    }
  }

  /**
   * callback method to handle callback from child components
   */
  handleChildData = async (workId) => {
    await this.getInspectionById(workId);
  }

  /**
   * Method to handle 'cancel inspection' button click
   */
  handleCancelInspectionButtonClick = () => {
    this.setState(state => ({ showCancelInspectionModal: !state.showCancelInspectionModal }));
  }


  /**
   * method to handle page change for pagination
   * @param {Number} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllInspections();
  }


  /**
   * Method to handle 'create house inspeciton' rent payment bill button click
   */
  handleCreateHouseInspecitonButtonClick = () => {
    this.setState(state => ({ showCreateHouseInspectionModal: !state.showCreateHouseInspectionModal }));
  }

  /**
   * Method to handle assign service request button click
   * @param {Object} assignReassign
   */
  handleAssignInspectionButtonClick = (assignReassign = null) => {
    if (assignReassign !== null) {
      this.setState({ assignReassign })
    }
    this.setState(state => ({ showAssignInspectionModal: !state.showAssignInspectionModal }));
  }

  /**
  * Method to handle publish report button click
  * @param {*} confirmPublish
  */
  handlePublishReportButtonClick = async (confirmPublish = false) => {
    if (confirmPublish === true) {
      const result = await InspectionReportServices.publishReport(this.state.accordionData.houseInspection.id);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Report successfully published!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        await this.getInspectionById(this.state.accordionData.id);
      }
      confirmPublish = false;
    }
    this.setState(state => ({ showPublishReportModal: !state.showPublishReportModal }));
  }

  /**
  * Method to handle 'create house inspeciton' rent payment bill button click
  */
  handleNewHouseInspecitonWorkButtonClick = () => {
    this.setState(state => ({ showNewHouseInspectionWorkModal: !state.showNewHouseInspectionWorkModal }));
  }

  /**
   * Method to log report
   * @param {Boolean} isEdit
   */
  logReport = (isEdit = undefined) => {
    isEdit && firebase.analytics().logEvent("edit_report_button_click", { description: "Edit Report Button Clicked" });
    !isEdit && firebase.analytics().logEvent("view_report_button_click", { description: "View Report Button Clicked" });
  }

  /**
   * Method to handle data of filter component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.resetPage();
      await this.getAllInspections();
      await this.getPropertyManagers();
      await this.getInspectionAssigneeData();
      await this.getCities();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Inspections' });
    }
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  render() {
    const { inspectionProperties = [], accordionData, page, total, totalCount,
      houseIdFromPropertyProfile, isFetching, showCreateHouseInspectionModal,
      assignReassign, propertySupervisors, minimumDueDate, maximumDueDate, maximumScheduleStartDatetime,
      propertyManagers, propertyManagerId, assigneeId, formattedCities, preSelectedCities,
      requestedFilters, state, terminalStatusForInspection, showNewHouseInspectionWorkModal, propertyManagementStatus
    } = this.state;
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    return (
      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader title={`Inspections (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  {houseIdFromPropertyProfile && <Button className="mr-2" variant="link" size="sm" onClick={this.handleNewHouseInspecitonWorkButtonClick}>New Inspection Work</Button>}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody className="">
              <FilterComponent
                requestedFilters={requestedFilters}
                state={state}
                terminalStatusForInspection={terminalStatusForInspection}
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                minimumDueDate={minimumDueDate}
                maximumDueDate={maximumDueDate}
                maximumScheduleStartDatetime={maximumScheduleStartDatetime}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                propertySupervisors={propertySupervisors}
                assigneeId={assigneeId}
                propertyManagementStatus={propertyManagementStatus}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />

              <Row>
                <Col xs={12}>
                  <Accordion defaultActiveKey="1">
                    {inspectionProperties && inspectionProperties.length === 0 &&
                      <p>No Upcoming Inspections</p>
                    }
                    {inspectionProperties && inspectionProperties.length > 0 && <Card>
                      <Card.Header>
                        <Row className="py-2 px-3 azuro-table-header">
                          {!houseIdFromPropertyProfile && <Col md={4}><strong>Property</strong></Col>}
                          <Col md={houseIdFromPropertyProfile ? 4 : 2}><strong>Due Date</strong></Col>
                          <Col md={houseIdFromPropertyProfile ? 4 : 2}><strong>Scheduled at</strong></Col>
                          <Col md={3}><strong>Assignee</strong></Col>
                        </Row>
                      </Card.Header>
                    </Card>}
                    {inspectionProperties && inspectionProperties.map(property =>
                      <Card key={property.id}>
                        <Accordion.Toggle as={Card.Header} eventKey={property.id} className="row py-2 azuro-table-row"
                          onClick={() => { this.handleAccordionClick(property.id) }}>
                          {!houseIdFromPropertyProfile && <Col md={4} className="d-flex flex-row">
                            <span className="pr-3">
                              {this.getInspectionStatusBadge(property.state)}
                            </span>
                            {property.house && <span>{property.house.formattedHouseName} <br />
                              <span className="small text-muted">{property.house.propertyManager ? `PM: ${(property.house.propertyManager && property.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                            </span>}

                          </Col>}
                          <Col md={houseIdFromPropertyProfile ? 4 : 2}>{houseIdFromPropertyProfile && <span className="pr-3">{this.getInspectionStatusBadge(property.state)}</span>}{formatDate(property.dueDate)}</Col>
                          <Col md={houseIdFromPropertyProfile ? 4 : 2}>{formatDateTime(property.latestScheduleStartDatetime)}</Col>
                          <Col md={3} className="d-flex justify-content-between">
                            <span>
                              {property?.schedules && property?.schedules?.length > 0 && property?.schedules[0]?.assignments?.length > 0 &&
                                property?.schedules[0]?.assignments[0]?.assignee.fullName}
                              {property?.schedules && property?.schedules?.length > 0 && property?.schedules[0]?.assignments?.length === 0 &&
                                <span className="small text-warning">Schedule Not Assigned</span>}
                              {property?.schedules && property?.schedules?.length === 0 &&
                                <span className="small text-muted">Not Scheduled</span>}
                            </span>
                            {/* <i className="flaticon2-sort"></i> */}

                          </Col>
                          {property.house && <Col md={1}>
                            <Link to={`/property-profile/${property.house.id}`}>
                              <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                            </Link>
                          </Col>}
                        </Accordion.Toggle>

                        {accordionData && (accordionData.id === property.id) &&
                          <Accordion.Collapse key={property.id} eventKey={property.id}>
                            <Card.Body>

                              <Col xs={12}>
                                {/* {property.state && property.state === 'OPEN' && <p><strong>Assignee:</strong> {' '}
                                  {accordionData.assignments && accordionData.assignments?.map((assignment, index) =>
                                    <>
                                      {(index === (accordionData.assignments.length - 1)) && <span key={assignment.id}>
                                        {assignment.assignee && assignment.assignee.fullName}
                                      </span>}
                                    </>
                                  )
                                  }


                                  {
                                    (accordionData.assignments.length === 0) &&
                                    <Button variant="clean" size="sm" onClick={() => this.handleAssignInspectionButtonClick({ showAssign: true })}>Assign</Button>
                                  }
                                  {
                                    (accordionData.assignments.length > 0) &&
                                    <Button variant="clean" size="sm" onClick={() => this.handleAssignInspectionButtonClick({ showReassign: true })}>Reassign</Button>
                                  }
                                </p>
                                } */}

                                {/* {accordionData.state && accordionData.assignments.length > 0 && <p><strong>Assignee:</strong>
                                {req.accordionData || ''} ({req.accordionData || ''})
                                <Button variant="clean" size="sm" onClick={() => this.handleReAssignServiceRequestButtonClick({ showReassign: true })}>Reassign</Button>
                              </p>
                              } */}

                              </Col>
                              <TasksListComponent
                                tasks={accordionData.tasks || []} allowAddTasks={
                                  property.state && property.state === 'OPEN'
                                }
                                workId={property.id}
                                houseId={property?.house?.id}
                                parentCallback={this.handleChildData}
                                // approverData={this.state.propertySupervisors && this.state.propertySupervisors}
                                approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                              />

                              <NotesComponent notes={accordionData.notes || []} allowAddNotes={
                                property.state && property.state === 'OPEN'
                              }
                                workId={property.id}
                                parentCallback={this.handleChildData} />

                              <SchedulesListComponent schedules={accordionData.schedules || []} allowAddSchedules={
                                property.state && property.state === 'OPEN'
                              } workId={property.id}
                                parentCallback={this.handleChildData}
                                house={property?.house} />

                              <Row className="mb-4">
                                {accordionData && accordionData.terminalStatus === 'CANCELLED' &&
                                  <Col xs={12}>
                                    <p><strong>{accordionData.terminalStatus}</strong> {accordionData.terminalRemarks ? ': '+accordionData.terminalRemarks : ''}</p>
                                  </Col>
                                }
                                <Col xs={12} className="text-right">
                                  {/* {property.inspection_status === 'Open' && <><Button variant="primary" size="sm">Re-schedule</Button> <Button variant="primary" size="sm">Re-assign</Button></>}
                                  <span>&nbsp;</span> */}
                                  {
                                  accordionData && !accordionData.houseInspection && accordionData.house?.propertyManagementStatus === 'YES' && 
                                    <Button variant="primary" className='mr-2' size="sm" onClick={this.handleCreateHouseInspecitonButtonClick}>Create Report</Button>
                                  }
                                  {accordionData && accordionData.houseInspection && accordionData.houseInspection.isPublished !== true &&
                                    <Link onClick={() => this.logReport(true)} to={`/inspection-report/${accordionData.houseInspection.id}/edit`}>
                                      <Button variant="primary" className='mr-2' size="sm">Edit Report</Button>
                                    </Link>
                                  }
                                  {accordionData && accordionData.houseInspection && accordionData.houseInspection.isPublished === true && 
                                    <Link onClick={() => this.logReport(false)} to={`/inspection-report/${accordionData.houseInspection.id}/view`}>
                                      <Button variant="primary" className='mr-2' size="sm">View Report</Button>
                                    </Link>
                                  }
                                  {accordionData && accordionData.houseInspection && accordionData.houseInspection.isPublished !== true && <Button className="mr-2" variant="primary" size="sm" onClick={this.handlePublishReportButtonClick}>
                                    <i className="flaticon2-google-drive-file"></i> Publish
                                  </Button>}
                                  {property.state === 'OPEN' && <><span>&nbsp;</span><Button variant="primary" size="sm" onClick={this.handleCancelInspectionButtonClick}>Cancel Inspection</Button></>}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Accordion.Collapse>
                        }
                      </Card>
                    )
                    }
                  </Accordion>
                </Col>
              </Row>
              {/* Code for pagination */}

              {showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>



        <Modal backdrop="static" keyboard={false} key={'ci' + 1} show={this.state.showCancelInspectionModal} onHide={this.handleCancelInspectionButtonClick} >
          <Modal.Header closeButton>
            <Modal.Title>Cancel Inspection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                terminalStatus: "CANCELLED",
                terminalRemarksCategory: "",
                terminalRemarks: ""
              }}
              // validator={() => ({})}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await InspectionServices.closeInspection(accordionData && accordionData.id, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Inspection Cancelled!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCancelInspectionModal: !state.showCancelInspectionModal }));
                    this.getAllInspections();;
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                (

                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p>Are you sure you want to cancel this inspection?</p>
                        {/* <Form.Group controlId="terminalStatus">
                          <Form.Control as="select" name='terminalStatus' required="required" onChange={handleChange} onBlur={handleBlur} value={values.terminalStatus}>
                            <option value={null}>{constants.DEFAULT_OPTION}</option>
                            <option value='CANCELLED'>Cancelled</option>
                            <option value='DONE'>Completed</option>
                          </Form.Control>
                        </Form.Group> */}
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="terminalRemarks">
                          <Form.Label>Cancelation Remarks</Form.Label>
                          <Form.Control as="textarea" name='terminalRemarks' onChange={handleChange} onBlur={handleBlur} value={values.terminalRemarks} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCancelInspectionButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`chir1`} show={showCreateHouseInspectionModal} onHide={this.handleCreateHouseInspecitonButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Create Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                workId: accordionData && accordionData.id
              }}
              validationSchema={createHouseInspectionSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await InspectionServices.createHouseInspection(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `House inspection created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateHouseInspectionModal: !state.showCreateHouseInspectionModal }));
                    await this.getInspectionById(accordionData && accordionData.id);
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>

                    {/* <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p> */}
                    <p><strong>Are you sure you want to create the Inspection Report?</strong></p>

                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCreateHouseInspecitonButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Creating...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`ARI1`} show={this.state.showAssignInspectionModal} onHide={this.handleAssignInspectionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                assignReassign && assignReassign.showAssign === true &&
                `Assign Inspection`
              }
              {
                assignReassign && assignReassign.showReassign === true &&
                `Re-assign Inspection`
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                workId: (accordionData && accordionData.id) || undefined,
                // place: "",
                assigneeId: undefined,
                assignmentRemarks: undefined
              }}
              validationSchema={assignInspectionSchema}
              onSubmit={async (values) => {
                values.assigneeId = parseInt(values.assigneeId, 10);
                const result = await commonServices.adminAssignments(values);
                if (result.status === 201) {
                  // this.props.parentCallback(values.serviceRequestId);
                  store.addNotification({
                    title: "Success!",
                    message: "House Inspection assigned!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.getInspectionById(values.workId);
                  this.setState(state => ({ showAssignInspectionModal: !state.showAssignInspectionModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assignee</Form.Label>
                          <Form.Control as="select" name="assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId || 0}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              propertySupervisors && propertySupervisors.map((ps =>
                                <option key={ps.id} value={ps.id}>{ps.fullName}{' '}
                                  ({ps.userRoleCodes && formatMultipleUserRoles(ps.userRoleCodes)})
                                  </option>
                              ))
                            }
                          </Form.Control>
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">
                            {errors.assigneeId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="assignmentRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control name="assignmentRemarks" type="text" onChange={handleChange} onBlur={handleBlur} value={values.assignmentRemarks} />
                          {errors.assignmentRemarks && touched.assignmentRemarks && <Form.Text className="text-danger">
                            {errors.assignmentRemarks}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAssignInspectionButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 7} show={this.state.showPublishReportModal} onHide={this.handlePublishReportButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Publish Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Do you want to publish this report and make it available to the Owners &amp; Tenants?</strong></p>
            <p>This inspection will also be marked as Completed.</p>
            <p>Note: Make sure all the details filled in are correct before publishing. You won't be able to unpublish this report.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handlePublishReportButtonClick}>
              Cancel
              </Button>
            <Button variant="success" onClick={() => this.handlePublishReportButtonClick(true)}>
              Publish
              </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={`chiw1`} show={showNewHouseInspectionWorkModal} onHide={this.handleNewHouseInspecitonWorkButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Inspection Work</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                houseInspectionWork: {
                  houseId: houseIdFromPropertyProfile || undefined,
                  dueDate: undefined
                }
              }}
              // validationSchema={createHouseInspectionSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await InspectionServices.createHouseInspectionWork(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `New Inspection Work created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showNewHouseInspectionWorkModal: !state.showNewHouseInspectionWorkModal }));
                    this.getAllInspections();;
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="houseInspectionWork.dueDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Due Date'
                            dateFormat='MMMM d, yyyy'
                            name='houseInspectionWork.dueDate'
                            value={values.houseInspectionWork.dueDate}
                            onChange={setFieldValue}
                          />
                          {/* {errors.houseInspectionWork.dueDate && touched.houseInspectionWork.dueDate && <Form.Text className="text-danger">
                            {errors.houseInspectionWork.dueDate}
                          </Form.Text>} */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleNewHouseInspecitonWorkButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(Inspections);
