import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { commonServices } from '../../commonServices/commonServices';
import * as constants from '../../constants';
import { formatMultipleUserRoles } from '../../../_metronic/utils/utils';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { store } from 'react-notifications-component';

const assignmentSchema = Yup.object().shape({
    assigneeId: Yup.number().required('Please select one of the assignee')
})

/**
 * Takes leaseId, houseId as props from the parent components
 */
class AdminAssignments extends Component {


    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAssignmentModal: false
        }
    }

    /**
     * Default component did mount method
     */
    async componentDidMount() { }

    /**
     * Method to get the assignee
     * @param {Object} data
     */
    getAssignee = async (data) => {
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ assigneeData: result.data.users })
        }
    }


    /**
     * Method to handle the assign PM button click
     * @param {Number} id
     * @param {String} typeOfAssignment
     */
    handleAssignButtonClick = async () => {
        const { leaseId, scheduleId, houseId, salesManagerHouseId } = this.state;
        let data = {};
        if (leaseId) {
            data = {
                userRoleCodes: ['MOVE_IN_MANAGER'],
            }
        } else if(scheduleId) {
            data = {
                userRoleCodes: ['PROPERTY_SUPERVISOR'],
            }
        }else if(salesManagerHouseId) {
            data = {
                userRoleCodes: ['SALES_MANAGER'],
            }
        } else if(houseId) {
            data = {
                userRoleCodes: ['PROPERTY_MANAGER'],
            }
        }
        await this.getAssignee(data);
        this.setState(state => ({ showAssignmentModal: !state.showAssignmentModal }));
    }


    render() {
        const { showAssignmentModal, houseId, leaseId, scheduleId, assigneeData, isReassign, adminAssignmentsParent,
            title, salesManagerHouseId } = this.state;
        return (
            <>
                <Button variant={this.props.dropdownItem ? "dropdown-item" : "clean"} size={this.props.dropdownItem ? "" : "sm"} className={this.props.dropdownItem ? "dropdown-item" : ""} onClick={() => this.handleAssignButtonClick()}>
                    {title}
                </Button>

                <Modal backdrop="static" keyboard={false} show={showAssignmentModal} onHide={this.handleAssignButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                houseId: houseId || undefined,
                                leaseId: leaseId || undefined,
                                scheduleId: scheduleId || undefined,
                                assigneeId: undefined,
                                salesManagerHouseId: salesManagerHouseId || undefined,
                                // assignmentRemarks: undefined
                            }}
                            validationSchema={assignmentSchema}
                            onSubmit={async (values) => {
                                if(values.houseId){ values.houseId = parseInt(values.houseId, 10) }
                                if(values.leaseId){ values.leaseId = parseInt(values.leaseId, 10) }
                                if(values.scheduleId){ values.scheduleId = parseInt(values.scheduleId, 10) }
                                if(values.salesManagerHouseId){ values.salesManagerHouseId = parseInt(values.salesManagerHouseId, 10) }
                                values.assigneeId = parseInt(values.assigneeId, 10)
                                const result = await commonServices.adminAssignments(values);
                                if (result && result.status === 201) {
                                    store.addNotification({
                                        title: "Success!",
                                        message: `Assignment done!`,
                                        type: "success",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 5000,
                                            onScreen: true,
                                            showIcon: true,
                                            pauseOnHover: true
                                        }
                                    });
                                    !isReassign && scheduleId && await adminAssignmentsParent(isReassign, `scheduleList`)
                                    !isReassign && houseId && await adminAssignmentsParent(isReassign, `house`)
                                    !isReassign && leaseId && await adminAssignmentsParent(isReassign, `lease`)
                                    !isReassign && salesManagerHouseId && await adminAssignmentsParent(isReassign, `house`)
                                    isReassign && scheduleId && await adminAssignmentsParent(isReassign, `scheduleList`)
                                    isReassign && houseId && await adminAssignmentsParent(isReassign, `house`)
                                    isReassign && leaseId && await adminAssignmentsParent(isReassign, `lease`)
                                    isReassign && salesManagerHouseId && await adminAssignmentsParent(isReassign, `house`)
                                }
                                this.setState(state => ({ showAssignmentModal: !state.showAssignmentModal }));
                            }}
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group controlId="assigneeId">
                                                    <Form.Label>Assign To</Form.Label>
                                                    <Form.Control name="assigneeId" as="select" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId}>
                                                        <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            assigneeData && assigneeData.map((assignee =>
                                                                <option key={assignee.id} value={assignee.id}>{assignee.fullName}{' '}
                                                                    ({assignee.userRoleCodes && formatMultipleUserRoles(assignee.userRoleCodes)})
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                                {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">
                                                    {errors.assigneeId}
                                                </Form.Text>}
                                            </Col>
                                            {/* <Col xs={12}>
                                                <Form.Group controlId="title">
                                                    <Form.Label>Remarks</Form.Label>
                                                    <Form.Control name="assignmentRemarks" type="text" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.assignmentRemarks} />
                                                </Form.Group>
                                            </Col> */}

                                            <Col xs={12} className="mt-2 text-right">
                                                <Button variant="secondary" className="mr-2" onClick={this.handleAssignButtonClick}>
                                                    Cancel
                                                </Button>
                                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default AdminAssignments;