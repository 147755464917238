import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { RentReceiptsServices } from './RentReceipts.services';
import FilterComponent from '../../../partials/layout/FilterComponent';
import * as constants from '../../../constants';
import { formatDate } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import * as firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import { commonServices } from '../../../commonServices/commonServices';

export default class RentReceipts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      state: !props.houseIdFromPropertyProfile ? ['OPEN'] : undefined,
      requestedFilters: [
        'state'
      ]
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.getAllRentReceipts();
    await this.getCities();
    await this.getPropertyManagers();
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
    * Method to get all the cities
    */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get all the rent receipts data
   */
  getAllRentReceipts = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, state, cityIds,
      propertyManagerId } = this.state;
    const data = {
      cityIds,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId: houseIdFromPropertyProfile,
      state: (state && state[0]) || undefined,
      include: ['REQUESTOR', 'HOUSE'],
      offset,
      limit,
    }
    const result = await RentReceiptsServices.getAllRentReceipts(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        rentReceipts: result.data.rentReceipts,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false })
  }

  /**
   * Method to get the status badge
   * @param {*} receiptStatus 
   */
  getReceiptStatusBadge(receiptStatus) {
    return receiptStatus === 'CLOSED' ? <Badge variant="info" title="Closed">C</Badge> : <Badge variant="success" title="Open">O</Badge>
  }


  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllRentReceipts();
  }



  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData })
      await this.resetPage();
      await this.getAllRentReceipts();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Payments' });
    }
  }

  /**
   * Method to reset Pagination
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    })
  }


  render() {
    const { isFetching, rentReceipts = [], page, total, totalCount, houseIdFromPropertyProfile,
      requestedFilters, state, formattedCities, preSelectedCities,
      propertyManagers, propertyManagerId } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid key="c1">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Rent Receipts (${totalCount || 0})`}
            />
            <PortletBody>
              <FilterComponent
                state={state}
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                requestedFilters={requestedFilters}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Card>
                {rentReceipts && rentReceipts.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {rentReceipts && rentReceipts.length > 0 && <Card.Header>
                  <Row className="azuro-table-header d-flex align-items-center py-2">
                    {!houseIdFromPropertyProfile && <Col xs={3}><strong>Property</strong></Col>}
                    <Col xs={2}><strong>Requestor</strong></Col>
                    <Col xs={2}><strong>Duration</strong></Col>
                    <Col xs={houseIdFromPropertyProfile ? 3 : 2}><strong>Requested on</strong></Col>
                    <Col xs={houseIdFromPropertyProfile ? 4 : 3}><strong>Receipts</strong></Col>
                  </Row>
                </Card.Header>}
                <Card.Body className="p-0">
                  {rentReceipts && rentReceipts.map(rentReceipt =>
                    <>
                      <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                        {!houseIdFromPropertyProfile && <Col xs={3} className="d-flex flex-row">
                          <span className="pr-3">{this.getReceiptStatusBadge(rentReceipt.state)}</span>
                          <Link to={`/property-profile/${rentReceipt?.house?.id}`}>
                            <span>{rentReceipt.house?.formattedHouseName}</span>
                          </Link>
                        </Col>}
                        <Col xs={2} className={houseIdFromPropertyProfile ? "d-flex flex-row" : ""}>
                          {houseIdFromPropertyProfile && <span className="pr-3">{this.getReceiptStatusBadge(rentReceipt.state)}</span>}
                          <span>{rentReceipt.requestor ? `${(rentReceipt.requestor?.fullName)}` : `Not Available`}</span>
                        </Col>
                        <Col xs={2}>
                          {rentReceipt.formattedDurationLength ? (`${formatDate(rentReceipt?.startDate)} - ${formatDate(rentReceipt?.endDate)} (${rentReceipt.formattedDurationLength})`) : `${formatDate(rentReceipt?.startDate)} - ${formatDate(rentReceipt?.endDate)}`}
                        </Col>
                        <Col xs={houseIdFromPropertyProfile ? 3 : 2}>{formatDate(rentReceipt?.createdAt)}</Col>
                        <Col xs={houseIdFromPropertyProfile ? 4 : 3}>
                          {rentReceipt?.unsignedFileUrl && <><a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={`${rentReceipt.unsignedFileUrl}`} title="Click to View">
                            <span>Unsigned Receipt</span>
                          </a>
                            <br /> </>}
                          {rentReceipt?.signedFileUrl && <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={`${rentReceipt.signedFileUrl}`} title="Click to View">
                            <span>Signed Receipt</span>
                          </a>}
                        </Col>
                      </Row>
                      <hr className="my-1" />
                    </>
                  )
                  }
                </Card.Body>
              </Card>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>
      </>
    )
  }
}