import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all pending house offerings
 * @param {Object} data
 */
async function getHouseOfferings(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseOfferings`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getHouseOfferings ', err);
  }

}

/**
 * Method ot mark onboarding status
 * @param {Number} id 
 * @param {Object} data 
 */
async function markOnboardingStatus(id,data) {
  try {

    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseOfferings/${id}/markOnboardingStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markOnboardingStatus ', err);
  }
}

/**
 * Method ot mark onboarding terminal status
 * @param {Number} id 
 * @param {Object} data 
 */
async function markOnboardingTerminalStatus(id,data) {
  try {

    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseOfferings/${id}/markOnboardingTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markOnboardingTerminalStatus   ', err);
  }
}


export const PendingOnboardingServices = {
    getHouseOfferings,
    markOnboardingStatus,
    markOnboardingTerminalStatus
};