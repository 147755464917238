import React, { Component } from 'react';
import { Form, Col, Row, Button, Modal } from 'react-bootstrap';
import { formatDateTime, formatShortDate } from '../../../_metronic/utils/utils';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import { Formik } from 'formik';
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
export default class TaskItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      // serviceRequestId: this.props.serviceRequestId,
      // houseId: this.props.houseId,
      // item: this.props.item,
      // approverData: this.props.approverData,
      showDeleteTaskModal: false,
      showEditTaskModal: false
    }
  }

  //   title: "test 1"
  // createdAt: "2020-04-02T04:25:55.153Z"
  // scheduledAt: "2020-04-18T17:30:00.000Z"

  static getDerivedStateFromProps(props, state) {
    let result = {}
    let isDifferent = false;

    if (
      state.item.title !== props.item.title ||
      state.item.scheduledAt !== props.item.scheduledAt ||
      state.item.completedAt !== props.item.completedAt ||
      state.item.createdAt !== props.item.createdAt
    ) {
      isDifferent = true;
    }

    if (state.item.length !== props.item.length || isDifferent) {
      result.item = props.item
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  handleDeleteTaskButtonClick = (id = null) => {

    if (id !== null) {
      this.setState({ idToBeDeleted: id })
    }
    this.setState(state => ({ showDeleteTaskModal: !state.showDeleteTaskModal }));

  }

  handleConfirmDeleteScheduleButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await ServiceRequestsServices.deleteTasks(this.state.idToBeDeleted);
      if (result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Task deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        if (this.props.callFromCard === true) {
          this.props.cardCallback(true);
        } else {
          this.props.taskListCallback(true);
        }
        this.setState(state => ({ showDeleteTaskModal: !state.showDeleteTaskModal }));
      }
    }
  }

  handleEditTaskButtonClick = () => {
    //showEditTaskModal
    this.setState(state => ({ showEditTaskModal: !state.showEditTaskModal }));
  }
  handleChecked = async (_e) => {
    if (_e.target.validity.valid) {
      if (_e.target.checked === true) {
        const result = await ServiceRequestsServices.markTaskAsDone(this.state.item.id);
        if (result.status === 200) {
          // this.props.parentCallback(this.props.serviceRequestId);
          store.addNotification({
            title: "Success!",
            message: `Task completed!`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
              showIcon: true,
              pauseOnHover: true
            }
          });
          if (this.props.callFromCard === true) {
            this.props.cardCallback(true);
          } else {
            this.props.taskListCallback(true);
          }
        } else {
          console.error('Something went wrong');
        }
      }

    }
  }

  render() {
    return (
      <>
        <Row>
          <Col xs={8}>
            <Form.Group controlId={"task" + this.state.item.id} className="mb-3">
              <Form.Check type="checkbox">
                <Form.Check.Input type="checkbox"
                  checked={this.state.item.completedAt !== null ? true : false}
                  onChange={(_e) => this.handleChecked(_e)}
                  disabled={this.state.allowAddTasks === false ? true : false}
                />
                <Form.Check.Label className="ml-2">{this.state.item.title}<br />
                  <p className="small">
                    {this.props.callFromCard ? formatShortDate(this.state.item.scheduledAt) : formatDateTime(this.state.item.scheduledAt)} {this.state.item.assignee && <strong>| {this.state.item.assignee.fullName}</strong>}
                    {this.state.item.house && <>
                      <br />
                      <strong>{this.state.item.house.formattedHouseName}</strong>
                    </>
                    }
                  </p>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Col>
          <Col xs={4} className='text-right'>
            {(this.state.allowAddTasks !== false) &&
              !this.state.item.completedAt &&
              <>
                <Button variant="clean" size="sm" onClick={() => this.handleEditTaskButtonClick(this.state.item.id)}><i className="flaticon2-edit mr-0"></i></Button>
                <Button variant="clean" size="sm" onClick={() => this.handleDeleteTaskButtonClick(this.state.item.id)}><i className="flaticon2-trash mr-0"></i></Button>
              </>
            }
          </Col>

        </Row>

        <Modal backdrop="static" keyboard={false} show={this.state.showDeleteTaskModal} onHide={this.handleDeleteTaskButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Task?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteTaskButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteScheduleButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal backdrop="static" keyboard={false} show={this.state.showEditTaskModal} onHide={this.handleEditTaskButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                task: {
                  // taskId: this.state.item.id || undefined,
                  title: this.state.item && this.state.item.title,
                  scheduledAt: this.state.item && this.state.item.scheduledAt
                }
              }}
              validate={values => {
                const errors = {};
                if (!values.task.scheduledAt) {
                  errors.task.scheduledAt = "Schedule Date Time is required";
                }
                if (!values.task.title) {
                  errors.task.title = "Task is required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                const result = await ServiceRequestsServices.editTasks(this.state.item && this.state.item.id, values);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: `Task rescheduled!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  if (this.props.callFromCard === true) {
                    this.props.cardCallback(true);
                  } else {
                    this.props.taskListCallback(true);
                  }
                  this.setState(state => ({ showEditTaskModal: !state.showEditTaskModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      <Col xs={12}>
                        <Form.Group controlId="task.title">
                          <Form.Label>Task</Form.Label>
                          <Form.Control name="task.title" type="text" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.task.title} />
                          {errors.task && errors.task.title && touched.task && touched.task.title && <Form.Text className="text-danger">{errors.task.title}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="task.scheduledAt">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Schedule For'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='task.scheduledAt'
                            value={formatDateTime(values.task.scheduledAt)}
                            onChange={setFieldValue}
                          />
                          {errors.task && errors.task.scheduledAt && touched.task && touched.task.scheduledAt && errors.task.scheduledAt}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditTaskButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    );
  }

}
