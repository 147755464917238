import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all inspections
 * @param {Object} data 
 */
async function getAllInspections(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseInspectionWorks`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllInspections ', err);
  }

}

/**
 * Method to get inspection by id
 * @param {Number} id 
 */
async function getInspectionById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseInspectionWorks/${id}`);
    return result;
  } catch (err) {
    console.error('error in getInspectionById ', err);
  }

}

/**
 * Method to close inspection
 * @param {Number} id 
 * @param {Object} values 
 */
async function closeInspection(id, values) {
  try {
    const data = {
      houseInspectionWork: {
        ...values
      }
    }
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseInspectionWorks/${id}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in closeInspection ', err);
  }
}

/**
 * Method to create inspection report
 * @param {Object} data 
 */
async function createHouseInspection(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/houseInspections`, data);
    return result;
  } catch (err) {
    console.error('error in createHouseInspection ', err);
  }
}
/**
 * Method to create house inspection work
 * @param {Object} data
 */
async function createHouseInspectionWork(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/houseInspectionWorks`, data);
    return result;
  } catch (err) {
    console.error('error in createHouseInspectionWorks ', err);
  }
}

export const InspectionServices = {
  getAllInspections,
  getInspectionById,
  closeInspection,
  createHouseInspection,
  createHouseInspectionWork,
};