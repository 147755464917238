import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import {
  Container, Row, Col, Card, Button, Form, Badge, Accordion,
  Modal
} from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import EstimatesComponent from '../../../partials/layout/EstimatesComponent.js';
import TasksListComponent from '../../../partials/layout/TasksListComponent.js';
import SchedulesListComponent from '../../../partials/layout/SchedulesListComponent';
import PhotoGridComponent from '../../../partials/layout/PhotoGridComponent';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { ServiceRequestsServices } from './ServiceRequests.services'
import { commonServices } from '../../../../app/commonServices/commonServices';
import { HouseOnboardingServices } from '../HouseOnboarding/HouseOnboarding.services';
import ServiceRequestTimelineComponent from '../../../partials/layout/ServiceRequestTimelineComponent';
import * as constants from '../../../constants';
import {
  formatProperCase, formatDate, formatMultipleUserRoles, formatMedia, formatMultipleUsersWithoutSpan,
  datePlusOneDay
} from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as Yup from 'yup'; // for everything
import * as firebase from 'firebase/app';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';

const newServiceRequestSchema = Yup.object().shape({
  houseId: Yup.number().required('Property is a mandaotory field'),
  requestorId: Yup.number().required('Requestor is a mandatory field'),
  serviceRequestCategoryId: Yup.number().required('Category is a mandatory field'),
  priority: Yup.string().notRequired(),
  ownerVisibility: Yup.string().required('Visible to owner is mandatory field'),
  tenantVisibility: Yup.string().required('Visible to tenant is mandatory field'),
  nextAction: Yup.string().required('Next Action is mandatory field'),
  expectedClosureDate: Yup.date().required('Expected Closure Date is mandatory field'),
  title: Yup.string().required('Title is a mandatory field'),
  description: Yup.string().notRequired(),
  // files: Yup.array().of(Yup
  //   .mixed()
  //   // .required("A file is required")
  //   // .test(
  //   //     "fileSize",
  //   //     "File too large",
  //   //     value => value && value.size <= FILE_SIZE
  //   // )
  //   .test(
  //     "fileFormat",
  //     "Please upload one of these extensions: .jpg, .jpeg, .gif or .png (audio), mp4, .mov, .wmv, .flv or .mkv (video)",
  //     value => value && constants.SUPPORTED_MEDIA_FORMATS.includes(value.type)
  //   )
  // )
});

const editServiceRequestSchema = Yup.object().shape({
  serviceRequestCategoryId: Yup.number().required('Category is a mandatory field'),
  priority: Yup.string().notRequired(),
  ownerVisibility: Yup.string().required('Visible to owner is mandatory field'),
  tenantVisibility: Yup.string().required('Visible to tenant is mandatory field'),
  nextAction: Yup.string().required('Next Action is mandatory field'),
  expectedClosureDate: Yup.date().required('Expected Closure Date is mandatory field'),
  title: Yup.string().required('Title is a mandatory field'),
  description: Yup.string().notRequired(),
});

const assignServiceRequestSchema = Yup.object().shape({
  serviceRequestId: Yup.number().required('Service Request Id is a mandatory'),
  assigneeId: Yup.number().required('Assignee is a mandatory field'),
  assignmentRemarks: Yup.string().required('Remarks is required field'),
});

const acceptServiceRequestSchema = Yup.object().shape({
  ownerVisibility: Yup.string().required('Visible to owner is mandatory field'),
  tenantVisibility: Yup.string().required('Visible to tenant is mandatory field'),
});

const rejectServiceRequestSchema = Yup.object().shape({
  ownerVisibility: Yup.string().required('Visible to owner is mandatory field'),
  tenantVisibility: Yup.string().required('Visible to tenant is mandatory field'),
});

const cancelServiceRequestSchema = Yup.object().shape({
  files: Yup.array().of(Yup
    .mixed()
    // .required("A file is required")
    // .test(
    //     "fileSize",
    //     "File too large",
    //     value => value && value.size <= FILE_SIZE
    // )
    .test(
      "fileFormat",
      "Unsupported Format. Please upload one of these extensions: .jpg, .jpeg, .gif or .png (audio), mp4, .mov, .wmv, .flv or .mkv (video)",
      value => value && constants.SUPPORTED_MEDIA_FORMATS.includes(value.type)
    )
  )
});

class ServiceRequests extends Component {

  //default component methods
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      totalCount: undefined,
      state: !props.houseIdFromPropertyProfile ? ['OPEN'] : undefined,
      isFetching: false,
      showAddServiceRequestModal: false,
      showEditServiceRequestModal: false,
      showDeleteServiceRequestModal: false,
      showAssignServiceRequestModal: false,
      showReAssignServiceRequestModal: false,
      showCloseServiceRequestModal: false,
      showAcceptServiceRequestModal: false,
      showRejectServiceRequestModal: false,
      showTimelineModal: false,
      isTaskCreated: false,
      requests: [],
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      requestedFilters: ['state'],
      isLoading: false,
      propertyOptions: [],
      propertyQuery: '',
      propertyOffset: 0,
      propertyLimit: 50,
    };

    // this.getData = this.getData.bind(this);

  }

  propertyCache = {};

  /**
   * Component Did Mount method
   */
  componentDidMount = async () => {
    await this.getData();
    await this.getServiceRequestAssigneeData();
    await this.getCities();
    await this.getPropertyManagers();
  }

  /**
     * Method to get property managers
     */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
   * Method to get all the cities
   */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to get service reqeust data
   */
  getData = async () => {
    this.setState({ isFetching: true });
    const { state, offset, limit, houseIdFromPropertyProfile, cityIds, minimumCreatedAt,
      maximumCreatedAt, propertyManagerId, minimumClosureDatetime, maximumClosureDatetime } = this.state;
    let newMaximumCreatedAt = maximumCreatedAt && datePlusOneDay(maximumCreatedAt);
    let newMaximumClosureDatetime = maximumClosureDatetime && datePlusOneDay(maximumClosureDatetime);
    const data = {
      state: (state && state[0]) || undefined,
      cityIds,
      minimumCreatedAt,
      maximumCreatedAt: newMaximumCreatedAt,
      minimumClosureDatetime,
      maximumClosureDatetime: newMaximumClosureDatetime,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      offset,
      limit,
      houseId: houseIdFromPropertyProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER']
    }
    const dataForPropertyData = {
      offset,
      limit: 100,
    }

    const serviceRequestsData = await ServiceRequestsServices.getServiceRequestsData(data);
    if (serviceRequestsData && serviceRequestsData.status === 200) {
      const totalCount = serviceRequestsData.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit)
      await this.setState({
        requests: serviceRequestsData.data.serviceRequests,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    if (!this.props.houseIdFromPropertyProfile) {
      const propertyData = await commonServices.getPropertyData(dataForPropertyData)
      if (propertyData && propertyData.status === 200) {
        await this.setState({ initialHouseData: propertyData.data.houses })
      }
    }
    this.setState({ isFetching: false });
  }

  /**
   * Method to get service request by id
   * @param {Number} id
   */
  getServiceRequestDetails = async (id) => {
    const result = await ServiceRequestsServices.getServiceRequestById(id);
    if (result && result.status === 200) {
      const rawMedia = result.data && result.data.serviceRequest && result.data.serviceRequest.files;
      const formattedMedia = formatMedia(rawMedia);
      await this.setState({
        accordionData: result.data.serviceRequest,
        toBeEdited: result.data.serviceRequest,
        formattedMedia
      });
      this.state.requests.forEach(req => {
        if(this.state.toBeEdited?.id === req?.id){
          req.priority =this.state.toBeEdited.priority;
        }
        if(this.state.accordionData?.id === req?.id){
          req.expectedClosureDate =this.state.accordionData.expectedClosureDate;
        }
      })
      
      // await this.setState({ toBeEdited: result.data.serviceRequest });
    }
  }

  /**
   * Method to get assignee data for service request
   */
  getServiceRequestAssigneeData = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertySuperVisors: result.data.users })
    }
  }

  /**
   * Method to get stake holder data for service request
   */
  getStakeHolderData = async () => {
    const { houseIdFromPropertyProfile } = this.state;
    const data = {
      houseId: houseIdFromPropertyProfile || (this.state.accordionData && this.state.accordionData.house && this.state.accordionData.house.id),
      stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS']
    }
    const requestorData = await commonServices.getUserListData(data);
    if (requestorData && requestorData.status === 200) {
      await this.setState({ approverData: requestorData.data.users });
    }
  }


  /**
   * Method to get stake holder data for service request
   */
  // getTaskAssignee = async () => {
  //   const data = {
  //     userRoleCodes: ['PROPERTY_SUPERVISOR'],
  //     isVendor: 'TRUE'
  //   }
  //   const userData = await commonServices.getUserListData(data);
  //   if (userData && userData.status === 200) {
  //     (userData.data.users).push(this.props.userDetails);
  //     await this.setState({ taskAssigneeData: userData.data.users });
  //   }
  // }

  /**
   * Method to get service reqeust badge
   * @param {String} requestStatus
   */
  getReqStatusBadge = (requestStatus) => {
    return requestStatus === 'CLOSED' ? <Badge variant="info" title="Closed">C</Badge> : <Badge variant="success" title="Open">O</Badge>
  }

  /**
   * Method to hand 'New Service Request' button click
   */
  handleNewRequestButtonClick = async () => {
    if (this.state.houseIdFromPropertyProfile) {
      await this.getStakeHolderData();
    }
    await this.setState(state => ({ showAddServiceRequestModal: !state.showAddServiceRequestModal }));
    if (this.state.showAddServiceRequestModal === true) {
      firebase.analytics().logEvent("new_request_button_click", { description: "New Service Request Button Clicked" });
    }
  }

  /**
   * Method to handle 'Edit Service Request' button click
   * @param {Number} id
   */
  handleEditRequestButtonClick = async (id = null) => {
    await this.setState(state => ({ showEditServiceRequestModal: !state.showEditServiceRequestModal }));
    if (this.state.showEditServiceRequestModal === true) {
      firebase.analytics().logEvent("edit_request_button_click", { description: "Edit Service Request Button Clicked", srId: id });
    }
    // if (id !== null) {
    //   const elementToBeEdited = this.state.requests.find(x => x.id === id);
    //   await this.setState({ toBeEdited: elementToBeEdited });
    // }
  }

  /**
   * Method to handle 'Delete' service request button click
   * @param {Number} id
   */
  handleDeleteRequestButtonClick = async (id = null) => {
    this.setState(state => ({ showDeleteServiceRequestModal: !state.showDeleteServiceRequestModal }));
    if (id) {
      await this.setState(state => ({
        idToBeDeleted: id
      }))
    }
  }

  /**
   * Method to handle confirm of delete service request
   */
  handleApproveDeleteRequestButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await ServiceRequestsServices.deleteServiceRequests(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: "The service request has been deleted successfully!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        })
        this.setState(state => ({ showDeleteServiceRequestModal: !state.showDeleteServiceRequestModal }));
        await this.getData();
      }
    }
  }

  /**
   * Method to handle assign service request button click
   */
  handleAssignServiceRequestButtonClick = async (assignReassign = null) => {
    // if(id !== null){
    //   // this.getServiceRequestAssigneeData();
    //   this.setState({assignServiceRequestId: id})
    // }
    if (assignReassign !== null) {
      this.setState({ assignReassign })
    }
    await this.setState(state => ({ showAssignServiceRequestModal: !state.showAssignServiceRequestModal }));
    if (this.state.showAssignServiceRequestModal === true) {
      firebase.analytics().logEvent("assign_request_button_click", { description: "Assign Service Request Button Clicked", reassign: this.state.assignReassign });
    }
  }

  /**
   * Method to handle 'Re-assign' service request button click
   */
  // handleReAssignServiceRequestButtonClick = () => {
  //   this.setState(state => ({ showReAssignServiceRequestModal: !state.showReAssignServiceRequestModal }));
  // }

  /**
   * Method to handle 'Close' service request button click
   * @param {Number} id
   */
  handleCloseRequestButtonClick = async (id = null) => {
    if (id !== null) {
      this.setState({ serviceIdToBeClosed: id });
    }
    await this.setState(state => ({ showCloseServiceRequestModal: !state.showCloseServiceRequestModal }));
    if (this.state.showCloseServiceRequestModal === true) {
      firebase.analytics().logEvent("close_request_button_click", { description: "Close Service Request Button Clicked", srId: id });
    }
  }

  /**
   * Method to handle approval of 'Close' service request button click
   */
  handleConfirmCloseRequestButtonClick = async () => {
    if (this.state.serviceIdToBeClosed) {
      const result = await ServiceRequestsServices.closeServiceRequests(this.state.serviceIdToBeClosed);
      if (result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: "Service request closed!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showCloseServiceRequestModal: !state.showCloseServiceRequestModal }));
        await this.getData();
      }
    }
  }

  /**
   * Method to handle 'Re-open' service request
   */
  handleReOpenServiceRequest = () => {
    this.setState(state => ({ showReOpenServiceRequestModal: !state.showReOpenServiceRequestModal }))
  }

  /**
   * Method to handle 'Re-close' service request
   */
  handleReCloseServiceRequest = () => {
    this.setState(state => ({ showReCloseServiceRequestModal: !state.showReCloseServiceRequestModal }))
  }

  /**
   * Method to handle 'View Timeline' button click
   */
  handleViewTimelineButtonClick = async () => {
    await this.setState(state => ({ showTimelineModal: !state.showTimelineModal }));
    if (this.state.showTimelineModal === true) {
      firebase.analytics().logEvent("view_service_request_timeline_button_click", { description: "View Timeline Button on Service Request Clicked" });
    }
  }

  /**
   * Method to handle 'Accept' service request button click
   * @param {Number} id
   */
  handleAcceptServiceRequestButtonClick = async (id = null) => {
    await this.setState(state => ({ showAcceptServiceRequestModal: !state.showAcceptServiceRequestModal }));
    if (this.state.showAcceptServiceRequestModal === true) {
      firebase.analytics().logEvent("accept_request_button_click", { description: "Accept Service Request Button Clicked", srId: id });
    }
    // if (id) {
    //   await this.setState(state => ({
    //     idToBeAccepted: id
    //   }))
    // }
  }

  /**
   * Method to handle 'Accept' service request confirmation
   */
  // handleAcceptServiceRequestConfirmationClick = async () => {
  //   const data = {
  //     'serviceRequestId': this.state.idToBeAccepted,
  //     'acceptanceStatus': 'YES'
  //   }
  //   const result = await ServiceRequestsServices.acceptanceStatus(data);
  //   if (result.status === 200) {
  //     this.setState(state => ({ showAcceptServiceRequestModal: !state.showAcceptServiceRequestModal }));
  //     await this.getServiceRequestDetails(this.state.idToBeAccepted);
  //   } else {
  //     console.error('Something went wrong!');
  //   }
  // }

  /**
 * Method to handle 'Reject' service request button click
 * @param {Number} id
 */
  handleRejectServiceRequestButtonClick = async (id = null) => {
    await this.setState(state => ({ showRejectServiceRequestModal: !state.showRejectServiceRequestModal }));
    if (this.state.showRejectServiceRequestModal === true) {
      firebase.analytics().logEvent("reject_request_button_click", { description: "Reject Service Request Button Clicked", srId: id });
    }

    // if (id) {
    //   await this.setState(state => ({
    //     idToBeRejected: id
    //   }))
    // }
  }

  /**
   * Method to handle 'Reject' service request
   */
  // handleRejectServiceRequestConfirmationClick = async () => {
  //   const data = {
  //     'serviceRequestId': this.state.idToBeRejected,
  //     'acceptanceStatus': 'NO'
  //   }
  //   const result = await ServiceRequestsServices.acceptanceStatus(data);
  //   if (result.status === 200) {
  //     this.setState(state => ({ showRejectServiceRequestModal: !state.showRejectServiceRequestModal }));
  //     await this.getData();
  //   }
  // }

  /**
   * Method to handle accordion click
   * @param {Number} id
   */
  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getServiceRequestDetails(id);
      await this.getStakeHolderData();
      // await this.getTaskAssignee();
      firebase.analytics().logEvent("service_request_details_view_click", { description: "Service Request Details View Clicked", srId: id });
    }
  }

  /**
   * Method to handle data from child components
   * @param {Number} childData
   */
  handleChildData = async (childData) => {
    await this.getServiceRequestDetails(childData);
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getData();
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      console.log(childData);
      this.setState({ ...childData });
      await this.resetPage();
      await this.getData();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Service Requests' });
    }
  }

  handlePropertyInputChange = propertyQuery => {
    this.setState({ propertyQuery });
  };

  handlePropertyPagination = async (e, shownResults) => {
    const { propertyQuery } = this.state;
    const cachedPropertyQuery = this.propertyCache[propertyQuery];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedPropertyQuery.options.length > shownResults ||
      cachedPropertyQuery.options.length === cachedPropertyQuery.total_count
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const propertyPage = cachedPropertyQuery.propertyPage + 1;

    const { propertyLimit } = this.state;
    const propertyOffset = (propertyLimit * (propertyPage - 1));
    await this.setState({ propertyPage, propertyOffset });

    const response = await this.getAllProperties();
    const propertyOptions = cachedPropertyQuery.options.concat(response.options);
    this.propertyCache[propertyQuery] = { ...cachedPropertyQuery, propertyOptions, propertyPage, propertyOffset };
    this.setState({
      isLoading: false,
      propertyOptions,
    });
  };

  handlePropertySearch = async (propertyQuery) => {
    if (this.propertyCache[propertyQuery]) {
      this.setState({ propertyOptions: this.propertyCache[propertyQuery].options });
      return;
    }
    this.setState({ isLoading: true });
    const response = await this.getAllProperties();
    this.propertyCache[propertyQuery] = { ...response, propertyPage: 1 };
    this.setState({
      isLoading: false,
      propertyOptions: response && response.options,
    });
  };

  /**
     * Method to get all the properties Data
     */
  getAllProperties = async () => {
    this.setState({ isLoading: true });
    const { propertyOffset, propertyLimit, propertyQuery } = this.state;
    const data = {
      searchQuery: propertyQuery,
      offset: propertyOffset,
      limit: propertyLimit,
      scope: 'ALL',
      include: ['OWNERS', 'CURRENT_TENANTS']
    }
    const result = await HouseOnboardingServices.getAllProperties(data);
    if (result && result.status === 200) {
      const total_count = result.headers['x-total-count'];
      const options = result.data.houses.map(houseData => ({
        id: houseData.id,
        property: `${houseData.formattedHouseName} | Owners: ${formatMultipleUsersWithoutSpan(houseData.owners)} | Tenants: ${formatMultipleUsersWithoutSpan(houseData.currentTenants)}`,
        propertyManagementStatus: houseData.propertyManagementStatus
      }));
      return { options, total_count };
    }
    this.setState({ isLoading: false });
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  //render function with view & modals
  //TODO: extract modals into separate components/files if needed
  render() {
    const { requests, accordionData, page, total, totalCount, houseIdFromPropertyProfile,
      serviceRequestCategories, assignReassign, formattedMedia, requestedFilters, state,
      formattedCities, preSelectedCities, minimumCreatedAt, maximumCreatedAt,
      propertyManagers, propertyManagerId, minimumClosureDatetime, maximumClosureDatetime
    } = this.state;
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (this.state.isFetching) {
      return <p>Loading data...</p>
    }

   return (
      <>
        <Container fluid key={0}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Service Requests (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  <Button className="mr-2" variant="link" size="sm" onClick={this.handleNewRequestButtonClick}>New Request</Button>
                  {/* <Accordion.Toggle as={Card.Header} eventKey={req.id} className="row py-2 azuro-table-row"
                    onClick={() => { this.handleAccordionClick(req.id) }}>
                  </Accordion.Toggle> */}

                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                requestedFilters={requestedFilters}
                state={state}
                minimumCreatedAt={minimumCreatedAt}
                maximumCreatedAt={maximumCreatedAt}
                minimumClosureDatetime={minimumClosureDatetime}
                maximumClosureDatetime={maximumClosureDatetime}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Accordion defaultActiveKey="0" className="mt-2">
                {requests && requests.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {requests && requests.length > 0 && <Card key={'headerrq'}>
                  <Card.Header>
                    <Row className="py-2 px-3 azuro-table-header">
                      <Col md={houseIdFromPropertyProfile ? 4 : 3}><strong>Request</strong></Col>
                      {!houseIdFromPropertyProfile && <Col md={2}><strong>Property</strong></Col>}
                      <Col md={houseIdFromPropertyProfile ? 3 : 2}><strong>Requestor</strong></Col>
                      <Col md={2}><strong>Assignee</strong></Col>
                      <Col md={2}><strong>Expected Closure Date</strong></Col>
                    </Row>
                  </Card.Header>
                </Card>}
                {requests && requests.map(req =>
                  <Card key={req.id + 'rq'}>
                    <Accordion.Toggle as={Card.Header} eventKey={req.id} className="row py-2 azuro-table-row"
                      onClick={() => { this.handleAccordionClick(req.id) }}>
                      <Col md={houseIdFromPropertyProfile ? 4 : 3} className="d-flex flex-row"><span className="pr-3">{this.getReqStatusBadge((accordionData && (accordionData.id === req.id) && accordionData.state) || req.state)}</span> <span>{(accordionData && (accordionData.id === req.id) && accordionData.title) || req.title} {req.priority!==null && req.priority!=='LOW'? <span ><p><small>Priority:<Badge variant={req.priority==='HIGH'?"danger":''} >{req.priority}</Badge>{' '}</small></p></span>:''}</span></Col>
                      {!houseIdFromPropertyProfile &&
                        <Col md={2}>
                          {req.house && <span>{req.house.formattedHouseName} <br />
                            {req.house.propertyManager ? <span className="small text-muted">PM: {req.house.propertyManager && req.house.propertyManager.fullName}</span> : <span className="small text-warning">PM: Not Assigned</span>}
                          </span>}
                        </Col>}
                      <Col md={houseIdFromPropertyProfile ? 3 : 2}>{req.requestor && req.requestor.fullName} ({formatProperCase(req.userCategory)})</Col>
                      <Col md={2}>
                        {req?.schedules && req?.schedules?.length > 0 && req?.schedules[0]?.assignments?.length > 0 && req?.schedules[0]?.status !== 'COMPLETED' && req?.schedules[0]?.status !== 'CANCELLED' &&
                          req?.schedules[0].assignments[0].assignee.fullName}
                        {req?.schedules && req?.schedules?.length > 0 && req?.schedules[0]?.assignments?.length > 0 && req?.schedules[0]?.status === 'COMPLETED' &&
                          <span className="small text-success">Scheduled Job Completed</span>}
                        {req?.schedules && req?.schedules?.length > 0 && req?.schedules[0]?.assignments?.length > 0 && req?.schedules[0]?.status === 'CANCELLED' &&
                          <span className="small text-danger">Scheduled Job Cancelled</span>}
                        {req?.schedules && req?.schedules?.length > 0 && req?.schedules[0]?.assignments?.length === 0 &&
                          <span className="small text-warning">Schedule Not Assigned</span>}
                        {req?.schedules && req?.schedules?.length === 0 &&
                          <span className="small text-muted">Not Scheduled</span>}
                      </Col>
                      <Col md={2} className="">
                        <span>{req.expectedClosureDate ? formatDate(req.expectedClosureDate) : <span className="small text-warning">Not Set</span>} <br />
                          <span className="small text-muted">{`Created At: ${formatDate(req.createdAt)}`}</span>
                        </span>
                      </Col>
                      {req.house && <Col md={1}>
                        <Link to={`/property-profile/${req.house.id}`}>
                          <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                        </Link>
                      </Col>}
                    </Accordion.Toggle>
                    {accordionData && (accordionData.id === req.id) &&
                      <Accordion.Collapse key={accordionData.id} eventKey={accordionData && accordionData.id}>
                        <Card.Body>
                          <Row className="mb-4 d-flex align-items-center">
                            <Col xs={10}>
                              <h5 className="mb-0">{accordionData && accordionData.title} <Badge variant="secondary" className="ml-2">{(accordionData.serviceRequestCategory && accordionData.serviceRequestCategory.name) || 'Not Set'}</Badge></h5>
                            </Col>
                            <Col xs={2}>
                              {
                                req.state && req.state === 'OPEN' &&
                                <>
                                  <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleEditRequestButtonClick(accordionData.id)}><i className="flaticon2-edit"></i></Button>
                                  {/* {' | '}
                                <Button variant="clean" size="sm" onClick={() => this.handleDeleteRequestButtonClick(accordionData.id)}><i className="flaticon2-trash"></i></Button> */}
                                </>
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <p>{accordionData.description}</p>
                            </Col>
                            <Col xs={6}>
                              {/* TODO: uncomment this and add assignments logic here 
                              {accordionData.state && accordionData.state === 'OPEN' && <p><strong>Assignee: </strong> {' '}
                                {accordionData.assignments && accordionData?.assignments?.length > 0 &&
                                  (accordionData?.assignments[req.assignments.length - 1].assigneeCategory === 'EMPLOYEE' ? accordionData.assignments[req.assignments.length - 1].assignee.fullName : accordionData.assignments[req.assignments.length - 1].assigneeName)
                                }

                                {
                                  (accordionData.assignments.length === 0) &&
                                  <Button variant="clean" size="sm" onClick={() => this.handleAssignServiceRequestButtonClick({ showAssign: true })}>Assign</Button>
                                }
                                {
                                  (accordionData.assignments.length > 0) &&
                                  <Button variant="clean" size="sm" onClick={() => this.handleAssignServiceRequestButtonClick({ showReassign: true })}>Reassign</Button>
                                }
                              </p>
                              } */}

                              {/* {accordionData.state && accordionData.assignments.length > 0 && <p><strong>Assignee:</strong>
                                {req.accordionData || ''} ({req.accordionData || ''})
                                <Button variant="clean" size="sm" onClick={() => this.handleReAssignServiceRequestButtonClick({ showReassign: true })}>Reassign</Button>
                              </p>
                              } */}

                            </Col>
                            <Col xs={6}>
                              <p><strong>Requestor:</strong> {accordionData.requestor && accordionData.requestor.fullName} {accordionData.requestor && accordionData.requestor.phoneNumbers.length > 0 && <span>({`+` + accordionData.requestor.phoneNumbers
                              [0].countryCode}-{accordionData.requestor.phoneNumbers[0].number})</span>}</p>
                            </Col>

                            {accordionData.nextAction && <Col xs={6}>
                              <p><strong>Next Action:</strong> {accordionData.nextAction}</p>
                            </Col>}

                            {accordionData.expectedClosureDate && <Col xs={6}>
                              <p><strong>Expected Closure Date:</strong> {accordionData.expectedClosureDate}</p>
                            </Col>}

                            <Col xs={12}>
                              <p><strong>{`Images & Videos:`}</strong></p>
                            </Col>

                            <Col xs={12} className="mb-3">
                              {/* images, mode, serviceReqeustId, reportSectionId, reportItemId, title */}
                              <PhotoGridComponent
                                medias={formattedMedia.BEFORE || []}
                                // mode={`edit`}
                                serviceRequestId={accordionData.id}
                                title={`BEFORE`}
                                photoGridComponentParentCallback={this.handleChildData}
                                identifier={`BEFORE`}
                              />
                              {accordionData && accordionData.state === 'CLOSED' &&
                                <PhotoGridComponent
                                  medias={formattedMedia.AFTER || []}
                                  // mode={`edit`}
                                  serviceRequestId={accordionData.id}
                                  title={`AFTER`}
                                  photoGridComponentParentCallback={this.handleChildData}
                                  identifier={`AFTER`}
                                />}
                            </Col>
                          </Row>
                          <EstimatesComponent estimates={accordionData.costEstimates || []}
                            currency={accordionData.costEstimates && (accordionData.costEstimates.length > 0) && accordionData.costEstimates[0].currency}
                            allowAddEstimates={(req.state && req.state !== 'CLOSED')}
                            serviceRequestId={req.id}
                            approverData={this.state.approverData && this.state.approverData.length > 0 && this.state.approverData}
                            houseId={accordionData && accordionData.house && accordionData.house.id}
                            parentCallback={this.handleChildData}
                          />

                          <SchedulesListComponent schedules={accordionData.schedules || []} allowAddSchedules={
                            (req.state && req.state !== 'CLOSED')
                          } serviceRequestId={req.id}
                            parentCallback={this.handleChildData}
                            house={accordionData?.house}
                          />
                          <TasksListComponent tasks={accordionData.tasks || []} allowAddTasks={
                            req.state && req.state !== 'CLOSED'
                          }
                            serviceRequestId={req.id}
                            // houseId={req.house && req.house.id}
                            parentCallback={this.handleChildData}
                            approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                            houseId={accordionData?.house?.id}
                          />
                          <NotesComponent notes={accordionData.notes || []} allowAddNotes={
                            req.state && req.state !== 'CLOSED'
                          }
                            serviceRequestId={req.id}
                            parentCallback={this.handleChildData}
                          />
                          <Row className="mb-4">
                            <Col xs={12} className="text-right">
                              {
                                !accordionData.acceptanceStatus &&
                                <>
                                  <Button className='mr-2' variant='danger' size="sm" onClick={() => this.handleRejectServiceRequestButtonClick(req.id)}> Reject Request</Button>
                                  <Button size="sm" variant='success' onClick={() => this.handleAcceptServiceRequestButtonClick(req.id)}> Accept Request</Button>
                                </>

                              }

                              {
                                accordionData.acceptanceStatus &&
                                <ServiceRequestTimelineComponent serviceRequestId={req.id} />
                              }

                              {
                                accordionData.state && accordionData.state !== 'CLOSED' &&
                                accordionData.acceptanceStatus &&
                                <Button variant="success" size="sm" onClick={() => this.handleCloseRequestButtonClick(req.id)}>Close Request</Button>
                              }
                              {/* {accordionData.state && accordionData.state === 'CLOSED' &&
                                <Button variant="primary" size="sm" onClick={this.handleReOpenServiceRequest}>Reopen Request</Button>
                              } */}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    }
                  </Card>
                )
                }
              </Accordion>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={this.state.page}
                totalPages={this.state.total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key={1} show={this.state.showAddServiceRequestModal} onHide={this.handleNewRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>New Service Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                houseId: houseIdFromPropertyProfile || undefined,
                requestorId: undefined,
                serviceRequestCategoryId: undefined,
                priority: undefined,
                ownerVisibility: undefined,
                tenantVisibility: undefined,
                nextAction: undefined,
                expectedClosureDate: undefined,
                title: undefined,
                description: undefined,
                files: [{}]
              }}
              validationSchema={newServiceRequestSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let formData = new FormData();
                  values.houseId && formData.append('serviceRequest[houseId]', values.houseId);
                  values.requestorId && formData.append('serviceRequest[requestorId]', values.requestorId);
                  values.serviceRequestCategoryId && formData.append('serviceRequest[serviceRequestCategoryId]', values.serviceRequestCategoryId);
                  values.priority && formData.append('serviceRequest[priority]', values.priority);
                  values.ownerVisibility && formData.append('serviceRequest[ownerVisibility]', values.ownerVisibility);
                  values.tenantVisibility && formData.append('serviceRequest[tenantVisibility]', values.tenantVisibility);
                  values.nextAction && formData.append('serviceRequest[nextAction]', values.nextAction);
                  values.expectedClosureDate && formData.append('serviceRequest[expectedClosureDate]', values.expectedClosureDate);
                  values.title && formData.append('serviceRequest[title]', values.title);
                  values.description && formData.append('serviceRequest[description]', values.description);
                  values.files && (values.files).forEach((file, index) => {
                    formData.append(`serviceRequest[files][${index}][doc].`, file);
                  });

                  const result = await ServiceRequestsServices.createServiceRequests(formData);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: "New service request created!",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.getData();
                    this.setState(state => ({ showAddServiceRequestModal: !state.showAddServiceRequestModal }));
                    firebase.analytics().logEvent("save_new_request_button_click", { description: "Save New Service Request Button Clicked" });
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {!houseIdFromPropertyProfile && <Col xs={12}>
                        <Form.Group controlId="houseSelect">
                          <Form.Label>Property</Form.Label>
                          {/* <Form.Control as="select" name="houseId" onChange=
                              {
                                async (e) => {
                                  handleChange(e);
                                  // await this.getStakeHolderData();
                                  const data = {
                                    houseId: houseIdFromPropertyProfile || e.currentTarget.value,
                                    stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS'],
                                  }
                                  const requestorData = await commonServices.getUserListData(data);
                                  if (requestorData && requestorData.status === 200) {
                                    await this.setState({ approverData: requestorData.data.users })
                                  } else {
                                    console.error('Something went wrong!');
                                  }
                                }
                              }
                              onBlur={handleBlur} value={values.houseId}>
                              <option value='0'>{constants.DEFAULT_OPTION}</option>
                              {this.state.initialHouseData && this.state.initialHouseData.map((houseData =>
                                <option key={"houseData" + houseData.id} value={houseData.id}>{houseData.formattedHouseName}</option>
                              ))
                              }
                            </Form.Control> */}
                          <AsyncTypeahead
                            // {...this.state}
                            name="houseId"
                            isLoading={this.state.isLoading}
                            options={this.state.propertyOptions}
                            query={this.state.propertyQuery}
                            id="async-pagination-property"
                            labelKey="property"
                            maxResults={this.state.propertyLimit - 1}
                            minLength={3}
                            onChange={async (e) => {
                              const houseId = (e.length > 0) ? e[0].id : undefined;
                              const houseName = (e.length > 0) ? e[0].property : undefined;
                              // const propertyManagementStatus = (e.length > 0) ? e[0].propertyManagementStatus : undefined;
                              setFieldValue('houseId', houseId);
                              setFieldValue('houseName', houseName);
                              // setFieldValue('house.propertyManagementStatus', propertyManagementStatus);
                              const data = {
                                houseId: houseId,
                                stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS'],
                              }
                              const approverData = await commonServices.getUserListData(data);
                              if (approverData && approverData.status === 200) {
                                await this.setState({ approverData: approverData.data.users })
                              } else {
                                console.error('Something went wrong!');
                              }
                            }}
                            onInputChange={this.handlePropertyInputChange}
                            onPaginate={this.handlePropertyPagination}
                            onSearch={this.handlePropertySearch}
                            paginate
                            placeholder={values.houseName ? values.houseName : "Search for your property here..."}
                            renderMenuItemChildren={option => (
                              <div key={option.id}>
                                <span>{option.property}</span>
                                {/* <br/><span className="small text-muted">{`Property Management Status: `}{option.propertyManagementStatus}</span> */}
                              </div>
                            )}
                            useCache={false}
                          />
                          {errors.houseId && touched.houseId && <Form.Text className="text-danger">
                            {errors.houseId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>}
                      <Col xs={12}>
                        <Form.Group controlId="requestorSelect">
                          <Form.Label>Requestor</Form.Label>
                          <Form.Control as="select" name="requestorId" onChange={handleChange} onBlur={handleBlur} value={values.requestorId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              this.state.approverData && this.state.approverData.map((requestorData =>
                                <option key={"requestorData" + requestorData.id} value={requestorData.id}>{requestorData.fullName}
                                  {requestorData.userSubCategory && ' (' + formatProperCase(requestorData.userSubCategory) + ')'}
                                </option>
                              ))
                            }
                          </Form.Control>
                          {errors.requestorId && touched.requestorId && <Form.Text className="text-danger">
                            {errors.requestorId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="categorySelect">
                          <Form.Label>Category</Form.Label>
                          <Form.Control as="select" name="serviceRequestCategoryId" onChange={handleChange} onBlur={handleBlur} value={values.serviceRequestCategoryId}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              serviceRequestCategories && serviceRequestCategories.map((category =>
                                <option key={"category" + category.id} value={category.id}> {category.name}  </option>
                              ))
                            }
                          </Form.Control>
                          {errors.serviceRequestCategoryId && touched.serviceRequestCategoryId && <Form.Text className="text-danger">
                            {errors.serviceRequestCategoryId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="prioritySelect">
                          <Form.Label>Priority</Form.Label>
                          <Form.Control as="select" name="priority" onChange={handleChange} onBlur={handleBlur} value={values.priority}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.HIGH_LOW.map((highLow, index) =>
                                (<option key={index} value={highLow}>{highLow}</option>)
                              )
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="ownerVisibilitySelect">
                          <Form.Label>Visible to Owner</Form.Label>
                          <Form.Control as="select" name="ownerVisibility" onChange={handleChange} onBlur={handleBlur} value={values.ownerVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.ownerVisibility && touched.ownerVisibility && <Form.Text className="text-danger">
                            {errors.ownerVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="tenantVisibilitySelect">
                          <Form.Label>Visible to Tenant</Form.Label>
                          <Form.Control as="select" name="tenantVisibility" onChange={handleChange} onBlur={handleBlur} value={values.tenantVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.tenantVisibility && touched.tenantVisibility && <Form.Text className="text-danger">
                            {errors.tenantVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="nextAction">
                          <Form.Label>Next Action</Form.Label>
                          <Form.Control type="text" name="nextAction" onChange={handleChange} onBlur={handleBlur} value={values.nextAction} />
                          {errors.nextAction && touched.nextAction && <Form.Text className="text-danger">
                            {errors.nextAction}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="expectedClosureDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Closure Date'
                            dateFormat='MMMM d, yyyy'
                            name='expectedClosureDate'
                            value={values.expectedClosureDate}
                            onChange={setFieldValue}
                          />
                          {errors.expectedClosureDate && touched.expectedClosureDate && <Form.Text className="text-danger">
                            {errors.expectedClosureDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="titleInput">
                          <Form.Label>Title</Form.Label>
                          <Form.Control type="text" name="title" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.title} />
                          {errors.title && touched.title && <Form.Text className="text-danger">
                            {errors.title}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="desriptionTextarea">
                          <Form.Label>Description</Form.Label>
                          <Form.Control as="textarea" name="description" rows="3" onChange={handleChange} onBlur={handleBlur} value={values.description} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <FieldArray
                          name="files"
                          render={arrayHelpers => (
                            <div>
                              <Row>
                                <Col xs={10}>
                                  <Form.Label>{`Add Images / Videos`}</Form.Label>
                                </Col>
                              </Row>
                              {values.files.map((file, index) =>
                              (<Row key={index} className='mt-2'>
                                <Col xs={10}>
                                  <input id={`file${index}`} name={`files[${index}]`} type="file" onChange={(event) => {
                                    setFieldValue(`files[${index}]`, event.currentTarget.files[0]);
                                  }}
                                    value={file.files} />
                                </Col>
                                <Col xs={2}>
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>
                                <Col xs={12}>
                                  {errors.files && touched.files && errors.files[index] && touched.files[index] && <Form.Text className="text-danger">
                                    {(errors.files[index])}
                                  </Form.Text>}
                                </Col>
                              </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add More</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleNewRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={2} show={this.state.showEditServiceRequestModal} onHide={this.handleEditRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Service Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                serviceId: (this.state.toBeEdited && this.state.toBeEdited.id) || undefined,
                serviceRequestCategoryId: (this.state.toBeEdited && this.state.toBeEdited.serviceRequestCategory && this.state.toBeEdited.serviceRequestCategory.id) || undefined,
                priority: (this.state.toBeEdited && this.state.toBeEdited.priority) || undefined,
                ownerVisibility: (this.state.toBeEdited && this.state.toBeEdited.ownerVisibility) || undefined,
                tenantVisibility: (this.state.toBeEdited && this.state.toBeEdited.tenantVisibility) || undefined,
                nextAction: (this.state.toBeEdited && this.state.toBeEdited.nextAction) || undefined,
                expectedClosureDate: (this.state.toBeEdited && this.state.toBeEdited.expectedClosureDate) || undefined,
                title: (this.state.toBeEdited && this.state.toBeEdited.title) || undefined,
                description: (this.state.toBeEdited && this.state.toBeEdited.description) || undefined,
              }}
              validationSchema={editServiceRequestSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const data = {
                    'serviceRequest': {
                      'serviceRequestCategoryId': values.serviceRequestCategoryId,
                      'priority': values.priority,
                      'ownerVisibility': values.ownerVisibility,
                      'tenantVisibility': values.tenantVisibility,
                      'nextAction': values.nextAction,
                      'expectedClosureDate': values.expectedClosureDate,
                      'title': values.title,
                      'description': values.description
                    }
                  }
                  let id = values.serviceId;
                  const result = await ServiceRequestsServices.updateServiceRequests(id, data);
                  if (result.status === 200 || result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: "Service Request Updated!",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    await this.getServiceRequestDetails(values.serviceId);
                    this.setState(state => ({ showEditServiceRequestModal: !state.showEditServiceRequestModal }));
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="categorySelect">
                          <Form.Label>Category</Form.Label>
                          <Form.Control as="select" name="serviceRequestCategoryId" onChange={handleChange} onBlur={handleBlur} value={values.category}>
                            {
                              serviceRequestCategories && serviceRequestCategories.map((category =>
                                <option key={"category" + category.id} value={category.id}> {category.name}  </option>
                              ))
                            }
                          </Form.Control>
                          {errors.serviceRequestCategoryId && touched.serviceRequestCategoryId && <Form.Text className="text-danger">
                            {errors.serviceRequestCategoryId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="prioritySelect">
                          <Form.Label>Priority</Form.Label>
                          <Form.Control as="select" name="priority" onChange={handleChange} onBlur={handleBlur} value={values.priority}>
                            <option key="ps0" value={null}>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.HIGH_LOW.map((highLow, index) =>
                                (<option key={index} value={highLow}>{highLow}</option>)
                              )
                            }
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="ownerVisibilitySelect">
                          <Form.Label>Visible to Owner</Form.Label>
                          <Form.Control as="select" name="ownerVisibility" onChange={handleChange} onBlur={handleBlur} value={values.ownerVisibility}>
                            <option key="vtos0" value={null}>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.ownerVisibility && touched.ownerVisibility && <Form.Text className="text-danger">
                            {errors.ownerVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="tenantVisibilitySelect">
                          <Form.Label>Visible to Tenant</Form.Label>
                          <Form.Control as="select" name="tenantVisibility" onChange={handleChange} onBlur={handleBlur} value={values.tenantVisibility}>
                            <option key="vtts0" value={null}>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.tenantVisibility && touched.tenantVisibility && <Form.Text className="text-danger">
                            {errors.tenantVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="nextAction">
                          <Form.Label>Next Action</Form.Label>
                          <Form.Control type="text" name="nextAction" onChange={handleChange} onBlur={handleBlur} value={values.nextAction} />
                          {errors.nextAction && touched.nextAction && <Form.Text className="text-danger">
                            {errors.nextAction}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="expectedClosureDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Expected Closure Date'
                            dateFormat='MMMM d, yyyy'
                            name='expectedClosureDate'
                            value={values.expectedClosureDate}
                            onChange={setFieldValue}
                          />
                          {errors.expectedClosureDate && touched.expectedClosureDate && <Form.Text className="text-danger">
                            {errors.expectedClosureDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="titleInput">
                          <Form.Label>Title</Form.Label>
                          <Form.Control type="text" name="title" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.title} />
                          {errors.title && touched.title && <Form.Text className="text-danger">
                            {errors.title}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="desriptionTextarea">
                          <Form.Label>Description</Form.Label>
                          <Form.Control as="textarea" name="description" rows="3" onChange={handleChange} onBlur={handleBlur} value={values.description} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>

          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={3} show={this.state.showDeleteServiceRequestModal} onHide={this.handleDeleteRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Service Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete all the linked estimates, tasks, schedules and notes.</p>
            <p><strong>Confirm deletion of this Service Request?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteRequestButtonClick}>
              Cancel
              </Button>
            <Button variant="danger" onClick={this.handleApproveDeleteRequestButtonClick}>
              Delete
              </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={4} show={this.state.showAssignServiceRequestModal} onHide={this.handleAssignServiceRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                assignReassign && assignReassign.showAssign === true &&
                `Assign Service Request`
              }
              {
                assignReassign && assignReassign.showReassign === true &&
                `Re-assign Service Request`
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                serviceRequestId: this.state.accordionData ? this.state.accordionData.id : undefined,
                // place: "",
                assigneeId: null,
                assignmentRemarks: ""
              }}
              validationSchema={assignServiceRequestSchema}
              onSubmit={async (values) => {
                values.assigneeId = parseInt(values.assigneeId, 10);
                const result = await commonServices.adminAssignments(values);
                if (result.status === 201) {
                  // this.props.parentCallback(values.serviceRequestId);
                  store.addNotification({
                    title: "Success!",
                    message: "Service request assigned!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.getServiceRequestDetails(values.serviceRequestId);
                  this.setState(state => ({ showAssignServiceRequestModal: !state.showAssignServiceRequestModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {/* <Col xs={12}>
                        <Form.Group controlId="place">
                          <Form.Label>Place</Form.Label>
                          <Form.Control name="place" typee="text" onChange={handleChange} onBlur={handleBlur} value={values.place} />
                        </Form.Group>
                        {errors.place && touched.place && errors.place}
                      </Col> */}
                      <Col xs={12}>
                        <Form.Check inline name="typeOfServiceRequestAssignment" onChange={() => setFieldValue("typeOfServiceRequestAssignment", "Vendor")} checked={values.typeOfServiceRequestAssignment === "Vendor"} value="Vendor" label="Assign Vendor" type="radio" id="inline-radio-1" />
                        <Form.Check inline name="typeOfServiceRequestAssignment" onChange={() => setFieldValue("typeOfServiceRequestAssignment", "PS")} checked={values.typeOfServiceRequestAssignment === "PS"} value="PS" label="Assign PS" type="radio" id="inline-radio-2" />
                      </Col>
                      {values.typeOfServiceRequestAssignment === 'Vendor' && <>
                        <Col xs={12}>
                          <Form.Group controlId="assigneeName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="assigneeName" type="text" onChange={handleChange} onBlur={handleBlur} value={values.assigneeName} />
                          </Form.Group>
                          {errors.assigneeName && touched.assigneeName && errors.assigneeName}
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="assigneePhoneCountryCode">
                            <Form.Label>Country Code</Form.Label>
                            <Form.Control name="assigneePhoneCountryCode" type="number" onChange={handleChange} onBlur={handleBlur} value={values.assigneePhoneCountryCode} />
                          </Form.Group>
                          {errors.assigneePhoneCountryCode && touched.assigneePhoneCountryCode && errors.assigneePhoneCountryCode}
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="assigneePhoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control name="assigneePhoneNumber" type="number" onChange={handleChange} onBlur={handleBlur} value={values.assigneePhoneNumber} />
                          </Form.Group>
                          {errors.assigneePhoneNumber && touched.assigneePhoneNumber && errors.assigneePhoneNumber}
                        </Col>
                      </>}

                      {values.typeOfServiceRequestAssignment === 'PS' && <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assignee</Form.Label>
                          <Form.Control as="select" name="assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId || 0}>
                            <option value={0}>{constants.DEFAULT_OPTION}</option>
                            {
                              this.state.propertySuperVisors && this.state.propertySuperVisors.map((ps =>
                                <option key={ps.id} value={ps.id}>{ps.fullName}{' '}
                                  ({ps.userRoleCodes && formatMultipleUserRoles(ps.userRoleCodes)})
                                  </option>
                              ))
                            }
                          </Form.Control>
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">
                            {errors.assigneeId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>}
                      <Col xs={12}>
                        <Form.Group controlId="assignmentRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control name="assignmentRemarks" type="text" onChange={handleChange} onBlur={handleBlur} value={values.assignmentRemarks} />
                          {errors.assignmentRemarks && touched.assignmentRemarks && <Form.Text className="text-danger">
                            {errors.assignmentRemarks}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAssignServiceRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit">

                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={6} show={this.state.showCloseServiceRequestModal} onHide={this.handleCloseRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Close Service Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                files: [{}]
              }}

              validationSchema={cancelServiceRequestSchema}

              onSubmit={async (values, { setSubmitting }) => {
                let formData = new FormData();
                values.files && (values.files).forEach((file, index) => {
                  formData.append(`serviceRequest[files][${index}][doc].`, file);
                });

                if (this.state.serviceIdToBeClosed) {
                  const result = await ServiceRequestsServices.closeServiceRequests(this.state.serviceIdToBeClosed, formData);
                  if (result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: "Service request closed!",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCloseServiceRequestModal: !state.showCloseServiceRequestModal }));
                    await this.getData();
                  }
                }



              }
              }

            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <FieldArray
                          name="files"
                          render={arrayHelpers => (
                            <div>
                              <Row>
                                <Col xs={10}>
                                  <Form.Label>{`Add Images/Videos`}</Form.Label>
                                </Col>
                              </Row>
                              {values.files.map((file, index) =>
                              (<Row key={index} className='mt-2'>
                                <Col xs={10}>
                                  <input id={`file${index}`} name={`files[${index}]`} type="file" onChange={(event) => {
                                    setFieldValue(`files[${index}]`, event.currentTarget.files[0]);
                                  }}
                                    value={file.files} />
                                </Col>
                                <Col xs={2}>
                                  <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                </Col>
                                <Col xs={12}>
                                  {errors.files && touched.files && errors.files[index] && touched.files[index] && <Form.Text className="text-danger">
                                    {(errors.files[index])}
                                  </Form.Text>}
                                </Col>
                              </Row>)
                              )}
                              <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add More</Button>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCloseRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={7} show={this.state.showAcceptServiceRequestModal} onHide={this.handleAcceptServiceRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Accept Service Request`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                'serviceRequestId': (accordionData && accordionData.id) || undefined,
                'acceptanceStatus': 'YES',
                'ownerVisibility': undefined,
                'tenantVisibility': undefined
              }}
              validationSchema={acceptServiceRequestSchema}
              onSubmit={async (values) => {
                const data = {
                  ...values
                }
                const result = await ServiceRequestsServices.acceptanceStatus(data);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: "Service Request accepted!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showAcceptServiceRequestModal: !state.showAcceptServiceRequestModal }));
                  await this.getServiceRequestDetails(values.serviceRequestId);
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      <Col xs={12}>
                        <Form.Group controlId="ownerVisibilitySelect">
                          <Form.Label>Visible to Owner</Form.Label>
                          <Form.Control as="select" name="ownerVisibility" onChange={handleChange} onBlur={handleBlur} value={values.ownerVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.ownerVisibility && touched.ownerVisibility && <Form.Text className="text-danger">
                            {errors.ownerVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="tenantVisibilitySelect">
                          <Form.Label>Visible to Tenant</Form.Label>
                          <Form.Control as="select" name="tenantVisibility" onChange={handleChange} onBlur={handleBlur} value={values.tenantVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.tenantVisibility && touched.tenantVisibility && <Form.Text className="text-danger">
                            {errors.tenantVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAcceptServiceRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={8} show={this.state.showRejectServiceRequestModal} onHide={this.handleRejectServiceRequestButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Reject Service Request`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                'serviceRequestId': (accordionData && accordionData.id) || undefined,
                'acceptanceStatus': 'NO',
                'ownerVisibility': undefined,
                'tenantVisibility': undefined,
                'acceptanceStatusChangeRemarks': undefined
              }}
              validationSchema={rejectServiceRequestSchema}
              onSubmit={async (values) => {
                const data = {
                  ...values
                }
                const result = await ServiceRequestsServices.acceptanceStatus(data);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: "Service Request rejected!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showRejectServiceRequestModal: !state.showRejectServiceRequestModal }));
                  await this.getServiceRequestDetails(values.serviceRequestId);
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="ownerVisibilitySelect">
                          <Form.Label>Visible to Owner</Form.Label>
                          <Form.Control as="select" name="ownerVisibility" onChange={handleChange} onBlur={handleBlur} value={values.ownerVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.ownerVisibility && touched.ownerVisibility && <Form.Text className="text-danger">
                            {errors.ownerVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="tenantVisibilitySelect">
                          <Form.Label>Visible to Tenant</Form.Label>
                          <Form.Control as="select" name="tenantVisibility" onChange={handleChange} onBlur={handleBlur} value={values.tenantVisibility}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            {
                              constants.YES_NO.map((yesNo, index) =>
                                (<option key={index} value={yesNo}>{yesNo}</option>)
                              )
                            }
                          </Form.Control>
                          {errors.tenantVisibility && touched.tenantVisibility && <Form.Text className="text-danger">
                            {errors.tenantVisibility}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="acceptanceStatusChangeRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control name="acceptanceStatusChangeRemarks" type="text" onChange={handleChange} onBlur={handleBlur} value={values.acceptanceStatusChangeRemarks} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleRejectServiceRequestButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  serviceRequestCategories: state.auth && state.auth.staticData && state.auth.staticData.serviceRequests.categories,
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(ServiceRequests);
