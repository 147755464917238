import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to list PMS Subscriptions
 * @param {Object} data 
 */
async function listReports(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseInspections/listReports`, { params: data });
    return result;
  } catch (err) {
    console.error('error in listReports ', err);
  }

}

async function createReport(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/houseInspections`, data);
    return result;
  } catch (err) {
    console.error('error in createReport ', err);
  }
}

export const InspectionReportsServices = {
  listReports,
  createReport
};