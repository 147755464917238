import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all pms renewal works
 * @param {Object} data 
 */
async function getAllPMSRenewalWorks(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/pmsRenewalWorks`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllPMSRenewalWorks ', err);
  }

}

/**
 * Method to get PMS Renewal Work By Id
 * @param {Number} id 
 */
async function getPMSRenewalWorkById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/pmsRenewalWorks/${id}`);
    return result;
  } catch (err) {
    console.error('error in getAllPMSRenewalWorkById ', err);
  }

}

/**
 * Method to renew pms subscription
 * @param {Number} id 
 * @param {Object} values 
 */
async function renewPMSSubscription(id, values) {
  try {
    
    const result = await axiosInstance.post(`${baseApiUrl}admin/pmsRenewalWorks/${id}/renewPmsSubscription`, values);
    return result;
  } catch (err) {
    console.error('error in renewPMSSubscription ', err);
  }
}


/**
 * Method to discontinue pms subscription
 * @param {Number} id 
 * @param {Object} values 
 */
async function discontinuePMSSubscription(id, values) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/pmsRenewalWorks/${id}/discontinuePmsSubscription`, values);
    return result;
  } catch (err) {
    console.error('error in discontinuePMSSubscription ', err);
  }
}


export const pmsRenewalServices = {
  getAllPMSRenewalWorks,
  getPMSRenewalWorkById,
  renewPMSSubscription,
  discontinuePMSSubscription
};