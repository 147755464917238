import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { LeaseTerminationsServices } from './LeaseTerminations.services'
import { formatDate } from '../../../../_metronic/utils/utils';
import * as constants from '../../../constants';
import FilterComponent from '../../../partials/layout/FilterComponent';
import * as firebase from 'firebase/app';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { commonServices } from '../../../commonServices/commonServices';
import { debounce } from 'throttle-debounce';
// import moment from 'moment';

class MoveOutInitiatedLease extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            totalCount: undefined,
            isFetching: false,
            page: 1,
            total: undefined,
            offset: 0,
            limit: 10,
            showLeaseExtensionModal: false,
            showInitiateMoveOutModal: false,
            state: ['OPEN'],
            requestedFilters: [
                'propertyManagementStatus', 'state'
            ],
            propertyManagementStatus: ['YES']
        }
    }

    async componentDidMount() {
        await this.getLeasesList();
        await this.getCities();
        await this.getPropertyManagers();
    }

    /**
     * Method to get property managers
     */
    getPropertyManagers = async () => {
        const data = {
            userRoleCodes: ['PROPERTY_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ propertyManagers: result.data.users })
        }
    }

    /**
 * Method to get all the cities
 */
    getCities = async () => {
        const { cityIds } = this.state;
        const data = {
            pmsOperationStatus: 'YES'
        }
        const result = await commonServices.getCities(data);
        if (result && result.status === 200) {
            const cities = result?.data?.cities;
            const formattedCities = commonServices.formatCities(cities);
            let preSelectedCities = null;
            if (this.state.cityIds?.length > 0) {
                preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
            }
            this.setState({ formattedCities });
            preSelectedCities && this.setState({ preSelectedCities });
        }
    }
    /**
   * component will mount method
   */
    componentWillMount() {
        this.getLeasesList = debounce(
            800,
            this.getLeasesList
        )
    }

    getLeasesList = async () => {
        this.setState({ isFetching: true });
        const { offset, state, limit, propertyManagerId, propertyManagementStatus, minimumExpectedMoveOutDate, maximumExpectedMoveOutDate, cityIds } = this.state;
        // let maximumLeaseEndDate = moment().format('YYYY-MM-DD');
        // maximumLeaseEndDate = moment().add(45, 'd').format('YYYY-MM-DD');
        const data = {
            /**
             * maximumLeaseEndDate - '2020-09-16' (current + 45 days)
             * isMoveOutInitiated - 'FALSE'
             * isLeaseExtensionInitiated - 'FALSE'
             */
            // maximumLeaseEndDate,
            // isMoveOutInitiated: 'TRUE',
            // isLeaseExtensionInitiated: 'FALSE',
            cityIds,
            propertyManagementStatus: propertyManagementStatus && propertyManagementStatus[0],
            minimumExpectedMoveOutDate,
            maximumExpectedMoveOutDate,
            propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
            state: (state && state[0]) || undefined,
            moveOutState: 'OPEN',
            offset,
            limit,
            include: ['HOUSE', 'PROPERTY_MANAGER']
        }

        const result = await LeaseTerminationsServices.getLeasesList(data);
        if (result && result.status === 200) {
            const totalCount = result.headers['x-total-count'];
            const totalPageNo = Math.ceil(totalCount / limit)
            await this.setState({
                leases: result.data.leases,
                total: totalPageNo === 0 ? 1 : totalPageNo,
                totalCount
            });
        }

        this.setState({ isFetching: false });
    }

    onPageChange = async (page) => {
        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });
        await this.getLeasesList();
    }

    /**
     * Method to handle data of add payment component
   * @param {String} childData
   */
    handleFilterChildData = async (childData) => {
        if (childData) {
            this.setState({ ...childData })
            await this.resetPage();
            await this.getLeasesList();
            await this.getCities();
            await this.getPropertyManagers();
            firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'On Going Move-outs' });
        }
    }

    /**
  * Method to reset Pagination
  */
    resetPage = async () => {
        await this.setState({
            page: 1,
            offset: 0,
            // limit: 10,
        })
    }

    getLeaseById = async (id) => {
        const data = {
            taskGroupName: 'LEASE_TERMINATION',
            noteGroupName: 'LEASE_TERMINATION',
            include: ['HOUSE_OWNERS', 'MOVE_IN_MANAGER', 'NOTES', 'TASKS', 'TENANTS']
        }
        const result = await LeaseTerminationsServices.getLeaseById(id, data);
        if (result && result.status === 200) {
            await this.setState({ accordionData: result.data.lease });
            // await this.setState({ toBeEdited: result.data.serviceRequest });
        }
    }

    handleAccordionClick = async (id) => {
        const { prevId } = this.state;
        if (prevId !== id) {
            this.setState({ prevId: id });
            await this.getLeaseById(id);
            // await this.getStakeHolderData();
            // await this.getTaskAssignee();
            firebase.analytics().logEvent('lease_terminations_lease_details_view_click', { description: 'Lease Terminations Lease Details View Clicked', leaseId: id });
        }
    }


    handleChildData = async (childData) => {
        await this.getLeaseById(childData);
    }

    handleLeaseExtensionButtonClick = async () => {
        await this.setState(state => ({ showLeaseExtensionModal: !state.showLeaseExtensionModal }));
    }

    handleInitiateMoveOutButtonClick = async () => {
        await this.setState(state => ({ showInitiateMoveOutModal: !state.showInitiateMoveOutModal }));
    }

    getLeaseMoveOutStatusBadge = (status) => {
        if(status === 'YES') return <Badge variant="danger" title="Closed">C</Badge>
        else return <Badge variant="success" title="Open">O</Badge>
      }


    render() {
        const {
            leases, page, total, totalCount, isFetching, propertyManagementStatus, minimumExpectedMoveOutDate, maximumExpectedMoveOutDate, state, propertyManagers, formattedCities, preSelectedCities, propertyManagerId,
            requestedFilters,
        } = this.state;
        let showPagination = false;
        if (total) { showPagination = total !== 0 && total !== 1; }
        let taskAssigneeData = [];
        taskAssigneeData.push(this.props.userDetails)
        if (isFetching) {
            return <p>Loading data...</p>
        }
        return (
            <>
                <Container fluid>
                    <Portlet fluidHeight={true}>
                        <PortletHeader
                            title={`Move-Out Leases (${totalCount || 0})`}
                            toolbar={
                                <PortletHeaderToolbar>
                                </PortletHeaderToolbar>
                            }
                        />
                        <PortletBody >
                            <FilterComponent
                                formattedCities={formattedCities}
                                preSelectedCities={preSelectedCities}
                                requestedFilters={requestedFilters}
                                propertyManagers={propertyManagers}
                                propertyManagerId={propertyManagerId}
                                minimumExpectedMoveOutDate={minimumExpectedMoveOutDate}
                                maximumExpectedMoveOutDate={maximumExpectedMoveOutDate}
                                recordsPerPage={constants.RECORDS_PER_PAGE}
                                selectedRecordValue={this.state.limit}
                                state={this.state.state}
                                filterParentCallback={this.handleFilterChildData}
                                propertyManagementStatus={propertyManagementStatus}
                            />
                            <Card>
                                {leases && leases.length === 0 && <p>{constants.ZERO_DATA}</p>}
                                {leases && leases.length > 0 && <Card.Header>
                                    <Row className="azuro-table-header d-flex align-items-center px-2 py-1">
                                        <Col md={4}><strong>Property</strong></Col>
                                        <Col md={2}><strong>Lease Start Date</strong></Col>
                                        <Col md={2}><strong>Lease End Date</strong></Col>
                                        <Col md={2}><strong>Expected Move-out Date</strong></Col>
                                        <Col md={2}><strong>Move-out Date</strong></Col>
                                    </Row>
                                </Card.Header>}
                                <Card.Body className="p-0">
                                    {leases && leases.map(lease =>
                                        <React.Fragment key={"lease" + leases.id}>
                                            <Link xs={12} to={lease && `/lease-profile/${lease.id}`}>
                                                <Row className="azuro-table-row d-flex align-items-center px-2 py-1">
                                                    <Col md={4} ><span className="pr-3">{this.getLeaseMoveOutStatusBadge(lease.moveOutTerminalStatus)}</span><span>{lease.house.formattedHouseName}</span><br /><span className="small text-muted pl-5">{lease.propertyManager && lease.propertyManager.fullName ? `PM: ${lease.propertyManager.fullName}` : `PM: Not Assigned`}</span></Col>
                                                    <Col md={2}>{lease.leaseStartDate ? `${formatDate(lease.leaseStartDate)}` : `Not found`}</Col>
                                                    <Col md={2}>{lease.leaseEndDate ? `${formatDate(lease.leaseEndDate)}` : `Not found`}</Col>
                                                    <Col md={2}>{lease.expectedMoveOutDate ? `${formatDate(lease.expectedMoveOutDate)}` : `Not found`}</Col>
                                                    <Col md={2}>{lease.moveOutDate ? `${formatDate(lease.moveOutDate)}` : `Not found`}</Col>
                                                </Row>
                                            </Link>
                                            <hr style={{ margin: 0, padding: 0 }} />
                                        </React.Fragment>
                                    )
                                    }
                                </Card.Body>
                            </Card>

                            {/* Code for pagination */}
                            {showPagination && showPagination && <UltimatePagination
                                currentPage={page}
                                totalPages={total}
                                onChange={this.onPageChange}
                                boundaryPagesRange={0}
                                hidePreviousAndNextPageLinks={true}
                                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                            />}
                        </PortletBody>
                    </Portlet>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => ({
    serviceRequestCategories: state.auth && state.auth.staticData && state.auth.staticData.serviceRequests.categories,
    userDetails: state.auth && state.auth.user
});

export default
    connect(
        mapStateToProps,
        null
    )(MoveOutInitiatedLease);