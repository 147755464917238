import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import * as constants from '../../constants';
import { Formik } from 'formik';
import { commonServices } from '../../../app/commonServices/commonServices';
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
import * as firebase from 'firebase/app';

// const addLeaseSchema = Yup.object().shape({
//     startDate: Yup.date().required('Start Date is a required field'),
//     endDate: Yup.date().required('End Date is a required field'),
// });


class RequestMoveIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showRequestMoveInModal: false,
            isLoading: false,
        }
    }

    async componentDidMount() {
        await this.getMoveInManagers();
    }

    getMoveInManagers = async () => {
        const data = {
            userRoleCodes: ['MOVE_IN_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ moveInManagers: result.data.users })
        }
    }

    handleRequestMoveInButtonClick = (currentHouseOffering = null) => {
        if (currentHouseOffering !== null) {
            this.setState({ currentHouseOffering })
        }
        this.setState(state => ({ showRequestMoveInModal: !state.showRequestMoveInModal }));
    }

    render() {
        const { moveInManagers, showRequestMoveInModal } = this.state;
        return (
            <>
                <Button variant={this.props.dropdownItem ? "dropdown-item" : "link"} size={this.props.dropdownItem ? "" : "sm"} className={this.props.dropdownItem ? "dropdown-item" : ""} onClick={() => this.handleRequestMoveInButtonClick()}>Request Move-in</Button>

                <Modal size="lg" backdrop="static" keyboard={false} key={'RequestMoveIn' + 1} show={showRequestMoveInModal} onHide={this.handleRequestMoveInButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>Request Move-in</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                lease: {
                                    houseId: this.props.houseId || null,
                                    tenantFoundByAzuro: 'true',
                                    isRentFlowing: 'true',
                                    expectedMoveInDate: undefined,
                                    assignment: undefined
                                }
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    values.lease && values.lease.tenantFoundByAzuro
                                        && (values.lease.tenantFoundByAzuro === 'true' ? (values.lease.tenantFoundByAzuro = true) : (values.lease.tenantFoundByAzuro === 'false' ? values.lease.tenantFoundByAzuro = false : values.lease.tenantFoundByAzuro = undefined))

                                    values.lease && values.lease.isRentFlowing
                                        && (values.lease.isRentFlowing === 'true' ? (values.lease.isRentFlowing = true) : (values.lease.isRentFlowing === 'false' ? values.lease.isRentFlowing = false : values.lease.isRentFlowing = undefined))

                                    if (this.props.houseId) {
                                        delete values.lease.houseOfferingsId;
                                    } else if (this.props.houseOfferingId) {
                                        delete values.lease.houseId;
                                    }

                                    const result = await commonServices.createLease(values);
                                    if (result && result.status === 201) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `New Lease created!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        if (this.props.houseIdFromPropertyProfile) {
                                            firebase.analytics().logEvent("save_new_lease_button_click", { description: "Save New Lease Button Clicked", addLeaseFrom: 'Property Profile' });
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.pendingOnboardingsCallback) {
                                            firebase.analytics().logEvent("save_new_lease_button_click", { description: "Save New Lease Button Clicked", addLeaseFrom: 'Assigned Pending Onboardings' });
                                            this.props.pendingOnboardingsCallback();
                                        } else {
                                            firebase.analytics().logEvent("save_new_lease_button_click", { description: "Save New Lease Button Clicked", addLeaseFrom: 'Unassigned Onboardings' });
                                            this.props.unassignedOnboardingsCallback();
                                        }
                                        this.setState(state => ({ showAddLeaseModal: !state.showAddLeaseModal }));
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col xs={12}>
                                                <h5>Lease Details</h5>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group key={`lease.tenantFoundByAzuro`} controlId={`lease.tenantFoundByAzuro`}>
                                                    <Form.Label>Tenant Found By Azuro <span className="text-danger">*</span></Form.Label>
                                                    {
                                                        constants.YES_NO.map((yesNo, index) =>
                                                        (<Form.Check type="radio" key={`lease.tenantFoundByAzuro${index}`}>
                                                            <Form.Check.Input type="radio" onChange={handleChange}
                                                                name="lease.tenantFoundByAzuro"
                                                                value={yesNo === 'YES' ? true : false}
                                                                checked={
                                                                    (yesNo === 'YES' && (values.lease.tenantFoundByAzuro && values.lease.tenantFoundByAzuro.toString() === 'true')) ||
                                                                    (yesNo === 'NO' && (values.lease.tenantFoundByAzuro && values.lease.tenantFoundByAzuro.toString() === 'false'))
                                                                }
                                                            />
                                                            <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                                                        </Form.Check>)
                                                        )
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group key={`lease.isRentFlowing`} controlId={`lease.isRentFlowing`}>
                                                    <Form.Label>Rent Flowing through Azuro <span className="text-danger">*</span></Form.Label>
                                                    {
                                                        constants.YES_NO.map((yesNo, index) =>
                                                        (<Form.Check type="radio" key={`lease.isRentFlowing${index}`} >
                                                            <Form.Check.Input type="radio" onChange={handleChange}
                                                                name="lease.isRentFlowing"
                                                                value={yesNo === 'YES' ? true : false}
                                                                checked={
                                                                    (yesNo === 'YES' && (values.lease.isRentFlowing && values.lease.isRentFlowing.toString() === 'true')) ||
                                                                    (yesNo === 'NO' && (values.lease.isRentFlowing && values.lease.isRentFlowing.toString() === 'false'))
                                                                }
                                                            />
                                                            <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                                                        </Form.Check>)
                                                        )
                                                    }

                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group controlId="lease.expectedMoveInDate">
                                                    <DatePickerField
                                                        className="form-control"
                                                        autoComplete='off'
                                                        label='Expected Move-in Date'
                                                        dateFormat='MMMM d, yyyy'
                                                        name='lease.expectedMoveInDate'
                                                        value={values.lease.expectedMoveInDate}
                                                        onChange={setFieldValue}
                                                        minDate={new Date()}
                                                        isMandatory={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="mt-3">
                                                <h5>Assignment (Optional)</h5>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId="lease.assignment.assigneeId">
                                                    <Form.Label>Move-in Manager </Form.Label>
                                                    {/* <Form.Control type="text" name="house.houseOffering.lease.assignment.assigneeId" onChange={handleChange} onBlur={handleBlur}
                                                                value={values.house.houseOffering.lease.assignment.assigneeId} /> */}

                                                    <Form.Control as="select" name="lease.assignment.assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.lease.assignment && values.lease.assignment.assigneeId}>
                                                        <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                        {
                                                            moveInManagers && moveInManagers.map((mim =>
                                                                <option key={mim.id} value={mim.id}>{mim.fullName}</option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleRequestMoveInButtonClick()}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default RequestMoveIn;