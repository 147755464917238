import React, { Component } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import TaskItem from '../../partials/layout/TaskItem.js';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import { Formik } from 'formik';
import * as Yup from 'yup'; // for everything
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
import * as firebase from 'firebase/app';

const addTaskSchema = Yup.object().shape({
  assigneeId: Yup.number().required('Please select Assign To'),
  title: Yup.string().required('Task is required'),
  scheduledAt: Yup.date().required('Schedule Date Time is required').min(new Date(), 'Date Time should be of future, greater than ' + new Date().toLocaleString())
});

class TasksListComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showAddTaskModal: false,
    };
    // below line is to bind 'this' correctly, but not required since the method
    // handleAddTaskButtonClick has been defined using the arrow function
    // this.handleAddTaskButtonClick = this.handleAddTaskButtonClick.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    let isDifferent = false;

    if (props.preMoveInWorksId !== state.preMoveInWorksId) {
      result.preMoveInWorksId = props.preMoveInWorksId;
    }

    if (!state.approverData || (state.approverData.length !== props.approverData.length)) {
      result.approverData = props.approverData;
    }
    state.tasks && (state.tasks.length === props.tasks.length) && state.tasks.forEach((task, index) => {
      if (
        task.title !== props.tasks[index].title ||
        task.scheduledAt !== props.tasks[index].scheduledAt ||
        task.completedAt !== props.tasks[index].completedAt ||
        task.createdAt !== props.tasks[index].createdAt
      ) {
        isDifferent = true;
      }
    }
    )
    if (state.tasks.length !== props.tasks.length || isDifferent) {
      result.tasks = props.tasks
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  handleTaskItemData = (taskItemData) => {
    if (taskItemData === true) {
      this.props.parentCallback(
        (
          (this.state.serviceRequestId && this.state.serviceRequestId) ||
          (this.state.workId && this.state.workId) ||
          (this.state.renewalWorkId && this.state.renewalWorkId) ||
          (this.state.isDocumentation && 'DOCUMENTATION') ||
          (this.state.isFurnishing && 'FURNISHING') ||
          (this.state.isReport && 'MOVEINREPORT') ||
          (this.state.isReport && 'MOVEOUTREPORT') ||
          (this.state.rentPaymentId && this.state.rentPaymentId) ||
          (this.state.rentEscalationWorkId && this.state.rentEscalationWorkId) ||
          (this.state.invoiceId && this.state.invoiceId) ||
          (this.state.leaseId && this.state.leaseId) ||
          (this.state.noticePeriodInitiatedLeaseId && this.state.noticePeriodInitiatedLeaseId)
        )
      );
    }
  }

  renderTasks() {
    if (this.state.tasks.length === 0) return <Col className="text-center" xs={12}><p>No Tasks</p></Col>
    return (
      this.state.tasks.map(task =>
        <Col xs={12} key={task.id}>
          <TaskItem item={task} taskListCallback={this.handleTaskItemData}
            allowAddTasks={this.state.allowAddTasks}
          />
        </Col>
      )
    )
  }

  handleAddTaskButtonClick = () => {
    this.setState(state => ({ showAddTaskModal: !state.showAddTaskModal }));
  }

  render() {
    const { isMoveInReportPublished, isMoveOutReportPublished } = this.state;
    return (
      <React.Fragment>
        <Row className="mb-4" key={0}>
          <Col xs={10}>
            <h5>Tasks</h5>
          </Col>
          <Col xs={2} className="text-right">
            {this.state.allowAddTasks && !this.state.isReport && <Button variant="clean" size="sm" onClick={this.handleAddTaskButtonClick}>Add Task</Button>}
            {this.state.allowAddTasks && this.state.isReport === "MOVEINREPORT" && isMoveInReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddTaskButtonClick}>Add Task</Button>}
            {this.state.allowAddTasks && this.state.isReport === "MOVEOUTREPORT" && isMoveOutReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddTaskButtonClick}>Add Task</Button>}
          </Col>
          {this.renderTasks()}
        </Row>

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddTaskModal} onHide={this.handleAddTaskButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                houseId: this.state.houseId ? this.state.houseId : undefined,
                serviceRequestId: this.state.serviceRequestId ? this.state.serviceRequestId : undefined,
                // workId: this.state.workId ? (this.state.workId) : (this.state.renewalWorkId ? this.state.renewalWorkId : null),
                invoiceId: this.state.invoiceId ? this.state.invoiceId : undefined,
                workId: this.state.workId || this.state.renewalWorkId || this.state.preMoveInWorksId || this.state.rentEscalationWorkId || undefined,
                leaseId: this.state.leaseId || undefined,
                groupName: (this.state.leaseId && 'LEASE_TERMINATION') || undefined,
                tenantRentpaymentBillId: this.state.rentPaymentId || undefined,
                noticePeriodId: this.state.noticePeriodInitiatedLeaseId ? this.state.noticePeriodInitiatedLeaseId : undefined,
                assigneeId: (this.state.approverData && this.state.approverData[0].id) || undefined,
                title: undefined,
                scheduledAt: undefined
              }}
              validationSchema={addTaskSchema}
              onSubmit={async (values, setStatus) => {
                values.assigneeId = parseInt(values.assigneeId, 10)
                const result = await ServiceRequestsServices.addTasks(values);
                if (result && result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: `Task added!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  if (this.state.serviceRequestId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Service Requests' });
                    this.props.parentCallback(values.serviceRequestId);
                  } else if (this.state.workId) {
                    // For Inspections work
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Inspections' });
                    this.props.parentCallback(values.workId);
                  } else if (this.state.renewalWorkId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'PMS Renewals' });
                    this.props.parentCallback(values.workId);
                  } else if (this.state.isDocumentation) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Documentation in Lease Profile' });
                    this.props.parentCallback('DOCUMENTATION');
                  } else if (this.state.isFurnishing) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Furnishing in Lease Profile' });
                    this.props.parentCallback('FURNISHING')
                  } else if (this.state.isReport === 'MOVEINREPORT') {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Move-in Work in Lease Profile' });
                    this.props.parentCallback('MOVEINREPORT')
                  }else if (this.state.isReport === 'MOVEOUTREPORT') {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addScheduleFrom: 'Move-out Work in Lease Profile' });
                    this.props.parentCallback('MOVEOUTREPORT') 
                  }else if (this.state.rentPaymentId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Rent Payment Bills' });
                    this.props.parentCallback(values.tenantRentpaymentBillId)
                  } else if (this.state.rentEscalationWorkId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Rent Escalations' });
                    this.props.parentCallback(values.workId)
                  } else if (this.state.invoiceId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Invoices' });
                    this.props.parentCallback(values.invoiceId)
                  }else if(this.state.leaseId){
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Expiring Leases in Lease Terminations' });
                    this.props.parentCallback(values.leaseId)
                  } else if (this.state.noticePeriodInitiatedLeaseId) {
                    firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Notice Period Initiated Leases in Lease Terminations' });
                    this.props.parentCallback(values.noticePeriodId)
                  }
                  
                  this.setState(state => ({ showAddTaskModal: !state.showAddTaskModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="title">
                          <Form.Label>Task</Form.Label>
                          <Form.Control name="title" type="text" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.title} />
                          {errors.title && touched.title && <Form.Text className="text-danger">{errors.title}</Form.Text>}
                        </Form.Group>
                      </Col>
                      {/* <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assign To</Form.Label>
                          <Form.Control name="assigneeId" as="select" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              this.state.approverData && this.state.approverData.map((approverData =>
                                <option key={approverData.id} value={approverData.id}>{approverData.fullName} {approverData.userRoleCodes && ' (' + formatMultipleUserRoles(approverData.userRoleCodes) + ')'}</option>
                              ))
                            }
                          </Form.Control>
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">{errors.assigneeId}</Form.Text>}
                        </Form.Group>
                      </Col> */}

                      <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assign To</Form.Label>
                          <Form.Control name="assigneeId" type="text" readOnly onChange={handleChange} onBlur={handleBlur} value={(this.state.approverData && this.state.approverData[0].fullName)} />
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">{errors.assigneeId}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="scheduledAt">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Schedule For'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='scheduledAt'
                            value={values.scheduledAt}
                            onChange={setFieldValue}
                          />
                          {errors.scheduledAt && touched.scheduledAt && <Form.Text className="text-danger">{errors.scheduledAt}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddTaskButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TasksListComponent;
