import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import '../../../../index.scss'
import { Container, Row, Col, Card, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { pmsSubscriptionsServices } from './PMSSubscriptions.services';
import * as constants from '../../../constants';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { HouseOnboardingServices } from '../HouseOnboarding/HouseOnboarding.services';
import { commonServices } from '../../../../app/commonServices/commonServices';
import * as firebase from 'firebase/app';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import { Link } from 'react-router-dom';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { formatMultipleUsersWithoutSpan, formatDate } from '../../../../_metronic/utils/utils';
import { format } from 'date-fns';
import { connect } from 'react-redux';

const newPMSSubscriptionSchema = Yup.object().shape({
  pmsSubscription: Yup.object().shape({
    houseId: Yup.number().required('Please select one of the values from property dropdown'),
    startDate: Yup.date().required('Start Date is a mandatory field'),
    endDate: Yup.date().required('End date is a mandatory field'),
    planSelected: Yup.string().required('Choosing a plan is mandatory'),
    tcfIdentifier: Yup.string()
      .when('feeRateQuantity', {
        is: (feeRateQuantity) => feeRateQuantity && feeRateQuantity > 0,
        then: Yup.string()
          .required('TCF ID is required')
      }),
    file: Yup.mixed()
      .when('feeRateQuantity', {
        is: (feeRateQuantity) => feeRateQuantity > 0,
        then: Yup.mixed().required('Service Agreement File is required.')
      }),
  })
});

const editPMSSubscriptionSchema = Yup.object().shape({
  pmsSubscription: Yup.object().shape({
    startDate: Yup.date().required('Start Date is a mandatory field'),
    endDate: Yup.date().required('End date is a mandatory field'),
    planSelected: Yup.string().required('Choosing a plan is  mandatory'),
    tcfIdentifier: Yup.string()
      .when('feeRateQuantity', {
        is: (feeRateQuantity) => feeRateQuantity && feeRateQuantity > 0,
        then: Yup.string()
          .required('TCF ID is required')
      }),
    serviceAgreementFileUrl: Yup.string().nullable(),
    file: Yup.mixed()
      .when(['serviceAgreementFileUrl', 'feeRateQuantity'], {
        is: (serviceAgreementFileUrl, feeRateQuantity) => (feeRateQuantity > 0 && serviceAgreementFileUrl === null),
        then: Yup.mixed().required('Service Agreement File is required.')
      }),
  })
});

class PMSSubscriptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showNewPMSSubscriptionModal: false,
      showEditPMSSubscriptionModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      isLoading: false,
      propertyOptions: [],
      propertyQuery: '',
      propertyOffset: 0,
      propertyLimit: 50,
      activeDate: !props.houseIdFromPropertyProfile ? format(new Date(), 'yyyy-MM-dd') : undefined,
    }
  }

  propertyCache = {};

  async componentDidMount() {
    const { houseIdFromPropertyProfile } = this.state;
    if (!houseIdFromPropertyProfile) {
      this.getPropertyData();
    }
    await this.listPMSSubscriptions();
    await this.getCities();
    await this.getPropertyManagers();
  }

  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  listPMSSubscriptions = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, cityIds,
      minimumStartDate, maximumStartDate,
      minimumEndDate, maximumEndDate, activeDate, propertyManagerId } = this.state;
    const data = {
      cityIds,
      minimumStartDate,
      maximumStartDate,
      minimumEndDate,
      maximumEndDate,
      activeDate,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId: houseIdFromPropertyProfile,
      include: houseIdFromPropertyProfile ? undefined : ['HOUSE', 'PROPERTY_MANAGER'],
      offset,
      limit,
      // include: ['PROPERTY_MANAGER']
    }
    const result = await pmsSubscriptionsServices.listPMSSubscriptions(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        pmsSubscriptions: result.data.pmsSubscriptions,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false })
  }

  getPropertyData = async () => {
    const dataForPropertyData = {
      offset: 0,
      limit: 100,
    }
    if (!this.props.houseIdFromPropertyProfile) {
      const propertyData = await commonServices.getPropertyData(dataForPropertyData)
      if (propertyData && propertyData.status === 200) {
        await this.setState({ propertyData: propertyData.data.houses })
      }
    }
  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.listPMSSubscriptions();
  }

  handleNewPMSSubscriptionButtonClick = async () => {
    await this.setState(state => ({ showNewPMSSubscriptionModal: !state.showNewPMSSubscriptionModal }));
    if (this.state.showNewPMSSubscriptionModal === true) {
      firebase.analytics().logEvent("new_pms_subscription_click", { description: "New PMS Subscription Button Clicked" });
    }
  }

  handleEditPMSSubscriptionButtonClick = async (elementToBeEdited = null) => {
    if (elementToBeEdited !== null) {
      await this.setState({ toBeEdited: elementToBeEdited });
    }
    await this.setState(state => ({ showEditPMSSubscriptionModal: !state.showEditPMSSubscriptionModal }));
    if (this.state.showEditPMSSubscriptionModal === true) {
      firebase.analytics().logEvent("edit_pms_subscription_button_click", { description: "Edit PMS Subscription Button Clicked", id: elementToBeEdited.id });
    }
  }

  handleFilterChildData = async (childData) => {
    // const { houseIdFromPropertyProfile } = this.state;
    if (childData) {
      this.setState({ ...childData });
      await this.listPMSSubscriptions();
      await this.resetPage();
      // if (!houseIdFromPropertyProfile) {
      //     this.getPropertyData();
      // }
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'PMS Subscriptions' });
    }
  }

  handlePropertyInputChange = propertyQuery => {
    this.setState({ propertyQuery });
  };

  handlePropertyPagination = async (e, shownResults) => {
    const { propertyQuery } = this.state;
    const cachedPropertyQuery = this.propertyCache[propertyQuery];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedPropertyQuery.options.length > shownResults ||
      cachedPropertyQuery.options.length === cachedPropertyQuery.total_count
    ) {
      return;
    }

    this.setState({ isLoading: true });

    const propertyPage = cachedPropertyQuery.propertyPage + 1;
    const { propertyLimit } = this.state;
    const propertyOffset = (propertyLimit * (propertyPage - 1));
    await this.setState({ propertyPage, propertyOffset });

    const response = await this.getAllProperties();
    const propertyOptions = cachedPropertyQuery.options.concat(response.options);
    this.propertyCache[propertyQuery] = { ...cachedPropertyQuery, propertyOptions, propertyPage, propertyOffset };
    this.setState({
      isLoading: false,
      propertyOptions,
    });
  };

  handlePropertySearch = async (propertyQuery) => {
    if (this.propertyCache[propertyQuery]) {
      this.setState({ propertyOptions: this.propertyCache[propertyQuery].options });
      return;
    }
    this.setState({ isLoading: true });
    const response = await this.getAllProperties();
    this.propertyCache[propertyQuery] = { ...response, propertyPage: 1 };
    this.setState({
      isLoading: false,
      propertyOptions: response && response.options,
    });
  };

  getAllProperties = async () => {
    this.setState({ isLoading: true });
    const { propertyOffset, propertyLimit, propertyQuery } = this.state;
    const data = {
      searchQuery: propertyQuery,
      offset: propertyOffset,
      limit: propertyLimit,
      scope: 'ALL',
      include: ['OWNERS', 'CURRENT_TENANTS']
    }
    const result = await HouseOnboardingServices.getAllProperties(data);
    if (result && result.status === 200) {
      const total_count = result.headers['x-total-count'];
      const options = result.data.houses.map(houseData => ({
        id: houseData.id,
        property: `${houseData.formattedHouseName} | Owners: ${formatMultipleUsersWithoutSpan(houseData.owners)} | Tenants: ${formatMultipleUsersWithoutSpan(houseData.currentTenants)}`,
        propertyManagementStatus: houseData.propertyManagementStatus
      }));
      return { options, total_count };
    }
    this.setState({ isLoading: false });
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  validateTCFId = (currentText) => {
    let error;
    if (currentText && currentText.length === 17) {
      let prefixPart = currentText.substr(0, 4);
      if (prefixPart === 'TCF-') {
        let datePart = currentText.substr(4, 8);
        let yearPart = datePart.substr(0, 4);
        let monthPart = datePart.substr(4, 2);
        let dayPart = datePart.substr(6, 2);
        let tcfDate = new Date(yearPart, (Number(monthPart) - 1), dayPart);
        let currentDate = new Date();
        if (tcfDate && (tcfDate <= currentDate)) {
          console.log("seems fine");
          let finalSerialPartSeparator = currentText.substr(12, 1);
          if (finalSerialPartSeparator === "-") {
            let finalSerialPart = currentText.substr(13, 4);
            if (Number(finalSerialPart)) {
              console.log("TCF ID seems fine");
            } else {
              error = "TCF ID is invalid";
            }
          } else {
            error = "TCF ID is invalid";
          }
        } else {
          error = "TCF ID is invalid";
        }
      } else {
        error = "TCF ID is invalid";
      }
    } else if (currentText && currentText.length !== 17) {
      error = "TCF ID should be 17 characters long";
    } else {
      console.log('=== possibly empty or valid', error);
    }
    return error;
  }

  handlePlanChange = async (e, setFieldValue, validateForm) => {
    if (e.target.validity.valid) {
      switch (e.target.value) {
        case 'freePlan':
          setFieldValue('pmsSubscription.feeRateQuantity', '0');
          setFieldValue('pmsSubscription.feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('pmsSubscription.feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('pmsSubscription.feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[0]);
          break;
        case 'eightPercentPlan':
          setFieldValue('pmsSubscription.feeRateQuantity', '8');
          setFieldValue('pmsSubscription.feeRateUnit', constants.FEE_RATE_UNIT[0]);
          setFieldValue('pmsSubscription.feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('pmsSubscription.feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'fixedAmountFromRentPlan':
          setFieldValue('pmsSubscription.feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('pmsSubscription.feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('pmsSubscription.feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'customPlan':
          setFieldValue('pmsSubscription.feeRateUnit', constants.FEE_RATE_UNIT[0]);
          setFieldValue('pmsSubscription.feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('pmsSubscription.feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'fullAmountPlan':
          setFieldValue('pmsSubscription.feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('pmsSubscription.feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[1]);
          setFieldValue('pmsSubscription.feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[1]);
          break;
        default: break;
      }
      validateForm();
    }
  };


  render() {
    const { isFetching, pmsSubscriptions, page, total, totalCount, houseIdFromPropertyProfile,
      formattedCities, preSelectedCities, minimumStartDate, maximumStartDate,
      minimumEndDate, maximumEndDate, activeDate, propertyManagers, propertyManagerId } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container key={'c' + 0} fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`PMS Subscriptions (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  <Container className='d-flex flex-column' >
                    <Button className='ml-auto' variant="primary" size="sm" onClick={this.handleNewPMSSubscriptionButtonClick} disabled={this.props.disableNewPMSButton}>New PMS Subscription</Button>
                    {this.props.disableNewPMSButton && <Row className='mt-1'><Form.Text >Direct subscription creation is disabled due to pending Renewal works</Form.Text></Row>}
                  </Container>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                minimumStartDate={minimumStartDate}
                maximumStartDate={maximumStartDate}
                minimumEndDate={minimumEndDate}
                maximumEndDate={maximumEndDate}
                activeDate={activeDate}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Card>
                {pmsSubscriptions && pmsSubscriptions.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {pmsSubscriptions && pmsSubscriptions.length > 0 && <Card.Header>
                  <Row className="azuro-table-header d-flex align-items-center px-3 py-2">
                    {!houseIdFromPropertyProfile && <Col xs={3}><strong>Property</strong></Col>}

                    <Col xs={!houseIdFromPropertyProfile ? 2 : 3}><strong>Duration</strong></Col>
                    <Col xs={!houseIdFromPropertyProfile ? 1 : 2}><strong>Rate </strong></Col>
                    <Col xs={!houseIdFromPropertyProfile ? 2 : 3}><strong>Billing Method</strong></Col>
                    <Col xs={2}><strong>TCF ID</strong></Col>
                    <Col xs={2}><strong>Service Agreement</strong></Col>
                  </Row>
                </Card.Header>}
                <Card.Body className="p-0">
                  {pmsSubscriptions && pmsSubscriptions.map(pmsSubscription =>
                    <React.Fragment key={"pmsSubscription" + pmsSubscription.id}>
                      <Row className="azuro-table-row d-flex align-items-center px-3 py-2">
                        {!houseIdFromPropertyProfile && <Col xs={3} className="d-flex flex-row">
                          {pmsSubscription.house && <Link to={`/property-profile/${pmsSubscription.house.id}`}>
                            <span>{pmsSubscription.house?.formattedHouseName}</span> <br />
                            <span className="small text-muted">{pmsSubscription.house.propertyManager ? `PM: ${(pmsSubscription.house.propertyManager && pmsSubscription.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                          </Link>}
                        </Col>}
                        <Col xs={!houseIdFromPropertyProfile ? 2 : 3}>{formatDate(pmsSubscription.startDate)} to<br />{formatDate(pmsSubscription.endDate)}</Col>
                        <Col xs={!houseIdFromPropertyProfile ? 1 : 2}>{pmsSubscription.formattedRate === "INR 0" ? "Free" : pmsSubscription.formattedRate}</Col>
                        <Col xs={!houseIdFromPropertyProfile ? 2 : 3}>{(pmsSubscription.paymentChargeMode === 'DEDUCT_FROM_RENT' && "Deduct from Rent") || (pmsSubscription.paymentChargeMode === "INVOICE" && "Invoice") || (pmsSubscription.paymentChargeMode === null && <span className="small text-muted">Not Available</span>)}</Col>
                        <Col xs={2} className={pmsSubscription.tcfIdentifier === null && "small text-muted"}>{pmsSubscription.tcfIdentifier === null ? "Not Available" : pmsSubscription.tcfIdentifier}</Col>
                        <Col xs={2}>{pmsSubscription.serviceAgreementFileUrl === null ? <span className="small text-muted">Not Available</span> : <a target='_blank' rel="noopener noreferrer" href={pmsSubscription.serviceAgreementFileUrl}><Button title="View Service Agreement" variant="clean" size="sm"><i className="flaticon2-file"></i></Button></a>}
                          {/* Comparing start date with current date and hide the edit PMS button after 150 days */}
                          {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') || (Math.floor((Math.abs(new Date() - new Date(pmsSubscription.startDate))) / (1000 * 60 * 60 * 24)) < 150)) && <Button className="pull-right" title="Edit PMS Subscription" variant="clean" size="sm" onClick={() => this.handleEditPMSSubscriptionButtonClick(pmsSubscription)}><i className="flaticon2-edit"></i></Button>}
                        </Col>
                      </Row>
                      <hr className="my-1" />
                    </React.Fragment>
                  )
                  }
                </Card.Body>
              </Card>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showNewPMSSubscriptionModal} onHide={this.handleNewPMSSubscriptionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>New PMS Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                pmsSubscription: {
                  houseId: houseIdFromPropertyProfile || undefined,
                  startDate: undefined,
                  endDate: undefined,
                  feeRateQuantity: undefined,
                  feeRateUnit: undefined,
                  feePaymentInterval: undefined,
                  feePaymentMechanism: undefined,
                  planSelected: undefined,
                  paymentChargeMode: "FREE_TRIAL",
                  file: undefined,
                  tcfIdentifier: undefined
                }
              }}
              validationSchema={newPMSSubscriptionSchema}
              onSubmit={
                async (values) => {
                  switch (values.pmsSubscription.planSelected) {
                    case 'freePlan':
                      values.pmsSubscription.feeRateQuantity = '0';
                      values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[0];
                      break;

                    case 'eightPercentPlan':
                      values.pmsSubscription.feeRateQuantity = '8';
                      values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[0];
                      values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'fixedAmountFromRentPlan':
                      values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'customPlan':
                      values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[0];
                      values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'fullAmountPlan':
                      values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[1];
                      values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[1];
                      break;
                    default: break;
                  }
                  delete values.pmsSubscription.planSelected;
                  if (values.pmsSubscription.paymentChargeMode === "FREE_TRIAL") {
                    values.pmsSubscription.paymentChargeMode = "";
                  }
                  // if (values.pmsSubscription.file === undefined) {
                  //   delete values.pmsSubscription.file;
                  // }

                  let formData = new FormData();
                  values.pmsSubscription.file && formData.append('pmsSubscription[file]', values.pmsSubscription.file);
                  values.pmsSubscription.houseId && formData.append('pmsSubscription[houseId]', values.pmsSubscription.houseId);
                  values.pmsSubscription.startDate && formData.append('pmsSubscription[startDate]', values.pmsSubscription.startDate);
                  values.pmsSubscription.endDate && formData.append('pmsSubscription[endDate]', values.pmsSubscription.endDate);
                  values.pmsSubscription.feeRateQuantity && formData.append('pmsSubscription[feeRateQuantity]', values.pmsSubscription.feeRateQuantity);
                  values.pmsSubscription.feeRateUnit && formData.append('pmsSubscription[feeRateUnit]', values.pmsSubscription.feeRateUnit);
                  values.pmsSubscription.feePaymentInterval && formData.append('pmsSubscription[feePaymentInterval]', values.pmsSubscription.feePaymentInterval);
                  values.pmsSubscription.feePaymentMechanism !== undefined && formData.append('pmsSubscription[feePaymentMechanism]', values.pmsSubscription.feePaymentMechanism ? values.pmsSubscription.feePaymentMechanism : '');
                  values.pmsSubscription.paymentChargeMode !== undefined && formData.append('pmsSubscription[paymentChargeMode]', values.pmsSubscription.paymentChargeMode ? values.pmsSubscription.paymentChargeMode : '');
                  values.pmsSubscription.tcfIdentifier !== undefined && formData.append('pmsSubscription[tcfIdentifier]', values.pmsSubscription.tcfIdentifier ? values.pmsSubscription.tcfIdentifier : '');

                  // Object.keys(values.pmsSubscription).forEach(key => formData.append(key, values.pmsSubscription[key]));

                  const result = await pmsSubscriptionsServices.createPMSSubscription(formData);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `PMS Subscription created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showNewPMSSubscriptionModal: !state.showNewPMSSubscriptionModal }));
                    this.listPMSSubscriptions();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, validateForm }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {!houseIdFromPropertyProfile && <Col xs={12}>
                        <Form.Group controlId="pmsSubscription.houseId">
                          <Form.Label>Property</Form.Label>
                          <AsyncTypeahead
                            // {...this.state}
                            name="houseId"
                            isLoading={this.state.isLoading}
                            options={this.state.propertyOptions}
                            query={this.state.propertyQuery}
                            id="async-pagination-property"
                            labelKey="property"
                            maxResults={this.state.propertyLimit - 1}
                            minLength={3}
                            onChange={async (e) => {
                              const houseId = (e.length > 0) ? e[0].id : undefined;
                              const houseName = (e.length > 0) ? e[0].property : undefined;
                              setFieldValue('houseId', houseId);
                              setFieldValue('pmsSubscription.houseId', houseId);
                              setFieldValue('houseName', houseName);
                            }}
                            onInputChange={this.handlePropertyInputChange}
                            onPaginate={this.handlePropertyPagination}
                            onSearch={this.handlePropertySearch}
                            paginate
                            placeholder={values.houseName ? values.houseName : "Search for your property here..."}
                            renderMenuItemChildren={option => (
                              <div key={option.id}>
                                <span>{option.property}</span>
                              </div>
                            )}
                            useCache={false}
                          />
                          {errors.pmsSubscription && errors.pmsSubscription.houseId && touched.pmsSubscription && touched.pmsSubscription.houseId && <Form.Text className="text-danger">
                            {errors.pmsSubscription.houseId}
                          </Form.Text>}
                        </Form.Group>
                      </Col>}
                      <Col xs={6}>
                        <Form.Group controlId="pmsSubscription.startDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='pmsSubscription.startDate'
                            value={values.pmsSubscription.startDate}
                            onChange={setFieldValue}
                          />
                          {errors.pmsSubscription && errors.pmsSubscription.startDate && touched.pmsSubscription && touched.pmsSubscription.startDate && <Form.Text className="text-danger">
                            {errors.pmsSubscription.startDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="pmsSubscription.endDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='pmsSubscription.endDate'
                            value={values.pmsSubscription.endDate}
                            onChange={setFieldValue}
                          />
                          {errors.pmsSubscription && errors.pmsSubscription.endDate && touched.pmsSubscription && touched.pmsSubscription.endDate && <Form.Text className="text-danger">
                            {errors.pmsSubscription.endDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Upload Service Agreement {values.pmsSubscription.planSelected !== 'freePlan' && <span className="text-danger">*</span>}</Form.Label>
                          <br />
                          <input id="file" name="file" type="file" onChange={(event) => {
                            setFieldValue("pmsSubscription.file", event.currentTarget.files[0]);
                          }} />
                          {errors.pmsSubscription && errors.pmsSubscription.file && touched.pmsSubscription && touched.pmsSubscription.file && <Form.Text className="text-danger">
                            {errors.pmsSubscription.file}
                          </Form.Text>}
                        </Form.Group>

                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label htmlFor="payment-mode" >Subscription Payment Mode <span className="text-danger">*</span></Form.Label><br />
                          <Form.Check inline type="radio" label='Free Trial' defaultChecked name={'pmsSubscription.paymentChargeMode'} id="freeTrialRadio" value={"FREE_TRIAL"} onChange={handleChange} />
                          <Form.Check inline type="radio" label='Deduct from Rent' name={'pmsSubscription.paymentChargeMode'} id="deductFromRentRadio" value={constants.PAYMENT_CHARGE_MODE[0]} onChange={handleChange} />
                          <Form.Check inline type="radio" label="Invoice Full Amount" name={'pmsSubscription.paymentChargeMode'} id="invoiceAmountRadio" value={constants.PAYMENT_CHARGE_MODE[1]} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <label htmlFor='planGroupID'>Select Plan <span className="text-danger">*</span></label>
                        <div className='planGroup' id='planGroupID'>
                          <Form.Group controlId="pmsSubscription.planSelected">
                            {/* values.pmsSubscription.planSelected */}
                            {(values.pmsSubscription.paymentChargeMode === "FREE_TRIAL" || values.pmsSubscription.paymentChargeMode === null) &&
                              <div className="radio--card-group">
                                <div className="radio--card__item" >
                                  <input id="rateCard1" name='pmsSubscription.planSelected' type="radio" value={'freePlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor='rateCard1'>
                                    <Card.Body>
                                      <Card.Title>Free Trial</Card.Title>
                                      <Card.Text>
                                        INR 0 for the duration between Start &amp; End Date
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>
                            }
                            {values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard2" name='pmsSubscription.planSelected' type="radio" value={'eightPercentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard2">
                                    <Card.Body>
                                      <Card.Title>8% of Monthly Rent</Card.Title>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>

                                <div className="radio--card__item">
                                  <input id="rateCard4" name="pmsSubscription.planSelected" type="radio" value={'fixedAmountFromRentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard4">
                                    <Card.Body>
                                      <Card.Title>Fixed Amount per Month</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="text" onChange={handleChange} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">INR</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>

                                {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <div className="radio--card__item">
                                  <input id="rateCard5" name="pmsSubscription.planSelected" type="radio" value={'customPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard5">
                                    <Card.Body>
                                      <Card.Title>Custom Plan</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="text" className="px-2" onChange={handleChange} onBlur={handleBlur} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">% of Monthly Rent</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>}
                              </div>
                            }
                            {values.pmsSubscription.paymentChargeMode === constants.PAYMENT_CHARGE_MODE[1] &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard6" name="pmsSubscription.planSelected" type="radio" value={'fullAmountPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard6">
                                    <Card.Body>
                                      <Card.Title>Enter Amount</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">INR</InputGroup.Text>
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="text" onChange={handleChange} aria-describedby="basic-addon1" />
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        The total fee for the duration of the PMS subscription
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>
                            }
                            {errors.pmsSubscription && errors.pmsSubscription.planSelected && touched.pmsSubscription && touched.pmsSubscription.planSelected && <Form.Text className="text-danger">
                              {errors.pmsSubscription.planSelected}
                            </Form.Text>}
                          </Form.Group>
                        </div>
                      </Col>

                      {values.pmsSubscription.paymentChargeMode !== "FREE_TRIAL" &&
                        <Col xs={6}>
                          <Form.Group controlId="pmsSubscription.tcfIdentifier">
                            <Form.Label>TCF ID <span className="text-danger">*</span></Form.Label>
                            <Field as={Form.Control} validate={this.validateTCFId} name="pmsSubscription.tcfIdentifier" type="text" placeholder="Eg: TCF-20210315-0056" minLength="17" maxLength="17" pattern={constants.tcfIdPattern} onChange={handleChange} onBlur={handleBlur} value={values.pmsSubscription.tcfIdentifier} />
                            {errors.pmsSubscription && errors.pmsSubscription.tcfIdentifier && touched.pmsSubscription && touched.pmsSubscription.tcfIdentifier && <Form.Text className="text-danger">
                              {errors.pmsSubscription.tcfIdentifier}
                            </Form.Text>}
                          </Form.Group>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleNewPMSSubscriptionButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showEditPMSSubscriptionModal} onHide={this.handleEditPMSSubscriptionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit PMS Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                pmsSubscription: {
                  startDate: (this.state.toBeEdited && this.state.toBeEdited.startDate) || undefined,
                  endDate: (this.state.toBeEdited && this.state.toBeEdited.endDate) || undefined,
                  feeRateQuantity: this.state.toBeEdited ? this.state.toBeEdited.feeRateQuantity : '',
                  feeRateUnit: (this.state.toBeEdited && this.state.toBeEdited.feeRateUnit) || undefined,
                  feePaymentInterval: (this.state.toBeEdited && this.state.toBeEdited.feePaymentInterval) || undefined,
                  feePaymentMechanism: (this.state.toBeEdited && this.state.toBeEdited.feePaymentMechanism) || undefined,
                  tcfIdentifier: (this.state.toBeEdited && this.state.toBeEdited.tcfIdentifier) || undefined,
                  serviceAgreementFileUrl: (this.state.toBeEdited && this.state.toBeEdited.serviceAgreementFileUrl) || null,
                  planSelected: (
                    this.state.toBeEdited && (this.state.toBeEdited.feeRateQuantity === 0 ? 'freePlan' : (
                      this.state.toBeEdited.feeRateQuantity === 8 ? 'eightPercentPlan' : (
                        this.state.toBeEdited.feeRateUnit === constants.FEE_RATE_UNIT[0] ? 'customPlan' : (
                          this.state.toBeEdited.feeRateUnit === constants.FEE_RATE_UNIT[1] && this.state.toBeEdited.feePaymentInterval === constants.FEE_PAYMENT_INTERVAL[1] ? 'fullAmountPlan' : 'fixedAmountFromRentPlan'
                        )
                      )
                    ))
                  ),
                  paymentChargeMode: (this.state.toBeEdited ? (this.state.toBeEdited.paymentChargeMode) : undefined),
                  file: undefined,
                }
              }}
              validationSchema={editPMSSubscriptionSchema}
              onSubmit={async (values) => {
                switch (values.pmsSubscription.planSelected) {
                  case 'freePlan':
                    values.pmsSubscription.feeRateQuantity = '0';
                    values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                    values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                    values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[0];
                    break;

                  case 'eightPercentPlan':
                    values.pmsSubscription.feeRateQuantity = '8';
                    values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[0];
                    values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                    values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                    break;

                  case 'fixedAmountFromRentPlan':
                    values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                    values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                    values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                    break;

                  case 'customPlan':
                    values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[0];
                    values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                    values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                    break;

                  case 'fullAmountPlan':
                    values.pmsSubscription.feeRateUnit = constants.FEE_RATE_UNIT[1];
                    values.pmsSubscription.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[1];
                    values.pmsSubscription.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[1];
                    break;
                  default: break;
                }

                delete values.pmsSubscription.planSelected;
                if (values.pmsSubscription.paymentChargeMode === "FREE_TRIAL") {
                  values.pmsSubscription.paymentChargeMode = "";
                }
                if (values.pmsSubscription.file === undefined) {
                  delete values.pmsSubscription.file;
                }

                let formData = new FormData();
                values.pmsSubscription.file && formData.append('pmsSubscription[file]', values.pmsSubscription.file);
                values.pmsSubscription.startDate && formData.append('pmsSubscription[startDate]', values.pmsSubscription.startDate);
                values.pmsSubscription.endDate && formData.append('pmsSubscription[endDate]', values.pmsSubscription.endDate);
                values.pmsSubscription.feeRateQuantity && formData.append('pmsSubscription[feeRateQuantity]', values.pmsSubscription.feeRateQuantity);
                values.pmsSubscription.feeRateUnit && formData.append('pmsSubscription[feeRateUnit]', values.pmsSubscription.feeRateUnit);
                values.pmsSubscription.feePaymentInterval && formData.append('pmsSubscription[feePaymentInterval]', values.pmsSubscription.feePaymentInterval);
                values.pmsSubscription.feePaymentMechanism !== undefined && formData.append('pmsSubscription[feePaymentMechanism]', values.pmsSubscription.feePaymentMechanism ? values.pmsSubscription.feePaymentMechanism : '');
                values.pmsSubscription.paymentChargeMode !== undefined && formData.append('pmsSubscription[paymentChargeMode]', values.pmsSubscription.paymentChargeMode ? values.pmsSubscription.paymentChargeMode : '');
                values.pmsSubscription.tcfIdentifier && formData.append('pmsSubscription[tcfIdentifier]', values.pmsSubscription.tcfIdentifier ? values.pmsSubscription.tcfIdentifier : '');

                // Object.keys(values.pmsSubscription).forEach(key => formData.append(key, values.pmsSubscription[key]));

                const result = await pmsSubscriptionsServices.updatePMSSubscription(this.state.toBeEdited.id, formData);

                if (result && result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: `PMS Subscription updated!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showEditPMSSubscriptionModal: !state.showEditPMSSubscriptionModal }));
                  this.listPMSSubscriptions();
                }
              }
              }
            >

              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, validateForm }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Form.Group controlId="pmsSubscription.startDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='pmsSubscription.startDate'
                            value={values.pmsSubscription.startDate}
                            onChange={setFieldValue}
                          />
                          {errors.pmsSubscription && errors.pmsSubscription.startDate && touched.pmsSubscription && touched.pmsSubscription.startDate && <Form.Text className="text-danger">
                            {errors.pmsSubscription.startDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="pmsSubscription.endDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='pmsSubscription.endDate'
                            value={values.pmsSubscription.endDate}
                            onChange={setFieldValue}
                          />
                          {errors.pmsSubscription && errors.pmsSubscription.endDate && touched.pmsSubscription && touched.pmsSubscription.endDate && <Form.Text className="text-danger">
                            {errors.pmsSubscription.endDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{this.state?.toBeEdited?.serviceAgreementFileUrl !== null ? 'Re-' : ''}Upload Service Agreement {this.state?.toBeEdited?.serviceAgreementFileUrl !== null ? '(if required)' : values.pmsSubscription.feeRateQuantity > 0 && <span className="text-danger">*</span>}</Form.Label>
                          <br />
                          <input id="file" name="file" type="file" onChange={(event) => {
                            setFieldValue("pmsSubscription.file", event.currentTarget.files[0]);
                          }} />
                          {errors.pmsSubscription && errors.pmsSubscription.file && touched.pmsSubscription && touched.pmsSubscription.file && <Form.Text className="text-danger">
                            {errors.pmsSubscription.file}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label htmlFor="payment-mode" >Subscription Payment Mode <span className="text-danger">*</span></Form.Label><br />
                          <Form.Check inline type="radio" label='Free Trial' defaultChecked={values.pmsSubscription.paymentChargeMode === 'FREE_TRIAL' || values.pmsSubscription.paymentChargeMode === null} name={'pmsSubscription.paymentChargeMode'} id="freeTrialRadio" value={"FREE_TRIAL"} onClick={handleChange} />
                          <Form.Check inline type="radio" label='Deduct from Rent' defaultChecked={values.pmsSubscription.paymentChargeMode === 'DEDUCT_FROM_RENT'} name={'pmsSubscription.paymentChargeMode'} id="deductFromRentRadio" value={constants.PAYMENT_CHARGE_MODE[0]} onClick={handleChange} />
                          <Form.Check inline type="radio" label="Invoice Full Amount" name={'pmsSubscription.paymentChargeMode'} defaultChecked={values.pmsSubscription.paymentChargeMode === "INVOICE"} id="invoiceAmountRadio" value={constants.PAYMENT_CHARGE_MODE[1]} onClick={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <label htmlFor='planGroupID'>Select Plan <span className="text-danger">*</span></label>
                        <div className='planGroup' id='planGroupID'>
                          <Form.Group controlId="pmsSubscription.planSelected">
                            {/* values.pmsSubscription.planSelected */}
                            {(values.pmsSubscription.paymentChargeMode === "FREE_TRIAL" || values.pmsSubscription.paymentChargeMode == null) &&
                              <div className="radio--card-group">
                                <div className="radio--card__item" >
                                  <input id="rateCard1" name='pmsSubscription.planSelected' defaultChecked={(values.pmsSubscription.paymentChargeMode === "FREE_TRIAL" || values.pmsSubscription.paymentChargeMode == null) && values.pmsSubscription.feeRateUnit === "ABSOLUTE" && values.pmsSubscription.feeRateQuantity === 0} type="radio" value={'freePlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor='rateCard1'>
                                    <Card.Body>
                                      <Card.Title>Free Trial</Card.Title>
                                      <Card.Text>
                                        INR 0 for the duration between Start &amp; End Date
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>}
                            {values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard2" name='pmsSubscription.planSelected' defaultChecked={values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" && values.pmsSubscription.feeRateUnit === "%" && values.pmsSubscription.feeRateQuantity === 8} type="radio" value={'eightPercentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard2">
                                    <Card.Body>
                                      <Card.Title>8% of Monthly Rent</Card.Title>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                                <div className="radio--card__item">
                                  <input id="rateCard4" name="pmsSubscription.planSelected" defaultChecked={values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" && values.pmsSubscription.feePaymentMechanism === "POST_PAID" && values.pmsSubscription.feeRateUnit === 'ABSOLUTE' && values.pmsSubscription.feeRateQuantity > 0} type="radio" value={'fixedAmountFromRentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard4">
                                    <Card.Body>
                                      <Card.Title>Fixed Amount per Month</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="value" value={(values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" && values.pmsSubscription.feePaymentMechanism === "POST_PAID" && values.pmsSubscription.feeRateUnit === 'ABSOLUTE' && values.pmsSubscription.feeRateQuantity > 0) ? values.pmsSubscription.feeRateQuantity : ''} onChange={handleChange} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">INR</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>

                                {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <div className="radio--card__item">
                                  <input id="rateCard5" name="pmsSubscription.planSelected" defaultChecked={values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" && values.pmsSubscription.feeRateUnit === "%" && (values.pmsSubscription.feeRateQuantity !== 0 && values.pmsSubscription.feeRateQuantity !== 8)} type="radio" value={'customPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard5">
                                    <Card.Body>
                                      <Card.Title>Custom Plan</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="text" className="px-2" value={(values.pmsSubscription.paymentChargeMode === "DEDUCT_FROM_RENT" && values.pmsSubscription.feeRateUnit === "%" && (values.pmsSubscription.feeRateQuantity !== 0 && values.pmsSubscription.feeRateQuantity !== 8)) ? values.pmsSubscription.feeRateQuantity : ''} onChange={handleChange} onBlur={handleBlur} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">% of Monthly Rent</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>}
                              </div>}

                            {values.pmsSubscription.paymentChargeMode === constants.PAYMENT_CHARGE_MODE[1] &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard6" name="pmsSubscription.planSelected" defaultChecked={values.pmsSubscription.paymentChargeMode === constants.PAYMENT_CHARGE_MODE[1] && values.pmsSubscription.feePaymentMechanism === "PRE_PAID" && values.pmsSubscription.feeRateUnit === constants.FEE_RATE_UNIT[1] && values.pmsSubscription.feeRateQuantity > 0} type="radio" value={'fullAmountPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard6">
                                    <Card.Body>
                                      <Card.Title>Enter Amount</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1">{values.pmsSubscription.currency === constants.SUPPORTED_CURRENCIES[1] ? constants.SUPPORTED_CURRENCIES[1] : constants.SUPPORTED_CURRENCIES[0]}</InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl name='pmsSubscription.feeRateQuantity' type="text" onBlur={handleBlur} onChange={handleChange} aria-describedby="basic-addon1" value={(values.pmsSubscription.paymentChargeMode === constants.PAYMENT_CHARGE_MODE[1] && values.pmsSubscription.feePaymentMechanism === "PRE_PAID" && values.pmsSubscription.feeRateUnit === constants.FEE_RATE_UNIT[1] && values.pmsSubscription.feeRateQuantity > 0) ? values.pmsSubscription.feeRateQuantity : null} />
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        The total fee for the duration of the PMS subscription
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>
                            }
                            {errors.pmsSubscription && errors.pmsSubscription.planSelected && touched.pmsSubscription && touched.pmsSubscription.planSelected && <Form.Text className="text-danger">
                              {errors.pmsSubscription.planSelected}
                            </Form.Text>}
                          </Form.Group>
                        </div>
                      </Col>
                      {(values.pmsSubscription.paymentChargeMode !== null || values.pmsSubscription.tcfIdentifier !== null) && <Col xs={6}>
                        <Form.Group controlId="pmsSubscription.tcfIdentifier">
                          <Form.Label>TCF ID {values.pmsSubscription.feeRateQuantity > 0 && <span className="text-danger">*</span>}</Form.Label>
                          <Field as={Form.Control} name="pmsSubscription.tcfIdentifier" validate={this.validateTCFId} type="text" placeholder="Eg: TCF-20210315-0056" minLength="17" maxLength="17" pattern={constants.tcfIdPattern} onChange={handleChange} onBlur={handleBlur} value={values.pmsSubscription.tcfIdentifier} />
                          {errors.pmsSubscription && errors.pmsSubscription.tcfIdentifier && touched.pmsSubscription && touched.pmsSubscription.tcfIdentifier && <Form.Text className="text-danger">
                            {errors.pmsSubscription.tcfIdentifier}
                          </Form.Text>}
                        </Form.Group>
                      </Col>}
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditPMSSubscriptionButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user && state.auth.user
});
export default
  connect(
    mapStateToProps,
    null
  )(PMSSubscriptions);
