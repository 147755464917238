import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all the notice period initiated leases
 * @param {Object} data
 */
async function getNoticePeriods(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/noticePeriods`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getNoticePeriods ', err);
    }

}

/**
 * Method to get notice period initiated lease by id
 * @param {Number} id
 */
async function getNoticePeriodDetails(id) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/noticePeriods/${id}`);
        return result;
    } catch (err) {
        console.error('error in getNoticePeriodDetails ', err);
    }

}

/**
 * Method to mark notice period terminal status
 * @param {Number} id
 * @param {Object} data
 */
async function markNoticePeriodTerminalStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/noticePeriods/${id}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markNoticePeriodTerminalStatus ', err);
  }
}


/**
 * Method to get all the leases
 * @param {Object} data 
 */
async function getLeasesList(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leases`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getLeasesList ', err);
  }

}

/**
 * Method to get lease by id
 * @param {Number} id 
 * @param {Object} data
 */
async function getLeaseById(id, data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leases/${id}`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getLeaseById ', err);
  }

}


/**
 * Method to create lease Extension
 * @param {Object} data 
 */
async function createLeaseExtensions(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/leaseExtensions`, data);
    return result;
  } catch (err) {
    console.error('error in createLeaseExtensions ', err);
  }
}


/**
 * Method to mark lease move out initiation status
 * @param {Number} id
 * @param {Object} data 
 */
async function markLeaseMoveOutInitiationStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/markMoveOutInitiationStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markLeaseMoveOutInitiationStatus ', err);
  }
}


export const LeaseTerminationsServices = {
    getNoticePeriods,
    getNoticePeriodDetails,
    markNoticePeriodTerminalStatus,
    getLeasesList,
    getLeaseById,
    createLeaseExtensions,
    markLeaseMoveOutInitiationStatus
};