import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

/**
 * Takes paymentDetails as props from the parent components
 */
class AdditionalPaymentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAdditionalPaymentDetails: false
        }
    }

    /**
     * Default component did mount method
     */
    async componentDidMount() { }

    /**
     * Method to handle the assign PM button click
     */
    handleAdditionalPaymentDetailsButtonClick = async () => {
        this.setState(state => ({ showAdditionalPaymentDetails: !state.showAdditionalPaymentDetails }));
    }


    render() {
        const {bankAccount, paymentDetails, showAdditionalPaymentDetails } = this.state;
        return (
            <>
                <Button variant="clean" size="sm" onClick={() => this.handleAdditionalPaymentDetailsButtonClick()}>
                    View Details
                </Button>

                <Modal key={3} show={showAdditionalPaymentDetails} onHide={() => this.handleAdditionalPaymentDetailsButtonClick()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Gateway Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Bank Account Details :</h6>
                        <pre>{JSON.stringify(bankAccount, null, 2)}</pre>
                        <br/>
                        <h6>Payment Details :</h6>
                        <pre>{JSON.stringify(paymentDetails, null, 2)}</pre>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleAdditionalPaymentDetailsButtonClick()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default AdditionalPaymentDetails;