import React from "react";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";

export default function ErrorPage2() {
    return (
        <>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1">
                <div className="kt-error-v1__container">
                    <h1 className="kt-error-v1__number">404</h1>
                    <p className="kt-error-v1__desc">OOPS! The page you're looking for doesn't exist.</p>
                </div>
            </div>
        </>
    );
}
