import React from "react";
import { Container, Row, Col, } from 'react-bootstrap';
import MoveOutInitiatedLease from './MoveOutInitiatedLease.js'
import ExpiringLeases from './ExpiringLeases.js';
import NoticePeriodInitiatedLeases from './NoticePeriodInitiatedLeases.js';

export default function LeaseTerminations() { 
    return (
        <>
            <Container fluid key={0}>
                <Row>
                    <Col xs={12} md={8}>
                        <Row>
                            <Col>
                            <MoveOutInitiatedLease />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4} >
                        <Row>
                            <Col>
                            <ExpiringLeases />  
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <NoticePeriodInitiatedLeases />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
