import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get property by id
 * @param {Number} id
 * @param {Object} data
 */
async function getPropertyById(id, data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houses/${id}`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getPropertyById ', err);
  }

}

/**
 * Method to get lease list
 * @param {Object} data 
 */
async function getLeaseListData(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leases`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getLeaseListData ', err);
  }

}

/**
 * Method to edit users
 * @param {Number} id
 * @param {Object} formData
 */
async function editUsers(id, formData) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/users/${id}`, formData);
    return result;
  } catch (err) {
    console.error('error in editUsers ', err);
  }
}

/**
 * Method to change property management status
 * @param {Number} id 
 * @param {Object} data 
 */
async function changePropertyManagementStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houses/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in changePropertyManagementStatus ', err);
  }
}

/**
 * Method to update transfer notes
 * @param {String} id 
 * @param {Object} data 
 */
async function updateTransferNotes(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houses/${id}/transferNotes`, data);
    return result;
  } catch (err) {
    console.error('error in updateTransferNotes ', err);
  }
}

async function getPropertyOwner(data){
  try{
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseOwnerships`,{ params: data })
    return result;
  } catch (err) {
    console.error('error in getPropertyOwner', err);
  }
}

/**
 * Method to delete HouseOwnership
 * @param {Number} id
 * Where it is used:
 *  leaseProfilePage and PropertyProflePage
 */
async function deleteHouseOwnership(id) {
  try {
      const result = await axiosInstance.delete(`${baseApiUrl}admin/houseOwnerships/${id}`);
      return result;
  } catch (err) {
      console.error('error in delete HouseOwnership ', err);
  }
}

async function markPrimaryHouseOwnership(id) {
  try{
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseOwnerships/${id}/markPrimary`);
    return result;
  }
  catch (err) {
    console.error('error in markPrimary HouseOwnership', err);
  }
}
export const PropertyProfilePageServices = {
  getPropertyById,
  getLeaseListData,
  editUsers,
  changePropertyManagementStatus,
  updateTransferNotes,
  getPropertyOwner,
  deleteHouseOwnership,
  markPrimaryHouseOwnership
};