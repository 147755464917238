import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all payments
 * @param {Object} data
 */
async function getAllPayments(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/payments`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllPayments ', err);
  }

}

/**
 * Method to delete payment
 * @param {Number} id 
 */
async function deletePayment(id) {
  try {
    const result = await axiosInstance.delete(`${baseApiUrl}admin/payments/${id}`);
    return result;
  } catch (err) {
    console.error('error in deletePayment ', err);
  }
}

export const PaymentsServices = {
  getAllPayments,
  deletePayment
};