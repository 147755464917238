import axiosInstance from "../../../myAxiosInstance";
import axios from 'axios';
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;
const beatsBaseApiUrl = process.env.REACT_APP_BEATS_HOST;

const axiosInstanceBeats = axios.create({
    headers: {
        'API_KEY': process.env.REACT_APP_BEATS_API_KEY,
    }
});

/**
 * Method to get all properties
 * @param {Object} data
 */
async function getAllProperties(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/houses`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllProperties ', err);
    }

}

/**
 * Method to get all properties
 * @param {Object} data
 */
async function getAllSocieties(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/societies`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllSocieties ', err);
    }

}


/**
 * Method to get all properties from beats master
 * @param {Object} data
 */
async function getAllSocietiesAndLocalitiesBeats(data) {
    try {
        const result = await axiosInstanceBeats.get(`${beatsBaseApiUrl}/api/location/DotComProjectWithLocality`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllSocietiesAndLocalitiesBeats ', err);
    }

}

/**
 * Method to create society
 * @param {Object} data
 */

async function createSociety(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/societies`, data);
        return result;
    } catch (err) {
        console.error('error in createSociety', err);
    }
}

/**
 * Method to get all localities
 * @param {Object} data
 */

async function getAllLocalities(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/localities`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllLocalities', err);
    }
}


/**
 * Method to get all properties from beats master
 * @param {Object} data
 */
// async function getAllLocalitiesBeats(data) {
//     try {
//         const result = await axiosInstanceBeats.get(`${beatsBaseApiUrl}api/location/locationResponseForcountries`, { params: data });
//         return result;
//     } catch (err) {
//         console.error('error in getAllLocalitiesBeats ', err);
//     }

// }

/**
 * Method to create locality
 * @param {Object} data
 */

async function createLocality(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/localities`, data);
        return result;
    } catch (err) {
        console.error('error in createLocality', err);
    }
}

/**
 * Method to get all cities
 * @param {Object} data
 */

async function getAllCities(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/cities`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllCities', err);
    }
}

/** 
 * Method to onboard house
 * @param {Object} data 
 */
async function houseOnboarding(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/houses/onboardings`, data);
        return result;
    } catch (err) {
        console.error('error in houseOnboarding ', err);
    }
}

/**
 * Method to create house
 * @param {Object} data
 */
async function createHouse(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/houses`, data);
        return result;
    } catch (err) {
        console.error('error in createHouse ', err);
    }
}


/**
 * Method to update house details
 * @param {Number} id 
 * @param {Object} data 
 */
 async function updateHouse(id, data) {
    try {
      const result = await axiosInstance.patch(`${baseApiUrl}admin/houses/${id}`, data);
      return result;
    } catch (err) {
      console.error('error in updateHouse ', err);
    }
  }

export const HouseOnboardingServices = {
    getAllProperties,
    getAllSocieties,
    getAllSocietiesAndLocalitiesBeats,
    houseOnboarding,
    createSociety,
    createLocality,
    getAllLocalities,
    getAllCities,
    createHouse,
    updateHouse
};