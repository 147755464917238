import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Button, Modal, Form, Card } from 'react-bootstrap';
import { InspectionReportServices } from './InspectionReport.services';
import { commonServices } from '../../../../app/commonServices/commonServices';
import { Formik, FieldArray } from 'formik';
import * as constants from '../../../constants';
import { formatDate, toAbsoluteUrl } from '../../../../_metronic/utils/utils';
import { store } from 'react-notifications-component';
import PhotoGridComponent from '../../../partials/layout/PhotoGridComponent.js';
import * as firebase from 'firebase/app';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import { connect } from 'react-redux';
class InspectionReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      workId: props.match.params.workId,
      isFetching: false,
      mode: props.match.params.mode,
      showAddSectionsModal: false,
      showEditSectionModal: false,
      showDeleteSectionModal: false,
      showDeleteItemModal: false,
      showAddPhotosModal: false,
      showDeletePhotoModal: false,
      reportItemId: null,
      doc: null,
      identifier: '',
      reportSectionId: null,
      showChangeExecutionDetailsModal: false,
    };
  }

  /**
   * Component did mount method
   */
  async componentDidMount() {
    await this.getInspectionReportById(this.state.workId);
  }

  /**
   * Method to get Item Status Badge
   * @param {String} status 
   */
  getItemStatusBadge = (status) => {
    if (status === 'RED') return <i className="flaticon2-hexagonal text-danger"></i>
    if (status === 'YELLOW') return <i className="flaticon2-hexagonal text-warning"></i>
    if (status === 'GREEN') return <i className="flaticon2-hexagonal text-success"></i>
  }

  /**
   * Method to handle 'add sections' button click
   */
  handleAddSectionsButtonClick = async () => {
    await this.setState(state => ({ showAddSectionsModal: !state.showAddSectionsModal }));
    if (this.state.showAddSectionsModal) {
      firebase.analytics().logEvent("add_report_sections_button_click", { description: "Add Report Sections Button Clicked" });
    }
  }

  /**
   * Method to handle 'edit section' button click
   * @param {Number} sectionId
   */
  handleEditSectionButtonClick = async (sectionId = null) => {
    if (sectionId !== null) {
      const elementToBeEdited = this.state.inspectionReport && this.state.inspectionReport.reportSections.find(repSecId => repSecId.id === sectionId);
      await this.setState({ toBeEdited: elementToBeEdited });
    }
    await this.setState(state => ({ showEditSectionModal: !state.showEditSectionModal }));
    if (this.state.showEditSectionModal) {
      firebase.analytics().logEvent("edit_report_section_button_click", { description: "Edit Report Section Button Clicked" });
    }
  }

  /**
   * Method to handle 'delete section' button click
   * @param {Number} sectionId
   */
  handleDeleteSectionButtonClick = async (sectionId = null) => {
    if (sectionId !== null) {
      await this.setState(state => ({
        idToBeDeleted: sectionId
      }))
    }
    await this.setState(state => ({ showDeleteSectionModal: !state.showDeleteSectionModal }));
    if (this.state.showDeleteSectionModal) {
      firebase.analytics().logEvent("delete_report_section_button_click", { description: "Delete Report Section Button Clicked" });
    }
  }

  /**
   * Method to handle confirm of 'delete section' dialog
   */
  handleApproveDeleteSectionButtonClick = async () => {
    this.setState(state => ({ showDeleteSectionModal: !state.showDeleteSectionModal }));
    if (this.state.idToBeDeleted) {
      const result = await commonServices.deleteReportSection(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        await this.getInspectionReportById(this.state.workId);
      }
    }
  }

  /**
   * Method to handle 'delete' button click of section items
   * @param {Number} itemId
   */
  handleDeleteItemButtonClick = async (itemId) => {
    if (itemId !== null) {
      await this.setState(state => ({
        itemToBeDeleted: itemId
      }))
    }
    this.setState(state => ({ showDeleteItemModal: !state.showDeleteItemModal }));
  }

  /**
   * Method to handle the approval of 'delete' button of section item
   */
  handleApproveDeleteItemButtonClick = async () => {
    this.setState(state => ({ showDeleteItemModal: !state.showDeleteItemModal }));
    if (this.state.itemToBeDeleted) {
      const result = await commonServices.deleteReportSectionItem(this.state.itemToBeDeleted);
      if (result && result.status === 200) {
        await this.getInspectionReportById(this.state.workId);
      }
    }
  }

  /**
   * Method to handle 'change execution details' button click
   * @param {Boolean} isApiCall
   */
  handleChangeExecutionDetailsButtonClick = async (isApiCall = false) => {
    (isApiCall === true) && await this.getPropertySupervisors();
    await this.setState(state => ({ showChangeExecutionDetailsModal: !state.showChangeExecutionDetailsModal }));
    if (this.state.showChangeExecutionDetailsModal) {
      firebase.analytics().logEvent("change_execution_details_button_click", { description: "Change Execution Details Button Clicked" });
    }
  }

  /**
   * Method to get inspection report by Id
   * @param {*} id
   */
  getInspectionReportById = async (id) => {
    this.setState({ isFetching: true });
    const result = await InspectionReportServices.getInspectionReportById(id);
    if (result && result.status === 200) {
      await this.setState({ inspectionReport: result.data.inspectionReport })
    }
    this.setState({ isFetching: false });
  }



  /**
   * Method to get property supervisors
   */
  getPropertySupervisors = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      result.data.users.push(this.props.userDetails);
      await this.setState({ propertySuperVisors: result.data.users })
    }
  }

  /**
   * Method to handle data from child components
   * @param {Boolean} childData
   */
  handlePhotoGridComponentData = async (photoData) => {
    (photoData === true) && await this.getInspectionReportById(this.state.workId);
  }
  render() {
    const { inspectionReport, toBeEdited, mode, propertySuperVisors, isFetching } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    // let reportItemsNew = [];
    // if(toBeEdited && toBeEdited.reportItems){
    //   reportItemsNew = toBeEdited.reportItems;
    //   reportItemsNew.forEach(element => {
    //     delete element.reportItemFiles;
    //   });
    //   // delete reportItemsNew.reportItemFiles;

    // }
    return (
      <>
        <Container fluid key={'inspection-report-' + 0}>
          <Portlet fluidHeight={true}>
            {
              mode === 'edit' ?
                <PortletHeader
                  title="Edit Inspection Report"
                  toolbar={
                    <PortletHeaderToolbar>
                      {inspectionReport && (inspectionReport.isPublished !== true) && <Button variant="primary" className='mr-2' size="sm" onClick={this.handleAddSectionsButtonClick}>
                        <i className="flaticon2-plus"></i> Add Sections
                      </Button>}
                    </PortletHeaderToolbar>
                  }
                /> :
                <PortletHeader
                  title="View Inspection Report"
                />
            }
            <PortletBody>
              {inspectionReport &&
                <Container fluid>
                  <Row>
                    <Col md={6}>
                      <h5>Property Manager</h5>
                      <Row className="mb-3">
                        <Col xs={12}>
                          <div className="kt-widget4">
                            <div className="kt-widget4__item ">
                              <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                <img alt="" src={
                                  toAbsoluteUrl((inspectionReport.house && inspectionReport.house.propertyManager && inspectionReport.house.propertyManager.imgUrl) || '/media/users/default.jpg')
                                } />
                              </div>
                              <div className="kt-widget4__info ">
                                <p className="mb-0 kt-widget4__username">
                                  {
                                    inspectionReport.house.propertyManager && inspectionReport.house.propertyManager.fullName
                                  }
                                </p>
                                {/*<p className="kt-widget4__text small">
                                {this.state.inspection_report.property_manager.phone_no} | {this.state.inspection_report.property_manager.email}
                            </p>*/}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                    <Col md={6}>
                      <h5>Report Execution Details</h5>
                      <Row className="mb-3">
                        <Col xs={12} className="d-flex align-items-center justify-content-between">
                          {inspectionReport.executor && inspectionReport.executor?.fullName  &&
                            <div className="kt-widget4">
                              <div className="kt-widget4__item ">
                                <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                  <img alt="" src={
                                    toAbsoluteUrl((inspectionReport.executor.imgUrl) || '/media/users/default.jpg')
                                  } />
                                </div>
                                <div className="kt-widget4__info ">
                                  <p className="mb-0 kt-widget4__username">
                                    {inspectionReport.executor.fullName}
                                  </p>
                                  <p className="kt-widget4__text ">
                                    on {formatDate(inspectionReport.executionDate)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          }
                          {inspectionReport.executor === null && <span className="text-warning"><i className="flaticon-warning mr-2"></i>Details not entered! Fill in the details before publishing.</span>}
                          {inspectionReport && (inspectionReport.isPublished !== true) && <Button variant="info" title="Update Report Execution Details" className='mr-2' size="sm" onClick={() => this.handleChangeExecutionDetailsButtonClick(true)}>
                            {/* <i className="flaticon2-edit"></i> */}
                            Edit Details
                          </Button>}
                        </Col>
                      </Row>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    {inspectionReport.reportSections.length === 0 && <Col xs={12}>
                      <h5 className="text-warning"><i className="flaticon-warning mr-3"></i>No Report Sections created.</h5>
                      <p className="text-warning">Add atleast one Report Section with one Report Item &amp; some photos before publishing the report.</p>
                    </Col>}
                    {inspectionReport.reportSections.map(section => (
                      <Col xs={12} key={section.id}>
                        <Card className="my-3">
                          <Card.Header>
                            <Row className="d-flex align-items-center">
                              <Col xs={6}>
                                <h5 className="mb-0">{section.name}</h5>
                              </Col>
                              <Col xs={6} className="text-right">
                                {inspectionReport && (inspectionReport.isPublished !== true) && mode === 'edit' && <>
                                  <Button className='mr-2' variant="clean" size="sm" title="Edit" onClick={() => this.handleEditSectionButtonClick(section.id)}>
                                    <i className="flaticon2-edit"></i>
                                  </Button>
                                  <Button variant="clean" size="sm" title="Delete" onClick={() => this.handleDeleteSectionButtonClick(section.id)}>
                                    <i className="flaticon2-trash"></i>
                                  </Button>
                                </>}
                              </Col>
                              <Col xs={12}>
                                <p>
                                  {
                                    section.comments && section.comments.map((comment, idx) =>
                                      <React.Fragment key={idx + 'PUNS'}>
                                        <span key={idx + 'PUN'}>
                                          {(idx > 0 && idx < (section.comments.length)) ? ', ' : ''}
                                        </span>
                                        <span key={idx}>
                                          {comment}
                                        </span>
                                      </React.Fragment>
                                    )
                                  }
                                </p>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              {section.reportItems.length === 0 && <Col xs={12}>
                                <h5 className="text-warning"><i className="flaticon-warning mr-3"></i>No Report Items created.</h5>
                                <p className="text-warning">Add atleast one Report Item to this section before publishing the report.</p>
                              </Col>}
                            </Row>
                              {section.reportItems.map((item, i) => (
                                <Row key={"ri"+item.id}>
                                  <Col xs={6} key={item.id}>
                                    <h6>{item.name} {item.count > 0 && '(' + item.count + ')'}</h6>
                                    <p className="mb-0">
                                      Comment: {item.comment}
                                    </p>
                                  </Col>
                                  <Col xs={1}>
                                    <h6>Status:</h6>
                                    <p className="mb-0">
                                      {this.getItemStatusBadge(item.status)}
                                    </p>
                                  </Col>
                                  <Col xs={4}>
                                    {/* images, mode, serviceReqeustId, reportSectionId, reportItemId, title */}
                                    <PhotoGridComponent
                                      medias={item.reportItemFiles}
                                      mode={mode}
                                      reportItemId={item.id}
                                      title={`Photos:`}
                                      isItemMedia={true}
                                      photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                    />
                                  </Col>
                                  <Col xs={1}>
                                    {inspectionReport && (inspectionReport.isPublished !== true) && mode === 'edit' && <Button variant="clean" size="sm" title="Delete" onClick={() => this.handleDeleteItemButtonClick(item.id)} >
                                      <i className="flaticon2-trash"></i>
                                    </Button>}
                                  </Col>
                                  <Col xs={12}>
                                    {section.reportItems.length !== i + 1 && (
                                      <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                    )}
                                  </Col>
                                </Row>
                              ))}
                            <Row>
                              <Col xs={12}>
                                {/* images, mode, serviceReqeustId, reportSectionId, reportItemId, title */}
                                <PhotoGridComponent
                                  medias={section.collatedReportSectionFiles}
                                  mode={mode}
                                  reportSectionId={section.id}
                                  title={`SECTION PHOTOS`}
                                  photoGridComponentParentCallback={this.handlePhotoGridComponentData}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              }
            </PortletBody>
          </Portlet>
        </Container>

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddSectionsModal} onHide={this.handleAddSectionsButtonClick} >
          <Modal.Header closeButton>
            <Modal.Title>Add Sections</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                sections: [{
                  houseInspectionId: this.state.workId,
                  name: ""
                }]
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await commonServices.createReportSections(values.sections);
                  if (result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.sections.length + ` Sections added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddSectionsModal: !state.showAddSectionsModal }));
                    this.getInspectionReportById(this.state.workId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                  (

                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <FieldArray
                            name="sections"
                            render={arrayHelpers => (
                              <div>
                                {values.sections.map((section, index) =>
                                  (<Row key={index}>
                                    <Col xs={10}>
                                      <Form.Group controlId={"section" + index}>
                                        <Form.Label>Section Name</Form.Label>
                                        <Form.Control type="text" name={`sections[${index}].name`} required="required" onChange={handleChange} onBlur={handleBlur} value={section.name} list="sectionNamesList" />
                                        <datalist id="sectionNamesList">
                                          <option value="Living Room">Living Room</option>
                                          <option value="Hall">Hall</option>
                                          <option value="Kitchen">Kitchen</option>
                                          <option value="Master Bedroom">Master Bedroom</option>
                                          <option value="Children Bedroom">Children Bedroom</option>
                                          <option value="Guest Bedroom">Guest Bedroom</option>
                                          <option value="Common Bedroom">Common Bedroom</option>
                                          <option value="Master Bathroom">Master Bathroom</option>
                                          <option value="Children Bathroom">Children Bathroom</option>
                                          <option value="Common Bathroom">Common Bathroom</option>
                                          <option value="Guest Bathroom">Guest Bathroom</option>
                                          <option value="Flat Photos">Flat Photos</option>
                                          <option value="Passage">Passage</option>
                                          <option value="Store Room">Store Room</option>
                                          <option value="Hall Balcony">Hall Balcony</option>
                                          <option value="Master Bedroom Balcony">Master Bedroom Balcony</option>
                                          <option value="Children Bedroom Balcony">Children Bedroom Balcony</option>
                                          <option value="Guest Bedroom Balcony">Guest Bedroom Balcony</option>
                                        </datalist>
                                      </Form.Group>
                                    </Col>
                                    <Col xs={2} className="d-flex align-items-center">
                                      <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                    </Col>
                                  </Row>)
                                )}
                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({houseInspectionId: this.state.workId, name: ""})}>Add Another Section</Button>
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="text-right">
                          <Button variant="secondary" className="mr-2" onClick={this.handleAddSectionsButtonClick}>
                            Cancel
                            </Button>
                          <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
              }
            </Formik>
          </Modal.Body>
        </Modal>
        }

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showEditSectionModal} onHide={this.handleEditSectionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                name: toBeEdited ? toBeEdited.name : undefined,
                comments: toBeEdited ? toBeEdited.comments : [""],
                reportItems:
                  toBeEdited ?
                    toBeEdited.reportItems : [
                      {
                        id: undefined,
                        count: undefined,
                        name: undefined,
                        status: undefined,
                        comment: undefined
                      }
                    ]
              }}
              validate={values => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Name is required';
                }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  // TODO : check if this step can be avoided
                  (values?.reportItems).forEach((reportItem, index) => {
                    if (reportItem.count === '') { reportItem.count = undefined; }
                  });
                  const result = await commonServices.updateReportSection(this.state.toBeEdited.id, values);
                  if (result.status === 200 || result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.name + ` section updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.getInspectionReportById(this.state.workId);
                    this.setState(state => ({ showEditSectionModal: !state.showEditSectionModal }));
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                  (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="sectionName">
                            <Form.Label>Section Name</Form.Label>
                            <Form.Control type="text" name='name' onChange={handleChange} onBlur={handleBlur} value={values.name} />
                          </Form.Group>
                        </Col>
                        <Col xs={12}>Edit Comment(s)</Col>
                        <Col xs={12} className="mb-3">
                          <FieldArray
                            name="comments"
                            render={arrayHelpers => (
                              <div>
                                {values.comments && values.comments.length > 0 && values.comments.map((item, index) =>
                                  (<Row key={index}>
                                    <Col xs={10}>
                                      <Form.Group controlId={"comment" + index}>
                                        <Form.Control type="text" name={`comments[${index}]`} required="required" onChange={handleChange} onBlur={handleBlur} value={item} />
                                      </Form.Group>
                                    </Col>
                                    {/* <Col xs={5}>
                                      <Form.Group controlId={"itemsAmount" + index}>
                                        <Form.Control type="number" name={`costEstimateItems[${index}].amount`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.amount} />
                                      </Form.Group>
                                    </Col> */}
                                    <Col xs={2}>
                                      <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                    </Col>
                                  </Row>)
                                )}
                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Comment</Button>
                              </div>
                            )}
                          />
                        </Col>

                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                        {/* TODO: Array of Comments & Array of Items */}
                        <Col xs={12}>
                          <FieldArray
                            name="reportItems"
                            render={arrayHelpers => (
                              <div>
                                {values.reportItems.map((item, index) =>
                                  (
                                    <Row key={index}>
                                      <Col xs={6}>
                                        <h6>Item #{index + 1}</h6>
                                      </Col>
                                      <Col xs={6} className="text-right">
                                        {/* <Button variant="clean" onClick={() => arrayHelpers.remove(index)}>
                                        <i className="flaticon2-cross"></i>
                                      </Button> */}
                                        {
                                          !item.id &&
                                          <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                        }
                                      </Col>
                                      <Col xs={3}>
                                        <Form.Group controlId={"itemName" + index}>
                                          <Form.Label>Item Name</Form.Label>
                                          <Form.Control type="text" name={`reportItems[${index}].name`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.name} />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={3}>
                                        <Form.Group controlId={"itemStatus" + index}>
                                          <Form.Label>Status</Form.Label>
                                          <Form.Control as="select" name={`reportItems[${index}].status`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.status}>
                                            {
                                              !item.id &&
                                              <option key={'' + index} value={''}>{constants.DEFAULT_OPTION}</option>
                                            }
                                            <option key={"RED" + index} value={"RED"}>Red</option>
                                            <option key={"YELLOW" + index} value={"YELLOW"}>Yellow</option>
                                            <option key={"GREEN" + index} value={"GREEN"}>Green</option>
                                          </Form.Control>
                                        </Form.Group>
                                      </Col>
                                      <Col xs={3}>
                                        <Form.Group controlId={"itemCount" + index}>
                                          <Form.Label>Count</Form.Label>
                                          <Form.Control type="number" name={`reportItems[${index}].count`} onChange={handleChange} onBlur={handleBlur} value={item.count} />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={3}>
                                        <Form.Group controlId={"itemComment" + index}>
                                          <Form.Label>Comment</Form.Label>
                                          <Form.Control as="textarea" rows="3" name={`reportItems[${index}].comment`} onChange={handleChange} onBlur={handleBlur} value={item.comment} />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={12}>
                                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                      </Col>
                                      {/* <Col xs={2}>
                                        <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                      </Col> */}
                                    </Row>
                                  )
                                )}
                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push(
                                  {
                                    count: undefined,
                                    name: undefined,
                                    status: undefined,
                                    comment: undefined
                                  }
                                )}>Add Item</Button>
                              </div>
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} className="text-right">
                          <Button variant="secondary" className="mr-2" onClick={this.handleEditSectionButtonClick}>
                            Cancel
                            </Button>
                          <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
              }
            </Formik>
          </Modal.Body>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 3} show={this.state.showDeleteSectionModal} onHide={this.handleDeleteSectionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Section</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete all the linked section items, comments, item status and photos.</p>
            <p><strong>Confirm deletion of this section?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteSectionButtonClick}>
              Cancel
              </Button>
            <Button variant="danger" onClick={this.handleApproveDeleteSectionButtonClick}>
              Delete
              </Button>
          </Modal.Footer>
        </Modal>}

        {mode === 'edit' && <Modal backdrop="static" keyboard={false} key={'m' + 4} show={this.state.showDeleteItemModal} onHide={this.handleDeleteItemButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible. It will also delete the item comments, item status and item photos.</p>
            <p><strong>Confirm deletion of this item?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteItemButtonClick}>
              Cancel
              </Button>
            <Button variant="danger" onClick={this.handleApproveDeleteItemButtonClick}>
              Delete
              </Button>
          </Modal.Footer>
        </Modal>}

        {mode === 'edit' && <Modal key={'m' + 8} backdrop="static" keyboard={false} show={this.state.showChangeExecutionDetailsModal} onHide={this.handleChangeExecutionDetailsButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Report Execution Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                executorId: (this.state.inspectionReport && this.state.inspectionReport.executor && this.state.inspectionReport.executor.id) || "",
                executionDate: (this.state.inspectionReport && formatDate(this.state.inspectionReport.executionDate)) || "",
                comments: (this.state.inspectionReport && this.state.inspectionReport.comments) || [""]
              }}
              validate={values => {
                const errors = {};
                if (!values.executorId) {
                  errors.executorId = 'Executor is required';
                }
                if (!values.executionDate) {
                  errors.executionDate = 'Date is required';
                }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  values.executorId = parseInt(values.executorId, 10);
                  const result = await InspectionReportServices.changeExecutionDetails(this.state.workId, values);
                  if (result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Execution details recorded!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showChangeExecutionDetailsModal: !state.showChangeExecutionDetailsModal }));
                    this.getInspectionReportById(this.state.workId);
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                  (

                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="executorId">
                            <Form.Label>Report Created by</Form.Label>
                            <Form.Control as="select" name="executorId" onChange={handleChange} onBlur={handleBlur} value={values.executorId}>
                              <option value={0}>{constants.DEFAULT_OPTION}</option>
                              {
                                propertySuperVisors && propertySuperVisors.map((psData =>
                                  <option key={"ps"+psData.id} value={psData.id}>{psData.fullName}</option>
                                ))
                              }
                            </Form.Control>
                            <span className="text-danger my-2">
                              {errors.executorId && touched.executorId && errors.executorId}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col xs={12}>

                          {/* <Form.Group controlId="executionDate">
                            <Form.Label>Execution Date</Form.Label>
                            <Form.Control type="date" name='executionDate' onChange={handleChange} onBlur={handleBlur} value={values.executionDate} />
                          </Form.Group> */}

                          <Form.Group controlId="executionDate">
                            <DatePickerField
                              className='form-control'
                              autoComplete='off'
                              label='Execution Date'
                              dateFormat='MMMM d, yyyy'
                              name='executionDate'
                              value={values.executionDate}
                              onChange={setFieldValue}
                            />
                            {errors.executionDate && touched.executionDate && <Form.Text className="text-danger">
                              {errors.executionDate}
                            </Form.Text>}
                          </Form.Group>
                        </Col>

                        <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                        <Col xs={12}>Add Comment(s)</Col>
                        <Col xs={12}>
                          <FieldArray
                            name="comments"
                            render={arrayHelpers => (
                              <div>
                                {values.comments.map((item, index) =>
                                  (
                                    <Row key={index}>
                                      <Col xs={10}>
                                        <Form.Group controlId={"comment" + index}>
                                          <Form.Control type="text" name={`comments[${index}]`} required="required" onChange={handleChange} onBlur={handleBlur} value={item} />
                                        </Form.Group>
                                      </Col>
                                      <Col xs={2}>
                                        <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                      </Col>
                                    </Row>)
                                )}
                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push("")}>Add Item</Button>
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="text-right">
                          <Button variant="secondary" className="mr-2" onClick={this.handleChangeExecutionDetailsButtonClick}>
                            Cancel
                            </Button>
                          <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
              }
            </Formik>
          </Modal.Body>
        </Modal>}
      </>

    );
  }

}


const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(InspectionReport);
