import axios from 'axios';
import store from './store/store';
import * as auth from './store/ducks/auth.duck';
import * as notificationStore from 'react-notifications-component';

const axiosInstance = axios.create({
  withCredentials: true,
});

/**
 * Appending the authToken to axios instance
 */
axiosInstance.interceptors.request.use(
  config => {
    const {
      auth: { authToken }
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  err => {
    Promise.reject(err);
  }
);

/**
 * Loggin the user out when the http status code of the response is 401
 */
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { dispatch } = store;

    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if (error.response.status === 401) {
        dispatch(auth.actions.logout());
      } else if (
        error.response.status === 422 ||
        error.response.status === 403 ||
        error.response.status === 404 ||
        error.response.status === 400 ||
        error.response.status === 500
      ) {
        notificationStore.store.addNotification({
          title: "Error!",
          message: `${error.response.data.message || 'Something went wrong!'} (${error.response.status})`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      notificationStore.store.addNotification({
        title: "Error!",
        message: `${'No response received from the server'}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
    } else {
      // Something happened in setting up the request and triggered an Error
      notificationStore.store.addNotification({
        title: "Error!",
        message: `${error.message || 'Error in setting up the request'}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
          showIcon: true,
          pauseOnHover: true
        }
      });
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
