import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form, Card } from 'react-bootstrap';
import { commonServices } from '../../../app/commonServices/commonServices';
import { HouseOnboardingServices } from '../../pages/home/HouseOnboarding/HouseOnboarding.services';
import { Formik, FieldArray } from 'formik';
import { formatProperCase } from '../../../_metronic/utils/utils';
import { store } from 'react-notifications-component';
import * as Yup from 'yup'; // for everything
import { DatePickerField } from './DatePickerField';
import * as firebase from 'firebase/app';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { formatMultipleUsersWithoutSpan } from '../../../_metronic/utils/utils';

const addInvoiceSchema = Yup.object().shape({
    title: Yup.string().required('Invoice Title is a required field'),
    // raisedBy: Yup.string().required('Raised By is a required field'),
    invoiceNumber: Yup.string().required('Invoice Number is a required field'),
    invoiceDate: Yup.date().required('Invoice Date is a required field'),
    houseId: Yup.number().required('Property is a required field'),
    invoiceItems: Yup.array().of(Yup.object().shape({
        name: Yup.string().required('Item name is a required field'),
        amount: Yup.number().required('Please enter the amount').min(1, 'The amount must be greater than 0')
    })).min(1, 'There should at least be one Invoice Item'),
});

class AddInvoiceComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAddInvoiceModal: false,
            isLoading: false,
            options: [],
            query: '',
            offset: 0,
            limit: 50,
            propertyOptions: [],
            propertyQuery: '',
            propertyOffset: 0,
            propertyLimit: 50,
        };
    }

    cache = {};
    propertyCache = {};

    /**
     * Component did mount method
     */
    async componentDidMount() {
    }

    /**
     * Method to handle 'New Invoice' button click
    * @param {Boolean} isApiCall
    */
    handleNewInvoiceButtonClick = async (isApiCall = false) => {
        // if (isApiCall === true && this.props.isEstimateInvoice === 0 && !this.props.houseIdFromPropertyProfile) {
        //     await this.getPropertyDetails();
        // }
        if (this.props.houseId && !this.props.isLeaseProfile) {
            this.setState({ partiesData: this.props.approverData })
        }
        if (this.props.isLeaseProfile || this.props.houseIdFromPropertyProfile) {
            const data = {
                houseId: this.props.houseId || this.props.houseIdFromPropertyProfile,
                stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS'],
            }
            await this.getUserListData(data);
        }
        this.setState(state => ({ showAddInvoiceModal: !state.showAddInvoiceModal }));
    }

    /**
      * Method to get property details
      */
    // getPropertyDetails = async () => {
    //     const data = {
    //         offset: 0,
    //         limit: 100
    //     }
    //     const propertyData = await commonServices.getPropertyData(data)
    //     if (propertyData && propertyData.status === 200) {
    //         await this.setState({ initialHouseData: propertyData.data.houses })
    //     }
    // }

    /**
      * Method to get userList Data
      * @param {Object} data
      */
    getUserListData = async (data) => {
        const requestorData = await commonServices.getUserListData(data);
        if (requestorData && requestorData.status === 200) {
            await this.setState({ partiesData: requestorData.data.users })
        }
    }

    handleInputChange = query => {
        this.setState({ query });
    };

    handlePropertyInputChange = propertyQuery => {
        this.setState({ propertyQuery });
    };

    handlePagination = async (e, shownResults) => {
        const { query } = this.state;
        const cachedQuery = this.cache[query];

        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedQuery.options.length > shownResults ||
            cachedQuery.options.length === cachedQuery.total_count
        ) {
            return;
        }

        this.setState({ isLoading: true });

        const page = cachedQuery.page + 1;

        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });

        const response = await this.getAllVendors();
        const options = cachedQuery.options.concat(response.options);
        this.cache[query] = { ...cachedQuery, options, page, offset };
        this.setState({
            isLoading: false,
            options,
        });
    };

    handlePropertyPagination = async (e, shownResults) => {
        const { propertyQuery } = this.state;
        const cachedPropertyQuery = this.propertyCache[propertyQuery];

        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedPropertyQuery.options.length > shownResults ||
            cachedPropertyQuery.options.length === cachedPropertyQuery.total_count
        ) {
            return;
        }

        this.setState({ isLoading: true });

        const propertyPage = cachedPropertyQuery.propertyPage + 1;

        const { propertyLimit } = this.state;
        const propertyOffset = (propertyLimit * (propertyPage - 1));
        await this.setState({ propertyPage, propertyOffset });

        const response = await this.getAllProperties();
        const propertyOptions = cachedPropertyQuery.options.concat(response.options);
        this.propertyCache[propertyQuery] = { ...cachedPropertyQuery, propertyOptions, propertyPage, propertyOffset };
        this.setState({
            isLoading: false,
            propertyOptions,
        });
    };


    handleSearch = async (query) => {
        if (this.cache[query]) {
            this.setState({ options: this.cache[query].options });
            return;
        }
        this.setState({ isLoading: true });
        const response = await this.getAllVendors();
        this.cache[query] = { ...response, page: 1 };
        this.setState({
            isLoading: false,
            options: response && response.options,
        });
    };

    handlePropertySearch = async (propertyQuery) => {
        if (this.propertyCache[propertyQuery]) {
            this.setState({ propertyOptions: this.propertyCache[propertyQuery].options });
            return;
        }
        this.setState({ isLoading: true });
        const response = await this.getAllProperties();
        this.propertyCache[propertyQuery] = { ...response, propertyPage: 1 };
        this.setState({
            isLoading: false,
            propertyOptions: response && response.options,
        });
    };

    getAllVendors = async () => {
        this.setState({ isLoading: true });
        const { offset, limit, query } = this.state;
        const data = {
            searchQuery: query,
            isVendor: 'TRUE',
            offset,
            limit
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const options = result.data.users.map(userData => ({
                id: userData.id,
                vendor: userData.fullName,
            }));
            return { options, total_count };
        }
        this.setState({ isLoading: false });
    }

    /**
     * Method to get all the properties Data
     */
    getAllProperties = async () => {
        this.setState({ isLoading: true });
        const { propertyOffset, propertyLimit, propertyQuery } = this.state;
        const data = {
            searchQuery: propertyQuery,
            offset: propertyOffset,
            limit: propertyLimit,
            scope: 'ALL',
            include: ['OWNERS', 'CURRENT_TENANTS']
        }
        const result = await HouseOnboardingServices.getAllProperties(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const options = result.data.houses.map(houseData => ({
                id: houseData.id,
                property: `${houseData.buildingCode}-${houseData.unitNumber}, ${houseData.society && houseData.society.name} | ${houseData.buildingName} | Owners: ${formatMultipleUsersWithoutSpan(houseData.owners)} | Tenants: ${formatMultipleUsersWithoutSpan(houseData.currentTenants)}`,
                propertyManagementStatus: houseData.propertyManagementStatus
            }));
            return { options, total_count };
        }
        this.setState({ isLoading: false });
    }

    render() {
        return (
            <>
                <Button variant="link" size="sm" onClick={() => this.handleNewInvoiceButtonClick(true)}>New Invoice</Button>

                <Modal backdrop="static" keyboard={false} show={this.state.showAddInvoiceModal} onHide={this.handleNewInvoiceButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Invoice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                title:undefined,
                                houseId: this.props.houseId || this.props.houseIdFromPropertyProfile || null,
                                houseName: undefined,
                                costEstimateId: this.props.isEstimateInvoice ? this.props.isEstimateInvoice : null,
                                invoiceDate: undefined,
                                invoiceNumber: undefined,
                                raisedBy: undefined,
                                validityStatus: "VALID",
                                invoiceItems: [
                                    {
                                        name: undefined,
                                        amount: undefined
                                    }
                                ],
                                invoiceParticipations: [],
                            }}
                            validationSchema={addInvoiceSchema}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    let invoiceParticipationArray = [];
                                    values.invoiceParticipations.forEach((invoicep, index) => {
                                        let invoicepObject = {};
                                        invoicepObject.userId = parseInt(invoicep, 10);
                                        invoiceParticipationArray.push(invoicepObject);
                                    })
                                    values.invoiceParticipations = invoiceParticipationArray;
                                    const result = await commonServices.createInvoice(values);
                                    if (result && result.status === 201) {
                                        // await this.getAllinvoicesData();
                                        // await this.setState({ showButton: false });
                                        store.addNotification({
                                            title: "Success!",
                                            message: `New Invoice created!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        if (this.props.isLeaseProfile) {
                                            firebase.analytics().logEvent("save_new_invoice_button_click", { description: "Save New Invoice Button Clicked", addInvoiceFrom: 'Lease Profile' });
                                            this.props.estimatesComponentCallback('LeaseProfilePage');
                                        } else if (this.props.isEstimateInvoice === 0) {
                                            firebase.analytics().logEvent("save_new_invoice_button_click", { description: "Save New Invoice Button Clicked", addInvoiceFrom: 'Invoices' });
                                            this.props.invoicesComponentCallback('InvoicesPage');
                                        } else {
                                            firebase.analytics().logEvent("save_new_invoice_button_click", { description: "Save New Invoice Button Clicked", addInvoiceFrom: 'Estimates in Service Request' });
                                            this.props.estimatesComponentCallback('ServiceRequests');
                                        }
                                        this.setState(state => ({ showAddInvoiceModal: !state.showAddInvoiceModal }));
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                    (
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group controlId="title">
                                                        <Form.Label>Invoice Title</Form.Label>
                                                        <Form.Control type="text" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                                        {errors.title && touched.title && <Form.Text className="text-danger">
                                                            {errors.title}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>
                                                {!this.props.houseIdFromPropertyProfile && this.props.isEstimateInvoice === 0 &&
                                                    <Col xs={12}>
                                                        <Form.Group controlId="houseSelect">
                                                        <Form.Label>Property <span className="text-danger">*</span></Form.Label>
                                                            {/* <Form.Control as="select" name="houseId"
                                                                onChange={
                                                                    async (e) => {
                                                                        handleChange(e);
                                                                        const data = {
                                                                            houseId: e.currentTarget.value,
                                                                            stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS'],
                                                                        }
                                                                        const requestorData = await commonServices.getUserListData(data);
                                                                        if (requestorData && requestorData.status === 200) {
                                                                            await this.setState({ partiesData: requestorData.data.users })
                                                                        } else {
                                                                            console.error('Something went wrong!');
                                                                        }
                                                                    }
                                                                }
                                                                onBlur={handleBlur} value={values.houseId}>
                                                                <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                {this.state.initialHouseData && this.state.initialHouseData.map((houseData =>
                                                                    <option value={houseData.id}>{houseData.buildingCode}-{houseData.unitNumber}, {houseData.society.name}</option>
                                                                ))
                                                                }
                                                            </Form.Control> */}
                                                        <AsyncTypeahead
                                                            // {...this.state}
                                                            name="houseId"
                                                            isLoading={this.state.isLoading}
                                                            options={this.state.propertyOptions}
                                                            query={this.state.propertyQuery}
                                                            id="async-pagination-property"
                                                            labelKey="property"
                                                            maxResults={this.state.propertyLimit - 1}
                                                            minLength={3}
                                                            onChange={async (e) => {
                                                                const houseId = (e.length > 0) ? e[0].id : undefined;
                                                                const houseName = (e.length > 0) ? e[0].property : undefined;
                                                                // const propertyManagementStatus = (e.length > 0) ? e[0].propertyManagementStatus : undefined;
                                                                setFieldValue('houseId', houseId);
                                                                setFieldValue('houseName', houseName);
                                                                // setFieldValue('house.propertyManagementStatus', propertyManagementStatus);
                                                                const data = {
                                                                    houseId: houseId,
                                                                    stakeHolderCategories: ['LATEST_TENANTS', 'OWNERS'],
                                                                }
                                                                const requestorData = await commonServices.getUserListData(data);
                                                                if (requestorData && requestorData.status === 200) {
                                                                    await this.setState({ partiesData: requestorData.data.users })
                                                                } else {
                                                                    console.error('Something went wrong!');
                                                                }
                                                            }}
                                                            onInputChange={this.handlePropertyInputChange}
                                                            onPaginate={this.handlePropertyPagination}
                                                            onSearch={this.handlePropertySearch}
                                                            paginate
                                                            placeholder={values.houseName ? values.houseName : "Search for your property here..."}
                                                            renderMenuItemChildren={option => (
                                                                <div key={option.id}>
                                                                    <span>{option.property}</span>
                                                                    {/* <br/><span className="small text-muted">{`Property Management Status: `}{option.propertyManagementStatus}</span> */}
                                                                </div>
                                                            )}
                                                            useCache={false}
                                                        />
                                                            {errors.houseId && touched.houseId && <Form.Text className="text-danger">
                                                                {errors.houseId}
                                                            </Form.Text>}
                                                        </Form.Group>
                                                    </Col>
                                                }
                                                <Col xs={12} className="mt-2">
                                                    <Form.Group controlId="invoiceParticipations">
                                                        <Form.Label>In the Name of</Form.Label>
                                                        <Form.Control as="select" name="invoiceParticipations" onChange={handleChange} onBlur={handleBlur} value={values.invoiceParticipations} multiple>
                                                            {
                                                                this.state.partiesData && this.state.partiesData.map((parties =>
                                                                    <option value={parties.id}>{parties.fullName}
                                                                        {parties.userSubCategory && ' (' + formatProperCase(parties.userSubCategory) + ')'}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Control>
                                                        {errors.invoiceNumber && touched.invoiceNumber && <Form.Text className="text-danger">
                                                            {errors.invoiceNumber}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Group controlId="invoiceDate">
                                                        <DatePickerField
                                                            className='form-control'
                                                            autoComplete='off'
                                                            label='Invoice Date'
                                                            dateFormat='MMMM d, yyyy'
                                                            name='invoiceDate'
                                                            value={values.invoiceDate}
                                                            onChange={setFieldValue}
                                                        />
                                                        {errors.invoiceDate && touched.invoiceDate && <Form.Text className="text-danger">
                                                            {errors.invoiceDate}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6}>
                                                    <Form.Group controlId="invoiceNumber">
                                                        <Form.Label>Invoice Number</Form.Label>
                                                        <Form.Control type="text" name="invoiceNumber" onChange={handleChange} onBlur={handleBlur} value={values.invoiceNumber} />
                                                        {errors.invoiceNumber && touched.invoiceNumber && <Form.Text className="text-danger">
                                                            {errors.invoiceNumber}
                                                        </Form.Text>}
                                                    </Form.Group>
                                                </Col>

                                                {/* <Col xs={12}>
                                                    <Form.Group controlId="raisedBy">
                                                        <Form.Label>Raised By</Form.Label>
                                                        <Form.Control type="text" name="raisedBy" onChange={handleChange} onBlur={handleBlur} value={values.raisedBy} />
                                                    </Form.Group>
                                                    <div className="text-danger mb-4 small">
                                                        {errors.raisedBy && touched.raisedBy && errors.raisedBy}
                                                    </div>
                                                </Col> */}
                                                <Col xs={12}>
                                                    <Card className="mb-2" variant="secondary">
                                                        <Card.Body>
                                                            <h5><i className="flaticon2-warning"></i>&nbsp;Please Note</h5>
                                                            <p className="mb-0">{`If the Invoice is being raised by Azuro, you do not need to fill Vendor Name. Please leave it blank!`}</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col xs={12} className="mb-3">
                                                    <label>Raised By</label>
                                                    <AsyncTypeahead
                                                        // {...this.state}
                                                        name="raisedBy"
                                                        isLoading={this.state.isLoading}
                                                        options={this.state.options}
                                                        query={this.state.query}
                                                        id="async-pagination-example"
                                                        labelKey="vendor"
                                                        maxResults={this.state.limit - 1}
                                                        minLength={3}
                                                        onChange={(selected) => {
                                                            const vendorId = (selected.length > 0) ? selected[0].id : undefined;
                                                            setFieldValue('raisedBy', vendorId);
                                                        }}
                                                        onInputChange={this.handleInputChange}
                                                        onPaginate={this.handlePagination}
                                                        onSearch={this.handleSearch}
                                                        paginate
                                                        placeholder={"Search for vendor here..."}
                                                        renderMenuItemChildren={option => (
                                                            <div key={option.id}>
                                                                <span>{option.vendor}</span>
                                                            </div>
                                                        )}
                                                        useCache={false}
                                                    />
                                                </Col>

                                                <Col xs={12}>Invoice items</Col>
                                                <Col xs={12}>
                                                    <Row>
                                                        <Col xs={5}>
                                                            <Form.Label>Item Name</Form.Label>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <Form.Label>Amount</Form.Label>
                                                        </Col>
                                                    </Row>
                                                    <FieldArray
                                                        name="invoiceItems"
                                                        render={arrayHelpers => (
                                                            <div>
                                                                {values.invoiceItems.map((item, index) =>
                                                                    (<Row key={index}>
                                                                        <Col xs={5}>
                                                                            <Form.Group controlId={"itemsParticular" + index}>
                                                                                <Form.Control type="text" name={`invoiceItems[${index}].name`} onChange={handleChange} onBlur={handleBlur} value={item.name} />
                                                                                {errors.invoiceItems && touched.invoiceItems && errors.invoiceItems[index] && touched.invoiceItems[index] && <Form.Text className="text-danger">
                                                                                    {(errors.invoiceItems[index].name && touched.invoiceItems[index].name && errors.invoiceItems[index].name)}
                                                                                </Form.Text>}
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={5}>
                                                                            <Form.Group controlId={"itemsAmount" + index}>
                                                                                <Form.Control type="number" name={`invoiceItems[${index}].amount`} onChange={handleChange} onBlur={handleBlur} value={item.amount} />
                                                                                {errors.invoiceItems && touched.invoiceItems && errors.invoiceItems[index] && touched.invoiceItems[index] && <Form.Text className="text-danger">
                                                                                    {(errors.invoiceItems[index].amount && touched.invoiceItems[index].amount && errors.invoiceItems[index].amount)}
                                                                                </Form.Text>}
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={2}>
                                                                            <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                                                        </Col>
                                                                    </Row>)
                                                                )}
                                                                {typeof errors.invoiceItems === 'string' ? <p className="text-danger mb-2">{errors.invoiceItems}</p> : null}
                                                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({ name: undefined, amount: undefined })}>Add Item</Button>
                                                            </div>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="text-right">
                                                    <Button variant="secondary" className="mr-2" onClick={() => this.handleNewInvoiceButtonClick()}>
                                                        Cancel
                                                    </Button>
                                                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Saving...' : 'Save'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

            </>
        )
    }
}

export default AddInvoiceComponent;