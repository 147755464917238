import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to list PMS Subscriptions
 * @param {Object} data 
 */
async function listLeaseExtensions(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leaseExtensions`, { params: data });
    return result;
  } catch (err) {
    console.error('error in listLeaseExtensions ', err);
  }

}

/**
 * Method to reschedule tasks
 * @param {Object} data 
 */
async function markLeaseExtensionTerminalStatus(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/leaseExtensions/${id}/markTerminalStatus`, data);
    return result;
  } catch (err) {
    console.error('error in markLeaseExtensionTerminalStatus ', err);
  }
}



export const LeaseExtensionServices = {
  listLeaseExtensions,
  markLeaseExtensionTerminalStatus
};