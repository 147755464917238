import React, { Component } from "react";
import { connect } from 'react-redux';
import { Formik } from "formik";
import {
    Container, Row, Col, Card, Button, Form, Popover, OverlayTrigger
} from 'react-bootstrap';
import * as constants from '../../../constants';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { HouseOnboardingServices } from './HouseOnboarding.services'
import { store } from 'react-notifications-component';
import * as Yup from 'yup'; // for everything
import { commonServices } from '../../../../app/commonServices/commonServices';
import { formatMultipleUsersWithoutSpan } from '../../../../_metronic/utils/utils';

const addHouseSchema = Yup.object().shape({
    house: Yup.object().shape({
        id: Yup.number(),
        bhk: Yup.number().required('Please select one of the values from bhk dropdown'),
        buildingName: Yup.string(),
        unitNumber: Yup.number().required('Unit Number is a mandatory field'),
        propertyManagementStatus: Yup.string().required('Please select one'),
        assignment: Yup.object().shape({
            assigneeId: Yup.number()
        }),
        houseOffering: Yup.object().shape({
            beatsListingIdentifier: Yup.number().required('Beats Listing ID is a mandatory Field')
        }),
        propertyType: Yup.string(),
    })
});

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const required = value => (value ? undefined : "Required");

// const Error = ({ name }) => (
//     <Field
//         name={name}
//         render={({ form: { touched, errors } }) =>
//             touched[name] && errors[name] ? <span>{errors[name]}</span> : null
//         }
//     />
// );
export class HouseOnboarding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            options: [],
            query: '',
            offset: 0,
            limit: 50,
            isSocietyLoading: false,
            societyOptions: [],
            societyQuery: '',
            societyOffset: 0,
            societyLimit: 50,
            isLocalityLoading: false,
            localityOptions: [],
            localityQuery: '',
            localityOffset: 0,
            localityLimit: 50,
            selectedHouse: {},
            toggleAddHouse: false
        };
    }

    cache = {};
    societyCache = {};
    localityCache = {};

    async componentDidMount() {
        await this.getPropertyManagers();
        await this.getAllCities();
    }

    handleInputChange = query => {
        this.setState({ query });
    };

    handleSocietyInputChange = societyQuery => {
        this.setState({ societyQuery });
    };

    handleLocalityInputChange = localityQuery => {
        this.setState({ localityQuery });
    };

    getAllProperties = async () => {
        this.setState({ isLoading: true });
        const { offset, limit, query } = this.state;
        const data = {
            searchQuery: query,
            offset,
            limit,
            scope: 'ALL',
            include: ['OWNERS', 'CURRENT_TENANTS']
        }
        const result = await HouseOnboardingServices.getAllProperties(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const options = result.data.houses.map(houseData => ({
                id: houseData.id,
                property: `${houseData.formattedHouseName} | Owners: ${formatMultipleUsersWithoutSpan(houseData.owners)} | Tenants: ${formatMultipleUsersWithoutSpan(houseData.currentTenants)}`,
                propertyManagementStatus: houseData.propertyManagementStatus
            }));
            return { options, total_count };
        }
        this.setState({ isLoading: false });
    }

    getAllSocieties = async () => {
        this.setState({ isSocietyLoading: true });
        const {
            // societyOffset, societyLimit,
            societyQuery, selectedCity } = this.state;
        const data = {
            countryId: selectedCity?.country?.beatsDatabaseId,
            SearchText: societyQuery,
            cityId: selectedCity?.connectDatabaseId,
            // offset: societyOffset,
            // limit: societyLimit
        }
        const result = await HouseOnboardingServices.getAllSocietiesAndLocalitiesBeats(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const societiesToBeMapped = result.data.locationDetail;
            const options = societiesToBeMapped?.map(societyData => (
                societyData.type === 'Project' ? {
                    id: societyData.refId,
                    society: `${societyData.refName}`,
                    localityId: societyData.localityid,
                    localityName: `${societyData.localityName}`
                } : (societyData.type === 'Society' || societyData.type === 'Locality') ? {
                    id: societyData.localityId,
                    society: `${societyData.localityName}`,
                    localityId: societyData.localityId,
                    localityName: `${societyData.localityName}`,
                } : undefined
            ));
            return { options, total_count };
        }
        await this.setState({ isSocietyLoading: false });
    }

    getAllLocalities = async () => {
        this.setState({ isLocalityLoading: true });
        const {
            // localityOffset, localityLimit,
            localityQuery, selectedCity } = this.state;
        const data = {
            countryId: selectedCity?.country?.beatsDatabaseId,
            SearchText: localityQuery,
            cityId: selectedCity?.connectDatabaseId,
            // offset: localityOffset,
            // limit: localityLimit
        }
        const result = await HouseOnboardingServices.getAllSocietiesAndLocalitiesBeats(data);
        if (result && result.status === 200) {
            const total_count = result.headers['x-total-count'];
            const localitiesToBeMapped = result.data.locationDetail;
            const options = localitiesToBeMapped?.map(localityData => (
                localityData.type === 'Locality' ? {
                    id: localityData.localityId,
                    locality: `${localityData.localityName}`
                } : (localityData.type === 'Society' || localityData.type === 'Project') ? {
                    id: localityData.localityId,
                    society: `${localityData.localityName}`,
                    localityId: localityData.localityId,
                    localityName: `${localityData.localityName}`,
                } : undefined
            ));
            //TODO: Resolve issue of undefined also getting mapped unnecessarily. Scope for performance improvement.
            return { options, total_count };
        }
        await this.setState({ isLocalityLoading: false });
    }

    getAllCities = async () => {
        this.setState({ isLoading: true });
        const data = {
            include: ['COUNTRY']
        }
        const result = await HouseOnboardingServices.getAllCities(data);
        if (result && result.status === 200) {
            this.setState({ cities: result.data.cities });
        }
        this.setState({ isLoading: false });
    }


    handleSearch = async (query) => {
        if (this.cache[query]) {
            this.setState({ options: this.cache[query].options });
            return;
        }

        this.setState({ isLoading: true });
        const response = await this.getAllProperties();
        this.cache[query] = { ...response, page: 1 };
        this.setState({
            isLoading: false,
            options: response && response.options,
        });

        // makeAndHandleRequest(query).then(resp => {
        //     this.cache[query] = { ...resp, page: 1 };
        //     this.setState({
        //         isLoading: false,
        //         options: resp.options,
        //     });
        // });
    };

    handleSocietySearch = async (societyQuery) => {
        if (this.societyCache[societyQuery]) {
            this.setState({ societyOptions: this.societyCache[societyQuery].options });
            return;
        }

        this.setState({ isSocietyLoading: true });
        const response = await this.getAllSocieties();
        this.societyCache[societyQuery] = { ...response, societyPage: 1 };
        this.setState({
            isSocietyLoading: false,
            societyOptions: response && response.options,
        });

        // makeAndHandleRequest(query).then(resp => {
        //     this.cache[query] = { ...resp, page: 1 };
        //     this.setState({
        //         isSocietyLoading: false,
        //         options: resp.options,
        //     });
        // });
    };

    handleLocalitySearch = async (localityQuery) => {
        if (this.localityCache[localityQuery]) {
            this.setState({ localityOptions: this.localityCache[localityQuery].options });
            return;
        }

        this.setState({ isLocalityLoading: true });
        const response = await this.getAllLocalities();
        this.localityCache[localityQuery] = { ...response, localityPage: 1 };
        this.setState({
            isLocalityLoading: false,
            localityOptions: response && response.options,
        });

        // makeAndHandleRequest(query).then(resp => {
        //     this.cache[query] = { ...resp, page: 1 };
        //     this.setState({
        //         isLocalityLoading: false,
        //         options: resp.options,
        //     });
        // });
    };

    getPropertyManagers = async () => {
        const data = {
            userRoleCodes: ['PROPERTY_MANAGER'],
        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            await this.setState({ propertyManagers: result.data.users })
        }
    }

    /**
     * Custom method to handle change for dropdowns in formik
     * @param {Object} e
     * @param {Object} setFieldValue
     */
    handleCustomChange = async (e, setFieldValue) => {
        const { cities } = this.state;
        if (e.target.validity.valid) {
            if (e.target.name === 'house.propertyType') {
                setFieldValue('house.bhk', undefined);
            }
            if (e.target.name === 'house.cityId') {
                const selectedCity = cities.find(city => (city.id === parseInt(e.target.value, 10)));
                await this.setState({ selectedCity })
            }
        }
    };

    render() {
        const { propertyManagers, cities, selectedCity } = this.state;
        const popover = (
            <Popover variant="light" className="d-block w-100">
                <Popover.Title>How to onboard a house?</Popover.Title>
                <Popover.Content>
                    <p>Here are the steps to follow:</p>
                    <ol>
                        <li>Search for the House if it already exists in the system. If it does, select the house and continue with Step 4.</li>
                        <li>If the house doesn't exist, use the 'Add House' button and fill in the details. Optionally, assign the Property Manager.</li>
                        <li>Click on 'Finish Onboarding', you will be redirected to the Property Profile page.</li>
                        <li>On the Property Profile page of the house and continue with 'Add Lease' to enter all the Lease details. Then skip to Step 5.</li>
                        <li>Onboarding is completed.</li>
                    </ol>
                    <hr />
                    <p className="mb-0">
                        Note: This process is applicable only to the Houses closed by Azuro and handed over to the PMS Team via email.
                    </p>
                </Popover.Content>
            </Popover>
        )
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col xs={12} className="d-flex column justify-content-end">
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                                <Button className="mb-2" variant="secondary" size="sm">Help</Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Formik
                                        initialValues={{
                                            house: {
                                                id: undefined,
                                                buildingName: undefined,
                                                unitNumber: undefined,
                                                propertyType: undefined,
                                                bhk: undefined,
                                                propertyManagementStatus: undefined,
                                                houseOffering: {
                                                    beatsListingIdentifier: undefined
                                                },
                                                assignment: undefined
                                            }
                                        }}
                                        validationSchema={addHouseSchema}
                                        onSubmit={async (values, actions) => {
                                            if (values?.house?.buildingName === '') { values.house.buildingName = undefined }
                                            const result = await HouseOnboardingServices.createHouse(values);
                                            if (result.status === 201) {
                                                store.addNotification({
                                                    title: "Success!",
                                                    message: `House Onboarding completed`,
                                                    type: "success",
                                                    insert: "top",
                                                    container: "top-right",
                                                    animationIn: ["animated", "fadeIn"],
                                                    animationOut: ["animated", "fadeOut"],
                                                    dismiss: {
                                                        duration: 5000,
                                                        onScreen: true,
                                                        showIcon: true,
                                                        pauseOnHover: true
                                                    }
                                                });
                                                window.location = `/property-profile/${result.data.houseId}`;
                                                actions.setSubmitting(false);
                                            }
                                        }
                                        }
                                    >
                                        {({ errors, handleChange, handleBlur, values, setFieldValue, handleSubmit, isSubmitting, touched }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col xs={12} className="mb-3">
                                                        <h4>SEARCH OR ADD HOUSE</h4>
                                                    </Col>
                                                </Row>
                                                {!this.state.toggleAddHouse &&
                                                    <Row>
                                                        <Col xs={12} className="mb-3">
                                                            <label>Search House <span className="text-danger">*</span></label>
                                                            <AsyncTypeahead
                                                                // {...this.state}
                                                                name="house.id"
                                                                isLoading={this.state.isLoading}
                                                                options={this.state.options}
                                                                query={this.state.query}
                                                                id="async-pagination-example"
                                                                labelKey="property"
                                                                maxResults={this.state.limit - 1}
                                                                minLength={3}
                                                                onChange={(selected) => {
                                                                    // const houseId = (selected.length > 0) ? selected[0].id : undefined;
                                                                    // const houseName = (selected.length > 0) ? selected[0].property : undefined;
                                                                    // const propertyManagementStatus = (selected.length > 0) ? selected[0].propertyManagementStatus : undefined;
                                                                    // setFieldValue('house.id', houseId);
                                                                    // setFieldValue('house.name', houseName);
                                                                    // setFieldValue('house.propertyManagementStatus', propertyManagementStatus)
                                                                    window.location = `/property-profile/${selected[0].id}`;
                                                                }}
                                                                onInputChange={this.handleInputChange}
                                                                // onPaginate={this.handlePagination}
                                                                onSearch={this.handleSearch}
                                                                paginate
                                                                placeholder={values.house.name ? values.house.name : "Search for your property here..."}
                                                                renderMenuItemChildren={option => (
                                                                    <div key={option.id}>
                                                                        <span>{option.property}</span><br />
                                                                        <span className="small text-muted">{`Property Management Status: `}{option.propertyManagementStatus}</span>
                                                                    </div>
                                                                )}
                                                                useCache={false}
                                                            />
                                                        </Col>
                                                        <Col xs={12} className="mb-4">
                                                            <p className="mb-2">Didn't find House?</p>
                                                            <Button variant="outline-primary" onClick={() => this.setState({ toggleAddHouse: true })}>Add House</Button>
                                                        </Col>
                                                    </Row>
                                                }
                                                {this.state.toggleAddHouse === true &&
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Button variant="link" size="sm" className="mb-3" onClick={() => this.setState({ toggleAddHouse: false })}><i className="flaticon2-left-arrow-1 mr-2"></i> Search for House again?</Button>
                                                        </Col>

                                                        <Col xs={12}>
                                                            <Form.Group controlId="citySelect">
                                                                <Form.Label>Select City</Form.Label>
                                                                <Form.Control as="select" name="house.cityId"
                                                                    // onChange={handleChange} 
                                                                    onChange={
                                                                        async (e) => {
                                                                            handleChange(e);
                                                                            await this.handleCustomChange(e, setFieldValue)
                                                                        }
                                                                    }
                                                                    onBlur={handleBlur} value={values.house.cityId}>
                                                                    <option value='None'>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        cities?.map((city, index) =>
                                                                        (<option key={index}
                                                                            value={city.id}>{city.name}</option>)
                                                                        )
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        {selectedCity?.id === 449 && <Col xs={6} className='mb-3'>
                                                            <label>Select Locality <span className="text-danger">*</span></label>
                                                            <AsyncTypeahead
                                                                // {...this.state}
                                                                isLoading={this.state.isLocalityLoading}
                                                                options={this.state.localityOptions}
                                                                query={this.state.localityQuery}
                                                                id="async-pagination-example"
                                                                labelKey="locality"
                                                                disabled={values.house?.cityId === undefined || values.house?.cityId === 'None'}
                                                                onChange={(selected) => {
                                                                    const identifier = (selected.length > 0) ? selected[0].id : undefined;
                                                                    const name = (selected.length > 0) ? selected[0].locality : undefined;
                                                                    setFieldValue('house.locality.identifier', identifier);
                                                                    setFieldValue('house.locality.name', name);
                                                                    this.setState({ selectedLocalityId: identifier })
                                                                }}
                                                                maxResults={this.state.localityLimit - 1}
                                                                minLength={3}
                                                                onInputChange={this.handleLocalityInputChange}
                                                                // onPaginate={this.handleLocalityPagination}
                                                                onSearch={this.handleLocalitySearch}
                                                                paginate
                                                                placeholder={values.house.society?.locality?.name ? values.house.society?.locality?.name : "Type to search Locality..."}
                                                                renderMenuItemChildren={option => (
                                                                    <div key={option.id}>
                                                                        <span>{option.locality}</span>
                                                                    </div>
                                                                )}
                                                                useCache={false}
                                                            />
                                                            {errors.house && errors.house.localityId && touched.house && touched.house.localityId && <Form.Text className="text-danger">
                                                                    {errors.house.localityId}
                                                                </Form.Text>}
                                                        </Col>}
                                                        <Col xs={selectedCity?.id === 449 ? 6 : 12} className='mb-3'>
                                                            <label>Select Society <span className="text-danger">*</span></label>
                                                            <AsyncTypeahead
                                                                // {...this.state}
                                                                isLoading={this.state.isSocietyLoading}
                                                                options={this.state.societyOptions}
                                                                query={this.state.societyQuery}
                                                                id="async-pagination-example"
                                                                labelKey="society"
                                                                disabled={values.house?.cityId === undefined || values.house?.cityId === 'None'}
                                                                onChange={(selected) => {
                                                                    const societyId = (selected.length > 0) ? selected[0].id : undefined;
                                                                    let localityOriginalName = (selected.length > 0) ? selected[0].localityName : undefined;
                                                                    let societyName = (selected.length > 0) ? selected[0].society : undefined;
                                                                    if (societyName) {
                                                                        let newSocietyName = societyName.substring(0, societyName.lastIndexOf(localityOriginalName) === -1 ? undefined : societyName.lastIndexOf(localityOriginalName)).trim()
                                                                        societyName = newSocietyName
                                                                    }
                                                                    setFieldValue('house.society.identifier', societyId);
                                                                    setFieldValue('house.society.name', societyName);
                                                                    if (selectedCity?.id !== 449) {
                                                                        const localityId = (selected.length > 0) ? selected[0].localityId : undefined;
                                                                        const localityName = (selected.length > 0) ? selected[0].localityName : undefined;
                                                                        setFieldValue('house.locality.identifier', localityId);
                                                                        setFieldValue('house.locality.name', localityName);
                                                                        this.setState({ selectedLocalityId: localityId })
                                                                    }
                                                                }}
                                                                maxResults={this.state.societyLimit - 1}
                                                                minLength={3}
                                                                onInputChange={this.handleSocietyInputChange}
                                                                // onPaginate={this.handleSocietyPagination}
                                                                onSearch={this.handleSocietySearch}
                                                                paginate
                                                                placeholder={values.house.society?.name ? values.house.society?.name : "Type to search Society..."}
                                                                renderMenuItemChildren={option => (
                                                                    <div key={option.id}>
                                                                        <span>{option.society}</span>
                                                                    </div>
                                                                )}
                                                                useCache={false}
                                                            />
                                                            {errors.house && errors.house.societyId && touched.house && touched.house.societyId && <Form.Text className="text-danger">
                                                                {errors.house.societyId}
                                                            </Form.Text>}
                                                        </Col>
                                                        <Col xs={12}>
                                                            <h5>Add House Details</h5>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group controlId="house.propertyType">
                                                                <Form.Label>Property Type <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control as="select" name="house.propertyType"
                                                                    // onChange={handleChange} 
                                                                    onChange={
                                                                        async (e) => {
                                                                            handleChange(e);
                                                                            await this.handleCustomChange(e, setFieldValue)
                                                                        }
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    value={values.house.propertyType}>
                                                                    <option value={undefined}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        constants.PROPERTY_TYPES.map((propertyType, index) =>
                                                                            (<option key={index} value={propertyType}>{propertyType}</option>)
                                                                        )
                                                                    }
                                                                </Form.Control>
                                                                {errors.house && errors.house.propertyType && touched.house && touched.house.propertyType && <Form.Text className="text-danger">
                                                                    {errors.house.propertyType}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group controlId="house.bhk">
                                                                <Form.Label>BHK <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control as="select" name="house.bhk"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.house.bhk}>
                                                                    <option value={undefined}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        values?.house?.propertyType === 'APARTMENT/CONDO' && constants.BHK_FOR_APARTMENT.map((bhksForApartment, index) =>
                                                                            (<option key={index} value={bhksForApartment}>{bhksForApartment}</option>)
                                                                        )
                                                                    }
                                                                    {
                                                                        values?.house?.propertyType === 'INDEPENDENT HOUSE/VILLA' && constants.BHK_FOR_VILLA.map((bhksForVilla, index) =>
                                                                            (<option key={index} value={bhksForVilla}>{bhksForVilla}</option>)
                                                                        )
                                                                    }
                                                                </Form.Control>
                                                                {errors.house && errors.house.bhk && touched.house && touched.house.bhk && <Form.Text className="text-danger">
                                                                    {errors.house.bhk}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group controlId="house.buildingName">
                                                                <Form.Label>Building/Wing/Tower</Form.Label>
                                                                <Form.Control type="text" name="house.buildingName" onChange={handleChange} onBlur={handleBlur}
                                                                    value={values.house.buildingName} />
                                                                {errors.house && errors.house.buildingName && touched.house && touched.house.buildingName && <Form.Text className="text-danger">
                                                                    {errors.house.buildingName}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Form.Group controlId="house.unitNumber">
                                                                <Form.Label>Unit Number <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control type="text" name="house.unitNumber" onChange={handleChange} onBlur={handleBlur}
                                                                    value={values.house.unitNumber} />
                                                                {errors.house && errors.house.unitNumber && touched.house && touched.house.unitNumber && <Form.Text className="text-danger">
                                                                    {errors.house.unitNumber}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group key={`pm-status-radio`} controlId={`pm-status-radio`}>
                                                                <Form.Label>Property Management Status <span className="text-danger">*</span></Form.Label>
                                                                {
                                                                    constants.YES_NO.map((yesNo, index) =>
                                                                    (<Form.Check type="radio" key={`house.propertyManagementStatus${index}`}>
                                                                        <Form.Check.Input type="radio" name="house.propertyManagementStatus" onChange={handleChange}
                                                                            value={yesNo}
                                                                            checked={
                                                                                (yesNo === values.house.propertyManagementStatus) ||
                                                                                (yesNo === values.house.propertyManagementStatus)
                                                                            }
                                                                        />
                                                                        <Form.Check.Label className="ml-3 mt-1">{yesNo}</Form.Check.Label>
                                                                    </Form.Check>)
                                                                    )
                                                                }
                                                                {errors.house && errors.house.propertyManagementStatus && touched.house && touched.house.propertyManagementStatus && <Form.Text className="text-danger">
                                                                    {errors.house.propertyManagementStatus}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group controlId="house.houseOffering.beatsListingIdentifierSelect">
                                                                <Form.Label>Beats Listing ID <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control type="text" name="house.houseOffering.beatsListingIdentifier" onChange={handleChange} onBlur={handleBlur} value={values.house.houseOffering.beatsListingIdentifier} />
                                                                {errors.house && errors.house.houseOffering && errors.house.houseOffering.beatsListingIdentifier && touched.house && touched.house.houseOffering && touched.house.houseOffering.beatsListingIdentifier && <Form.Text className="text-danger">
                                                                    {errors.house.houseOffering.beatsListingIdentifier}
                                                                </Form.Text>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group controlId="house.assignment.assigneeId">
                                                                <Form.Label>Property Manager (Optional) </Form.Label>
                                                                <Form.Control as="select" name="house.assignment.assigneeId" onChange={handleChange} onBlur={handleBlur} value={values.house.assignment && values.house.assignment.assigneeId}>
                                                                    <option value={0}>{constants.DEFAULT_OPTION}</option>
                                                                    {
                                                                        propertyManagers && propertyManagers.map((pm =>
                                                                            <option key={pm.id} value={pm.id}>{pm.fullName}</option>
                                                                        ))
                                                                    }
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} className="text-right">
                                                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                                {isSubmitting ? 'Saving...' : 'Save'}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
};

const mapStateToProps = state => ({
    userDetails: state.auth && state.auth.user && state.auth.user
});

export default
    connect(
        mapStateToProps,
        null
    )(HouseOnboarding);
