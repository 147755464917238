import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import TimelineItem from '../layout/TimelineItem';

export default class ServiceRequestTimelineComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      showTimelineModal: false,
    };

  }

  renderTimelineItems() {
    const { timeline } = this.state;
    if (timeline.length === 0) return <Col xs={12}><p>Nothing to show.</p></Col>
    return (
      <Row>
        {timeline.length > 0 && timeline.map((timelineItem, index) =>

          <Col xs={12} key={index}>
            <TimelineItem item={timelineItem} />
          </Col>
        )}
      </Row>
    )
  }

  handleViewTimelineButtonClick = async () => {
    const result = await ServiceRequestsServices.getTimeline(this.state.serviceRequestId);
    if (result && result.status === 200) {
      await this.setState({ timeline: result.data.timelineItems })

    } else {
      console.error('Something went wrong');
    }
    this.setState(state => ({ showTimelineModal: !state.showTimelineModal }));
  }
  async componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        {/* View Timeline Button */}
        <Button className="mr-2" variant="primary" size="sm" onClick={this.handleViewTimelineButtonClick}>View Timeline</Button>

        {/* Modal to show the Timeline */}
        <Modal show={this.state.showTimelineModal} onHide={this.handleViewTimelineButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Timeline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-4">
              {this.state.timeline ? this.renderTimelineItems() :  <p>Loading data...</p>}
            </Row>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
