import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import {
  Container, Row, Col, Card, Button, Accordion,
  Form, Modal, Badge
} from 'react-bootstrap';
import { Formik, FieldArray } from 'formik';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import TasksListComponent from '../../../partials/layout/TasksListComponent.js';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { InvoiceServices } from './Invoices.services';
import * as constants from '../../../constants';
import { commonServices } from '../../../../app/commonServices/commonServices';
import { formatAmount, formatDateTime, formatDate } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { connect } from 'react-redux';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import * as firebase from 'firebase/app';
import AdditionalPaymentDetails from '../../../partials/layout/AdditionalPaymentDetails';
import { Link } from 'react-router-dom';

const linkPaymentSchema = Yup.object().shape({
  invoicePaymentParticipation: Yup.object().shape({
    invoiceId: Yup.number().required('Invoice ID is required'),
    paymentId: Yup.number().required('Please select one of thek payments from dropdown'),
    participationAmount: Yup.number().required('Amount is required').moreThan(0, 'Amount should be greater than zero.'),
  })
});

const clearInvoiceSchema = Yup.object().shape({
  invoiceItems: Yup.array().of(Yup.object().shape({
    paymentId: Yup.number().required('Related Payments is a required field'),
    participationAmount: Yup.number().required('Amount is required').moreThan(0, 'Amount should be greater than zero.'),
  })).min(0, 'There should at least be one Invoice Item'),
})

class Invoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showAddInvoiceModal: false,
      showLinkPaymentsModal: false,
      showClearInvoiceModal: false,
      showMarkAsInvalidModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      validityStatus: ['VALID'],
      amountBalanceToClearInvoice: 0
    }
  }

  async componentDidMount() {
    await this.getAllinvoicesData();
    await this.getCities();
    await this.getPropertyManagers();
  }

  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  getAllinvoicesData = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, cityIds,
      minimumInvoiceDate, maximumInvoiceDate, propertyManagerId, status } = this.state;
    const data = {
      cityIds,
      minimumInvoiceDate,
      maximumInvoiceDate,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId: houseIdFromPropertyProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
      status: status !== "0" ? status : undefined
    }
    const allInvoices = await InvoiceServices.getAllInvoices(data)
    if (allInvoices && allInvoices.status === 200) {
      const totalCount = allInvoices.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        invoices: allInvoices.data.invoices,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    this.setState({ isFetching: false });
  }

  getInvoiceDetailsById = async (id) => {
    const result = await InvoiceServices.getInvoiceById(id);
    if (result && result.status === 200) {
      await this.setState({ ...this.state, accordionData: result.data.invoice });
    }
  }

  handleViewInvoiceButtonClick = async (fileUrl) => {
    const data = {
      unsignedFileUrl: fileUrl
    }
    const result = await InvoiceServices.getPreSignedFileURL(data);
    if (result && result.data) {
      window.open(result.data.filePath, "_blank");
    }
  }

  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getInvoiceDetailsById(id);
      firebase.analytics().logEvent("invoice_details_view_click", { description: "Invoice Details View Clicked", invoiceId: id });
    }
  }

  handleMarkAsInvalidClick = async (id = null) => {
    if (id) {
      await this.setState({
        invoiceToBeMarkedInvalid: id
      })
      firebase.analytics().logEvent("invoice_mark_as_invalid_button_click", { description: "Invoice Mark as Invalid Button Clicked", invoiceId: id });
    }
    this.setState(state => ({ showMarkAsInvalidModal: !state.showMarkAsInvalidModal }));
  }

  handleConfirmMarkAsInvalidClick = async () => {
    const { invoiceToBeMarkedInvalid } = this.state;
    if (invoiceToBeMarkedInvalid) {
      const result = await InvoiceServices.markInvoiceAsInvalid(invoiceToBeMarkedInvalid);
      if (result && result.status === 200) {
        this.setState(state => ({ showMarkAsInvalidModal: !state.showMarkAsInvalidModal }));
        store.addNotification({
          title: "Success!",
          message: `Invoice marked as Invalid.`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        await this.getAllinvoicesData();
      }
    }
  }

  getPaymentDetailsData = async () => {
    const { invoices, accordionData } = this.state;
    let selectedInvoiceObject = invoices && accordionData && invoices.find(inv => (inv.id === accordionData.id));
    const currentHouseId = selectedInvoiceObject && selectedInvoiceObject.house && selectedInvoiceObject.house.id;
    const data = {
      "houseId": currentHouseId,
      "participationAmountRemaining": 'TRUE',
      "direction": 'CREDIT'
    }
    const result = await InvoiceServices.getPaymentDetails(data)
    if (result && result.status === 200) {
      await this.setState({ paymentDetails: result.data.payments });
    }
  }

  static calculateTotal = (payments) => {
    let total = 0;
    payments.forEach(payment => {
      total += payment.participationAmount
    })
    return total;
  }


  handleClearInvoiceClick = async (id = null) => {
    if (id !== null) {
      await this.getPaymentDetailsData();
      firebase.analytics().logEvent("clear_invoice_button_click", { description: "Clear Invoice Button Clicked", invoiceId: id });
    }
    // calculate invoice amount - total of payments linked
    const { invoices, accordionData } = this.state;
    let selectedInvoiceObject = invoices && accordionData && invoices.find(inv => (inv.id === accordionData.id));
    let totalOfPaymentsAlreadyLinked = (accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.length > 0) ? Invoice.calculateTotal(accordionData.invoicePaymentParticipations) : 0;
    this.setState(state => ({ amountBalanceToClearInvoice: selectedInvoiceObject.totalAmount - totalOfPaymentsAlreadyLinked, currency: selectedInvoiceObject.currency }))
    this.setState(state => ({ showClearInvoiceModal: !state.showClearInvoiceModal }));
  }

  handleLinkPaymentsClick = async (id = null) => {
    if (id !== null) {
      await this.getPaymentDetailsData();
      firebase.analytics().logEvent("link_payment_against_invoice_button_click", { description: "Link Existing Payment Against Invoice Button Clicked", invoiceId: id });
    }
    this.setState(state => ({ showLinkPaymentsModal: !state.showLinkPaymentsModal }));
  }

  handleChildData = async (invoiceId) => {
    await this.getInvoiceDetailsById(invoiceId);
  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllinvoicesData();
  }

  handleAddInvoiceData = async (invoiceId) => {
    if (invoiceId) {
      await this.getAllinvoicesData();
      firebase.analytics().logEvent("new_invoice_button_click", { description: "New Invoice Button Clicked", addInvoiceFrom: 'Invoices' });

    }
  }

  getInvoiceValidityStatusBadge(invoiceStatus) {
    if (invoiceStatus === 'VALID') return <Badge variant="success" title="Valid">V</Badge>
    if (invoiceStatus === 'INVALID') return <Badge variant="warning" title="Invalid">I</Badge>
  }

  handleAddPaymentData = async (invoiceId) => {
    if (invoiceId) {
      await this.getInvoiceDetailsById(invoiceId);
      firebase.analytics().logEvent("save_new_payment_button_click", { description: "Save New Payment Button Clicked", addPaymentFrom: 'Invoices' });
    }
  }

  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.resetPage();
      await this.getAllinvoicesData();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Invoices' });
    }
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  render() {
    const { invoices, accordionData, page, total,
      totalCount, isFetching, houseIdFromPropertyProfile, requestedFilters, formattedCities, preSelectedCities,
      minimumInvoiceDate, maximumInvoiceDate, propertyManagers, propertyManagerId, status } = this.state;
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    return (
      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Invoices (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                requestedFilters={requestedFilters}
                minimumInvoiceDate={minimumInvoiceDate}
                maximumInvoiceDate={maximumInvoiceDate}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                status={status}
                filterParentCallback={this.handleFilterChildData}
              />
              <Accordion defaultActiveKey="0">
                <Card>
                  {invoices && invoices.length === 0 && <p>{constants.ZERO_DATA}</p>}
                  {invoices && invoices.length > 0 && <Card.Header>
                    <Row className="py-2 px-3 azuro-table-header">
                      {!houseIdFromPropertyProfile && <Col md={3}><strong>Property</strong></Col>}
                      <Col md={2}> <strong>Invoice Number</strong></Col>
                      <Col md={2}><strong>Details</strong></Col>
                      <Col md={houseIdFromPropertyProfile ? 3 : 2}><strong>In the Name of</strong></Col>
                      <Col md={houseIdFromPropertyProfile ? 2 : 1}><strong>Amount</strong></Col>
                      <Col md={houseIdFromPropertyProfile ? 2 : 1}><strong>Status</strong></Col>
                    </Row>
                  </Card.Header>}
                </Card>
                {invoices && invoices.map(inv =>
                  <Card key={inv.id}>
                    <Accordion.Toggle as={Card.Header}
                      onClick={() => { this.handleAccordionClick(inv.id) }} eventKey={inv.id} className="row py-2 azuro-table-row d-flex align-items-center">
                      {!houseIdFromPropertyProfile &&
                        <Col md={3} className="d-flex flex-row">
                          {inv.house && <span>{inv.house.formattedHouseName} <br />
                            <span className="small text-muted">{inv.house.propertyManager ? `PM: ${(inv.house.propertyManager && inv.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                          </span>}
                        </Col>}
                      <Col md={2}>
                        {inv.invoiceNumber ? inv.invoiceNumber : <span className="small text-muted"> Not Available</span>}
                      </Col>
                      <Col md={2}>
                        <span className="small text-muted">{formatDate(inv.invoiceDate)}</span><br />
                        <p>{inv.title}</p>
                      </Col>
                      <Col md={houseIdFromPropertyProfile ? 3 : 2}>{
                        inv.invoiceParticipants && inv.invoiceParticipants.map((participant, idx) =>
                          <React.Fragment key={participant.id + 'PUNS'}>
                            <span key={participant.id + 'PUN'}>
                              {(idx > 0 && idx < (inv.invoiceParticipants.length)) ? ', ' : ''}
                            </span>
                            <span key={participant.id}>
                              {participant.fullName}
                            </span>
                          </React.Fragment>
                        )
                      }</Col>
                      <Col md={houseIdFromPropertyProfile ? 2 : 1}>{formatAmount(inv.totalAmount, inv.currency)}</Col>
                      <Col md={houseIdFromPropertyProfile ? 2 : 1}>{inv.status !== null ? inv.status : <span className="text-warning small text-muted">Not Set</span>}</Col>
                      {inv.house && <Col md={1}>
                        <Link to={`/property-profile/${inv.house.id}`}>
                          <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                        </Link>
                      </Col>}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={inv.id}>
                      <Card.Body>
                        <Row>
                          <Col xs={6}>
                            <p className="small text-muted mb-0">Invoice ID #{accordionData?.id}</p>
                            {accordionData?.raisedBy && <p className="small text-muted"><strong>Raised by: </strong> {accordionData.raisedBy}</p>}
                          </Col>
                        </Row>
                        <Row className="mb-2">
                          <Col xs={10}>
                            <h5> Items</h5>
                          </Col>
                          <Col xs={2} className="text-right">
                            {/* <Button variant="clean" size="sm"><i className="flaticon2-edit"></i></Button> <Button variant="clean" size="sm"><i className="flaticon2-trash"></i></Button> */}
                          </Col>
                          <Col xs={12} className="mb-0">
                            {accordionData && accordionData.invoiceItems && accordionData.invoiceItems.map((item, i) =>
                              <Row key={"item" + i}>
                                <Col md={5}>
                                  <p className="mb-0">{item.name}</p>
                                </Col>
                                <Col md={2} className="text-right">
                                  <p className="mb-0">{formatAmount(item.amount, item.currency)}</p>
                                </Col>
                              </Row>
                            )
                            }
                            <Row>
                              <Col md={5}>
                                <p><strong>{`Total`}</strong></p>
                              </Col>
                              <Col md={2} className="text-right">
                                <p><strong>{formatAmount(inv.totalAmount, inv.currency)}</strong></p>
                              </Col>

                            </Row>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col xs={12}>
                            <h5>Payments</h5>
                          </Col>
                          <Col xs={12}>
                            {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.length === 0 && <Col className="text-center" xs={12}><p>No Payments</p></Col>}
                            {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.map(item =>
                              <Row key={item.id}>
                                <Col md={7}>
                                  <p className="small text-muted mb-0">Payment ID #{item.payment.id} | Ref No: {item.payment?.reference !== null ? item.payment?.reference : 'Not Available'}</p>
                                  <p className="mb-0"><span className={item.payment?.direction === 'CREDIT' ? 'text-success' : 'text-danger'}>{item.payment?.direction}</span> | {formatAmount(item.payment?.amount, item.payment?.currency)} via {item.payment?.mode}</p>
                                  <p className="small text-muted mb-0">{item.payment && formatDateTime(item.payment.datetime)}</p>
                                </Col>
                                {/* <Col md={2} className="text-right">
                                    <p>{formatAmount(item.participationAmount, item.currency)}</p>
                                  </Col> */}
                                <Col xs={3} className="text-right">
                                  {item.paymentGatewayProperties && <AdditionalPaymentDetails paymentDetails={item.paymentGatewayProperties} />}
                                </Col>
                              </Row>
                            )
                            }
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6} className="border-right">
                            <NotesComponent notes={(accordionData && accordionData.notes) || []}
                              allowAddNotes={
                                (inv.clearanceStatus && inv.clearanceStatus === 'PENDING')
                                && (inv.validityStatus && inv.validityStatus === 'VALID')
                              }
                              invoiceId={inv.id}
                              parentCallback={this.handleChildData}
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <TasksListComponent tasks={(accordionData && accordionData.tasks) || []} allowAddTasks={
                              (inv.clearanceStatus && inv.clearanceStatus === 'PENDING')
                              && (inv.validityStatus && inv.validityStatus === 'VALID')
                            }
                              invoiceId={inv.id}
                              houseId={inv?.house?.id}
                              parentCallback={this.handleChildData}
                              approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col xs={12} className="text-right">
                            {accordionData && accordionData.fileUrl && <Button className="btn btn-sm btn-primary d-inline-block mr-2" onClick={() => this.handleViewInvoiceButtonClick(accordionData.fileUrl)}>View Invoice</Button>}
                            {inv.validityStatus === 'VALID' &&
                              <>
                                {/* <AddPaymentComponent isInvoices={true}
                                  invoicesComponentCallback={this.handleAddPaymentData}
                                  houseId={inv && inv.house && inv.house.id}
                                  invoiceId={accordionData && accordionData.id} /> */}
                                {/* <Button className='mr-2' variant="danger" size="sm" onClick={() => this.handleMarkAsInvalidClick(inv.id)}>Cancel Invoice</Button> */}
                                {/* <Button className='mr-2' variant="primary" size="sm" onClick={() => this.handleLinkPaymentsClick(inv.id)}>Link Payments</Button> */}
                                {/* <Button variant="success" size="sm" onClick={() => this.handleClearInvoiceClick(inv.id)}>Clear Invoice</Button> */}

                                {/* <Button className='mr-2 d-inline' variant="danger" size="sm" onClick={() => this.handleMarkAsInvalidClick(inv.id)}>Mark as Invalid</Button>
                                <Dropdown id = 'new' className="d-inline">
                                  <Dropdown.Toggle className="d-inline" size="sm" variant="success" id="dropdown-update-invoice">Update Invoice</Dropdown.Toggle>
                                  <Dropdown.Menu alignRight="true">
                                    <Dropdown.Header>For Partial Payments</Dropdown.Header>
                                    <Dropdown.Item>
                                      <AddPaymentComponent isInvoices={true}
                                        invoicesComponentCallback={this.handleAddPaymentData}
                                        houseId={inv && inv.house && inv.house.id}
                                        invoiceId={accordionData && accordionData.id} />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <Button className='mr-2' variant="link" size="sm" onClick={() => this.handleLinkPaymentsClick(inv.id)}>Link Existing Payments</Button>
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                  <Dropdown.Header>For Clearing</Dropdown.Header>
                                    <Dropdown.Item>
                                      <Button variant="success" size="sm" onClick={() => this.handleClearInvoiceClick(inv.id)}>{`Link All & Clear Invoice`}</Button>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> */}
                              </>
                            }
                            {/* {inv.status === 'Closed' && <Button variant="primary" size="sm">Print</Button>} */}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
                }
              </Accordion>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key={1} show={this.state.showLinkPaymentsModal} onHide={this.handleLinkPaymentsClick}>
          <Modal.Header closeButton>
            <Modal.Title>Link Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
              </Col>
              <Col xs={12}>
                <Formik
                  initialValues={{
                    invoicePaymentParticipation: { invoiceId: accordionData && accordionData.id, paymentId: undefined, participationAmount: undefined },
                  }}
                  validationSchema={linkPaymentSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    values.invoicePaymentParticipation.paymentId = parseInt(values.invoicePaymentParticipation.paymentId, 10);
                    const result = await InvoiceServices.createInvoiceParticipation(values);
                    if (result && result.status === 201) {
                      store.addNotification({
                        title: "Success!",
                        message: `Payment linked to Invoice!`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                          showIcon: true,
                          pauseOnHover: true
                        }
                      });
                      this.setState(state => ({ showLinkPaymentsModal: !state.showLinkPaymentsModal }));
                      this.getInvoiceDetailsById(accordionData && accordionData.id);
                    }
                  }
                  }
                >
                  {
                    ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                    (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.length > 0 && <Col xs={12}>
                            <h5>Existing Payments Linked</h5>
                            {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.map(item =>
                              <Row>
                                <Col md={6}>
                                  <p>{item.payment && item.payment.reference}: {item.payment && formatDate(item.payment.datetime)} {item.payment && ' (' + formatAmount(item.payment.amount, item.payment.currency) + ')'}</p>
                                </Col>
                                <Col md={2} className="text-right">
                                  <p>{formatAmount(item.participationAmount, item.currency)}</p>
                                </Col>
                              </Row>
                            )
                            }
                          </Col>}
                          <Col xs={12}>
                            {/* <FieldArray
                                name="invoiceItems"
                                render={arrayHelpers => ( */}
                            <div>
                              <Row>
                                <Col xs={5}>
                                  <Form.Label>Related Payments</Form.Label>
                                </Col>
                                <Col xs={5}>
                                  <Form.Label>Amount</Form.Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={5}>
                                  <Form.Group controlId={"itemsParticular"}>
                                    {/* <Form.Control type="text" name={`invoiceItems.name`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.name} /> */}
                                    <Form.Control as="select" name={`invoicePaymentParticipation.paymentId`} onChange={handleChange} onBlur={handleBlur} value={values.invoicePaymentParticipation.paymentId}>
                                      <option value={0}>{constants.DEFAULT_OPTION}</option>
                                      {
                                        this.state.paymentDetails && this.state.paymentDetails.map((payment =>
                                          <option key={payment.id} value={payment.id}>
                                            {formatAmount(payment.amount, payment.currency)} - {(payment.datetime)} -#{payment.reference}
                                          </option>
                                        ))
                                      }
                                    </Form.Control>
                                    {errors.invoicePaymentParticipation && errors.invoicePaymentParticipation.paymentId && touched.invoicePaymentParticipation && touched.invoicePaymentParticipation.paymentId && <Form.Text className="text-danger">
                                      {errors.invoicePaymentParticipation.paymentId}
                                    </Form.Text>}
                                  </Form.Group>
                                </Col>
                                <Col xs={5}>
                                  <Form.Group controlId={"itemsAmount"}>
                                    <Form.Control type="number" min={1} name={`invoicePaymentParticipation.participationAmount`} onChange={handleChange} onBlur={handleBlur} value={values.invoicePaymentParticipation.participationAmount} />
                                    {errors.invoicePaymentParticipation && errors.invoicePaymentParticipation.participationAmount && touched.invoicePaymentParticipation && touched.invoicePaymentParticipation.participationAmount && <Form.Text className="text-danger">
                                      {errors.invoicePaymentParticipation.participationAmount}
                                    </Form.Text>}
                                  </Form.Group>
                                </Col>
                                {/* <Col xs={2}>
                                          <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove('index')}>X</Button>
                                        </Col> */}
                              </Row>

                              {/* <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({ paymentId: 0, participationAmount: 0 })}>Add Item</Button> */}
                            </div>
                            {/* )}
                              /> */}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={this.handleLinkPaymentsClick}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )
                  }
                </Formik>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={2} show={this.state.showClearInvoiceModal} onHide={() => this.handleClearInvoiceClick()}>
          <Modal.Header closeButton>
            <Modal.Title>Clear Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12}>
              </Col>
              <Col xs={12}>
                <Formik
                  initialValues={{
                    invoiceItems: [{ paymentId: undefined, participationAmount: undefined }],
                    // invoiceItems : undefined
                  }}
                  validationSchema={clearInvoiceSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    values.invoiceItems.forEach((ele, idx) => {
                      values.invoiceItems[idx].paymentId = parseInt(ele.paymentId, 10);
                    });
                    const id = this.state.accordionData && this.state.accordionData.id;
                    const result = InvoiceServices.clearInvoice(id, values.invoiceItems);
                    if (result && result.status === 201) {
                      store.addNotification({
                        title: "Success!",
                        message: `Invoice Cleared!`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                          showIcon: true,
                          pauseOnHover: true
                        }
                      });
                      this.setState(state => ({ showClearInvoiceModal: !state.showClearInvoiceModal }));
                      this.getAllinvoicesData();
                    }
                  }
                  }
                >
                  {
                    ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                    (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs={12}>
                            <h5>Existing Payments Linked</h5>
                          </Col>
                          <Col xs={12}>
                            {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.length === 0 &&
                              <p>No payments added or linked.</p>}
                            {accordionData && accordionData.invoicePaymentParticipations && accordionData.invoicePaymentParticipations.length > 0 && accordionData.invoicePaymentParticipations.map(item =>
                              <Row key={item.id}>
                                <Col md={6}>
                                  <p>{item.payment && item.payment.reference}: {item.payment && formatDate(item.payment.datetime)} {item.payment && ' (' + formatAmount(item.payment.amount, item.payment.currency) + ')'}</p>
                                </Col>
                                <Col md={2} className="text-right">
                                  <p>{formatAmount(item.participationAmount, item.currency)}</p>
                                </Col>
                              </Row>
                            )
                            }
                          </Col>
                          <Col xs={12}>
                            <hr />
                            <p><strong>Amount Balance: {formatAmount(this.state.amountBalanceToClearInvoice, this.state.currency && this.state.currency)}</strong></p>
                            <hr />
                          </Col>
                          {this.state.amountBalanceToClearInvoice === 0 && <Col xs={12}>
                            <p>This invoice looks good to be cleared.</p>
                          </Col>}
                          {this.state.amountBalanceToClearInvoice > 0 && <Col xs={12}>
                            <FieldArray
                              name="invoiceItems"
                              render={arrayHelpers => (
                                <div>
                                  <Row>
                                    <Col xs={5}>
                                      <Form.Label>Related Payments</Form.Label>
                                    </Col>
                                    <Col xs={5}>
                                      <Form.Label>Amount</Form.Label>
                                    </Col>
                                  </Row>
                                  {values.invoiceItems && values.invoiceItems.map((item, index) =>
                                  (<Row key={index}>
                                    <Col xs={5}>
                                      <Form.Group controlId={"itemsParticular" + index}>
                                        {/* <Form.Control type="text" name={`invoiceItems[${index}].name`} required="required" onChange={handleChange} onBlur={handleBlur} value={item.name} /> */}
                                        <Form.Control as="select" name={`invoiceItems[${index}].paymentId`} onChange={handleChange} onBlur={handleBlur} value={item.paymentId}>
                                          <option value={0}>{constants.DEFAULT_OPTION}</option>
                                          {
                                            this.state.paymentDetails && this.state.paymentDetails.map((payment =>
                                              <option value={payment.id}>
                                                {formatAmount(payment.amount, payment.currency)} - {(payment.datetime)} -#{payment.reference}
                                              </option>
                                            ))
                                          }
                                        </Form.Control>
                                        {errors.invoiceItems && touched.invoiceItems && errors.invoiceItems[index] && touched.invoiceItems[index] && <Form.Text className="text-danger">
                                          {(errors.invoiceItems[index].paymentId && touched.invoiceItems[index].paymentId && errors.invoiceItems[index].paymentId)}
                                        </Form.Text>}
                                      </Form.Group>
                                    </Col>
                                    <Col xs={5}>
                                      <Form.Group controlId={"itemsAmount" + index}>
                                        <Form.Control type="number" min={1} max={this.state.amountBalanceToClearInvoice} name={`invoiceItems[${index}].participationAmount`} onChange={handleChange} onBlur={handleBlur} value={item.participationAmount} />
                                        <Form.Text className="text-muted">Make sure this amount is less than or equal to the selected Payment's amount</Form.Text>
                                        {errors.invoiceItems && touched.invoiceItems && errors.invoiceItems[index] && touched.invoiceItems[index] && <Form.Text className="text-danger">
                                          {(errors.invoiceItems[index].participationAmount && touched.invoiceItems[index].participationAmount && errors.invoiceItems[index].participationAmount)}
                                        </Form.Text>}
                                      </Form.Group>
                                    </Col>
                                    <Col xs={2}>
                                      <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                    </Col>
                                  </Row>)
                                  )}
                                  <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({ paymentId: undefined, participationAmount: undefined })}>Add Item</Button>
                                </div>
                              )}
                            />
                          </Col>}
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={() => this.handleClearInvoiceClick()}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )
                  }
                </Formik>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>


        <Modal backdrop="static" keyboard={false} key={3} show={this.state.showMarkAsInvalidModal} onHide={this.handleMarkAsInvalidClick}>
          <Modal.Header closeButton>
            <Modal.Title>Mark Invoice as Invalid</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Do you want to mark this invoice as invalid?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleMarkAsInvalidClick}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleConfirmMarkAsInvalidClick}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(Invoice);
