import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import {Link} from 'react-router-dom'
import { Row, Col, } from 'react-bootstrap';
import { LeaseTerminationsServices } from './LeaseTerminations.services'
import * as constants from '../../../constants';
import { formatDate } from '../../../../_metronic/utils/utils';
import * as firebase from 'firebase/app';
import { commonServices } from '../../../../app/commonServices/commonServices';
import FilterComponent from '../../../partials/layout/FilterComponent';

class NoticePeriodInitiatedLeases extends Component {

  constructor(props) {
    super(props);
    this.state = {
      state: ['OPEN'],
      isFetching: false,
      leases: [],
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      requestedFilters: ['state'],
      showMarkAsClosedModal: false,
    }
  }

  componentDidMount = async () => {
    await this.getNoticePeriodInitiatedLeasesData();
    await this.getCities();
    await this.getPropertyManagers();
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  getNoticePeriodInitiatedLeasesData = async () => {
    this.setState({ isFetching: true });
    const { state, offset, limit, cityIds, propertyManagerId,
      maximumNoticePeriodEndDate, houseId
    } = this.state;
    const data = {
      cityIds,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId,
      maximumNoticePeriodEndDate,
      state: (state && state[0]) || undefined,
      offset,
      limit
    }
    const result = await LeaseTerminationsServices.getNoticePeriods(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit)
      this.setState({
        leases: result.data.noticePeriods,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      })
    }
    this.setState({ isFetching: false })
  }

  getNoticePeriodInitiatedLeaseDetails = async (id) => {
    const result = await LeaseTerminationsServices.getNoticePeriodDetails(id);
    if (result && result.status === 200) {
      await this.setState({ accordionData: result.data.noticePeriod });
    }
  }

  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getNoticePeriodInitiatedLeaseDetails(id);
      // await this.getStakeHolderData();
      // await this.getTaskAssignee();
      firebase.analytics().logEvent("notice_period_initiated_lease_view_click", { description: "Notice Period Initiated Lease Details View Clicked", id: id });
    }
  }

  handleMarkAsClosedButtonClick = async (id = null) => {
    // if (id !== null) {
    //     this.setState({ toBeClosed: id });
    // }
    await this.setState(state => ({ showMarkAsClosedModal: !state.showMarkAsClosedModal }));
    if (this.state.showMarkAsClosedModal === true) {
      firebase.analytics().logEvent("close_notice_period_button_click", { description: "Close Notice Period Button Clicked", id: id });
    }
  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getNoticePeriodInitiatedLeasesData();
  }

  handleChildData = async (childData) => {
    await this.getNoticePeriodInitiatedLeaseDetails(childData);
  }

  handleFilterChildData = async (childData) => {
    if (childData) {
      await this.setState({ ...childData });
      await this.resetPage();
      await this.getNoticePeriodInitiatedLeasesData();
      await this.getCities();
      await this.getPropertyManagers();
      await this.getSelectedProperty();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Notice Period Initiated Leases in Lease Terminations' });
    }
  }

  getSelectedProperty = async () => {
    const { houseId, propertyOptions } = this.state
    let selectedHouseObject = undefined;
    if (houseId && propertyOptions) {
      selectedHouseObject = propertyOptions.find(property => (property.id === houseId));
    }
    await this.setState({ selectedHouseObject })
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      limit: 10,
    });
  }

  render() {
    const { leases, page, total, totalCount, 
      propertyManagers, propertyManagerId, formattedCities, preSelectedCities,
      maximumNoticePeriodEndDate, selectedHouseObject } = this.state;
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (this.state.isFetching) {
      return <p>Loading data...</p>
    }
    return (
      <>
        <Portlet fluidHeight={true}>
          <PortletHeader
            title={`Notice Period Initiated Leases (${totalCount || 0})`}
            toolbar={
              <PortletHeaderToolbar>
                {/* <Button className="mr-2" variant="link" size="sm" onClick={this.handleNewRequestButtonClick}>New Request</Button> */}
                {/* <FilterComponent requestedFilters={requestedFilters} filterParentCallback={this.handleFilterChildData} state={state} /> */}
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <FilterComponent
              propertyManagers={propertyManagers}
              propertyManagerId={propertyManagerId}
              formattedCities={formattedCities}
              preSelectedCities={preSelectedCities}
              maximumNoticePeriodEndDate={maximumNoticePeriodEndDate}
              selectedHouseObject={selectedHouseObject}
              selectHouse={true}
              filterParentCallback={this.handleFilterChildData}
            />
            <Row className="pt-4" style={{display: "block"}}>
            {leases && leases.length === 0 && <p>{constants.ZERO_DATA}</p>}
            {leases && leases.length > 0 && leases.map(lease=>
            <Link key={lease.id} to={lease.lease ? `/lease-profile/${ lease.lease.id}`: 'lease-terminations'}> 
              {lease.lease && <Col xs={12}>
                <p className="mb-0">{ lease.lease && lease.lease.houseOfferings.house.formattedHouseName}</p>
                <p className="small mb-0 text-muted">{lease.lease && lease.lease.houseOfferings.house.propertyManager ? `PM: ${(lease.lease.houseOfferings.house.propertyManager && lease.lease.houseOfferings.house.propertyManager.fullName)}` : `PM: Not Assigned`}</p>
                <p className="font-weight-bold  mb-0  text-muted">End Date: {lease.noticePeriodEndDate ? formatDate(lease.noticePeriodEndDate) : 'Not Available'} <br /></p>
                <p className="small mb-0 text-muted">{`Initiated By: ${lease.initiator && lease.initiator.fullName}`}</p>
                <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} /> 
              </Col>}
            </Link>
            )}
            </Row>
           
            {/* Code for pagination */}
            {showPagination && showPagination && <UltimatePagination
              currentPage={this.state.page}
              totalPages={this.state.total}
              onChange={this.onPageChange}
              boundaryPagesRange={0}
              hidePreviousAndNextPageLinks={true}
              hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
            />}
          </PortletBody>
        </Portlet>

      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(NoticePeriodInitiatedLeases);
