import { format } from 'date-fns';
import React from 'react';

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
      key +
      "] from localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
      key +
      "] in localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}


/**
 * Helper methods that are common across all the components
 * (Added as per the new requirements, not the part of metronics)
 */

/**
 * Method to format the input string to ProperCase
 * @param {String} inputStr
 */
export function formatProperCase(inputStr) {
  const formattedString = inputStr.charAt(0).toUpperCase() + inputStr.slice(1).toLowerCase();
  return formattedString;
}

/**
 * Method to format the input date to  'MMM d, yyyy' format
 * @param {dateTime} date
 */
export function formatDate(date) {
  if (date) {
    return format(new Date(date), 'MMM d, yyyy')
  } else {
    return ''
  }

}

/**
 * Method to format the input date to 'MMM d' format
 * @param {dateTime} date
 */
export function formatShortDate(date) {
  return format(new Date(date), 'MMM d');
}

/**
 * Method to format the input date to 'MMM d, yyyy hh:mm a' format
 * @param {dateTime} dateTime
 */
export function formatDateTime(dateTime) {
  if (dateTime !== null) {
    return format(new Date(dateTime), 'MMM d, yyyy hh:mm a');
  } else {
    return ''
  }

}

/**
 * Method to format the input amount to required format
 * @param {string} amount
 * TODO: currently returns INR format, need to make dynamic for other formats
 */
export function formatAmount(amount, currency = 'INR') {
  if (!currency) {
    currency = 'INR';
  }
  const currencyOptions = {
    // maximumSignificantDigits: 3,
    style: 'currency',
    currency
  }
  return new Intl.NumberFormat('en-IN', currencyOptions).format(amount);
}

/**
 * Method to find the object with isPrimary key true from the array of objects
 * @param {Array} originalArray
 */
export function findPrimary(originalArray) {
  let primaryObj = originalArray.find(primary => (primary.isPrimary === true));
  return primaryObj;
}



/**
 * Method to format the input string to ProperCase with underscore (it is internal method this is 
 * here only, in formatMultipleUserRoles method, not in other components)
 * @param {String} inputStr
 */
export function formatProperCaseWithUnderscores(inputStr) {
  let formattedString = inputStr.charAt(0) + inputStr.slice(1).toLowerCase();
  // formattedString = formattedString.replace(/_/g, ' ')
  let splitFormattedString = formattedString.split('_');
  let resultString = '';
  splitFormattedString.forEach((splitFormattedString, index) => {
    if (index > 0) resultString += ' ';
    resultString += formatProperCase(splitFormattedString);

  });
  return resultString;
}


/**
 * Method to format multiple user roles
 * @param {Array} userRoles 
 */
export const formatMultipleUserRoles = (userRoles) => {
  let string = '';
  const finalString = userRoles.map((participant, idx) => {
    (idx > 0 && idx < (userRoles.length)) ? string += ', ' : string += ''
    string = formatProperCaseWithUnderscores(participant);
    return string
  })
  return finalString
}

/**
 * Method to format multiple cities
 * @param {Array} mutlipleCities
 */
export const formatMultipleCities = (mutlipleCities) => {
  const result = mutlipleCities.map((item, idx) =>
    <React.Fragment key={item.id + '-REN-' + idx}>
      <span key={item.id + 'REN'}>
        {(idx > 0 && idx < (mutlipleCities.length)) ? ', ' : ''}
      </span>
      <span key={item.id}>
        {item.name}
      </span>
    </React.Fragment>
  )
  return result;
}


/**
 * Method to format multiple users with span
 * @param {Array} mutlipleUsers 
 */
export const formatMultipleUsers = (mutlipleUsers) => {
  const result = mutlipleUsers.map((participant, idx) =>
    <React.Fragment key={participant.id + '-REN-' + idx}>
      <span key={participant.id + 'REN'}>
        {(idx > 0 && idx < (mutlipleUsers.length)) ? ', ' : ''}
      </span>
      <span key={participant.id}>
        {participant.fullName}
      </span>
    </React.Fragment>
  )
  return result;
}


/**
 * Method to format multiple users without span
 * @param {Array} mutlipleUsers 
 */
export const formatMultipleUsersWithoutSpan = (mutlipleUsers) => {
  let string = '';
  const finalString = mutlipleUsers.map((participant, idx) => {
    (idx > 0 && idx < (mutlipleUsers.length)) ? string += ', ' : string += ''
    string = participant.fullName;
    return string
  })
  return finalString
}


/**
 * Method to format terminated and non terminated works
 * @param {Array} works 
 */
export const formatWorks = (works) => {
  let workString = '';
  const finalWorkString = works.map((work, idx) => {
    (idx > 0 && idx < (works.length)) ? workString += ', ' : workString += ''
    workString = work.name;
    return workString
  })
  return finalWorkString
}


/**
 * Method to format Lease Parties data into owners and tenants
 * @param {*} rawImages
 */
export const formatMedia = (rawImages) => {
  const formattedData = rawImages.reduce((rawImage, data) => {
    const identifier = data.identifier;
    if (!rawImage[identifier]) {
      rawImage[identifier] = [];
    }
    rawImage[identifier].push(data);
    return rawImage;
  }, {});
  return formattedData;
}


/**
 * Method to add a day to the date
 * @param {*} rawDate
 */
export const datePlusOneDay = (rawDate) => {
  // Create new Date instance
  let date = new Date(rawDate)
  // Add a day
  date.setDate(date.getDate() + 1)
  return date;
}
