import React, { Component } from 'react';
import { Form, Col, Row, Button, Modal, Badge } from 'react-bootstrap';
import { formatProperCase } from '../../../_metronic/utils/utils';
import { commonServices } from '../../../app/commonServices/commonServices';
import { Formik } from 'formik';
import { store } from 'react-notifications-component';
import * as constants from '../../constants';

export default class UserBankAccounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAddBankAccountModal: false,
            showMarkPrimaryAccountModal: false,
            showLinkAccountModal: false,
            showUnLinkAccountModal: false,
            showReLinkAccountModal: false,
            showCreateSettlmentAccountModal: false,
            primaryAccountConfirmation: false,
            createBankAccountVerificationModal: false,
            accountVerified: false,
            accountNumber: undefined,
            ifsc: undefined,
            isVerifying: false
        }
    }

    /**
     * Static method Gets derived State From Props
     * @param {Object} props 
     * @param {Object} state 
     */
    static getDerivedStateFromProps(props, state) {
        let result = {}
        let isDifferent = false;

        if (props.bankAccounts && state.bankAccounts) {

            if (props.bankAccounts.length !== state.bankAccounts.length) {
                result.bankAccounts = props.bankAccounts;
            }

            (props.bankAccounts.length === state.bankAccounts.length) &&
                state.bankAccounts.forEach((bankAccount, index) => {
                    if (
                        (bankAccount.isPrimary !== props.bankAccounts[index].isPrimary) ||
                        (bankAccount.isLinkedAccount !== props.bankAccounts[index].isLinkedAccount)
                    ) {
                        isDifferent = true
                    }

                })

            if (isDifferent) {
                result.bankAccounts = props.bankAccounts
            }
        }
        return Object.keys(result).length > 0 ? result : null;
    }

    handleVerifyBankAccount = async (accountNumber,ifsc, setFieldValue) => {
        const data = {
            accountNumber,
            ifsc
        };
        const result = await commonServices.verifyBankAccount(data);
        if (result && result.status === 201) {
            const verificationData = result.data && result.data.bankAccountVerificationRequest
            this.setState({ accountVerified: true });
            setFieldValue("bankAccount.bankAccountVerificationRequest.id", verificationData.bankAccountVerificationRequestId);
            setFieldValue("bankAccount.accountHolderName", verificationData.accountHolderName);
            setFieldValue("bankAccount.bankName", verificationData.bankName);
            setFieldValue("bankAccount.branch", verificationData.branch);
        }
        this.setState({ isVerifying: false });
    };

    /**
     * Method to handle 'Add Bankd Account' button click
     * @param {Number} userId 
     */
    handleAddBankAccountButtonClick = (userId = null) => {
        this.setState(state => ({ showAddBankAccountModal: !state.showAddBankAccountModal, activeUser: userId }))
    }

    /**
     * Method to handle 'Link Account' button click
     * @param {String} account 
     */
    handleLinkAccountButtonClick = (account = null) => {
        if (account !== null) {
            this.setState({ toBeEdited: account });
        }
        this.setState(state => ({ showLinkAccountModal: !state.showLinkAccountModal }))
    }

    /**
     * Method to handle 'Unlink Account' button click
     * @param {String} account 
     */
    handleUnLinkAccountButtonClick = (account = null) => {
        if (account !== null) {
            this.setState({ toBeEdited: account });
        }
        this.setState(state => ({ showUnLinkAccountModal: !state.showUnLinkAccountModal }))
    }

    /**
     * Method to handle 'Relink Account' button click
     * @param {String} account 
     */
    handleReLinkAccountButtonClick = (account = null) => {
        if (account !== null) {
            this.setState({ toBeEdited: account });
        }
        this.setState(state => ({ showReLinkAccountModal: !state.showReLinkAccountModal }))
    }

    /**
     * Method to handle 'Mark Primary Account' button click
     * @param {String} account 
     */

    handleMarkPrimaryBankAccountButtonClick = (account = null) => {
        if (account !== null) {
            this.setState({ toBeEdited: account });
        }
        this.setState(state => ({
            showMarkPrimaryAccountModal: !state.showMarkPrimaryAccountModal,
            primaryAccountConfirmation: false,
        }))
    }

    /**
     * Method to handle 'Create Settlement Account' button click
     * @param {String} account 
     */
    handleCreateSettlementAccountButtonClick = (account = null) => {
        if (account !== null) {
            this.setState({ toBeEdited: account });
        }
        this.setState(state => ({ showCreateSettlmentAccountModal: !state.showCreateSettlmentAccountModal }))
    }

    render() {
        const { bankAccounts, userId, accountVerified, isVerifying } = this.state;
        return (
            <>
                <Row>
                    <Col xs={6} className="d-flex align-items-center">
                        <h6>Bank Account</h6>
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button variant="clean" size="sm" onClick={() => this.handleAddBankAccountButtonClick(userId)}>Add Bank Account</Button>
                    </Col>
                    {bankAccounts && bankAccounts.length === 0 && <Col className="text-center" xs={12}><p>No Bank Accounts</p></Col>}
                    {bankAccounts && bankAccounts.length > 0 && bankAccounts.map((account, index) => (
                        <React.Fragment key={'account' + index}>
                            <Col xs={12}>
                                <p className="small text-muted">Created by: {account.creator ? account.creator.fullName : 'Not recorded'}</p>
                                <p>{account.currency} Account | {account.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</p>
                                {account.accountNumber && account.vpa == null &&
                                    <>
                                        <p className="mb-0">Account Holder Name: {account.accountHolderName}</p>
                                        <p className="mb-0">Bank: {account.bankName} | Branch: {account.branch}</p>
                                        <p className="mb-0">Account No: {account.accountNumber}</p>
                                        {account.currency === 'INR' && <p>IFSC: {account.ifsc} {account.accountType && `| (${formatProperCase(account.accountType)})`}</p>}
                                        {account.currency === 'AED' && <p>IBAN: {account.iban} | Swift Code: {account.swiftCode}</p>}
                                    </>}
                                {account.accountNumber == null && account.vpa &&
                                    <>
                                        <p className="mb-0">Account Holder Name: {account.accountHolderName}</p>
                                        <p>UPI VPA: {account.vpa}</p>
                                    </>
                                }
                                {account.cashfreeBeneficiaryId && <p><strong>Cashfree Beneficiary Id: {account.cashfreeBeneficiaryId}</strong></p>}
                                {(account.isPrimary === false || account.isPrimary === null) && <Button variant="clean" size="sm" onClick={() => this.handleMarkPrimaryBankAccountButtonClick(account)}>Mark Primary Account</Button>}
                                {/* {account.currency === 'INR' && (account.isLinkedAccount === false || account.isLinkedAccount === null) && account.linkedAccountIdentifier === null && <Button variant="clean" size="sm" onClick={() => this.handleLinkAccountButtonClick(account)}>Link Account</Button>}
                                {account.currency === 'INR' && account.isLinkedAccount === true && account.linkedAccountIdentifier !== null && <Button variant="clean" size="sm" onClick={() => this.handleUnLinkAccountButtonClick(account)}>Un-link Account</Button>}
                                {account.currency === 'INR' && account.isLinkedAccount === false && account.linkedAccountIdentifier !== null && <Button variant="clean" size="sm" onClick={() => this.handleReLinkAccountButtonClick(account)}>Re-link Account</Button>} */}
                                {account.currency === 'INR' && account.cashfreeBeneficiaryId === null && <Button variant="clean" size="sm" onClick={() => this.handleCreateSettlementAccountButtonClick(account)}>Create Settlement Account</Button>}
                            </Col>
                            <Col xs={12}>
                                <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>

                <Modal backdrop="static" keyboard={false} key="ubaM1" show={this.state.showAddBankAccountModal} onHide={this.handleAddBankAccountButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Bank Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                bankAccount: {
                                    userId: this.state.activeUser || null,
                                    accountHolderName: '',
                                    accountNumber: undefined,
                                    bankName: '',
                                    branch: '',
                                    currency: 'INR',
                                    accountType: 'savings',
                                    ifsc: undefined,
                                    iban: undefined,
                                    swiftCode: undefined,
                                    isPrimary: undefined,
                                    isLinkedAccount: false,
                                    isVerified: true,
                                    bankAccountVerificationRequest: {
                                        id: undefined
                                    }
                                }
                            }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    values.bankAccount.isPrimary = (values.bankAccount.isPrimary === 'true');
                                    const result = await commonServices.createBankAccount(values);
                                    if (result && result.status === 201) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Bank Account added!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showAddBankAccountModal: !state.showAddBankAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        {!accountVerified && <Row>
                                            <Col xs={6}>
                                                <Form.Group controlId={"bankAccount.accountNumber"}>
                                                    <Form.Label>Account Number</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.accountNumber`} onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.accountNumber} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"bankAccount.ifsc"}>
                                                    <Form.Label>IFSC</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.ifsc`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.ifsc} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"currency"}>
                                                    <Form.Label>Currency</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.currency`} readOnly onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.currency} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"accountType"}>
                                                    <Form.Label>Account Type</Form.Label>
                                                    <Form.Control as="select" name={`bankAccount.accountType`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.accountType}>
                                                        <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                        <option value={'savings'}>Savings</option>
                                                        <option value={'current'}>Current</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} className="text-right">
                                                <Button type="button" variant="primary" onClick={() => {this.handleVerifyBankAccount(values.bankAccount.accountNumber, values.bankAccount.ifsc, setFieldValue); this.setState({isVerifying: true})}}>
                                                    {isVerifying ? 'Verifying...' : 'Verify Account'}
                                                </Button>
                                            </Col>
                                        </Row>}

                                        {accountVerified &&
                                         <>
                                          <Row>
                                            <Col xs={12}>
                                                <p className="text-warning">Verify the details below, before proceeding to add the bank account!</p>
                                            </Col>
                                            <Col xs={12}>
                                                <Form.Group controlId={"accountHolderName"}>
                                                    <Form.Label>Account Holder Name</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.accountHolderName`} required="required" readOnly value={values.bankAccount.accountHolderName} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"accountNumber"}>
                                                    <Form.Label>Account Number</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.accountNumber`} required="required" readOnly value={values.bankAccount.accountNumber} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"bankName"}>
                                                    <Form.Label>Bank Name</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.bankName`} required="required" readOnly value={values.bankAccount.bankName} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Form.Group controlId={"currency"}>
                                                    <Form.Label>Currency</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.currency`} required="required" readOnly value={values.bankAccount.currency} />
                                                </Form.Group>
                                            </Col>
                                            {values.bankAccount.currency === 'INR' && <Col xs={4}>
                                                <Form.Group controlId={"ifsc"}>
                                                    <Form.Label>IFSC</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.ifsc`} required="required" readOnly value={values.bankAccount.ifsc} />
                                                </Form.Group>
                                            </Col>}
                                            {values.bankAccount.currency === 'INR' && <Col xs={4}>
                                                <Form.Group controlId={"isPrimary"}>
                                                    <Form.Label>Account Type</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.accountType`} required="required" readOnly value={values.bankAccount.accountType} />
                                                </Form.Group>
                                            </Col>}
                                            {/* {values.bankAccount.currency === 'AED' && <Col xs={4}>
                                                <Form.Group controlId={"iban"}>
                                                    <Form.Label>IBAN</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.iban`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.iban} />
                                                </Form.Group>
                                            </Col>}
                                            {values.bankAccount.currency === 'AED' && <Col xs={4}>
                                                <Form.Group controlId={"swiftCode"}>
                                                    <Form.Label>Swift Code</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.swiftCode`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.swiftCode} />
                                                </Form.Group>
                                            </Col>} */}
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Group controlId={"branch"}>
                                                    <Form.Label>Branch</Form.Label>
                                                    <Form.Control className="border-success" type="text" name={`bankAccount.branch`} required="required" readOnly value={values.bankAccount.branch} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"isPrimary"}>
                                                    <Form.Label>Primary</Form.Label>
                                                    <Form.Control as="select" name={`bankAccount.isPrimary`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.isPrimary}>
                                                        <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {values.bankAccount.isPrimary === 'true' && this.props.existingUsers.filter((data) => data.id === this.state.activeUser)[0]?.houseOwnerships.length + this.props.existingUsers?.filter((data) => data.id === this.state.activeUser)[0]?.leaseTenantParticipations.length > 1 && <Col xs={12}>
                                                <h5>Please Note</h5>
                                                <p>Setting this Bank Account as Primary for this user, will also set it as Primary in all Properties where this user is an Owner, and all Leases where this user is a Tenant.</p>
                                            </Col>}
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.setState({ accountVerified: false })}>
                                                    Change Bank Account Details
                                                </Button>
                                                <Button variant="outline-secondary" className="mr-2" onClick={() => this.handleAddBankAccountButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row> </>}
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" keyboard={false} key="ubaM2" show={this.state.showLinkAccountModal} onHide={() => this.handleLinkAccountButtonClick(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Link Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                bankAccount: {
                                    isLinkedAccount: true,
                                    linkedAccountName: '',
                                    linkedAccountEmail: '',
                                    linkedAccountBusinessName: '',
                                    linkedAccountBusinessType: '',
                                }
                            }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.updateBankAccountLinkedAccount(this.state.toBeEdited.id, values);
                                    if (result && result.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Bank Account Linked!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showLinkAccountModal: !state.showLinkAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            {this.state.toBeEdited && <Col xs={12}>
                                                <p>{this.state.toBeEdited.currency} Account | {this.state.toBeEdited.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}{this.state.toBeEdited.isLinkedAccount && <Badge className="ml-1" variant="primary">Linked</Badge>}</p>
                                                <p className="mb-0">Account Holder Name: {this.state.toBeEdited.accountHolderName}</p>
                                                <p className="mb-0">Bank: {this.state.toBeEdited.bankName} | Branch: {this.state.toBeEdited.branch}</p>
                                                <p>Account No: {this.state.toBeEdited.accountNumber} ({this.state.toBeEdited.accountType})</p>
                                                {this.state.toBeEdited.cashfreeBeneficiaryId && <p><strong>Cashfree Beneficiary Id: {this.state.toBeEdited.cashfreeBeneficiaryId}</strong></p>}
                                                {this.state.toBeEdited.currency === 'INR' && <p>IFSC: {this.state.toBeEdited.ifsc} {this.state.toBeEdited.accountType && `| (${formatProperCase(this.state.toBeEdited.accountType)})`}</p>}
                                                {this.state.toBeEdited.currency === 'AED' && <p>IBAN: {this.state.toBeEdited.iban} | Swift Code: {this.state.toBeEdited.swiftCode}</p>}
                                            </Col>}
                                            <Col xs={6}>
                                                <Form.Group controlId={"linkedAccountName"}>
                                                    <Form.Label>Linked Account Name</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.linkedAccountName`} onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.linkedAccountName} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"linkedAccountEmail"}>
                                                    <Form.Label>Linked Account Email</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.linkedAccountEmail`} onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.linkedAccountEmail} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"linkedAccountBusinessName"}>
                                                    <Form.Label>Linked Account Business Name</Form.Label>
                                                    <Form.Control type="text" name={`bankAccount.linkedAccountBusinessName`} onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.linkedAccountBusinessName} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Group controlId={"linkedAccountBusinessType"}>
                                                    <Form.Label>Linked Account Business Type</Form.Label>
                                                    <Form.Control as="select" name={`bankAccount.linkedAccountBusinessType`} onChange={handleChange} onBlur={handleBlur} value={values.bankAccount.linkedAccountBusinessType}>
                                                        <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                        <option value={'llp'}>LLP</option>
                                                        <option value={'ngo'}>NGO</option>
                                                        <option value={'other'}>Other</option>
                                                        <option value={'individual'}>Individual</option>
                                                        <option value={'partnership'}>Partnership</option>
                                                        <option value={'proprietorship'}>Proprietorship</option>
                                                        <option value={'public_limited'}>Public Limited</option>
                                                        <option value={'private_limited'}>Private Limited</option>
                                                        <option value={'trust'}>Trust</option>
                                                        <option value={'society'}>Society</option>
                                                        <option value={'not_yet_registered'}>Not Yet Registered</option>
                                                        <option value={'educational_institutes'}>Educational Institutes</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleLinkAccountButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" keyboard={false} key="ubaM3" show={this.state.showUnLinkAccountModal} onHide={() => this.handleUnLinkAccountButtonClick(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Un-Link Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                bankAccount: {
                                    isLinkedAccount: false,
                                }
                            }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.updateBankAccountLinkedAccount(this.state.toBeEdited.id, values);
                                    if (result && result.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Bank Account unlinked!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showUnLinkAccountModal: !state.showUnLinkAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            {this.state.toBeEdited && <Col xs={12}>
                                                <p>{this.state.toBeEdited.currency} Account | {this.state.toBeEdited.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</p>
                                                <p className="mb-0">Account Holder Name: {this.state.toBeEdited.accountHolderName}</p>
                                                <p className="mb-0">Bank: {this.state.toBeEdited.bankName} | Branch: {this.state.toBeEdited.branch}</p>
                                                <p>Account No: {this.state.toBeEdited.accountNumber} ({this.state.toBeEdited.accountType})</p>
                                                {this.state.toBeEdited.cashfreeBeneficiaryId && <p><strong>Cashfree Beneficiary Id: {this.state.toBeEdited.cashfreeBeneficiaryId}</strong></p>}
                                                {this.state.toBeEdited.currency === 'INR' && <p>IFSC: {this.state.toBeEdited.ifsc} {this.state.toBeEdited.accountType && `| (${formatProperCase(this.state.toBeEdited.accountType)})`}</p>}
                                                {this.state.toBeEdited.currency === 'AED' && <p>IBAN: {this.state.toBeEdited.iban} | Swift Code: {this.state.toBeEdited.swiftCode}</p>}
                                            </Col>}
                                            <Col xs={12} className="mt-3">
                                                <p><strong>Confirm unlinking of this account?</strong></p>
                                            </Col>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleUnLinkAccountButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="danger" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Processing...' : 'Yes'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" keyboard={false} key="ubaM4" show={this.state.showReLinkAccountModal} onHide={() => this.handleReLinkAccountButtonClick(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Re-Link Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                bankAccount: {
                                    isLinkedAccount: true,
                                }
                            }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.updateBankAccountLinkedAccount(this.state.toBeEdited.id, values);
                                    if (result && result.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Bank Account relinked!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showReLinkAccountModal: !state.showReLinkAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            {this.state.toBeEdited && <Col xs={12}>
                                                <p>{this.state.toBeEdited.currency} Account | {this.state.toBeEdited.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</p>
                                                <p className="mb-0">Account Holder Name: {this.state.toBeEdited.accountHolderName}</p>
                                                <p className="mb-0">Bank: {this.state.toBeEdited.bankName} | Branch: {this.state.toBeEdited.branch}</p>
                                                <p>Account No: {this.state.toBeEdited.accountNumber} ({this.state.toBeEdited.accountType})</p>
                                                {this.state.toBeEdited.cashfreeBeneficiaryId && <p><strong>Cashfree Beneficiary Id: {this.state.toBeEdited.cashfreeBeneficiaryId}</strong></p>}
                                                {this.state.toBeEdited.currency === 'INR' && <p>IFSC: {this.state.toBeEdited.ifsc} {this.state.toBeEdited.accountType && `| (${formatProperCase(this.state.toBeEdited.accountType)})`}</p>}
                                                {this.state.toBeEdited.currency === 'AED' && <p>IBAN: {this.state.toBeEdited.iban} | Swift Code: {this.state.toBeEdited.swiftCode}</p>}
                                            </Col>}
                                            <Col xs={12} className="mt-3">
                                                <p><strong>Confirm relinking of this account?</strong></p>
                                            </Col>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleReLinkAccountButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="danger" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Processing...' : 'Yes'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" keyboard={false} key="ubaM5" show={this.state.showMarkPrimaryAccountModal} onHide={() => this.handleMarkPrimaryBankAccountButtonClick(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mark Primary Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                bankAccount: {
                                    isPrimary: true,
                                }
                            }}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.markPrimaryBankAccount(this.state.toBeEdited.id);
                                    if (result && result.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Bank Account marked as Primary!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showMarkPrimaryAccountModal: !state.showMarkPrimaryAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        {!this.state.primaryAccountConfirmation && this.props.existingUsers?.filter((data) => data.id === this.state.userId)[0]?.houseOwnerships.length + this.props.existingUsers?.filter((data) => data.id === this.state.userId)[0]?.leaseTenantParticipations.length > 1 ?
                                            <Row>
                                                <Col xs={12}>
                                                    <h5>Please Note</h5>
                                                    <p>Setting this Bank Account as Primary for this user, will also set it as Primary in all Properties where this user is an Owner, and all Leases where this user is a Tenant.</p>
                                                </Col>
                                                <Col xs={12} className="text-right">
                                                    <Button onClick={() => this.setState({ primaryAccountConfirmation: true })}>Continue</Button>
                                                </Col>
                                            </Row>
                                            :
                                            <Row>
                                                {this.state.toBeEdited && <Col xs={12}>
                                                    <p>{this.state.toBeEdited.currency} Account | {this.state.toBeEdited.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</p>
                                                    <p className="mb-0">Account Holder Name: {this.state.toBeEdited.accountHolderName}</p>
                                                    <p className="mb-0">Bank: {this.state.toBeEdited.bankName} | Branch: {this.state.toBeEdited.branch}</p>
                                                    <p>Account No: {this.state.toBeEdited.accountNumber} ({this.state.toBeEdited.accountType})</p>
                                                    {this.state.toBeEdited.currency === 'INR' && <p>IFSC: {this.state.toBeEdited.ifsc} {this.state.toBeEdited.accountType && `| (${formatProperCase(this.state.toBeEdited.accountType)})`}</p>}
                                                    {this.state.toBeEdited.currency === 'AED' && <p>IBAN: {this.state.toBeEdited.iban} | Swift Code: {this.state.toBeEdited.swiftCode}</p>}
                                                </Col>}
                                                <Col xs={12} className="mt-3">
                                                    <p><strong>Confirm marking this account as Primary?</strong> <br />There can be only one Primary Bank Account for each currency. This action will mark any other Bank Account for this same currency as not Primary. It will not affect the state of the Bank Accounts of other currency.</p>
                                                </Col>
                                                <Col xs={12} className="text-right">
                                                    <Button variant="secondary" className="mr-2" onClick={() => this.handleMarkPrimaryBankAccountButtonClick(null)}>
                                                        Cancel
                                                    </Button>
                                                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Processing...' : 'Yes'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

                <Modal backdrop="static" keyboard={false} key="ubaM6" show={this.state.showCreateSettlmentAccountModal} onHide={() => this.handleCreateSettlementAccountButtonClick(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Settlement Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{}}
                            validate={values => {
                                const errors = {};
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await commonServices.createSettlementAccount(this.state.toBeEdited?.id);
                                    if (result && result.status === 200) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `Settlement Account created!`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showCreateSettlmentAccountModal: !state.showCreateSettlmentAccountModal }));
                                        if (this.props.page === 'PropertyProfile') {
                                            this.props.propertyProfilePageCallback();
                                        } else if (this.props.page === 'LeaseProfile') {
                                            this.props.leaseProfilePageCallback();
                                        }
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            {this.state.toBeEdited && <Col xs={12}>
                                                <p>{this.state.toBeEdited.currency} Account | {this.state.toBeEdited.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</p>
                                                <p className="mb-0">Account Holder Name: {this.state.toBeEdited.accountHolderName}</p>
                                                <p className="mb-0">Bank: {this.state.toBeEdited.bankName} | Branch: {this.state.toBeEdited.branch}</p>
                                                <p>Account No: {this.state.toBeEdited.accountNumber} ({this.state.toBeEdited.accountType})</p>
                                                {this.state.toBeEdited.cashfreeBeneficiaryId && <p><strong>Cashfree Beneficiary Id: {this.state.toBeEdited.cashfreeBeneficiaryId}</strong></p>}
                                                {this.state.toBeEdited.currency === 'INR' && <p>IFSC: {this.state.toBeEdited.ifsc} {this.state.toBeEdited.accountType && `| (${formatProperCase(this.state.toBeEdited.accountType)})`}</p>}
                                                {this.state.toBeEdited.currency === 'AED' && <p>IBAN: {this.state.toBeEdited.iban} | Swift Code: {this.state.toBeEdited.swiftCode}</p>}
                                            </Col>}
                                            <Col xs={12} className="mt-3">
                                                <p><strong>Mark this account for settlements?</strong></p>
                                            </Col>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleCreateSettlementAccountButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="danger" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Processing...' : 'Yes'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

}
