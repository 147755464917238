import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all invoices
 * @param {Object} data
 */
async function getAllInvoices(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/invoices`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllInvoices ', err);
  }

}

/**
 * Method to get invoice details by id
 * @param {Number} id 
 */
async function getInvoiceById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/invoices/${id}`);
    return result;
  } catch (err) {
    console.error('error in getInvoiceById ', err);
  }

}

/**
 * Method to get property data
 */
async function getPropertyData() {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houses`);
    return result;
  } catch (err) {
    console.error('error in getPropertyData ', err);
  }
}

/**
 * Method to mark invoice as invalid
 * @param {Number} id 
 */
async function markInvoiceAsInvalid(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/invoices/${id}/markInvalid`);
    return result;
  } catch (err) {
    console.error('error in markInvoiceAsInvalid ', err);
  }
}

/**
 * Method to get payment Details
 * @param {Object} data 
 */
async function getPaymentDetails(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/payments`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getPaymentDetails ', err);
  }
}

/**
 * Method to clear invoice
 * @param {Number} id 
 * @param {Object} data 
 */
async function clearInvoice(id, data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/invoices/${id}/clear`, data);
    return result;
  } catch (err) {
    console.error('error in clearInvoice ', err);
  }
}


/**
 * Method to create Invoice
 * @param {Object} data 
 */
async function createInvoiceParticipation(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/invoicePaymentParticipations`, data);
    return result;
  } catch (err) {
    console.error('error in createInvoiceParticipation ', err);
  }
}

/**
 * Method to get preSignedFileURL
 * @param {object} data
 */
async function getPreSignedFileURL(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/beatsFiles/getPreSignedFileUrl`, { params: data });
    return result;
  } catch (err) {
    console.log(' error in getPreSignedFileURL ', err);
  }
}

export const InvoiceServices = {
  getAllInvoices,
  getInvoiceById,
  getPropertyData,
  markInvoiceAsInvalid,
  getPaymentDetails,
  clearInvoice,
  createInvoiceParticipation,
  getPreSignedFileURL
};