import React, { Component } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services'
import { formatDateTime } from '../../../_metronic/utils/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'; // for everything
import { store } from 'react-notifications-component';
import * as firebase from 'firebase/app';

const addNotesSchema = Yup.object().shape({
  content: Yup.string().required('Please write something to save this note.')
})

class NotesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      notes: this.props.notes,
      allowAddNotes: this.props.allowAddNotes,
      showAddNoteModal: false,
    };
  }

  //   static getDerivedStateFromProps(props, state) {
  //     let result = {}
  //     if (!_.isEqual(props.rmProfile, state.rmProfile)) {
  //         result.rmProfile = props.rmProfile
  //     }
  //     return Object.keys(result).length > 0 ? result : null;
  // }

  handleAddNoteClick = async () => {
    this.setState(state => ({ showAddNoteModal: !state.showAddNoteModal }));
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    if (state.notes.length !== props.notes.length) {
      result.notes = props.notes
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  render() {
    return (
      <>
        <Row className="mb-4">
          <Col xs={10}>
            <h5>Notes</h5>
          </Col>
          <Col xs={2} className="text-right">
            {this.state.allowAddNotes && !this.state.isReport && <Button variant="clean" size="sm" onClick={this.handleAddNoteClick}>Add Note</Button>}
            {this.state.allowAddNotes && this.state.isReport === "MOVEINREPORT" && this.state.isMoveInReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddNoteClick}>Add Note</Button>}
            {this.state.allowAddNotes && this.state.isReport === "MOVEOUTREPORT" && this.state.isMoveOutReportPublished !== true && <Button variant="clean" size="sm" onClick={this.handleAddNoteClick}>Add Note</Button>}
          </Col>

          {this.state.notes && this.state.notes.map(note =>
          
            <Col xs={12} key={note.id}>
              {note?.createdAt && <p> {note?.user?.fullName} @<strong> {formatDateTime(note?.createdAt)}</strong>: {note?.content}</p>}

              {/**
               * Case for createdAt not coming (for notes in invoices)
               * TODO: remove this once createdAt starts coming in the nodes (for notes in invoices)
               */}

              {!note.createdAt && <p><strong>missing createdAt</strong>: {note.content}</p>}
            </Col>
          )
          }
          {this.state.notes.length === 0 && <Col className="text-center" xs={12}><p>No Notes</p></Col>}
        </Row>
        <Modal backdrop="static" keyboard={false} show={this.state.showAddNoteModal} onHide={this.handleAddNoteClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                serviceRequestId: this.state.serviceRequestId ? this.state.serviceRequestId : undefined,
                invoiceId: this.state.invoiceId ? this.state.invoiceId : undefined,
                // workId: this.state.workId ? (this.state.workId) : (this.state.renewalWorkId ? this.state.renewalWorkId : null),
                workId: this.state.workId || this.state.renewalWorkId || this.state.preMoveInWorksId || this.state.rentEscalationWorkId || undefined,
                leaseId: this.state.leaseId || undefined,
                tenantRentpaymentBillId: this.state.rentPaymentId || undefined,
                paymentBillId: this.state.paymentBillId || undefined,
                noticePeriodId: this.state.noticePeriodInitiatedLeaseId || undefined,
                groupName: ((this.state.leaseId || this.state.noticePeriodId) && 'LEASE_TERMINATION') || undefined,
                content: undefined,
              }}
              validationSchema={addNotesSchema}
              onSubmit={async (values) => {
                const serviceRequestNoteData = {
                  'note': {
                    ...values
                  }
                }
                const result = await ServiceRequestsServices.addNotes(serviceRequestNoteData)
                if (result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: `Note added!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  if (this.state.serviceRequestId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Service Requests' });
                    this.props.parentCallback(values.serviceRequestId);
                  } else if (this.state.workId) {
                    // For Inspections Work
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Inspection' });
                    this.props.parentCallback(values.workId)
                  } else if (this.state.renewalWorkId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'PMS Renewals' });
                    this.props.parentCallback(values.workId)
                  } else if (this.state.isDocumentation) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Documentation in Lease Profile' });
                    this.props.parentCallback('DOCUMENTATION');
                  } else if (this.state.isFurnishing) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Furnishing in Lease Profile' });
                    this.props.parentCallback('FURNISHING');
                  } else if (this.state.isReport === 'MOVEINREPORT') {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Move-in Work in Lease Profile' });
                    this.props.parentCallback('MOVEINREPORT')
                  } else if (this.state.isReport === 'MOVEOUTREPORT') {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Move-out Work in Lease Profile' });
                    this.props.parentCallback('MOVEOUTREPORT')
                  } else if (this.state.rentPaymentId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Rent Payment Bills' });
                    this.props.parentCallback(values.tenantRentpaymentBillId);
                  } else if (this.state.paymentBillId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Security Deposit Payment Bills' });
                    this.props.parentCallback(values.paymentBillId);
                  } else if (this.state.rentEscalationWorkId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Rent Escalations' });
                    this.props.parentCallback(values.workId);
                  } else if (this.state.leaseId){
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Expiring Leases in Lease Terminations' });
                    this.props.parentCallback(values.leaseId);
                  } else if (this.state.noticePeriodInitiatedLeaseId) {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Notice Period Initiated Leases in Lease Terminations' });
                    this.props.parentCallback(values.noticePeriodId);
                  } else {
                    firebase.analytics().logEvent("add_note_button_click", { description: "Add Note Button Clicked", addNoteFrom: 'Invoices' });
                    this.props.parentCallback(values.invoiceId);
                  }
                  this.setState(state => ({ showAddNoteModal: !state.showAddNoteModal }));
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="content">
                          <Form.Control as="textarea" name="content" type="text" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.content} />
                          {errors.content && touched.content && <Form.Text className="text-danger">{errors.content}</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleAddNoteClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>)}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default NotesComponent;
