
import React from 'react';
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
export const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <>
            <label className='form-label' htmlFor={props.label}>{props.label}{props.isMandatory ? <span className='text-danger'> * </span> : <></>}</label>
            <DatePicker
                {...field}
                {...props}
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={10}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    if (!props.showTimeSelect) {
                        if(props.autoTime){
                            setFieldValue(field.name, val);
                        }
                        else if (moment(val).format('YYYY-MM-DD') !== 'Invalid date') {
                            setFieldValue(field.name, moment(val).format('YYYY-MM-DD'));
                        } else {
                            moment(val).format('YYYY-MM-DD') === 'Invalid date' && setFieldValue(field.name, val);
                        }
                    }
                    props.showTimeSelect && setFieldValue(field.name, val);
                    if ((field.name === 'house.houseOffering.lease.moveInDate') && val) {
                        setFieldValue('house.houseOffering.lease.moveInTerminalStatus', 'YES');
                    } else if ((field.name === 'house.houseOffering.lease.moveInDate') && !val) {
                        setFieldValue('house.houseOffering.lease.moveInTerminalStatus', undefined);
                    }
                }}
            />
        </>
    );
};