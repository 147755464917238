import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Badge, Accordion, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { rentEscalationsServices } from './RentEscalations.services'
import { formatDate, formatMultipleUsers, formatAmount, findPrimary } from '../../../../_metronic/utils/utils';
import * as constants from '../../../constants';
// import FilterComponent from '../../../partials/layout/FilterComponent';
import TasksListComponent from '../../../partials/layout/TasksListComponent.js';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import * as firebase from 'firebase/app';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import { Link } from 'react-router-dom';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { commonServices } from '../../../../app/commonServices/commonServices';

class RentEscalations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      totalCount: undefined,
      state: ['OPEN'],
      isFetching: false,
      isTaskCreated: false,
      requests: [],
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      requestedFilters: ['state'],
    }
  }

  async componentDidMount() {
    await this.getRentEscalationsData();
    await this.getCities();
    await this.getPropertyManagers();
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
    * Method to get all the cities
    */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      await this.setState({ formattedCities });
      preSelectedCities && await this.setState({ preSelectedCities });
    }
  }

  getRentEscalationsData = async () => {
    this.setState({ isFetching: true });
    const { state, offset, limit, cityIds, minimumRentEscalationDate, maximumRentEscalationDate,
      propertyManagerId } = this.state;
    const data = {
      state: (state && state[0]) || undefined,
      minimumRentEscalationDate,
      maximumRentEscalationDate,
      cityIds,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      offset,
      limit,
      include: ['PROPERTY_MANAGER']
    }

    const rentEscalationsData = await rentEscalationsServices.listRentEscalations(data);
    if (rentEscalationsData && rentEscalationsData.status === 200) {
      const totalCount = rentEscalationsData.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit)
      await this.setState({
        rentEscalationWorks: rentEscalationsData.data.rentEscalationWorks,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
    }
    // if (!this.props.houseIdFromPropertyProfile) {
    //   const propertyData = await commonServices.getPropertyData(dataForPropertyData)
    //   if (propertyData && propertyData.status === 200) {
    //     await this.setState({ initialHouseData: propertyData.data.houses })
    //   }
    // }
    this.setState({ isFetching: false });
  }

  /**
   * Method to get rent escalation work by id
   * @param {Number} id
   */
  getRentEscalationWorkDetails = async (id) => {
    const result = await rentEscalationsServices.getRentEscalationById(id);
    if (result && result.status === 200) {
      await this.setState({ accordionData: result.data.rentEscalationWork });
      // await this.setState({ toBeEdited: result.data.serviceRequest });
    }
  }

  /**
   * Method to handle accordion click
   * @param {Number} id
   */
  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getRentEscalationWorkDetails(id);
      // await this.getStakeHolderData();
      // await this.getTaskAssignee();
      firebase.analytics().logEvent("rent_escalation_work_details_view_click", { description: "Rent Escalation Work Details View Clicked", reId: id });
    }
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getRentEscalationsData();
  }

  getRentEscalationStatusBadge(escalation_status) {
    return escalation_status === 'CLOSED' ? <Badge variant="info" title="Closed">C</Badge> : <Badge variant="success" title="Open">O</Badge>
  }

  /**
   * Method to handle data from child components
   * @param {Number} childData
   */
  handleChildData = async (childData) => {
    await this.getRentEscalationWorkDetails(childData);
  }

  handleCloseEscalationButtonClick = async () => {
    await this.setState(state => ({ showCloseEscalationModal: !state.showCloseEscalationModal }));
  }

  handleAddAcknowledgementButtonClick = async (acknowledger = null) => {
    await this.setState(state => ({ showAddAcknowledgementModal: !state.showAddAcknowledgementModal, acknowledger: acknowledger }));
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      await this.setState({ ...childData });
      await this.resetPage();
      await this.getRentEscalationsData();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'Rent Escalations' });
    }
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
     // limit: 10,
    });
  }

  render() {
    const {
      rentEscalationWorks, page, total, totalCount, accordionData,
      isFetching, showCloseEscalationModal, showAddAcknowledgementModal,
      acknowledger, formattedCities, preSelectedCities,
      minimumRentEscalationDate, maximumRentEscalationDate,
      propertyManagers, propertyManagerId, requestedFilters, state
    } = this.state;
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (isFetching) {
      return <p>Loading data...</p>
    }
    return (
      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Rent Escalations (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  {/* <FilterComponent requestedFilters={requestedFilters} filterParentCallback={this.handleFilterChildData}
                    state={state}
                  /> */}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <FilterComponent
                requestedFilters={requestedFilters}
                state={state}
                formattedCities={formattedCities}
                preSelectedCities={preSelectedCities}
                minimumRentEscalationDate={minimumRentEscalationDate}
                maximumRentEscalationDate={maximumRentEscalationDate}
                propertyManagers={propertyManagers}
                propertyManagerId={propertyManagerId}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Accordion defaultActiveKey="0">
                {rentEscalationWorks && rentEscalationWorks.length === 0 && <p>{constants.ZERO_DATA}</p>}
                {rentEscalationWorks && rentEscalationWorks.length > 0 && <Card key={'headerrq'}>
                  <Card.Header>
                    <Row className="py-2 px-3 azuro-table-header">
                      <Col md={2}><strong>Property</strong></Col>
                      <Col md={2}><strong>Escalation Date</strong></Col>
                      <Col md={2}><strong>Escalated Rent</strong></Col>
                      <Col md={2}><strong>Acknowledged By</strong></Col>
                      <Col md={2}><strong>Pending Acknowledgers</strong></Col>
                    </Row>
                  </Card.Header>
                </Card>}
                {rentEscalationWorks && rentEscalationWorks.length > 0 && rentEscalationWorks.map(work =>
                  <Card key={work.id + 're'}>
                    <Accordion.Toggle as={Card.Header} eventKey={work.id} className="row py-2 azuro-table-row" onClick={() => { this.handleAccordionClick(work.id) }}>
                      <Col md={2} className="d-flex flex-row align-items-center">
                        <span className="pr-3">{this.getRentEscalationStatusBadge(work.terminalStatus)}</span>
                        {work.lease && work.lease.houseOfferings && work.lease.houseOfferings.house && <span>{work.lease.houseOfferings.house.formattedHouseName} <br />
                          <span className="small text-muted">{work.lease.houseOfferings.house.propertyManager ? `PM: ${work.lease.houseOfferings.house.propertyManager && work.lease.houseOfferings.house.propertyManager.fullName}` : `PM: Not Assigned`}</span>
                        </span>}
                      </Col>
                      <Col md={2}>{formatDate(work.rentEscalationDate)}</Col>
                      <Col md={2}>{formatAmount(work.escalatedRent, work.currency)}<br /><span className="small text-muted">Prev: {formatAmount(work.previousRent, work.currency)}</span></Col>
                      <Col md={2}>
                        {work.acknowledgers.length > 0 ? formatMultipleUsers(work.acknowledgers) : <span className="small text-muted">No Acknowledgements.</span>}
                      </Col>
                      <Col md={2}>
                        {work.pendingAcknowledgers.length > 0 ? formatMultipleUsers(work.pendingAcknowledgers) : <span className="small text-muted">No Pending Acknowledgers.</span>}
                      </Col>
                      {work.lease && work.lease.houseOfferings && work.lease.houseOfferings.house && <Col md={1}>
                        <Link to={`/property-profile/${work.lease.houseOfferings.house.id}`}>
                          <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                        </Link>
                      </Col>}
                      {work.lease && <Col md={1}>
                        <Link to={`/lease-profile/${work.lease.id}`}>
                          <Button size='sm' variant='clean'><i className="flaticon-edit-1"></i></Button>
                        </Link>
                      </Col>}
                    </Accordion.Toggle>
                    {accordionData && (accordionData.id === work.id) &&
                      <Accordion.Collapse key={accordionData.id} eventKey={accordionData && accordionData.id}>
                        <Card.Body>
                          <Row className="mb-4 d-flex align-items-center">
                            <Col xs={12}>
                              <h5 className="mb-2">Acknowledgements</h5>
                            </Col>
                            {accordionData.acknowledgements.length === 0 && <Col xs={12}>
                              <p>No Acknowledgements received.</p>
                            </Col>}
                            {accordionData.acknowledgements.length > 0 && accordionData.acknowledgements.map(ack => <Col xs={12}>
                              <p>Received from {ack.acknowledger.fullName}</p>
                            </Col>)}
                          </Row>
                          <Row className="mb-4 d-flex align-items-center">
                            <Col xs={12}>
                              <h5 className="mb-2">Pending Acknowledgers</h5>
                            </Col>
                            {accordionData.pendingAcknowledgers.length === 0 && <Col xs={12}>
                              <p>No Pending Acknowledgers.</p>
                            </Col>}
                            {accordionData.pendingAcknowledgers.length > 0 && accordionData.pendingAcknowledgers.map(pack => <Col xs={12} className="d-flex justify-content-between align-items-center">
                              <span>{pack.fullName} <br />{pack.email} | +{findPrimary(pack.phoneNumbers).countryCode}-{findPrimary(pack.phoneNumbers).number}</span>
                              <Button variant="clean" size="sm" onClick={() => this.handleAddAcknowledgementButtonClick(pack)}>Add Acknowledgement</Button>
                            </Col>)}
                          </Row>
                          <TasksListComponent
                            tasks={accordionData.tasks || []}
                            allowAddTasks={work.terminalStatus !== undefined && work.terminalStatus !== 'CLOSED'}
                            rentEscalationWorkId={work.id} parentCallback={this.handleChildData}
                            approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                            houseId={accordionData?.house?.id}
                          />
                          <NotesComponent notes={accordionData.notes || []} allowAddNotes={work.terminalStatus !== undefined && work.terminalStatus !== 'CLOSED'} rentEscalationWorkId={work.id} parentCallback={this.handleChildData} />
                          <Row className="mb-4">
                            <Col xs={12} className="text-right">
                              {accordionData.terminalStatus !== undefined && accordionData.terminalStatus !== 'CLOSED' &&
                                <Button variant="primary" size="sm" onClick={() => this.handleCloseEscalationButtonClick()}>Close Escalation</Button>
                              }
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    }
                  </Card>
                )}
                {/* Code for pagination */}
                {showPagination && showPagination && <UltimatePagination
                  currentPage={page}
                  totalPages={total}
                  onChange={this.onPageChange}
                  boundaryPagesRange={0}
                  hidePreviousAndNextPageLinks={true}
                  hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                />}
              </Accordion>
            </PortletBody>
          </Portlet>
        </Container >

        <Modal backdrop="static" keyboard={false} key={1} show={showCloseEscalationModal} onHide={this.handleCloseEscalationButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Close Rent Escalation`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                rentEscalationWork: {
                  terminalStatus: 'CLOSED',
                  terminalRemarks: undefined
                },
              }}
              onSubmit={async (values) => {
                const result = await rentEscalationsServices.markRentEscalationWorkTerminalStatus(accordionData && accordionData.id, values);
                if (result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: "Escalation work closed!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showCloseEscalationModal: !state.showCloseEscalationModal }));
                  await this.getRentEscalationsData();
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      {accordionData && accordionData.pendingAcknowledgers.length > 0 && <Col xs={12}>
                        <p>There are {accordionData.pendingAcknowledgers.length} Pending Acknowledgements.<br />Are you sure you want to close this escalation?</p>
                      </Col>}
                      <Col xs={12}>
                        <Form.Group controlId="rentEscalationWork.terminalStatus">
                          <Form.Label>Terminal Status</Form.Label>
                          <Form.Control name="rentEscalationWork.terminalStatus" type="text" readOnly="readOnly" value={values.rentEscalationWork.terminalStatus} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="rentEscalationWork.terminalRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control name="rentEscalationWork.terminalRemarks" type="text" onChange={handleChange} onBlur={handleBlur} value={values.rentEscalationWork.terminalRemarks} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleCloseEscalationButtonClick()}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={2} show={showAddAcknowledgementModal} onHide={this.handleAddAcknowledgementButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Add Acknowledgement`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                acknowledgement: {
                  categoryName: 'RENT_ESCALATION',
                  startRentPeriodId: (accordionData && accordionData.startRentPeriod && accordionData.startRentPeriod.id) || undefined,
                  endRentPeriodId: (accordionData && accordionData.endRentPeriod && accordionData.endRentPeriod.id) || undefined,
                  acknowledgerId: (acknowledger && acknowledger.id) || undefined
                },
              }}
              onSubmit={async (values) => {
                const result = await rentEscalationsServices.createAcknowledgement(values);
                if (result.status === 201) {
                  store.addNotification({
                    title: "Success!",
                    message: "Acknowledgement added!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showAddAcknowledgementModal: !state.showAddAcknowledgementModal }));
                  await this.getRentEscalationWorkDetails(accordionData && accordionData.id);
                }
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <p>Confirm Acknowledgement from {acknowledger && acknowledger.fullName} for this Rent Escalation?</p>
                      </Col>
                      <Col xs={12} className="mt-2 text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleAddAcknowledgementButtonClick(null)}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  serviceRequestCategories: state.auth && state.auth.staticData && state.auth.staticData.serviceRequests.categories,
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(RentEscalations);
