import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import TaskItem from './TaskItem.js';
import { ServiceRequestsServices } from '../../pages/home/ServiceRequests/ServiceRequests.services';
import FilterComponent from './FilterComponent';
import * as constants from '../../constants';
import UltimatePagination from './PaginationComponent';
import * as Yup from 'yup'; // for everything
import { store } from 'react-notifications-component';
import { DatePickerField } from './DatePickerField';
import * as firebase from 'firebase/app';
import { connect } from 'react-redux';

const addTaskSchema = Yup.object().shape({
  assigneeId: Yup.number().required('Please select Assign To'),
  title: Yup.string().required('Task is required'),
  scheduledAt: Yup.date().required('Schedule Date Time is required').min(new Date(), 'Date Time should be of future, greater than ' + new Date().toLocaleString())
})

class MyTasksCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      tasks: this.props.tasks,
      requestedFilters: ['state'],
      state: ['OPEN'],
      showAddTaskModal: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    let isDifferent = false;
    if (state.totalTaskCount !== props.totalTaskCount) {
      result = props
    }
    state.tasks && (state.tasks.length === props.tasks.length) && state.tasks.forEach((task, index) => {
      if (
        task.title !== props.tasks[index].title ||
        task.scheduledAt !== props.tasks[index].scheduledAt ||
        task.completedAt !== props.tasks[index].completedAt ||
        task.createdAt !== props.tasks[index].createdAt
      ) {
        isDifferent = true;
      }
    }
    )
    if (((state.tasks && state.tasks.length) !== (props.tasks && props.tasks.length)) || isDifferent) {
      result = props
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  handleTaskItemCallback = (callbackToCard) => {
    if (callbackToCard === true) {
      this.props && this.props.homepageCallback(true, this.state.taskPage, this.state.taskOffset, this.state.state);
    }
  }

  // getRequestor = async () => {
  //   const data = {
  //     userRoleCodes: ['PROPERTY_MANAGER'],
  //   }
  //   const result = await commonServices.getUserListData(data);
  //   if (result && result.status === 200) {
  //     await this.setState({ propertyMangers: result.data.users })
  //   }
  // }

  renderTasks() {
    return (
      <>
        <Row className="mt-4">
          {this.state.tasks.map(task =>
            <Col xs={12} key={task.id}>
              <TaskItem callFromCard={true} cardCallback={this.handleTaskItemCallback} item={task} />
            </Col>
          )}
        </Row>
      </>
    )
  }

  handleAddTaskButtonClick = (isApiCall = false) => {
    // if (isApiCall === true) {
    //   this.getRequestor();
    // }
    this.setState(state => ({ showAddTaskModal: !state.showAddTaskModal }));
    if (this.state.showAddTaskModal === true) {
      firebase.analytics().logEvent("add_task_button_click", { description: "Add Task Button Clicked", addTaskFrom: 'Dashboard' });
    }
  }

  /**
 * Method to handle data of add payment component
 * @param {String} childData
 */
  handleFilterChildData = async (childData) => {
    if (childData) {
      await this.setState({ ...childData});
      await this.resetPage();
      this.props.homepageCallback(true, this.state.taskPage, this.state.taskOffset, this.state.state);
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'My Tasks Card' });
    }
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (taskPage) => {
    const { tasklimit, state } = this.state;
    const taskOffset = (tasklimit * (taskPage - 1));
    await this.setState({ taskPage, taskOffset });
    await this.props && this.props.homepageCallback(true, taskPage, taskOffset, state);
  }

  /**
   * Method to reset page
   */
  resetPage = async () => {
    await this.setState({
      taskPage: 1,
      taskOffset: 0,
      tasklimit: 10,
    });
  }

  render() {
    const { tasks, taskTotal, totalTaskCount, showTaskPagination, taskPage, requestedFilters, state } = this.state;
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    return (
      <React.Fragment>
        <Portlet>
          <PortletHeader title={`My Tasks (${totalTaskCount || 0})`}
            toolbar={
              <PortletHeaderToolbar>
                <Button variant="clean" size="sm" onClick={() => this.handleAddTaskButtonClick(true)}><i className="flaticon2-plus"></i></Button>
                {/* <FilterComponent requestedFilters={requestedFilters} filterParentCallback={this.handleFilterChildData}
                    state={state}
                  /> */}
              </PortletHeaderToolbar>
            }
          />
          <PortletBody className="p-3">
            {!tasks && <p className="mb-0">{constants.LOADING_DATA}</p>}
            {tasks && <FilterComponent requestedFilters={requestedFilters} filterParentCallback={this.handleFilterChildData} state={state} />}
            {tasks && tasks.length === 0 && <p className="mb-0">No tasks pending.</p>}
            {tasks && tasks.length > 0 && <>
                {this.renderTasks()}
                {/* Code for pagination */}
                {showTaskPagination && showTaskPagination && <UltimatePagination
                  currentPage={taskPage}
                  totalPages={taskTotal}
                  onChange={this.onPageChange}
                  boundaryPagesRange={0}
                  hidePreviousAndNextPageLinks={true}
                  hideFirstAndLastPageLinks={taskPage !== 1 && taskPage !== taskTotal ? false : true}
                />}
              </>}
            </PortletBody>
          </Portlet>
        <Modal backdrop="static" keyboard={false} show={this.state.showAddTaskModal} onHide={this.handleAddTaskButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Add Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Formik
              initialValues={{
                assigneeId: (taskAssigneeData && taskAssigneeData[0].id) || undefined,
                title: undefined,
                scheduledAt: undefined
              }}
              validationSchema={addTaskSchema}
              onSubmit={async (values) => {
                const result = await ServiceRequestsServices.addTasks(values, this.props.authToken);
                if (result.status === 201) {
                  // TODO: Replace this parent call to reload the homepage and not the serviceRequest
                  store.addNotification({
                    title: "Success!",
                    message: `Task added!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showAddTaskModal: !state.showAddTaskModal }));
                  this.props.homepageCallback(true, this.state.taskPage, this.state.taskOffset, this.state.state);
                }
                //   this.setState(state => ({ showAddEstimateModal: !state.showAddEstimateModal }));
              }}
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, arrayHelpers, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="title">
                          <Form.Label>Task</Form.Label>
                          <Form.Control name="title" type="text" placeholder="Write here..." onChange={handleChange} onBlur={handleBlur} value={values.title} />
                          {errors.title && touched.title && <Form.Text className="text-danger">{errors.title}</Form.Text>}
                        </Form.Group>
                      </Col>
                      {/* <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assign To</Form.Label>
                          <Form.Control name="assigneeId" as="select" onChange={handleChange} onBlur={handleBlur} value={values.assigneeId}>
                            <option value='0'>{constants.DEFAULT_OPTION}</option>
                            {
                              this.state.propertyMangers && this.state.propertyMangers.map((pm =>
                                <option key={pm.id} value={pm.id}>{pm.fullName} {pm.userSubCategory && '(' + formatProperCase(pm.userSubCategory) + ')'}</option>
                              ))
                            }
                          </Form.Control>
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">{errors.assigneeId}</Form.Text>}
                        </Form.Group>
                      </Col> */}

                      <Col xs={12}>
                        <Form.Group controlId="assigneeId">
                          <Form.Label>Assign To</Form.Label>
                          <Form.Control name="assigneeId" type="text" readOnly onChange={handleChange} onBlur={handleBlur} value={(taskAssigneeData && taskAssigneeData[0].fullName)} />
                          {errors.assigneeId && touched.assigneeId && <Form.Text className="text-danger">{errors.assigneeId}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group controlId="scheduledAt">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Schedule For'
                            showTimeSelect
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='MMMM d, yyyy hh:mm a'
                            name='scheduledAt'
                            value={values.scheduledAt}
                            onChange={setFieldValue}
                          />
                          {errors.scheduledAt && touched.scheduledAt && <Form.Text className="text-danger">{errors.scheduledAt}</Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12} className="mt-2 text-right">
                        <Button className="mr-2" variant="secondary" onClick={this.handleAddTaskButtonClick}>
                          Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>

                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(MyTasksCard);
