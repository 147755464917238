import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from '../../../partials/content/Portlet';
import { Row, Col } from 'react-bootstrap';
import { LeaseTerminationsServices } from './LeaseTerminations.services'
import { formatDate } from '../../../../_metronic/utils/utils';
import * as constants from '../../../constants';
import * as firebase from 'firebase/app';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import moment from 'moment';

class ExpiringLeases extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            totalCount: undefined,
            isFetching: false,
            page: 1,
            total: undefined,
            offset: 0,
            limit: 10,
            showLeaseExtensionModal: false,
            showInitiateMoveOutModal: false,
        }
    }

    componentDidMount() {
        this.getLeasesList();
    }

    getLeasesList = async () => {
        this.setState({ isFetching: true });
        const { offset, limit } = this.state;
        let maximumLeaseEndDate = moment().format('YYYY-MM-DD');
        maximumLeaseEndDate = moment().add(45, 'd').format('YYYY-MM-DD');
        const data = {
            /**
             * maximumLeaseEndDate - '2020-09-16' (current + 45 days)
             * isMoveOutInitiated - 'FALSE'
             * isLeaseExtensionInitiated - 'FALSE'
             */
            maximumLeaseEndDate,
            isMoveOutInitiated: 'FALSE',
            isLeaseExtensionInitiated: 'FALSE',
            offset,
            limit,
            include: ['HOUSE', 'PROPERTY_MANAGER']
        }

        const result = await LeaseTerminationsServices.getLeasesList(data);
        if (result && result.status === 200) {
            const totalCount = result.headers['x-total-count'];
            const totalPageNo = Math.ceil(totalCount / limit)
            await this.setState({
                leases: result.data.leases,
                total: totalPageNo === 0 ? 1 : totalPageNo,
                totalCount
            });
        }
        this.setState({ isFetching: false });
    }

    getLeaseById = async (id) => {
        const data = {
            taskGroupName: 'LEASE_TERMINATION',
            noteGroupName: 'LEASE_TERMINATION',
            include: ['HOUSE_OWNERS', 'MOVE_IN_MANAGER', 'NOTES', 'TASKS', 'TENANTS']
        }
        const result = await LeaseTerminationsServices.getLeaseById(id, data);
        if (result && result.status === 200) {
            await this.setState({ accordionData: result.data.lease });
            // await this.setState({ toBeEdited: result.data.serviceRequest });
        }
    }

    handleAccordionClick = async (id) => {
        const { prevId } = this.state;
        if (prevId !== id) {
            this.setState({ prevId: id });
            await this.getLeaseById(id);
            // await this.getStakeHolderData();
            // await this.getTaskAssignee();
            firebase.analytics().logEvent('lease_terminations_lease_details_view_click', { description: 'Lease Terminations Lease Details View Clicked', leaseId: id });
        }
    }

    onPageChange = async (page) => {
        const { limit } = this.state;
        const offset = (limit * (page - 1));
        await this.setState({ page, offset });
        await this.getLeasesList();
    }

    handleChildData = async (childData) => {
        await this.getLeaseById(childData);
    }

    handleLeaseExtensionButtonClick = async () => {
        await this.setState(state => ({ showLeaseExtensionModal: !state.showLeaseExtensionModal }));
    }

    handleInitiateMoveOutButtonClick = async () => {
        await this.setState(state => ({ showInitiateMoveOutModal: !state.showInitiateMoveOutModal }));
    }

    render() {
        const {
            leases, page, total, totalCount, 
            isFetching,
            // requestedFilters,
        } = this.state;
        let showPagination = false;
        if (total) { showPagination = total !== 0 && total !== 1; }
        let taskAssigneeData = [];
        taskAssigneeData.push(this.props.userDetails)
        if (isFetching) {
            return <p>Loading data...</p>
        }
        return (
            <>
                <Portlet fluidHeight={true}>
                    <PortletHeader
                        title={`Expiring Leases (${totalCount || 0})`}
                        toolbar={
                            <PortletHeaderToolbar>
                                {/* <FilterComponent requestedFilters={requestedFilters} filterParentCallback={this.handleFilterChildData}
                                    state={state}
                                /> */}
                            </PortletHeaderToolbar>
                        }
                    />
                    <PortletBody >
                        {leases && leases.length === 0 && <p>{constants.ZERO_DATA}</p>}
                        <Row style={{display: 'block'}}>

                        {leases && leases.length > 0 && leases.map(lease =>
                            <Link key={lease.id} to={lease && `/lease-profile/${lease.id}`}>
                                <Col xs={12}>
                                    <p className="mb-0">{lease.house.formattedHouseName}</p>
                                    <p  className='small mb-0 text-muted'>{lease.house.propertyManager ? `PM: ${lease.house.propertyManager && lease.house.propertyManager.fullName} ` : `PM: Not Assigned `}</p>
                                    <p  className='font-weight-bold  mb-0  text-muted'>{lease.leaseEndDate  ? `End Date: ${formatDate(lease.leaseEndDate)}`: `Last Date: Not found`}</p>
                                    <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                </Col>
                            </Link>
                            )}
                            </Row>

                        {showPagination && showPagination && <UltimatePagination
                                currentPage={page}
                                totalPages={total}
                                onChange={this.onPageChange}
                                boundaryPagesRange={0}
                                hidePreviousAndNextPageLinks={true}
                                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                            />}
                    </PortletBody>
                </Portlet>
            </>
        )
    }
}

const mapStateToProps = state => ({
    serviceRequestCategories: state.auth && state.auth.staticData && state.auth.staticData.serviceRequests.categories,
    userDetails: state.auth && state.auth.user
});

export default
    connect(
        mapStateToProps,
        null
    )(ExpiringLeases);
