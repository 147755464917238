import React, { Component } from "react";
// import QuickStatsChart from "../../widgets/QuickStatsChart";
// import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
// import OrdersWidget from "../../widgets/OrdersWidget";
// import SalesBarChart from "../../widgets/SalesBarChart";
// import DownloadFiles from "../../widgets/DownloadFiles";
// import NewUsers from "../../widgets/NewUsers";
// import LatestUpdates from "../../widgets/LatestUpdates";
// import BestSellers from "../../widgets/BestSellers";
// import RecentActivities from "../../widgets/RecentActivities";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { Container, Row, Col, Table, Button, Dropdown, DropdownButton, Badge } from 'react-bootstrap';
// import StatsCard from '../../partials/layout/StatsCard.js';
import MyTasksCard from '../../partials/layout/MyTasksCard.js';
import { HomePageServices } from './HomePage/HomePage.services';
import { PendingOnboardingServices } from './PendingOnboardings/PendingOnboarding.services';
import * as constants from '../../constants';
import UltimatePagination from '../../partials/layout/PaginationComponent';
import AdminAssignments from '../../partials/layout/AdminAssignments';
import { commonServices } from '../../../app/commonServices/commonServices';
import FilterComponent from '../../partials/layout/FilterComponent';
import { formatMultipleUsers } from '../../../_metronic/utils/utils';
import AddLeaseComponent from '../../partials/layout/AddLeaseComponent';
import ToggleHoldOnboarding from '../../partials/layout/ToggleHoldOnboarding';
import RequestMoveIn from '../../partials/layout/RequestMoveIn';
import CloseOnboarding from '../../partials/layout/CloseOnboarding';

// const markOnboardingTerminalStatusSchema = Yup.object().shape({
//   houseOffering: Yup.object().shape({
//     onboardingTerminalStatus: Yup.string().required('Onboarding Terminal status is a required field')
//   })
// });

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAssignPMModal: false,
      unassignedLeaseDataPage: 1,
      unassignedLeaseDataTotal: undefined,
      unassignedLeaseDataOffset: 0,
      unassignedLeaseDataLimit: 10,
      assignedLeaseDataPage: 1,
      assignedLeaseDataTotal: undefined,
      assignedLeaseDataOffset: 0,
      assignedLeaseDataLimit: 10,
      unassignedOnboardingsDataPage: 1,
      unassignedOnboardingsDataTotal: undefined,
      unassignedOnboardingsDataOffset: 0,
      unassignedOnboardingsDataLimit: 10,
      houseDataPage: 1,
      houseDataTotal: undefined,
      houseDataOffset: 0,
      houseDataLimit: 10,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      taskPage: 1,
      taskTotal: undefined,
      taskOffset: 0,
      taskLimit: 10,
      state: ['OPEN'],
      terminalStatusForOnboarding: undefined,
      requestedFilters: ['state', 'terminalStatusForOnboarding'],
    }
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    if (state.accordionData?.terminalStatusForOnboarding !== props.houseInspectionWork?.terminalStatusForOnboarding) {
      result.houseInspectionWork = props.accordionData
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  async componentDidMount() {
    this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && await this.getUnassignedLeaseList();
    this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && await this.getUnassignedOnboardingsList();
    this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && await this.getUnassignedHouseList();
    await this.getAssignedLeaseList();
    await this.getTaskList();
    await this.getCities();
    await this.getMoveInManagers();
    // await this.getPropertyManagers();
  }

  // getPropertyManagers = async () => {
  //   const data = {
  //     userRoleCodes: ['PROPERTY_MANAGER'],
  //   }
  //   const result = await commonServices.getUserListData(data);
  //   if (result && result.status === 200) {
  //     await this.setState({ propertyManagers: result.data.users })
  //   }
  // }

  getMoveInManagers = async () => {
    const data = {
      userRoleCodes: ['MOVE_IN_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ moveInManagers: result.data.users })
    }
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  getUnassignedLeaseList = async () => {
    const { unassignedLeaseDataOffset, unassignedLeaseDataLimit, cityIds } = this.state;
    // const include = ['DEAL_CLOSURE_DATE', 'LEASE_TERMS_MOVE_IN_DATE', 'HOUSE', 'OWNERS', 'TENANTS', 'RENT_PERIODS'];
    // const state = false;
    const data = {
      cityIds,
      moveInManagerAssigned: 'FALSE',
      moveInState: 'OPEN',
      include: ['DEAL_CLOSURE_DATE', 'LEASE_TERMS_MOVE_IN_DATE', 'HOUSE', 'OWNERS', 'TENANTS', 'RENT_PERIODS'],
      offset: unassignedLeaseDataOffset,
      limit: unassignedLeaseDataLimit
    }
    const unAssignedLeaseList = await HomePageServices.getLeaseListData(data);
    if (unAssignedLeaseList && unAssignedLeaseList.status === 200) {
      const totalUnassignedLeaseCount = unAssignedLeaseList.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalUnassignedLeaseCount / unassignedLeaseDataLimit);
      await this.setState({
        unassignedLeaseListData: unAssignedLeaseList.data.leases,
        unassignedLeaseDataTotal: totalPageNo === 0 ? 1 : totalPageNo,
        totalUnassignedLeaseCount
      })
    }
  }

  onUnassignedLeaseDataPageChange = async (unassignedLeaseDataPage) => {
    const { unassignedLeaseDataLimit } = this.state;
    const unassignedLeaseDataOffset = (unassignedLeaseDataLimit * (unassignedLeaseDataPage - 1));
    await this.setState({ unassignedLeaseDataPage, unassignedLeaseDataOffset });
    await this.getUnassignedLeaseList();
  }

  getAssignedLeaseList = async () => {
    const { assignedLeaseDataOffset, assignedLeaseDataLimit, cityIds, propertyManagerId } = this.state;
    // const include = ['DEAL_CLOSURE_DATE', 'LEASE_TERMS_MOVE_IN_DATE', 'HOUSE', 'OWNERS', 'TENANTS', 'RENT_PERIODS'];
    // const state = false;
    const data = {
      cityIds,
      moveInManagerId: this.props.userDetails.userRoleCodes.includes('MOVE_IN_MANAGER') ? this.props.userDetails.id : undefined,
      propertyManagerId,
      moveInManagerAssigned: this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') ? 'TRUE' : undefined,
      moveInState: 'OPEN',
      include: ['DEAL_CLOSURE_DATE', 'LEASE_TERMS_MOVE_IN_DATE', 'HOUSE', 'OWNERS', 'TENANTS', 'RENT_PERIODS', 'MOVE_IN_MANAGER'],
      offset: assignedLeaseDataOffset,
      limit: assignedLeaseDataLimit
    }
    const assignedLeaseList = await HomePageServices.getLeaseListData(data);
    if (assignedLeaseList && assignedLeaseList.status === 200) {
      const totalAssignedLeaseCount = assignedLeaseList.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalAssignedLeaseCount / assignedLeaseDataLimit);
      await this.setState({
        assignedLeaseListData: assignedLeaseList.data.leases,
        assignedLeaseDataTotal: totalPageNo === 0 ? 1 : totalPageNo,
        totalAssignedLeaseCount
      })
    }
  }

  onAssignedLeaseDataPageChange = async (assignedLeaseDataPage) => {
    const { assignedLeaseDataLimit } = this.state;
    const assignedLeaseDataOffset = (assignedLeaseDataLimit * (assignedLeaseDataPage - 1));
    await this.setState({ assignedLeaseDataPage, assignedLeaseDataOffset });
    await this.getAssignedLeaseList();
  }

  getUnassignedHouseList = async () => {
    const { houseDataOffset, houseDataLimit, cityIds } = this.state;
    // const propertyManagementStatus = 'YES';
    // const propertyManagerAssigned = false;
    // const include = ['LATEST_LEASE_MOVE_IN_MANAGER', 'CURRENT_RENT', 'PROPERTY_MANAGER', 'OCCUPANCY_STATUS', 'CURRENT_TENANTS', 'LATEST_LEASE', 'OWNERS', 'PMS_SUBSCRIPTIONS'];
    const data = {
      propertyManagementStatus: "YES",
      propertyManagerAssigned: this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') ? 'FALSE' : undefined,
      include: ['LATEST_LEASE_MOVE_IN_MANAGER', 'CURRENT_RENT', 'PROPERTY_MANAGER', 'OCCUPANCY_STATUS', 'CURRENT_TENANTS', 'LATEST_LEASE', 'OWNERS', 'PMS_SUBSCRIPTIONS'],
      offset: houseDataOffset,
      limit: houseDataLimit,
      cityIds
    }
    const adminList = await HomePageServices.getHouseListData(data);
    if (adminList && adminList.status === 200) {
      const totalHouseCount = adminList.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalHouseCount / houseDataLimit);
      await this.setState({
        houseListData: adminList.data.houses,
        houseDataTotal: totalPageNo === 0 ? 1 : totalPageNo,
        totalHouseCount
      })
    }
  }

  onHouseListDataPageChange = async (houseDataPage) => {
    const { houseDataLimit } = this.state;
    const houseDataOffset = (houseDataLimit * (houseDataPage - 1));
    await this.setState({ houseDataPage, houseDataOffset });
    await this.getUnassignedHouseList();
  }

  getUnassignedOnboardingsList = async () => {
    const { unassignedOnboardingsDataOffset, state, terminalStatusForOnboarding, unassignedOnboardingsDataLimit, cityIds, onboardingStatus, onboardingRequirementMarkedOn, beatsListingIdentifier } = this.state;
    const data = {
      propertyManagerAssigned: 'FALSE',
      onboardingRequirementStatus: 'YES',
      state: (state && state[0]) || undefined,
      terminalStatus: (terminalStatusForOnboarding && terminalStatusForOnboarding[0]) || undefined,
      include: ['OWNERS', 'PROPERTY_MANAGER', 'MOVE_IN_MANAGER'],
      offset: unassignedOnboardingsDataOffset,
      limit: unassignedOnboardingsDataLimit,
      cityIds,
      onboardingStatus,
      onboardingRequirementMarkedOn,
      beatsListingIdentifier,
    }
    const unAssignedOnboardingsList = await PendingOnboardingServices.getHouseOfferings(data);
    if (unAssignedOnboardingsList && unAssignedOnboardingsList.status === 200) {
      const totalUnassignedOnboardingsCount = unAssignedOnboardingsList.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalUnassignedOnboardingsCount / unassignedOnboardingsDataLimit);
      await this.setState({
        unassignedOnboardingsListData: unAssignedOnboardingsList.data.houseOfferings,
        unassignedOnboardingsDataTotal: totalPageNo === 0 ? 1 : totalPageNo,
        totalUnassignedOnboardingsCount
      })
    }
  }

  onUnassignedOnboardingsDataPageChange = async (unassignedOnboardingsDataPage) => {
    const { unassignedOnboardingsDataLimit } = this.state;
    const unassignedOnboardingsDataOffset = (unassignedOnboardingsDataLimit * (unassignedOnboardingsDataPage - 1));
    await this.setState({ unassignedOnboardingsDataPage, unassignedOnboardingsDataOffset });
    await this.getUnassignedOnboardingsList();
  }

  getTaskList = async () => {
    const { taskOffset, taskLimit, state } = this.state;
    const data = {
      state: (state && state[0]) || undefined,
      offset: taskOffset,
      limit: taskLimit
    }
    const taskList = await HomePageServices.getTaskList(data);
    if (taskList && taskList.status === 200) {
      const totalTaskCount = taskList.headers['x-total-count'];
      const totalPageNo = Math.ceil(taskList.headers['x-total-count'] / taskLimit);
      await this.setState({
        taskListData: taskList.data.tasks,
        taskTotal: totalPageNo === 0 ? 1 : totalPageNo,
        totalTaskCount
      })
    }
  }

  handleCardCallback = async (callbackSuccess, taskPage = undefined, taskOffset = undefined, state = undefined) => {
    if (callbackSuccess === true) {
      await this.setState({
        taskPage,
        taskOffset,
        state,
      })
      await this.getTaskList();
    }
  }

  handleAdminAssignmentsData = async (isReassign, leaseOrHouse) => {
    !isReassign && (leaseOrHouse === 'lease') && await this.getUnassignedLeaseList();
    !isReassign && (leaseOrHouse === 'lease') && await this.getAssignedLeaseList();
    !isReassign && (leaseOrHouse === 'house') && await this.getUnassignedHouseList();
    !isReassign && (leaseOrHouse === 'house') && await this.getUnassignedOnboardingsList();
  }

  handleFilterChildDataForUnassignedLeaseList = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.getUnassignedLeaseList();
    }
  }

  handleFilterChildDataForUnassignedHouseList = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.getUnassignedHouseList();
    }
  }

  handleFilterChildDataForUnassignedOnboardingsList = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.getUnassignedOnboardingsList();
    }
  }

  handleFilterChildDataForAssignedLeaseList = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.getAssignedLeaseList();
    }
  }

  render() {
    const {
      unassignedLeaseListData, unassignedLeaseDataPage, requestedFilters, state, terminalStatusForOnboarding, unassignedLeaseDataTotal, totalUnassignedLeaseCount,
      assignedLeaseListData, assignedLeaseDataPage, assignedLeaseDataTotal, totalAssignedLeaseCount, formattedCities, preSelectedCities,
      unassignedOnboardingsListData, unassignedOnboardingsDataPage, unassignedOnboardingsDataTotal, totalUnassignedOnboardingsCount, moveInManagers, 
      houseListData, houseDataPage, houseDataTotal, totalHouseCount,
      taskListData, taskPage, taskTotal, taskOffset, taskLimit, totalTaskCount, moveInManagerId, onboardingStatus, beatsListingIdentifier,
      // propertyManagers, propertyManagerId, onboardingRequirementMarkedOn,
    } = this.state;
    let showUnassignedLeaseDataPagination, showAssignedLeaseDataPagination, showHouseDataPagination, showTaskPagination, showUnassignedOnboardingsDataPagination = false;
    if (unassignedLeaseDataTotal) { showUnassignedLeaseDataPagination = unassignedLeaseDataTotal !== 0 && unassignedLeaseDataTotal !== 1; }
    if (assignedLeaseDataTotal) { showAssignedLeaseDataPagination = assignedLeaseDataTotal !== 0 && assignedLeaseDataTotal !== 1; }
    if (unassignedOnboardingsDataTotal) { showUnassignedOnboardingsDataPagination = unassignedOnboardingsDataTotal !== 0 && unassignedOnboardingsDataTotal !== 1; }
    if (houseDataTotal) { showHouseDataPagination = houseDataTotal !== 0 && houseDataTotal !== 1; }
    if (taskTotal) { showTaskPagination = taskTotal !== 0 && taskTotal !== 1; }
    return (
      <>
        {/* <Jumbotron fluid>
          <Container>
            <h1>Good Morning {this.props.userDetails.fullName}!</h1>
            <p>Showing all the tasks and activities due for you today!</p>
          </Container>
        </Jumbotron> */}
        <Container fluid>
          <Row>
            <Col xs={12} md={8}>
              {/* <Row id="azuro-stats" className="mb-3">
                {stats && stats.map(stat =>
                  <Col xs={6} key={stat.id}>
                    <StatsCard stat={stat} />
                  </Col>
                )
                }
              </Row> */}
              <Row id="azuro-tables">
                <Col xs={12}>
                  {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                    <Portlet fluidHeight={true}>
                      <PortletHeader title={`Unassigned Move-ins (${totalUnassignedLeaseCount || 0})`} />
                      <PortletBody className="p-3">
                        <FilterComponent
                          formattedCities={formattedCities}
                          preSelectedCities={preSelectedCities}
                          filterParentCallback={this.handleFilterChildDataForUnassignedLeaseList}
                        />
                        {!unassignedLeaseListData && <p className="mb-0">{constants.LOADING_DATA}</p>}
                        {unassignedLeaseListData && unassignedLeaseListData.length === 0 && <p className="mb-0">{constants.ZERO_DATA}</p>}
                        {unassignedLeaseListData && unassignedLeaseListData.length > 0 &&
                          <>
                            <Table responsive striped borderless hover>
                              <thead>
                                <tr>
                                  <th scope="col">Property Name</th>
                                  <th scope="col">Deal Closure Date</th>
                                  <th scope="col">Expected Move-in Date</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  unassignedLeaseListData.length > 0 && unassignedLeaseListData.map((val =>
                                    <tr key={val.id}>
                                      <td>
                                        <Link to={`/lease-profile/${val.id}`} title="Open Lease Profile">
                                          {val.house?.formattedHouseName}
                                        </Link>
                                      </td>
                                      <td>{val.dealClosureDate}</td>
                                      <td>{val.leaseTermsMoveInDate || ''}</td>
                                      <td className="py-0 pt-1">
                                        {/* <Button variant="clean" size="sm" onClick={() => this.handleAssignPMButtonClick(val.id, "leaseAssignment")}>Assign</Button> */}
                                        <AdminAssignments leaseId={val.id} isReassign={false}
                                          adminAssignmentsParent={this.handleAdminAssignmentsData}
                                          title={'Assign MIM'}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                            {/* Code for pagination */}
                            {showUnassignedLeaseDataPagination && showUnassignedLeaseDataPagination && <UltimatePagination
                              currentPage={unassignedLeaseDataPage}
                              totalPages={unassignedLeaseDataTotal}
                              onChange={this.onUnassignedLeaseDataPageChange}
                              boundaryPagesRange={0}
                              hidePreviousAndNextPageLinks={true}
                              hideFirstAndLastPageLinks={unassignedLeaseDataPage !== 1 && unassignedLeaseDataPage !== unassignedLeaseDataTotal ? false : true}
                            />}
                          </>
                        }
                      </PortletBody>
                    </Portlet>
                  }
                </Col>
                <Col xs={12}>
                  {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                    <Portlet fluidHeight={true}>
                      <PortletHeader title={`Unassigned Properties (${totalHouseCount || 0})`} />
                      <PortletBody className="p-3">
                        <FilterComponent
                          formattedCities={formattedCities}
                          preSelectedCities={preSelectedCities}
                          filterParentCallback={this.handleFilterChildDataForUnassignedHouseList}
                        />
                        {!houseListData && <p className="mb-0">{constants.LOADING_DATA}</p>}
                        {houseListData && houseListData.length === 0 && <p className="mb-0">{constants.ZERO_DATA}</p>}
                        {houseListData && houseListData.length > 0 &&
                          <>
                            <Table responsive striped borderless hover>
                              <thead>
                                <tr>
                                  <th scope="col">Property Name</th>
                                  <th scope="col">Deal Closure Date</th>
                                  <th scope="col">Move-in Date</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  houseListData.length > 0 && houseListData.map((val =>
                                    <tr key={val.id}>
                                      <td>
                                        <Link to={`/property-profile/${val.id}`}>
                                          {val.formattedHouseName}
                                        </Link>
                                      </td>
                                      <td>{val.latestLease ? val.latestLease.dealClosureDate : ''}</td>
                                      <td>{val.latestLease ? val.latestLease.moveInDate : ''} </td>
                                      <td className="py-0 pt-1">
                                        {/* <Button variant="clean" size="sm" onClick={() => this.handleAssignPMButtonClick(val.id, "houseAssignment")}>Assign</Button> */}
                                        <AdminAssignments houseId={val.id} isReassign={false}
                                          adminAssignmentsParent={this.handleAdminAssignmentsData}
                                          title={'Assign PM'}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                            {/* Code for pagination */}
                            {showHouseDataPagination && showHouseDataPagination && <UltimatePagination
                              currentPage={houseDataPage}
                              totalPages={houseDataTotal}
                              onChange={this.onHouseListDataPageChange}
                              boundaryPagesRange={0}
                              hidePreviousAndNextPageLinks={true}
                              hideFirstAndLastPageLinks={houseDataPage !== 1 && houseDataPage !== houseDataTotal ? false : true}
                            />}
                          </>
                        }
                      </PortletBody>
                    </Portlet>
                  }
                </Col>
                <Col xs={12}>
                  {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                    <Portlet fluidHeight={true}>
                      <PortletHeader title={`Unassigned Onboardings (${totalUnassignedOnboardingsCount || 0})`} />
                      <PortletBody className="p-3">
                        <FilterComponent
                          formattedCities={formattedCities}
                          state={state}
                          preSelectedCities={preSelectedCities}
                          terminalStatusForOnboarding={terminalStatusForOnboarding}
                          requestedFilters={requestedFilters}
                          // propertyManagers={propertyManagers}
                          // propertyManagerId={propertyManagerId}
                          // moveInManagers={moveInManagers}
                          // moveInManagerId={moveInManagerId}
                          onboardingStatus={onboardingStatus}
                          // onboardingRequirementMarkedOn={onboardingRequirementMarkedOn}
                          beatsListingIdentifier={beatsListingIdentifier}
                          filterParentCallback={this.handleFilterChildDataForUnassignedOnboardingsList}
                        />
                        {!unassignedOnboardingsListData && <p className="mb-0">{constants.LOADING_DATA}</p>}
                        {unassignedOnboardingsListData && unassignedOnboardingsListData.length === 0 && <p className="mb-0">{constants.ZERO_DATA}</p>}
                        {unassignedOnboardingsListData && unassignedOnboardingsListData.length > 0 &&
                          <>
                            <Table responsive striped borderless hover>
                              <thead>
                                <tr>
                                  <th scope="col">Property</th>
                                  <th scope="col">City</th>
                                  <th scope="col">Owners</th>
                                  <th scope="col">Onboarding Status</th>
                                  <th scope="col">Lease Status</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  unassignedOnboardingsListData.length > 0 && unassignedOnboardingsListData.map((val =>
                                    <tr key={val.id}>
                                      <td>
                                        <Link to={`/property-profile/${val.house?.id}`}>
                                          {val.house?.formattedHouseName}
                                        </Link>
                                      </td>
                                      <td>{val.house?.society?.locality?.city?.name}</td>
                                      <td>{val.house ? formatMultipleUsers(val.house?.owners || []) : ''} </td>
                                      <td>
                                        <p>{val.onboardingStatus === "HOLD" ? `On hold ${"(Reason: " + val.onboardingStatusRemarks + ")"}` : 'Pending'}</p>
                                        {/* <p className="small mt-2 pt-0 mb-0 text-muted">{`on {"dd/mm/yyyy"}`}</p> */}
                                      </td>
                                      <td>
                                        <p className="pt-0 mb-0">{val.lease !== null ? "Added" : "Not Added"}</p>{val.lease !== null && <><p className="small mt-2 pt-0 mb-0 text-muted">tenant found by azuro: {val.lease?.tenantFoundByAzuro === true ? "Yes" : "No"}</p><p className="small mt-2 pt-0 mb-0 text-muted"> {val.moveInManager && `MIM: ${val.moveInManager?.fullName}`}</p></>}
                                      </td>
                                      <td>
                                        <DropdownButton id="dropdown-item-button" variant="clean" title="Choose Action">
                                          {val?.onboardingStatus === null && <Dropdown.Item as={ToggleHoldOnboarding} dropdownItem={true} houseOffering={val} unassignedOnboardingsCallback={this.getUnassignedOnboardingsList}></Dropdown.Item>}
                                          {val?.onboardingStatus === 'HOLD' && <Dropdown.Item as={ToggleHoldOnboarding} dropdownItem={true} houseOffering={val} unassignedOnboardingsCallback={this.getUnassignedOnboardingsList}></Dropdown.Item>}
                                          {val?.onboardingStatus === null && val?.lease === null && <Dropdown.Item as={AddLeaseComponent} dropdownItem={true} houseId={val.house.id} pendingOnboardingsCallback={this.getUnassignedOnboardingsList} ></Dropdown.Item>}
                                          {val?.onboardingStatus === null && val.lease === null && <Dropdown.Item as={RequestMoveIn} dropdownItem={true} houseId={val.house.id} unassignedOnboardingsCallback={this.getUnassignedOnboardingsList}></Dropdown.Item>}
                                          {val?.onboardingStatus === null && <Dropdown.Item as={AdminAssignments} dropdownItem={true} houseId={val.house?.id} isReassign={false} adminAssignmentsParent={this.handleAdminAssignmentsData} title={'Assign PM'}></Dropdown.Item>}
                                          {val?.onboardingStatus === null && val?.lease !== null && !val.moveInManager && <Dropdown.Item as={AdminAssignments} dropdownItem={true} leaseId={val.lease?.id} isReassign={false} adminAssignmentsParent={this.handleAdminAssignmentsData} title={'Assign MIM'}></Dropdown.Item>}
                                          {val?.onboardingStatus !== 'HOLD' && <Dropdown.Item as={CloseOnboarding} dropdownItem={true} houseOffering={val} unassignedOnboardingsCallback={this.getUnassignedOnboardingsList}></Dropdown.Item>}
                                        </DropdownButton>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                            {showUnassignedOnboardingsDataPagination && showUnassignedOnboardingsDataPagination && <UltimatePagination
                              currentPage={unassignedOnboardingsDataPage}
                              totalPages={unassignedOnboardingsDataTotal}
                              onChange={this.onUnassignedOnboardingsDataPageChange}
                              boundaryPagesRange={0}
                              hidePreviousAndNextPageLinks={true}
                              hideFirstAndLastPageLinks={unassignedOnboardingsDataPage !== 1 && unassignedOnboardingsDataPage !== unassignedOnboardingsDataTotal ? false : true}
                            />}
                          </>
                        }
                      </PortletBody>
                    </Portlet>
                  }
                </Col>
                <Col xs={12}>
                  {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') || this.props.userDetails.userRoleCodes.includes('MOVE_IN_MANAGER')) &&
                    <Portlet fluidHeight={true}>
                      <PortletHeader
                        title={`Pending Move-ins (${totalAssignedLeaseCount || 0})`}
                        toolbar={
                          <PortletHeaderToolbar>
                            {/* <Button variant="clean" size="sm" onClick={this.handleClick}><i className="la-filter"></i></Button> */}
                          </PortletHeaderToolbar>
                        }
                      />
                      <PortletBody className="p-3">
                        {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                          <FilterComponent
                            formattedCities={formattedCities}
                            preSelectedCities={preSelectedCities}
                            moveInManagers={moveInManagers}
                            moveInManagerId={moveInManagerId}
                            filterParentCallback={this.handleFilterChildDataForAssignedLeaseList}
                          />}
                        {!assignedLeaseListData && <p className="mb-0">{constants.LOADING_DATA}</p>}
                        {assignedLeaseListData && assignedLeaseListData.length === 0 && <p className="mb-0">{constants.ZERO_DATA}</p>}
                        {assignedLeaseListData && assignedLeaseListData.length > 0 &&
                          <>
                            <Table responsive striped borderless hover>
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Property Name</th>
                                  <th scope="col">Deal Closure Date</th>
                                  <th scope="col">Expected Move-in Date</th>
                                  {/* <th scope="col">Pending Works</th> */}
                                  {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <th scope="Col">Move In Manager</th>}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  assignedLeaseListData.length > 0 && assignedLeaseListData.map((val =>
                                    <tr key={val.id}>
                                      <th scope="row"><Badge variant="warning">O</Badge></th>
                                      <td>{val.house?.formattedHouseName}</td>
                                      <td>{val.dealClosureDate}</td>
                                      <td>{val.leaseTermsMoveInDate || ''}</td>
                                      {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <td>{val.moveInManager && val.moveInManager.fullName}</td>}
                                      <td>
                                        <Link to={`/lease-profile/${val.id}`}>
                                          <Button size='sm' variant='clean'>Open lease</Button>
                                        </Link>
                                      </td>
                                    </tr>

                                  ))
                                }
                              </tbody>
                            </Table>
                            {/* Code for pagination */}
                            {showAssignedLeaseDataPagination && showAssignedLeaseDataPagination && <UltimatePagination
                              currentPage={assignedLeaseDataPage}
                              totalPages={assignedLeaseDataTotal}
                              onChange={this.onAssignedLeaseDataPageChange}
                              boundaryPagesRange={0}
                              hidePreviousAndNextPageLinks={true}
                              hideFirstAndLastPageLinks={assignedLeaseDataPage !== 1 && assignedLeaseDataPage !== assignedLeaseDataTotal ? false : true}
                            />}
                          </>
                        }
                      </PortletBody>
                    </Portlet>
                  }
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              {
                // !taskListData && <p>{constants.LOADING_DATA}</p>
              }
              {
                // taskListData && taskListData.length === 0 && <p>{constants.ZERO_DATA}</p>
              }
              {
                // taskListData && taskListData.length > 0 &&
                <MyTasksCard tasks={taskListData} homepageCallback={this.handleCardCallback}
                  taskPage={taskPage && taskPage}
                  taskTotal={taskTotal && taskTotal}
                  taskOffset={taskOffset && taskOffset}
                  tasklimit={taskLimit && taskLimit}
                  totalTaskCount={totalTaskCount && totalTaskCount}
                  showTaskPagination={showTaskPagination && showTaskPagination}
                />
              }
            </Col>
          </Row>
        </Container>


      </>
    )
  };

}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(Dashboard);
