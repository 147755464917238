import React, { Component } from "react";
import {
    Button, Col, Modal, Form, Row
} from 'react-bootstrap';
import { Formik } from 'formik';
import { store } from 'react-notifications-component';
import { leaseProfileServices } from "../../pages/home/LeaseProfilePage/LeaseProfilePage.services";

export default class GenerateUserCredentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showGenerateUserCredentialsModal: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        let result = {}
        if (props.user !== state.user) {
            result.user = props.user;
        }
        if((props.user === state.user) && props.user.isPasswordSet !== state.user.isPasswordSet){
            result.user = props.user;
        }
        return Object.keys(result).length > 0 ? result : null;
    }

    /**
     * Method th handle Generate User Credentials Buttion click
     * @param {Number} userId
     */
    handleGenerateUserCredentials = (userId = null) => {

        userId !== null && this.setState({ generatePasswordFor: userId })

        this.setState(state => ({
            showGenerateUserCredentialsModal: !state.showGenerateUserCredentialsModal
        }));

    }

    render() {
        const { user, generatePasswordFor, isLeaseProfile, isPropertyProfile, generateUserCredentialsParentCallback } = this.state;
        return (
            <>
                {user?.isPasswordSet === false &&
                    <Button variant="clean" size="sm" title="Generate Login Credentials" onClick={() => this.handleGenerateUserCredentials(user.id)}>Generate Login Credentials</Button>
                }

                {user?.isPasswordSet === true &&
                    <p><strong>Password already set.</strong></p>
                }

                <Modal backdrop="static" keyboard={false} key={'m' + 15} show={this.state.showGenerateUserCredentialsModal} onHide={this.handleGenerateUserCredentials}>
                    <Modal.Header>
                        <Modal.Title> {`Generate Login Credentials`} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                generatePasswordFor
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {
                                    const result = await leaseProfileServices.generateUserCredentials(values?.generatePasswordFor);
                                    if (result?.status === 201) {
                                        store.addNotification({
                                            title: "Success!",
                                            message: `User Login Credentials Generated`,
                                            type: "success",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animated", "fadeIn"],
                                            animationOut: ["animated", "fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true,
                                                showIcon: true,
                                                pauseOnHover: true
                                            }
                                        });
                                        this.setState(state => ({ showGenerateUserCredentialsModal: !state.showGenerateUserCredentialsModal }));
                                        if (isLeaseProfile === true) {
                                            await generateUserCredentialsParentCallback('LeaseProfile');
                                        } else if (isPropertyProfile === true) {
                                            await generateUserCredentialsParentCallback('PropertyProfile')
                                        }
                                        // await this.getLeaseParties();
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        {/* <pre>{JSON.stringify(values)}</pre> */}
                                        <Row>
                                            <Col xs={12}>
                                                <p>
                                                    <strong>
                                                        {`Confirm generation of Login credentials?`}
                                                    </strong>
                                                </p>
                                            </Col>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={this.handleGenerateUserCredentials}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Generating credentials...' : 'Yes'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}
