export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: [
    ]
  }
};
