import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Badge, Accordion, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { pmsRenewalServices } from './PMSRenewal.services';
import NotesComponent from '../../../partials/layout/NotesComponent.js';
import * as constants from '../../../constants';
import TasksListComponent from "../../../partials/layout/TasksListComponent.js";
import { connect } from 'react-redux';
import { formatDate, formatMultipleUsers, findPrimary } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import * as firebase from 'firebase/app';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import { Link } from 'react-router-dom';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { commonServices } from '../../../../app/commonServices/commonServices';

const renewPMSSubscriptionSchema = Yup.object().shape({
  startDate: Yup.date().required('Start Date is a mandatory field'),
  endDate: Yup.date().required('End date is a mandatory field'),
  // feeRateQuantity: Yup.number().required('Rate is a mandatory field'),
  // feeRateUnit: Yup.string().required('Rate Unit is a mandatory field'),
  // feePaymentInterval: Yup.string().required('Payment Interval is a mandatory field'),
  // feePaymentMechanism: Yup.string().required('Payment Method is a mandatory field'),
  planSelected: Yup.string().required('Choosing a plan is mandatory'),
  tcfIdentifier: Yup.string()
    .when('feeRateQuantity', {
      is: (feeRateQuantity) => feeRateQuantity && feeRateQuantity > 0,
      then: Yup.string()
        .required('TCF ID is required')
    }),
  file: Yup.mixed()
    .when('feeRateQuantity', {
      is: (feeRateQuantity) => feeRateQuantity > 0,
      then: Yup.mixed().required('Service Agreement File is required.')
    }),
});

const cancelPMSSubscriptionSchema = Yup.object().shape({
  pmsRenewalWork: Yup.object().shape({
    terminalRemarks: Yup.string().optional(),
    terminalRemarksCategory: Yup.string().required('Remarks category is a mandatory field')
  })
})

class PMSRenewals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showCancelPMSSubscriptionModal: false,
      showRenewPMSSubscriptionModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      state: !props.houseIdFromPropertyProfile ? ['OPEN'] : undefined,
      terminalStatusForRenewal: undefined,
      propertyManagementStatus: ['YES'],
      requestedFilters: ['state', 'terminalStatusForRenewal', 'propertyManagementStatus']

    }
  }

  static getDerivedStateFromProps(props, state) {
    let result = {}
    if (state.accordionData?.terminalStatusForRenewal !== props.houseInspectionWork?.terminalStatusForRenewal) {
      result.houseInspectionWork = props.accordionData
    }
    return Object.keys(result).length > 0 ? result : null;
  }

  async componentDidMount() {
    await this.getAllPMSRenewalWorks();
    await this.getCities();
    await this.getPropertyManagers();
  }

  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  getAllPMSRenewalWorks = async () => {
    this.setState({ isFetching: true })
    const { offset, limit, terminalStatusForRenewal, houseIdFromPropertyProfile, cityIds, minimumSourcePmsSubscriptionEndDate,
      maximumSourcePmsSubscriptionEndDate, maximumLatestNoteCreatedAt, propertyManagerId, state, propertyManagementStatus } = this.state;
    const data = {
      state: (state && state[0]) || undefined,
      terminalStatus: (terminalStatusForRenewal && terminalStatusForRenewal[0]) || undefined,
      cityIds,
      minimumSourcePmsSubscriptionEndDate,
      maximumSourcePmsSubscriptionEndDate, maximumLatestNoteCreatedAt,
      propertyManagementStatus: (propertyManagementStatus && propertyManagementStatus[0]) || undefined,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      houseId: houseIdFromPropertyProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
    }
    const result = await pmsRenewalServices.getAllPMSRenewalWorks(data)
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(result.headers['x-total-count'] / limit);
      await this.setState({
        pmsRenewals: result.data.pmsRenewalWorks,
        total: (totalPageNo === 0) ? 1 : totalPageNo,
        totalCount
      })
      if (houseIdFromPropertyProfile) {
        if (this.state.pmsRenewals) {
          this.state.pmsRenewals.forEach((pmsRenewal) => {
            if (pmsRenewal.state === "OPEN") {
              this.props.updateNewPMSSubscriptionButtonStatus(true);
            }

          })
        }
      }
    }
    this.setState({ isFetching: false })
  }

  getPMSRenewalWorkById = async (id) => {
    const result = await pmsRenewalServices.getPMSRenewalWorkById(id)
    if (result && result.status === 200) {
      await this.setState({ accordionData: result.data.pmsRenewalWork })
      this.state.pmsRenewals.forEach(renew => {
        if (this.state.accordionData?.id === renew?.id) {
          renew.notes = this.state.accordionData.notes;
          renew.notes.reverse();
        }
      })
      this.forceUpdate();
    }
  }

  getPMSSubscriptionStatusBadge = (renewalStatus) => {
    if (renewalStatus === 'OPEN') return <Badge variant="success" title="Open">O</Badge>
    if (renewalStatus === 'DISCONTINUED') return <Badge variant="danger" title="Cancelled">C</Badge>
    if (renewalStatus === 'RENEWED') return <Badge variant="info" title="Renewed">R</Badge>
  }

  handleCancelPMSSubscriptionButtonClick = async () => {
    await this.setState(state => ({ showCancelPMSSubscriptionModal: !state.showCancelPMSSubscriptionModal }));
    if (this.state.showCancelPMSSubscriptionModal) {
      firebase.analytics().logEvent("cancel_pms_subscription_button_click", { description: "Cancel PMS Subscription Button Clicked" });
    }
  }

  handleRenewPMSSubscriptionButtonClick = () => {
    this.setState(state => ({ showRenewPMSSubscriptionModal: !state.showRenewPMSSubscriptionModal }));
    if (this.state.showCancelPMSSubscriptionModal) {
      firebase.analytics().logEvent("renew_pms_subscription_button_click", { description: "Renew PMS Subscription Button Clicked" });
    }
  }

  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getPMSRenewalWorkById(id);
      firebase.analytics().logEvent("pms_renewals_details_view_click", { description: "PMS Renewals Details View Clicked", pmsRenewalId: id });
    }
  }

  handleChildData = async (renewalWorkId) => {
    await this.getPMSRenewalWorkById(renewalWorkId);
  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllPMSRenewalWorks();
  }

  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData });
      await this.resetPage();
      await this.getAllPMSRenewalWorks();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'PMS Renewals' });
    }
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  validateTCFId = (currentText) => {
    let error;
    if (currentText && currentText.length === 17) {
      let prefixPart = currentText.substr(0, 4);
      if (prefixPart === 'TCF-') {
        let datePart = currentText.substr(4, 8);
        let yearPart = datePart.substr(0, 4);
        let monthPart = datePart.substr(4, 2);
        let dayPart = datePart.substr(6, 2);
        let tcfDate = new Date(yearPart, (Number(monthPart) - 1), dayPart);
        let currentDate = new Date();
        if (tcfDate && (tcfDate <= currentDate)) {
          console.log("seems fine");
          let finalSerialPartSeparator = currentText.substr(12, 1);
          if (finalSerialPartSeparator === "-") {
            let finalSerialPart = currentText.substr(13, 4);
            if (Number(finalSerialPart)) {
              console.log("TCF ID seems fine");
            } else {
              error = "TCF ID is invalid";
            }
          } else {
            error = "TCF ID is invalid";
          }
        } else {
          error = "TCF ID is invalid";
        }
      } else {
        error = "TCF ID is invalid";
      }
    } else {
      error = "TCF ID should be 17 characters long";
    }
    return error;
  }

  handlePlanChange = async (e, setFieldValue, validateForm) => {
    if (e.target.validity.valid) {
      switch (e.target.value) {
        case 'freePlan':
          setFieldValue('feeRateQuantity', '0');
          setFieldValue('feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[0]);
          break;
        case 'eightPercentPlan':
          setFieldValue('feeRateQuantity', '8');
          setFieldValue('feeRateUnit', constants.FEE_RATE_UNIT[0]);
          setFieldValue('feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'fixedAmountFromRentPlan':
          setFieldValue('feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'customPlan':
          setFieldValue('feeRateUnit', constants.FEE_RATE_UNIT[0]);
          setFieldValue('feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[0]);
          setFieldValue('feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[2]);
          break;
        case 'fullAmountPlan':
          setFieldValue('feeRateUnit', constants.FEE_RATE_UNIT[1]);
          setFieldValue('feePaymentInterval', constants.FEE_PAYMENT_INTERVAL[1]);
          setFieldValue('feePaymentMechanism', constants.FEE_PAYMENT_MECHANISM[1]);
          break;
        default: break;
      }
      validateForm();
    }
  };


  render() {
    const { isFetching, terminalStatusForRenewal, pmsRenewals, accordionData, page, total, totalCount, houseIdFromPropertyProfile,
      formattedCities, preSelectedCities, minimumSourcePmsSubscriptionEndDate, propertyManagementStatus,
      maximumSourcePmsSubscriptionEndDate, maximumLatestNoteCreatedAt, propertyManagers,
      propertyManagerId, requestedFilters, state } = this.state;
    let showPagination = false;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    if (total) { showPagination = total !== 0 && total !== 1; }
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    return (
      <>
        <Container fluid key={'c' + 1}>
          <Portlet fluidHeight={true}>
            <PortletHeader title={`PMS Renewals (${totalCount || 0})`} />
            <PortletBody className="">
              <FilterComponent
                requestedFilters={requestedFilters}
                state={state}
                terminalStatusForRenewal={terminalStatusForRenewal}
                formattedCities={!houseIdFromPropertyProfile ? formattedCities : undefined}
                preSelectedCities={!houseIdFromPropertyProfile ? preSelectedCities : undefined}
                minimumSourcePmsSubscriptionEndDate={minimumSourcePmsSubscriptionEndDate}
                maximumSourcePmsSubscriptionEndDate={maximumSourcePmsSubscriptionEndDate}
                propertyManagers={!houseIdFromPropertyProfile ? propertyManagers : undefined}
                propertyManagerId={!houseIdFromPropertyProfile ? propertyManagerId : undefined}
                maximumLatestNoteCreatedAt={maximumLatestNoteCreatedAt}
                propertyManagementStatus={propertyManagementStatus}
                recordsPerPage={constants.RECORDS_PER_PAGE}
                selectedRecordValue={this.state.limit}
                filterParentCallback={this.handleFilterChildData}
              />
              <Row>
                <Col xs={12}>
                  <Accordion>
                    <Card>
                      {pmsRenewals && pmsRenewals.length === 0 && <p>{constants.ZERO_DATA}</p>}
                      {pmsRenewals && pmsRenewals.length > 0 &&
                        <Card.Header>
                          <Row className="py-2 px-3 azuro-table-header">
                            {!houseIdFromPropertyProfile && <Col md={3}><strong>Property</strong></Col>}
                            {houseIdFromPropertyProfile && <Col md={1}><strong>Status</strong></Col>}
                            <Col md={houseIdFromPropertyProfile ? 3 : 2}><strong>Owner</strong></Col>
                            <Col md={houseIdFromPropertyProfile ? 3 : 2}><strong>Last PMS End Date</strong></Col>
                            <Col md={2}><strong>Latest Update</strong></Col>
                            <Col md={1}><strong>Last Rate</strong></Col>
                            <Col md={2}><strong>Last Frequency</strong></Col>
                          </Row>
                        </Card.Header>}
                    </Card>
                    {pmsRenewals && pmsRenewals.map(renewal =>
                      <Card key={renewal.id}>
                        <Accordion.Toggle as={Card.Header} eventKey={renewal.id} className="row py-2 azuro-table-row"
                          onClick={() => { this.handleAccordionClick(renewal.id) }}>
                          {!houseIdFromPropertyProfile && <Col md={3} className="d-flex flex-row">
                            <span className="pr-3">{this.getPMSSubscriptionStatusBadge(renewal.state)}</span>
                            {renewal.house && <span>{renewal.house?.formattedHouseName} <br />
                              <span className="small text-muted">{renewal.house.propertyManager ? `PM: ${(renewal.house.propertyManager && renewal.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                            </span>}
                          </Col>}
                          {houseIdFromPropertyProfile && <Col md={1} className="d-flex flex-row">
                            <span className="pr-3">{this.getPMSSubscriptionStatusBadge(renewal.state)}</span>
                          </Col>}
                          <Col md={houseIdFromPropertyProfile ? 3 : 2}>
                            {renewal.house && formatMultipleUsers((renewal.house && renewal.house.owners) || [])}
                            {renewal.house && renewal.house.owners && findPrimary(renewal.house.owners) && findPrimary(findPrimary(renewal.house.owners).phoneNumbers) &&
                              ` (+${findPrimary(findPrimary(renewal.house.owners).phoneNumbers).countryCode} ${findPrimary(findPrimary(renewal.house.owners).phoneNumbers).number})`
                            }
                          </Col>
                          <Col md={houseIdFromPropertyProfile ? 3 : 2}>{formatDate(renewal.sourcePmsSubscription && renewal.sourcePmsSubscription.endDate)}</Col>
                          <Col md={2}>
                            {(renewal.notes.length === 0) ? "No Updates" : (formatDate(renewal.notes[0].createdAt)) + " : " + (renewal.notes[0].content)}
                          </Col>
                          <Col md={1}>
                            {renewal.sourcePmsSubscription && renewal.sourcePmsSubscription.formattedRate}
                          </Col>
                          <Col md={2} className="d-flex justify-content-between">
                            <span>{renewal.sourcePmsSubscription && renewal.sourcePmsSubscription.feePaymentInterval}</span>
                            {/* <i className="flaticon2-sort"></i> */}
                            <Link to={`/property-profile/${renewal.house.id}`}>
                              <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                            </Link>
                          </Col>
                        </Accordion.Toggle>

                        {accordionData && (accordionData.id === renewal.id) &&
                          <Accordion.Collapse eventKey={renewal.id}>
                            <Card.Body>
                              <TasksListComponent
                                tasks={accordionData.tasks || []} allowAddTasks={
                                  renewal.state && renewal.state === 'OPEN'
                                }
                                renewalWorkId={renewal.id}
                                houseId={renewal?.house?.id}
                                parentCallback={this.handleChildData}
                                // approverData={userData}
                                approverData={taskAssigneeData && taskAssigneeData.length > 0 && taskAssigneeData}
                              />
                              <NotesComponent notes={accordionData.notes || []}
                                allowAddNotes={
                                  renewal.state && renewal.state === 'OPEN'
                                }
                                renewalWorkId={renewal.id}
                                parentCallback={this.handleChildData}
                              />
                              <Row className="mb-4">
                                <Col xs={12} className="text-right">
                                  {renewal.state === 'OPEN' && <>
                                    <Button className='mr-2' variant="primary" size="sm" onClick={() => this.handleCancelPMSSubscriptionButtonClick(renewal.id)}>Discontinue</Button>
                                    <Button variant="success" size="sm" onClick={() => this.handleRenewPMSSubscriptionButtonClick(renewal.id)}>Renew</Button>
                                  </>}
                                  {/*renewal.state === 'CANCELLED' && <Button variant="primary" size="sm">Undo</Button>*/}
                                </Col>
                              </Row>
                            </Card.Body>
                          </Accordion.Collapse>
                        }
                      </Card>
                    )
                    }
                  </Accordion>
                </Col>
              </Row>
              {/* Code for pagination */}
              {showPagination && showPagination && < UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showCancelPMSSubscriptionModal} onHide={this.handleCancelPMSSubscriptionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Discontinue Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                pmsRenewalWork: {
                  terminalRemarks: undefined,
                  terminalRemarksCategory: "",
                }
              }}
              validationSchema={cancelPMSSubscriptionSchema}
              onSubmit={async (values) => {
                const result = await pmsRenewalServices.discontinuePMSSubscription(accordionData && accordionData.id, values);
                if (result && result.status === 200) {
                  store.addNotification({
                    title: "Success!",
                    message: `PMS Subscription Cancelled!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true,
                      showIcon: true,
                      pauseOnHover: true
                    }
                  });
                  this.setState(state => ({ showCancelPMSSubscriptionModal: !state.showCancelPMSSubscriptionModal }));
                  this.getAllPMSRenewalWorks();
                }
              }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.Group controlId='pmsRenewalWork.terminalRemarksCategory'>
                          <Form.Label>Reason</Form.Label>
                          <Form.Control as='select' name='pmsRenewalWork.terminalRemarksCategory' onChange={handleChange} onBlur={handleBlur} value={values.pmsRenewalWork.terminalRemarksCategory}>
                            <option value="">{constants.DEFAULT_OPTION}</option>
                            <option value="Owner not aligned with services">Owner not aligned with services</option>
                            <option value="Move-Out Initiated">Move-Out Initiated</option>
                            <option value="Personal Use">Personal Use</option>
                            <option value="Re-Rented by Local Broker/Owner">Re-Rented by Local Broker/Owner</option>
                            <option value="Re-Rented by Azuro">Re-Rented by Azuro</option>
                            <option value="Vacant (Lead shared with Sales Team)">Vacant (Lead shared with Sales Team)</option>
                            <option value="Property Sold-Out">Property Sold-Out</option>
                            <option value="In Discussion">In Discussion</option>
                            <option value="Escalated">Escalated</option>
                          </Form.Control>
                          {errors.pmsRenewalWork && errors.pmsRenewalWork.terminalRemarksCategory && touched.pmsRenewalWork && touched.pmsRenewalWork.terminalRemarksCategory && <Form.Text className='text-danger'>
                            {errors.pmsRenewalWork.terminalRemarksCategory}</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group controlId="pmsRenewalWork.terminalRemarks">
                          <Form.Label>Remarks</Form.Label>
                          <Form.Control as="textarea" rows="3" name="pmsRenewalWork.terminalRemarks" onChange={handleChange} onBlur={handleBlur} value={values.pmsRenewalWork.terminalRemarks} />
                          {errors.pmsRenewalWork && errors.pmsRenewalWork.terminalRemarks && touched.pmsRenewalWork && touched.pmsRenewalWork.terminalRemarks && <Form.Text className="text-danger">
                            {errors.pmsRenewalWork.terminalRemarks}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleCancelPMSSubscriptionButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showRenewPMSSubscriptionModal} onHide={this.handleRenewPMSSubscriptionButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Renew Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                startDate: undefined,
                endDate: undefined,
                feeRateQuantity: undefined,
                feeRateUnit: undefined,
                feePaymentInterval: undefined,
                feePaymentMechanism: undefined,
                paymentChargeMode: "FREE_TRIAL",
                planSelected: undefined,
                file: undefined,
                tcfIdentifier: undefined
              }
              }

              validationSchema={renewPMSSubscriptionSchema}
              onSubmit={
                async (values) => {
                  switch (values.planSelected) {
                    case 'freePlan':
                      values.feeRateQuantity = '0';
                      values.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[0];
                      break;

                    case 'eightPercentPlan':
                      values.feeRateQuantity = '8';
                      values.feeRateUnit = constants.FEE_RATE_UNIT[0];
                      values.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'fixedAmountFromRentPlan':
                      values.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'customPlan':
                      values.feeRateUnit = constants.FEE_RATE_UNIT[0];
                      values.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[0];
                      values.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[2];
                      break;

                    case 'fullAmountPlan':
                      values.feeRateUnit = constants.FEE_RATE_UNIT[1];
                      values.feePaymentInterval = constants.FEE_PAYMENT_INTERVAL[1];
                      values.feePaymentMechanism = constants.FEE_PAYMENT_MECHANISM[1];
                      break;
                    default: break;
                  }

                  delete values.planSelected;
                  if (values.paymentChargeMode === "FREE_TRIAL") {
                    values.paymentChargeMode = "";
                  }
                  // if(values.file===undefined) {
                  //   delete values.file;
                  // }

                  let formData = new FormData();
                  values.file && formData.append('resultantPmsSubscription[file]', values.file);
                  values.startDate && formData.append('resultantPmsSubscription[startDate]', values.startDate);
                  values.endDate && formData.append('resultantPmsSubscription[endDate]', values.endDate);
                  values.feeRateQuantity && formData.append('resultantPmsSubscription[feeRateQuantity]', values.feeRateQuantity);
                  values.feeRateUnit && formData.append('resultantPmsSubscription[feeRateUnit]', values.feeRateUnit);
                  values.feePaymentInterval && formData.append('resultantPmsSubscription[feePaymentInterval]', values.feePaymentInterval);
                  values.feePaymentMechanism !== undefined && formData.append('resultantPmsSubscription[feePaymentMechanism]', values.feePaymentMechanism ? values.feePaymentMechanism : '');
                  values.paymentChargeMode !== undefined && formData.append('resultantPmsSubscription[paymentChargeMode]', values.paymentChargeMode ? values.paymentChargeMode : '');
                  values.tcfIdentifier !== undefined && formData.append('resultantPmsSubscription[tcfIdentifier]', values.tcfIdentifier ? values.tcfIdentifier : '');

                  // Object.keys(values).forEach(key => formData.append(key, values[key]));
                  // formData.append("id", accordionData && accordionData.id,)

                  const result = await pmsRenewalServices.renewPMSSubscription(accordionData && accordionData.id, formData);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `PMS Subscription Renewed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showRenewPMSSubscriptionModal: !state.showRenewPMSSubscriptionModal }));
                    this.getAllPMSRenewalWorks();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, validateForm }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Form.Group controlId="startDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Start Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='startDate'
                            value={values.startDate}
                            onChange={setFieldValue}
                          />
                          {errors.startDate && touched.startDate && <Form.Text className="text-danger">
                            {errors.startDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="endDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='End Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='endDate'
                            value={values.endDate}
                            onChange={setFieldValue}
                          />
                          {errors.endDate && touched.endDate && <Form.Text className="text-danger">
                            {errors.endDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Upload Service Agreement {values.planSelected !== 'freePlan' && <span className="text-danger">*</span>}</Form.Label>
                          <br />
                          <input id="file" name="file" type="file" onChange={(event) => {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }} />
                          {errors.file && touched.file && <Form.Text className="text-danger">
                            {errors.file}
                          </Form.Text>}
                        </Form.Group>
                      </Col>

                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label htmlFor="payment-mode" >Subscription Payment Mode <span className="text-danger">*</span></Form.Label><br />
                          <Form.Check inline type="radio" label='Free Trial' defaultChecked name={'paymentChargeMode'} id="freeTrialRadio" value={"FREE_TRIAL"} onChange={handleChange} />
                          <Form.Check inline type="radio" label='Deduct from Rent' name={'paymentChargeMode'} id="deductFromRentRadio" value={constants.PAYMENT_CHARGE_MODE[0]} onChange={handleChange} />
                          <Form.Check inline type="radio" label="Invoice Full Amount" name={'paymentChargeMode'} id="invoiceAmountRadio" value={constants.PAYMENT_CHARGE_MODE[1]} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <label htmlFor='planGroupID'>Select Plan <span className="text-danger">*</span></label>
                        <div className='planGroup' id='planGroupID'>
                          <Form.Group controlId="planSelected">
                            {/* value.planSelected */}
                            {(values.paymentChargeMode === "FREE_TRIAL" || values.paymentChargeMode === null) &&
                              <div className="radio--card-group">
                                <div className="radio--card__item" >
                                  <input id="rateCard1" name='planSelected' type="radio" value={'freePlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor='rateCard1'>
                                    <Card.Body>
                                      <Card.Title>Free Trial</Card.Title>
                                      <Card.Text>
                                        INR 0 for the duration between Start &amp; End Date
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>}
                            {(values.paymentChargeMode === "DEDUCT_FROM_RENT") &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard2" name='planSelected' type="radio" value={'eightPercentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard2">
                                    <Card.Body>
                                      <Card.Title>8% of Monthly Rent</Card.Title>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                                <div className="radio--card__item">
                                  <input id="rateCard4" name="planSelected" type="radio" value={'fixedAmountFromRentPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard4">
                                    <Card.Body>
                                      <Card.Title>Fixed Amount per Month</Card.Title>
                                      <Form.Group controlId="feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name='feeRateQuantity' type="text" onChange={handleChange} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">INR</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>

                                {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <div className="radio--card__item">
                                  <input id="rateCard5" name="planSelected" type="radio" value={'customPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard5">
                                    <Card.Body>
                                      <Card.Title>Custom Plan</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <FormControl name="feeRateQuantity" type="text" className="px-2" onChange={handleChange} onBlur={handleBlur} aria-describedby="basic-addon1" />
                                          <InputGroup.Text id="basic-addon1">% of Monthly Rent</InputGroup.Text>
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        Make sure the rent is flowing through Azuro app.
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>}
                              </div>}

                            {values.paymentChargeMode === constants.PAYMENT_CHARGE_MODE[1] &&
                              <div className="radio--card-group">
                                <div className="radio--card__item">
                                  <input id="rateCard6" name="planSelected" type="radio" value={'fullAmountPlan'} onChange={
                                    async (e) => {
                                      handleChange(e);
                                      await this.handlePlanChange(e, setFieldValue, validateForm)
                                    }
                                  } />
                                  <label htmlFor="rateCard6">
                                    <Card.Body>
                                      <Card.Title>Enter Amount</Card.Title>
                                      <Form.Group controlId="pmsSubscription.feeRateQuantity">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text id="basic-addon1">INR</InputGroup.Text>
                                          <FormControl name="feeRateQuantity" type="text" onChange={handleChange} aria-describedby="basic-addon1" />
                                        </InputGroup>
                                      </Form.Group>
                                      <Card.Text>
                                        The total fee for the duration of the PMS subscription
                                      </Card.Text>
                                    </Card.Body>
                                  </label>
                                </div>
                              </div>
                            }
                            {errors && errors.planSelected && touched && touched.planSelected && <Form.Text className="text-danger">
                              {errors.planSelected}
                            </Form.Text>}
                          </Form.Group>
                        </div>
                      </Col>
                      {values.paymentChargeMode !== "FREE_TRIAL" &&
                        <Col xs={6}>
                          <Form.Group controlId="tcfIdentifier">
                            <Form.Label>TCF ID <span className="text-danger">*</span></Form.Label>
                            <Field as={Form.Control} name="tcfIdentifier" validate={this.validateTCFId} type="text" placeholder="Eg: TCF-20210315-0056" minLength="17" maxLength="17" pattern={constants.tcfIdPattern} onChange={handleChange} onBlur={handleBlur} value={values.tcfIdentifier} />
                            {errors && errors.tcfIdentifier && touched && touched.tcfIdentifier && <Form.Text className="text-danger">
                              {errors.tcfIdentifier}
                            </Form.Text>}
                          </Form.Group>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleRenewPMSSubscriptionButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

      </>
    )
  }
}


const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default
  connect(
    mapStateToProps,
    null
  )(PMSRenewals);