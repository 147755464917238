import React, { Component } from 'react';
import {
  Container, Row, Col, Jumbotron, Button, Badge, Card, Tabs, Tab,
  Accordion, Modal, Form, DropdownButton, Dropdown
} from 'react-bootstrap';
import { toAbsoluteUrl, formatMultipleUsers, formatAmount, formatDate, formatDateTime, findPrimary } from "../../../../_metronic/utils/utils";
import { Link } from 'react-router-dom';
import { PropertyProfilePageServices } from './PropertyProfilePage.services';
import RentPaymentBills from '../RentPaymentBills/RentPaymentBills';
import ServiceRequests from '../ServiceRequests/ServiceRequests';
import Inspections from '../Inspections/Inspections';
import InspectionReports from '../InspectionReports/InspectionReports'
import PMSRenewals from '../PMSRenewals/PMSRenewals';
import PMSSubscriptions from '../PMSSubscriptions/PMSSubscriptions'
import Payments from '../Payments/Payments';
import Invoices from '../Invoices/Invoices';
import { Formik, FieldArray } from 'formik';
import * as constants from '../../../constants';
import { commonServices } from '../../../../app/commonServices/commonServices';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { store } from 'react-notifications-component';
import AdminAssignments from '../../../partials/layout/AdminAssignments';
import AddUserComponent from '../../../partials/layout/AddUserComponent';
import AddLeaseComponent from '../../../partials/layout/AddLeaseComponent';
import UserBankAccounts from '../../../partials/layout/UserBankAccounts';
import RentReceipts from '../RentReceipts/RentReceipts';
import GenerateUserCredentials from '../../../partials/layout/GenerateUserCredentialComponent';
import EditHouseDetailsComponent from '../../../partials/layout/EditHouseDetailsComponent';
import { connect } from 'react-redux';

class PropertyProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addDocumentForHouse: undefined,
      documentConfirmation: false,
      houseId: props.match.params.houseId,
      isFetching: false,
      isInspectionsTab: false,
      isInvoicesTab: false,
      isPaymentsTab: false,
      isPMSRenewalsTab: false,
      isPMSSubscriptionsTab: false,
      isRentPaymentBillsTab: false,
      isRentReceiptsTab: false,
      isServiceRequestsTab: false,
      limit: 10,
      offset: 0,
      page: 1,
      showAddDocumentsModal: false,
      showChangePropertyManagementStatusModal: false,
      showUpdateTransferNotesModal: false,
      total: undefined,
      userConfirmation: false,
      showDeleteHouseOwnershipModal: false,
      showMarkPrimaryUserModal: false,
      disableNewPMSButton: false,
    }
  }

  async componentDidMount() {
    await this.getPropertyById();
    await this.getLeaseList();
  }

  getPropertyById = async () => {
    this.setState({ isFetching: true })
    const { houseId } = this.state;
    const data = {
      include: ['OCCUPANCY_STATUS', 'OWNERS', 'PROPERTY_MANAGER', 'CURRENT_TENANTS', 'PROPERTY_DOCUMENTS', 'SALES_MANAGER']
    }
    const result = await PropertyProfilePageServices.getPropertyById(houseId, data);
    if (result && result.status === 200) {
      await this.setState({
        propertyDetails: result.data.house,
      });
    }
    this.setState({ isFetching: false })
  }

  getLeaseList = async () => {
    const { offset, limit, houseId } = this.state;
    const data = {
      // moveInManagerAssigned: 'FALSE',
      include: ['DEAL_CLOSURE_DATE', 'LEASE_TERMS_MOVE_IN_DATE', 'HOUSE', 'OWNERS', 'TENANTS', 'RENT_PERIODS', 'MOVE_IN_MANAGER'],
      offset,
      limit,
      houseId
    }
    const leaseList = await PropertyProfilePageServices.getLeaseListData(data);
    if (leaseList && leaseList.status === 200) {
      const totalLeaseCount = leaseList.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalLeaseCount / limit);
      await this.setState({
        leaseListData: leaseList.data.leases,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalLeaseCount
      })
    }
  }

  getOwners = async () => {
    const data = {
      houseId: this.state.houseId,
      include: ['USER_PHONE_NUMBERS', 'USER_BANK_ACCOUNTS', 'USER_DOCUMENTS', 'USER_HOUSE_OWNERSHIPS', 'USER_LEASE_TENANT_PARTICIPATIONS']
    }
    const result = await PropertyProfilePageServices.getPropertyOwner(data);
    if (result && result.status === 200) {
      const owners = result.data && result.data.houseOwnerships;

      if (owners && owners.length > 0) {
        owners.forEach((item) => {
          let propertiesLeaseCountArr = [];
          if (item.user.houseOwnerships.length > 0) {
            let propertyString = item.user.houseOwnerships.length === 1 ? " Property" : " Properties"
            propertiesLeaseCountArr.push("Owner in " + item.user.houseOwnerships.length + propertyString)
          }
          if (item.user.leaseTenantParticipations.length > 0) {
            let leaseString = item.user.leaseTenantParticipations.length === 1 ? " Lease" : " Leases"
            propertiesLeaseCountArr.push("Tenant in " + item.user.leaseTenantParticipations.length + leaseString)
          }
          item.propertiesLeaseCountArr = propertiesLeaseCountArr;
        })
      }
      this.setState({ owners })
    }

  }

  handleSelect = async (key) => {
    key === 'LeasesTab' && await this.getLeaseList();
    key === 'OwnersTab' && await this.getOwners();
    key === 'RentPaymentBillsTab' && await this.setState({ isRentPaymentBillsTab: true });
    key === 'RentReceiptsTab' && await this.setState({ isRentReceiptsTab: true });
    key === 'ServiceRequestsTab' && await this.setState({ isServiceRequestsTab: true });
    key === 'InspectionsTab' && await this.setState({ isInspectionsTab: true, isInspectionReportsTab: true });
    key === 'PMSRenewalsTab' && await this.setState({ isPMSRenewalsTab: true, isPMSSubscriptionsTab: true });
    key === 'PaymentsTab' && await this.setState({ isPaymentsTab: true });
    key === 'InvoicesTab' && await this.setState({ isInvoicesTab: true });
    key === 'DocumentsTab' && await this.setState({ isDocumentsTab: true, propertyDocumentsData: this.state.propertyDetails && this.state.propertyDetails.documents });
  }

  handleAddDocumentsButtonClick = async (userId = null, addDocumentForHouse = null) => {
    await this.setState(state => ({
      showAddDocumentsModal: !state.showAddDocumentsModal,
      activeUser: userId,
      addDocumentForHouse: addDocumentForHouse,
      documentConfirmation: false
    }));
  }

  handleDeleteDocumentButtonClick = (documentId = null) => {
    if (documentId !== null) {
      this.setState({ idToBeDeleted: documentId })
    }
    this.setState(state => ({ showDeleteDocumentModal: !state.showDeleteDocumentModal }));
  }

  handleConfirmDeleteDocumentButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await commonServices.deleteDocument(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Document deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteDocumentModal: !state.showDeleteDocumentModal }));
        this.getPropertyById();
      }
    }
  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getLeaseList();
  }

  handleEditUserButtonClick = (userType = null, userId = null) => {
    if (userId !== null) {
      const ownerArray = this.state.propertyDetails && this.state.propertyDetails.owners;
      let toBeEdited = undefined;
      toBeEdited = ownerArray.find(owner => (owner.id === userId));
      this.setState({ userToBeEdited: toBeEdited });
    }
    this.setState(state => ({
      showEditUserModal: !state.showEditUserModal,
      userType: userType,
      userConfirmation: false,
    }));
  }

  handleMarkPrimaryUserButtonClick = (houseOwnershipid = null) => {
    if (houseOwnershipid !== null) {
      this.setState({ houseOwnershipToBeEdited: houseOwnershipid });
    }
    this.setState(state => ({
      showMarkPrimaryUserModal: !state.showMarkPrimaryUserModal
    }));
  }

  handleConfirmMarkPrimaryUserButtonClick = async () => {
    if (this.state.houseOwnershipToBeEdited) {
      const result = await PropertyProfilePageServices.markPrimaryHouseOwnership(this.state.houseOwnershipToBeEdited);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Owner marked as Primary!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showMarkPrimaryUserModal: !state.showMarkPrimaryUserModal }));
        this.getPropertyById();
      }
    }
  }

  handleDeleteHouseOwnershipButtonClick = (houseOwnershipId = null) => {
    console.log(houseOwnershipId);
    if (houseOwnershipId !== null) {
      this.setState({ idToBeDeleted: houseOwnershipId })
    }
    this.setState(state => ({ showDeleteHouseOwnershipModal: !state.showDeleteHouseOwnershipModal }));
  }

  handleConfirmDeleteHouseOwnershipButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await PropertyProfilePageServices.deleteHouseOwnership(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Owner removed from the property!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteHouseOwnershipModal: !state.showDeleteHouseOwnershipModal }));
        this.getPropertyById();
      }
    }
  }


  handleAdminAssignmentPM = async (isReassign, leaseOrHouse) => {
    isReassign && (leaseOrHouse === 'house') && await this.getPropertyById();
  }

  handleAdminAssignmentSM = async (isReassign, leaseOrHouse) => {
    isReassign && (leaseOrHouse === 'house') && await this.getPropertyById();
  }

  handleAdminAssignmentMIM = async (isReassign, leaseOrHouse) => {
    isReassign && (leaseOrHouse === 'lease') && await this.getLeaseList();
  }

  handleChangePropertyManagementStatusButtonClick = async () => {
    await this.setState(state => ({ showChangePropertyManagementStatusModal: !state.showChangePropertyManagementStatusModal }));
  }

  handleUpdateTransferNotesButtonClick = async () => {
    await this.setState(state => ({ showUpdateTransferNotesModal: !state.showUpdateTransferNotesModal }));
  }

  getPropertyStatusBadge(status) {
    return status === 'OCCUPIED' ? <Badge variant="success" title="Occupied">Occupied</Badge> : <Badge variant="warning" title="Vacant">Vacant</Badge>
  }

  handleAddLeaseData = () => {
    this.getLeaseList();
  }

  handleGenerateUserCredentialsData = async (childData) => {
    if (childData === 'PropertyProfile') {
      await this.getOwners();
    }
  }

  updateNewPMSSubscriptionButtonStatus = async (value) => {
    this.setState({ disableNewPMSButton: value });
  }

  render() {
    const { isFetching, propertyDetails, owners, houseId, isRentPaymentBillsTab, isRentReceiptsTab, isServiceRequestsTab, isPaymentsTab,
      isPMSRenewalsTab, isPMSSubscriptionsTab, isInspectionsTab, isInspectionReportsTab, isInvoicesTab, page, total,
      totalLeaseCount, leaseListData, userToBeEdited, propertyDocumentsData, disableNewPMSButton } = this.state;
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid key={'c' + 1}>
          <Jumbotron fluid className="py-3 px-2">
            <Container>
              <span className="small">Property ID #{propertyDetails && propertyDetails.id}</span> <span className="pl-3">{this.getPropertyStatusBadge((propertyDetails && propertyDetails.occupancyStatus) || 'CLOSED')}</span>
              {propertyDetails && <h3>
                {propertyDetails.formattedHouseName} <br />
                <small>{propertyDetails.society?.locality?.name}, {propertyDetails?.society?.locality?.city?.name} <i className="la la-info-circle"
                  // IMP: maintain title spacing for correct display in browsers. Avoid indentations for these lines.
                  title={`Building/Wing/Tower: ${propertyDetails.buildingName}
Unit Number: ${propertyDetails.unitNumber}
Society Name: ${propertyDetails.society?.name}
Locality Name: ${propertyDetails.society?.locality?.name}
City: ${propertyDetails.society?.locality?.city?.name}`}></i>
                  {/* // IMP: maintain title spacing for correct display in browsers. Avoid indentations for these lines. */}
                </small>
              </h3>}
              <EditHouseDetailsComponent houseDetails={propertyDetails} />
              <p><small>Created by: {propertyDetails && propertyDetails.createdBy?.fullName ? propertyDetails.createdBy?.fullName : 'Unknown'} on {propertyDetails && formatDate(propertyDetails.createdAt)}</small></p>
              <p><small>{propertyDetails && propertyDetails.propertyManagementStatus === 'YES' ? <span className="text-success">Property Under Management</span> : <span className="text-danger">Not Under Management</span>}</small>{propertyDetails && propertyDetails.propertyManagementStatus === 'YES' ? this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <Button className="d-inline" variant="clean" size="sm" onClick={() => this.handleChangePropertyManagementStatusButtonClick()}>Change</Button> : <Button className="d-inline" variant="clean" size="sm" onClick={() => this.handleChangePropertyManagementStatusButtonClick()}>Change</Button>}</p>
              <Row>
                <Col xs={6} md={3}>
                  {
                    propertyDetails && propertyDetails.owners && findPrimary(propertyDetails.owners) &&
                    <div className="kt-widget4">
                      <p className="mb-0 small">OWNER</p>
                      <div className="kt-widget4__item ">
                        <div className="kt-widget4__pic kt-widget4__pic--pic ">
                          <img alt="" src={toAbsoluteUrl(findPrimary(propertyDetails.owners).profilePicture || '/media/users/default.jpg')} />
                        </div>
                        <div className="kt-widget4__info ">
                          <p className="mb-0 kt-widget4__username">
                            {findPrimary(propertyDetails.owners)?.fullName}
                            {findPrimary(propertyDetails.owners)?.isOnCustomerApp === true && <span className="bg-white text-success ml-2" title={'App installed'}><i className="la la-mobile"></i><i className="la la-check"></i></span>}
                            {(findPrimary(propertyDetails.owners)?.isOnCustomerApp === null || findPrimary(propertyDetails.owners)?.isOnCustomerApp === false) && <span className="bg-white text-danger ml-2" title="App not installed"><i className="la la-mobile"></i><i className="la la-exclamation"></i></span>}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                </Col>
                <Col xs={6} md={3}>
                  {
                    propertyDetails && propertyDetails.currentTenants &&
                    <div className="kt-widget4">
                      <p className="mb-0 small">CURRENT TENANT</p>
                      {propertyDetails?.currentTenants && propertyDetails?.currentTenants.length === 0 &&
                        <p className="text-warning small pt-2">Available post move-in date or Not Added.</p>
                      }
                      {propertyDetails?.currentTenants && propertyDetails?.currentTenants.length > 0 && findPrimary(propertyDetails.currentTenants) &&
                        <div className="kt-widget4__item ">
                          <div className="kt-widget4__pic kt-widget4__pic--pic ">
                            <img alt="" src={toAbsoluteUrl(findPrimary(propertyDetails.currentTenants).profilePicture || '/media/users/default.jpg')} />
                          </div>
                          <div className="kt-widget4__info ">
                            <p className="mb-0 kt-widget4__username">
                              {findPrimary(propertyDetails.currentTenants)?.fullName}
                              {findPrimary(propertyDetails.currentTenants)?.isOnCustomerApp === true && <span className="bg-white text-success ml-2" title={'App installed'}><i className="la la-mobile"></i><i className="la la-check"></i></span>}
                              {(findPrimary(propertyDetails.currentTenants)?.isOnCustomerApp === null || findPrimary(propertyDetails.currentTenants)?.isOnCustomerApp === false) && <span className="bg-white text-danger ml-2" title="App not installed"><i className="la la-mobile"></i><i className="la la-exclamation"></i></span>}
                            </p>
                          </div>
                        </div>}
                    </div>
                  }
                </Col>
                <Col xs={6} md={3}>
                  <div className="kt-widget4">
                    <p className="mb-0 small">PROPERTY MANAGER</p>
                    {propertyDetails && propertyDetails.propertyManager &&
                      <div className="kt-widget4__item ">
                        <div className="kt-widget4__pic kt-widget4__pic--pic ">
                          <img alt="" src={toAbsoluteUrl(propertyDetails.propertyManager.profilePicture || '/media/users/default.jpg')} />
                        </div>
                        <div className="kt-widget4__info ">
                          <p className="mb-0 kt-widget4__username">
                            {propertyDetails.propertyManager.fullName}
                          </p>
                          <p className="kt-widget4__text ">
                            {propertyDetails.propertyManager.email}
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                  <AdminAssignments houseId={houseId} isReassign={true}
                    adminAssignmentsParent={this.handleAdminAssignmentPM}
                    title={(propertyDetails && propertyDetails.propertyManager) ? 'Reassign PM' : 'Assign PM'}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <div className="kt-widget4">
                    <p className="mb-0 small">SALES MANAGER</p>
                    {propertyDetails && propertyDetails.salesManager &&
                      <div className="kt-widget4__item ">
                        <div className="kt-widget4__pic kt-widget4__pic--pic ">
                          <img alt="" src={toAbsoluteUrl(propertyDetails.salesManager.profilePicture || '/media/users/default.jpg')} />
                        </div>
                        <div className="kt-widget4__info ">
                          <p className="mb-0 kt-widget4__username">
                            {propertyDetails.salesManager.fullName}
                          </p>
                          <p className="kt-widget4__text ">
                            {propertyDetails.salesManager.email}
                          </p>
                        </div>
                      </div>
                    }
                  </div>
                  <AdminAssignments salesManagerHouseId={houseId} isReassign={true}
                    adminAssignmentsParent={this.handleAdminAssignmentSM}
                    title={(propertyDetails && propertyDetails.salesManager) ? 'Reassign Sales Manager' : 'Assign Sales Manager'}
                  />
                </Col>
              </Row>
            </Container>
          </Jumbotron>
          <Card>
            <Card.Body className="">
              <Tabs defaultActiveKey="LeasesTab" id="property-profile-tabs" variant="pills" className="mb-3"
                onSelect={(key) => this.handleSelect(key)}>

                <Tab eventKey="LeasesTab" title="Leases">
                  {!leaseListData && <p>{constants.LOADING_DATA}</p>}
                  {leaseListData && <Portlet fluidHeight={true}>
                    <PortletHeader
                      title={`Leases (${totalLeaseCount && totalLeaseCount})`}
                      toolbar={
                        <PortletHeaderToolbar>
                          {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) &&
                            <AddLeaseComponent houseId={propertyDetails?.id || houseId} currency={propertyDetails?.society?.locality?.city?.currency} pendingOnboardingsCallback={this.handleAddLeaseData} />}
                        </PortletHeaderToolbar>
                      }
                    />
                    <PortletBody>
                      {leaseListData.length === 0 ?
                        <Row className="mb-3">
                          <Col xs={12} className='text-center'>
                            <p>No Lease Found! {(!this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD')) && 'Get in touch with your PM Head if you require a new Lease to be added manually.'}</p>
                          </Col>
                        </Row> :
                        <Row>
                          <Col xs={12}>
                            {leaseListData && leaseListData.map((lease, index) =>
                              <Card className="my-3" key={'lease' + index}>
                                <Card.Header >
                                  <Row>
                                    <Col xs={9} className="d-flex align-items-center">
                                      <h6 className="mb-0">
                                        Status: {lease.moveInTerminalStatus === 'NO' ? <Badge variant="danger">Deal Cancelled</Badge> : lease.moveInTerminalStatus === 'YES' && lease.moveOutDate ? <Badge variant="dark">Moved-out</Badge> : (lease.moveInDate && lease.moveInTerminalStatus === 'YES' ? <Badge variant="success">Moved-in</Badge> : <Badge variant="success">{`Pre Move-in`}</Badge>)}
                                        <br /><small>Created by: {lease.createdBy?.fullName} on {formatDate(lease.createdAt)}</small>
                                      </h6>
                                    </Col>
                                    <Col xs={3} className="text-right">
                                      <Link to={`/lease-profile/${lease.id}`}>
                                        <Button size='sm' variant='outline-primary'><i className="flaticon2-website"></i> View Lease Profile</Button>
                                      </Link>
                                    </Col>
                                  </Row>
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    <Col xs={6}>
                                      <p><small>For House Offering ID #{lease.houseOffering?.id}</small></p>
                                      <p><strong>Beats Listing ID</strong> #{lease.houseOffering?.beatsListingIdentifier}</p>
                                    </Col>
                                    <Col xs={6}>
                                      {lease.moveInManager !== null ?
                                        <p className="mt-3 pt-4"><strong>Move-In Manager</strong>: {lease.moveInManager?.fullName}</p>
                                        :
                                        <AdminAssignments leaseId={lease.id} isReassign={true}
                                          adminAssignmentsParent={this.handleAdminAssignmentMIM}
                                          title={'Assign MIM'}
                                        />}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <p><strong>Tenants</strong>: {formatMultipleUsers(lease.tenants)}</p>
                                      <p><strong>Lease Type</strong>: {lease.leaseType}<br /><strong>Tenant Type</strong>: {lease.tenantType}</p>
                                    </Col>
                                    <Col xs={6} md={4}>
                                      {lease.moveOutDate && <p><strong>Moved-out on</strong>: {formatDate(lease.moveOutDate)}</p>}
                                      {lease.moveInDate && <p><strong>Moved-in on</strong>: {formatDate(lease.moveInDate)}</p>}
                                    </Col>
                                    <Col xs={12}><p><strong>Rent Periods:</strong></p></Col>
                                  </Row>
                                  {lease.rentPeriods.map((rp, index) =>
                                    <Row key={'rp' + index}>
                                      <Col xs={8} md={4}>
                                        <p className="mb-0">{formatDate(rp.startDate)} to {formatDate(rp.endDate)}</p>
                                      </Col>
                                      <Col xs={4} md={4}>
                                        <p className="mb-0">{formatAmount(rp.rent, rp.currency)}</p>
                                      </Col>
                                    </Row>
                                  )}
                                </Card.Body>
                              </Card>
                            )}
                          </Col>
                          <Col xs={12}>
                            {/* Code for pagination */}
                            {showPagination && showPagination && <UltimatePagination
                              currentPage={page}
                              totalPages={total}
                              onChange={this.onPageChange}
                              boundaryPagesRange={0}
                              hidePreviousAndNextPageLinks={true}
                              hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                            />}
                          </Col>
                        </Row>
                      }
                    </PortletBody>
                  </Portlet>}
                </Tab>

                <Tab eventKey="OwnersTab" title="Owners">
                  <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={12} sm={10}>
                          <h5>Transfer Notes</h5>
                          <p className="small mb-0">Information on how the transfer of rent &amp; security deposit are to be performed.</p>
                        </Col>
                        <Col xs={12} sm={2} className="text-right">
                          <Button variant="clean" size="sm" onClick={() => this.handleUpdateTransferNotesButtonClick()}>Update Notes</Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col xs={12}>
                          <p>{propertyDetails && propertyDetails.transferNotes}</p>
                          <p className="small text-muted mb-0">
                            Last Updated: {(propertyDetails && propertyDetails.transferNotesUpdatedAt) ? <strong>{formatDateTime(propertyDetails.transferNotesUpdatedAt)}</strong> : "N/A"} by {(propertyDetails && propertyDetails.transferNotesCreatedBy) ? <strong>{propertyDetails.transferNotesCreatedBy.fullName}</strong> : "N/A"}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  {!owners && <p>{constants.LOADING_DATA}</p>}
                  {owners && <Card className="my-3">
                    <Card.Header>
                      <Row>
                        <Col xs={10}>
                          <h5>Owners</h5>
                          <p className="small mb-0">Details and documents</p>
                        </Col>
                        <Col xs={2} className="text-right">
                          <AddUserComponent userType="Owner" page="PropertyProfile" houseId={propertyDetails.id} propertyProfilePageCallback={this.getPropertyById} />
                          {/* <Button variant="clean" size="sm" onClick={() => this.handleAddUserButtonClick('Owner')}>Add Owner</Button> */}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      {
                        owners.length === 0 &&
                        <Row className="mb-3">
                          <Col xs={12} className='text-center'>
                            <p>No Owners Found!</p>
                          </Col>
                        </Row>
                      }
                      {owners.map((owner, index) =>
                        <React.Fragment key={'owner' + index}>
                          <Row className="mb-3">
                            <Col xs={7}>
                              <div className="kt-widget4">
                                <div className="kt-widget4__item ">
                                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                    <img alt="" src={toAbsoluteUrl(owner.user.profilePicture || '/media/users/default.jpg')} />
                                  </div>
                                  <div className="kt-widget4__info ">
                                    <p className="mb-0 kt-widget4__username">
                                      {owner.user.fullName} {owner.isPrimary && <Badge className="ml-1" variant="warning">Primary</Badge>}
                                    </p>
                                    <p className="kt-widget4__text d-block">
                                      {owner.user?.email && owner.user?.email !== '' && <span><a target="_blank" rel="noopener noreferrer" title="Click to email" href={"mailto:" + owner.user.email}>{owner.user.email}</a></span>}
                                      {owner.user?.phoneNumbers && <span className="ml-3">
                                        <span> | +{owner.user?.phoneNumbers[0]?.countryCode}-{owner.user?.phoneNumbers[0]?.number}</span>
                                        <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"tel:" + owner.user?.phoneNumbers[0]?.countryCode + owner.user?.phoneNumbers[0]?.number} title="Click to Call">
                                          <i className="la la-phone mr-0"></i>
                                        </a>
                                        <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={"https://wa.me/" + owner.user?.phoneNumbers[0]?.countryCode + owner.user?.phoneNumbers[0]?.number + ""} title="Click to WhatsApp">
                                          <i className="flaticon-whatsapp mr-0"></i>
                                        </a>
                                      </span>}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={3} className="text-right">
                              <GenerateUserCredentials
                                user={owner.user}
                                isPropertyProfile={true}
                                generateUserCredentialsParentCallback={this.handleGenerateUserCredentialsData}
                              />
                            </Col>
                            <Col xs={2} className="text-right">
                              <DropdownButton id="dropdown-basic-button" size="sm" variant="outline-primary" title="Change">
                                <Dropdown.Item eventKey='edit Owner' onClick={() => this.handleEditUserButtonClick('Owner', owner.user.id)}>Edit User</Dropdown.Item>
                                {!owner.isPrimary && <Dropdown.Item eventKey='mark as primary' onClick={() => this.handleMarkPrimaryUserButtonClick(owner.id)}>Mark as Primary Owner </Dropdown.Item>}
                                <Dropdown.Item eventKey='remove owner' onClick={() => this.handleDeleteHouseOwnershipButtonClick(owner.id)}>Remove Owner from this Property</Dropdown.Item>
                                <Dropdown.Item as="a"
                                  href={"https://wa.me/" + owner?.user.phoneNumbers[0]?.countryCode + owner?.user.phoneNumbers[0]?.number + `?text=${encodeURIComponent('Please share your experience by reviewing us via this link: https://bit.ly/4bwzhDW')}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Click to WhatsApp"
                                >
                                  Send Google Review Link
                                </Dropdown.Item>
                              </DropdownButton>
                            </Col>
                            <Col xs={12}>
                              <p className="kt-widget4__text small text-muted">Occupation: {owner.user.worksAs || 'Unknown Designation'} at {owner.user.worksAt || 'Unknown Company'}</p>
                              <p className="kt-widget4__text mb-0"><i className="la la-info-circle mr-2"></i>This user is {owner.propertiesLeaseCountArr.join(" & ")}.</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} className="border-right">
                              <UserBankAccounts bankAccounts={owner.user.bankAccounts} userId={owner.user.id} page="PropertyProfile" propertyProfilePageCallback={this.getOwners} existingUsers={owners} />
                            </Col>

                            <Col xs={12} md={6}>
                              <Row>
                                <Col xs={6} className="d-flex align-items-center">
                                  <h6>Documents</h6>
                                </Col>
                                {//leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null &&
                                  <Col xs={6} className="text-right">
                                    <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentsButtonClick(owner.user.id)}>Add Document</Button>
                                  </Col>}
                                {owner.user.documents && owner.user.documents.length === 0 && <Col className="text-center" xs={12}><p>No Documents</p></Col>}
                                <Col xs={12}>
                                  {owner.user.documents && owner.user.documents.length > 0 && owner.user.documents.map((doc, index) =>
                                  (
                                    <div key={'document' + index} className='m-2 d-flex'>
                                      <Button className='flex-grow-1 text-left' target="_blank" rel="noopener noreferrer" href={doc.file} variant="link" size="sm">{doc.name}{doc.documentNumber ? ' (' + doc.documentNumber + ')' : ''}</Button>
                                      {//leaseProfile && leaseProfile.lease && leaseProfile.lease.moveInTerminalStatus === null &&
                                        this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') &&
                                        <Button className='flex-shrink-1' variant="clean" size="sm" onClick={() => this.handleDeleteDocumentButtonClick(doc.id)}>
                                          <i className="flaticon2-trash mr-0"></i>
                                        </Button>}
                                    </div>
                                  )
                                  )
                                  }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <hr />
                        </React.Fragment>
                      )
                      }
                    </Card.Body>
                  </Card>}
                </Tab>

                <Tab eventKey="DocumentsTab" title="Documents">
                  <Portlet fluidHeight={true}>
                    <PortletHeader
                      title={`Documents`}
                      toolbar={
                        <PortletHeaderToolbar>
                          <Button variant="clean" size="sm" onClick={() => this.handleAddDocumentsButtonClick(null, true)}>Add Property Document</Button>
                        </PortletHeaderToolbar>
                      }
                    />
                    <PortletBody>
                      <Row>
                        <Col xs={6}>
                          <Row>
                            {/* {!propertyDocumentsData && <p>{constants.LOADING_DATA}</p>} */}
                            {propertyDocumentsData && propertyDocumentsData.length === 0 &&
                              <Col xs={12}><p>{constants.ZERO_DATA}</p></Col>
                            }
                            {propertyDocumentsData && propertyDocumentsData.map((document, index) =>
                              <Col xs={12} key={'document' + index} className='d-flex border border-secondary mt-2'>
                                <Button className='flex-grow-1 text-left' target="_blank" rel="noopener noreferrer" href={document.file} variant="link" size="sm">{document.name}{document.documentNumber ? ' (' + document.documentNumber + ')' : ''}</Button>
                                {this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && <Button className='flex-shrink-1' variant="clean" size="sm" onClick={() => this.handleDeleteDocumentButtonClick(document.id)}>
                                  <i className="flaticon2-trash mr-0"></i>
                                </Button>}
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </PortletBody>
                  </Portlet>
                </Tab>

                <Tab eventKey="RentPaymentBillsTab" title="Rent Payment Bills">
                  <Accordion defaultActiveKey="0">
                    {isRentPaymentBillsTab === true && <RentPaymentBills houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="RentReceiptsTab" title="Rent Receipts">
                  <Accordion defaultActiveKey="0">
                    {isRentReceiptsTab === true && <RentReceipts houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="ServiceRequestsTab" title="Service Requests">
                  <Accordion defaultActiveKey="0">
                    {isServiceRequestsTab === true && <ServiceRequests houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="InspectionsTab" title="Inspections">
                  <Accordion defaultActiveKey="0">
                    {isInspectionsTab === true && <Inspections houseIdFromPropertyProfile={houseId} />}
                    {isInspectionReportsTab === true && <InspectionReports houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="PMSRenewalsTab" title="PMS">
                  <Accordion defaultActiveKey="0">
                    {isPMSRenewalsTab === true && <PMSRenewals houseIdFromPropertyProfile={houseId} updateNewPMSSubscriptionButtonStatus={this.updateNewPMSSubscriptionButtonStatus} />}
                    {isPMSSubscriptionsTab === true && <PMSSubscriptions houseIdFromPropertyProfile={houseId} disableNewPMSButton={disableNewPMSButton} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="PaymentsTab" title="Payments">
                  <Accordion defaultActiveKey="0">
                    {isPaymentsTab === true && <Payments houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

                <Tab eventKey="InvoicesTab" title="Invoices">
                  <Accordion defaultActiveKey="0">
                    {isInvoicesTab === true && <Invoices houseIdFromPropertyProfile={houseId} />}
                  </Accordion>
                </Tab>

              </Tabs>
            </Card.Body>
          </Card>
        </Container>

        <Modal backdrop="static" keyboard={false} key={'m' + 1} show={this.state.showAddDocumentsModal} onHide={this.handleAddDocumentsButtonClick} >
          <Modal.Header closeButton>
            <Modal.Title>Add Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                houseId: (this.state.addDocumentForHouse && houseId) || undefined,
                userId: this.state.activeUser || undefined,
                name: '',
                code: null,
                file: null,
                documentNumber: null,
              }}
              validate={values => {
                const errors = {};
                // if (!values.preMoveInWorks.length === 0) {
                //   errors.preMoveInWorks = 'Atleast one Furnishing Name is required';
                // }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  let formData = new FormData();
                  values.file && formData.append('file', values.file);
                  values.userId && formData.append('documents[userId]', values.userId);
                  values.houseId && formData.append('documents[houseId]', values.houseId);
                  values.name && formData.append('documents[name]', values.name);
                  switch (values.name) {
                    case 'Aadhaar Card':
                      values.code = 'AADHAAR_CARD'
                      break;
                    case 'Pan Card':
                      values.code = 'PAN_CARD'
                      break;
                    case 'Passport':
                      values.code = 'PASSPORT'
                      break;
                    case 'Driving License':
                      values.code = 'DRIVING_LICENSE'
                      break;
                    case 'Employment Proof':
                      values.code = 'EMPLOYMENT_PROOF'
                      break;
                    case 'Home Insurance':
                      values.code = 'HOME_INSURANCE'
                      break;
                    case 'Power of Attorney (POA)':
                      values.code = 'POA'
                      break;
                    case 'Sale Deed':
                      values.code = 'SALE_DEED'
                      break;
                    case 'Sale Index II':
                      values.code = 'SALE_INDEX_II'
                      break;
                    case 'Parking Allotment Letter':
                      values.code = 'PARKING_ALLOTMENT_LETTER'
                      break;
                    case 'Electricity Bill':
                      values.code = 'ELECTRICITY_BILL'
                      break;
                    default:
                      values.code = null
                      break;
                  }
                  values.code && formData.append('documents[code]', values.code);
                  values.documentNumber && formData.append('documents[documentNumber]', values.documentNumber);
                  const result = await commonServices.createUserDocuments(formData);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: values.name + ` Document added!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showAddDocumentsModal: !state.showAddDocumentsModal }));
                    this.getPropertyById();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {!this.state.documentConfirmation && owners?.filter((data) => data.user.id === this.state.activeUser)[0]?.user.houseOwnerships.length + owners?.filter((data) => data.user.id === this.state.activeUser)[0]?.user.leaseTenantParticipations.length > 1 ? <Row>
                      <Col xs={12}>
                        <h5>Please Note</h5>
                        <p>Any changes to this user's details will reflect in all Properties where this user is an Owner and all Leases where this user is a Tenant.</p>
                      </Col>
                      <Col xs={12} className="text-right">
                        <Button onClick={() => this.setState({ documentConfirmation: true })}>Continue</Button>
                      </Col>
                    </Row> :
                      <>
                        <Row>
                          <Col xs={12}>
                            <label>Select Document</label><br />
                            <input id="file" name="file" type="file" onChange={(event) => {
                              setFieldValue("file", event.currentTarget.files[0]);
                            }} />
                          </Col>
                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="name">
                              <Form.Label>Name</Form.Label>
                              {(values.userId !== null && values.userId !== undefined) && <Form.Control as="select" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}>
                                <option value={""}>{constants.DEFAULT_OPTION}</option>
                                <option value={"Aadhaar Card"}>Aadhaar Card</option>
                                <option value={"Pan Card"}>Pan Card</option>
                                <option value={"Passport"}>Passport</option>
                                <option value={"Driving License"}>Driving License</option>
                                <option value={"Emirates ID"}>Emirates ID</option>
                                <option value={"Visa"}>Visa</option>
                                <option value={"Employment Proof"}>Employment Proof</option>
                              </Form.Control>}
                              {(values.houseId !== null && values.houseId !== undefined) && <Form.Control as="select" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}>
                                <option value={""}>{constants.DEFAULT_OPTION}</option>
                                <option value="Power of Attorney (POA)">Power of Attorney (POA)</option>
                                <option value="Sale Deed">Sale Deed</option>
                                <option value="Sale Index II">Sale Index II</option>
                                <option value="Parking Allotment Letter">Parking Allotment Letter</option>
                                <option value="Electricity Bill">Electricity Bill</option>
                                <option value="Home Insurance">Home Insurance</option>
                                <option value="Title Deed">Title Deed</option>
                                <option value="Oqood">Oqood</option>
                                <option value="SPA">SPA</option>
                                <option value="Site Plan">Site Plan</option>
                              </Form.Control>}
                              <span className="text-danger my-2">
                                {errors.executorId && touched.executorId && errors.executorId}
                              </span>
                            </Form.Group>
                          </Col>
                          {(values.name === 'Aadhaar Card' || values.name === 'Pan Card') &&
                            <Col xs={10}>
                              <Form.Group controlId='documentNumber'>
                                <Form.Label>Document Number</Form.Label>
                                <Form.Control type="text" name={`documentNumber`} required="required" onChange={handleChange} onBlur={handleBlur} value={values.documentNumber} />
                              </Form.Group>
                            </Col>
                          }
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={this.handleAddDocumentsButtonClick}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 2} show={this.state.showDeleteDocumentModal} onHide={this.handleDeleteDocumentButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Document?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteDocumentButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteDocumentButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 3} show={this.state.showEditUserModal} onHide={() => this.handleEditUserButtonClick(null, null)} >
          <Modal.Header closeButton>
            <Modal.Title>Edit {this.state.userType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                file: undefined,
                fullName: (userToBeEdited && userToBeEdited.fullName) || '',
                email: (userToBeEdited && userToBeEdited.email) || '',
                worksAs: (userToBeEdited && userToBeEdited.worksAs) || '',
                worksAt: (userToBeEdited && userToBeEdited.worksAt) || '',
                phoneNumbers: (userToBeEdited && userToBeEdited.phoneNumbers) || [{
                  countryCode: undefined,
                  number: undefined,
                  isPrimary: undefined
                }],
                // leaseTenantParticipations: (userToBeEdited && userToBeEdited.leaseTenantParticipations) || [
                //   {
                //     leaseId: this.state.leaseId || undefined,
                //     isPrimary: undefined
                //   }
                // ],
                // houseOwnerships: (userToBeEdited && userToBeEdited.houseOwnerships) || [
                //   {
                //     houseId: (leaseProfile && leaseProfile.lease && leaseProfile.lease.houseOffering
                //       && leaseProfile.lease.houseOffering.house && leaseProfile.lease.houseOffering.house.id)
                //       || undefined,
                //     isPrimary: undefined
                //   }
                // ]
              }}
              validate={values => {
                const errors = {};
                // if (!values.preMoveInWorks.length === 0) {
                //   errors.preMoveInWorks = 'Atlessast one Furnishing Name is required';
                // }
                return errors;
              }}
              onSubmit={
                async (values, { setSubmitting }) => {

                  let formData = new FormData();
                  values.file && formData.append('file', values.file)
                  values.fullName && formData.append('user[fullName]', values.fullName);
                  values.email && formData.append('user[email]', values.email);
                  formData.append('user[worksAs]', values.worksAs);
                  formData.append('user[worksAt]', values.worksAt);
                  values.phoneNumbers && (values.phoneNumbers).forEach((phone, index) => {
                    formData.append(`user[phoneNumbers][${index}][countryCode]`, phone.countryCode);
                    formData.append(`user[phoneNumbers][${index}][number]`, phone.number);
                    formData.append(`user[phoneNumbers][${index}][isPrimary]`, (phone.isPrimary === 'true' || phone.isPrimary === true));
                  });

                  const result = await PropertyProfilePageServices.editUsers(userToBeEdited && userToBeEdited.id, formData);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: this.state.userType + ` Info updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showEditUserModal: !state.showEditUserModal }));
                    this.getPropertyById();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    {!this.state.userConfirmation && owners.filter((data) => data.user.id === userToBeEdited.id)[0].user.houseOwnerships.length + owners.filter((data) => data.user.id === userToBeEdited.id)[0].user.leaseTenantParticipations.length > 1 ?
                      <Row>
                        <Col xs={12}>
                          <h5>Please Note</h5>
                          <p> Any changes to this user's details will reflect in all Properties where this user is an Owner and all Leases where this user is a Tenant.
                          </p>
                        </Col>
                        <Col xs={12} className="text-right">
                          <Button onClick={() => this.setState({ userConfirmation: true })}>Continue</Button>
                        </Col>
                      </Row> :
                      <>
                        <Row>
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <Form.Label>Change Profile Picture</Form.Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <input id="file" name="file" type="file" multiple onChange={(event) => {
                                  setFieldValue("file", event.currentTarget.files[0]);
                                }} />
                                {errors.file && touched.file && <Form.Text className="text-danger">
                                  {errors.file}
                                </Form.Text>}
                              </Col>
                            </Row>

                          </Col>

                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="fullName">
                              <Form.Label>Full Name</Form.Label>
                              <Form.Control type="text" name='fullName' onChange={handleChange} onBlur={handleBlur} value={values.fullName} pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"} />
                            </Form.Group>
                          </Col>

                          <Col xs={12} className='mt-2'>
                            <Form.Group controlId="email">
                              <Form.Label>Email</Form.Label>
                              {userToBeEdited.email === null && <Form.Control type="text" name='email' onChange={handleChange} onBlur={handleBlur} value={values.email} />}
                              {userToBeEdited.email !== null && <Form.Control type="text" name='email' readOnly value={values.email} />}
                              {userToBeEdited.email !== null && <Form.Text className='text-muted'>Edit Disabled</Form.Text>}
                            </Form.Group>
                          </Col>

                          <Col xs={6} className='mt-2'>
                            <Form.Group controlId="worksAs">
                              <Form.Label>Work As</Form.Label>
                              <Form.Control type="text" name='worksAs' onChange={handleChange} onBlur={handleBlur} value={values.worksAs} />
                            </Form.Group>
                          </Col>

                          <Col xs={6} className='mt-2'>
                            <Form.Group controlId="worksAt">
                              <Form.Label>Work At</Form.Label>
                              <Form.Control type="text" name='worksAt' onChange={handleChange} onBlur={handleBlur} value={values.worksAt} />
                            </Form.Group>
                          </Col>


                          <Col xs={12}>
                            <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                            <FieldArray
                              name="phoneNumbers"
                              render={arrayHelpers => (
                                <div>
                                  {values.phoneNumbers.map((phoneNumber, index) =>
                                  (<Row key={index}>
                                    <Col xs={3}>
                                      <Form.Group controlId={"countryCode" + index}>
                                        <Form.Label>Country Code</Form.Label>
                                        <Form.Control type="text" name={`phoneNumbers[${index}].countryCode`} required="required" readOnly value={phoneNumber.countryCode} />
                                      </Form.Group>
                                    </Col>

                                    <Col xs={4}>
                                      <Form.Group controlId={"number" + index}>
                                        <Form.Label>Number</Form.Label>
                                        <Form.Control type="text" name={`phoneNumbers[${index}].number`} required="required" readOnly value={phoneNumber.number} />
                                      </Form.Group>
                                    </Col>

                                    <Col xs={3}>
                                      <Form.Group controlId={"isPrimary" + index}>
                                        <Form.Label>Primary</Form.Label>
                                        <Form.Control as="select" name={`phoneNumbers[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.isPrimary}>
                                          <option value={null}>{constants.DEFAULT_OPTION}</option>
                                          <option value={true}>YES</option>
                                          <option value={false}>NO</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>

                                    <Col xs={2} className='d-flex align-self-center justify-content-right'>
                                      {/* <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button> */}
                                    </Col>
                                    <Col xs={12}>
                                      <Form.Text className='text-muted'>You can edit the Phone Number's Primary status. However, editing the Phone Number itself is disabled. </Form.Text>
                                    </Col>
                                  </Row>)
                                  )}
                                  {/* <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({
                                    countryCode: null,
                                    number: null,
                                    isPrimary: null
                                  })}>Add Phone Number</Button> */}
                                </div>
                              )}
                            />
                            <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="text-right">
                            <Button variant="secondary" className="mr-2" onClick={() => this.handleEditUserButtonClick(null, null)}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                              {isSubmitting ? 'Saving...' : 'Save'}
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 4} show={this.state.showChangePropertyManagementStatusModal} onHide={() => this.handleChangePropertyManagementStatusButtonClick()}>
          <Modal.Header closeButton>
            <Modal.Title>Change Property Management Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                propertyManagementStatus: (propertyDetails && propertyDetails.propertyManagementStatus) || undefined
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await PropertyProfilePageServices.changePropertyManagementStatus(houseId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Property Management Status Changed!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showChangePropertyManagementStatusModal: !state.showChangePropertyManagementStatusModal }));
                    this.getPropertyById();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      <Col xs={12}>
                        <Form.Group controlId="propertyManagementStatusSelect">
                          <Form.Label>Property Management Status</Form.Label>
                          <Form.Control as="select" name="propertyManagementStatus" onChange={handleChange} onBlur={handleBlur} value={values.propertyManagementStatus}>
                            <option value='None'>{constants.DEFAULT_OPTION}</option>
                            <option key="pms1" value="YES">Property Under Management</option>
                            <option key="pms2" value="NO">Not Under Management</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleChangePropertyManagementStatusButtonClick()}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 5} show={this.state.showUpdateTransferNotesModal} onHide={() => this.handleUpdateTransferNotesButtonClick()}>
          <Modal.Header closeButton>
            <Modal.Title>Update Transfer Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                transferNotes: (propertyDetails && propertyDetails.transferNotes) || '',
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await PropertyProfilePageServices.updateTransferNotes(houseId, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Transfer Notes updated!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showUpdateTransferNotesModal: !state.showUpdateTransferNotesModal }));
                    this.getPropertyById();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>

                      <Col xs={12}>
                        <Form.Group controlId="transferNotes">
                          <Form.Label>Transfer Notes</Form.Label>
                          <Form.Control as="textarea" name="transferNotes" onChange={handleChange} onBlur={handleBlur} value={values.transferNotes} rows="4" maxlength="500" />
                          <Form.Text className="small text-muted">{values.transferNotes.length}/500 characters</Form.Text>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleUpdateTransferNotesButtonClick()}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 6} show={this.state.showDeleteHouseOwnershipModal} onHide={this.handleDeleteHouseOwnershipButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete HouseOwnership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Are you sure you want to remove the owner from this property?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteHouseOwnershipButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteHouseOwnershipButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={'m' + 7} show={this.state.showMarkPrimaryUserModal} onHide={this.handleMarkPrimaryUserButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Mark Primary Owner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Confirm marking this owner as Primary?</strong> <br />There can be only one Primary owner for each Property.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleMarkPrimaryUserButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmMarkPrimaryUserButtonClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(PropertyProfilePage);
