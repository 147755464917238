import React, { Component } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { formatDateTime, toAbsoluteUrl } from '../../../_metronic/utils/utils';

export default class TimelineItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
    }
  }

  getEstStatusBadge(estimate_status) {
    if (estimate_status === 'ACCEPTED') return <Badge variant="success" title="Accepted">A</Badge>
    if (estimate_status === '' || estimate_status === null) return <Badge variant="warning" title="Pending">P</Badge>
    if (estimate_status === 'REJECTED') return <Badge variant="danger" title="Rejected">R</Badge>
  }

  render() {
    return (
      <Card className="mb-3 mx-2">
        <Card.Header>
          {this.state.item && <div className="kt-widget4">
            <div className="kt-widget4__item ">
              <div className="kt-widget4__pic kt-widget4__pic--pic ">
                <img alt="" src={toAbsoluteUrl((this.state.item.user && this.state.item.user.profilePicture) || '/media/users/default.jpg')} />
              </div>
              <div className="kt-widget4__info ">
                <span className="kt-widget4__username">{this.state.item.user && this.state.item.user.fullName}</span>
                <span className="kt-widget4__title">
                  <p className="kt-widget4__text ">
                    on {formatDateTime(this.state.item.datetime)}
                  </p>
                </span>
              </div>
            </div>
          </div>}
        </Card.Header>
        <Card.Body>
          {this.state.item && this.state.item.category === 'createServiceRequest' && (
            <>
              <h5>New Service Request created</h5>
              <h6 className="mb-0">{this.state.item.title}</h6>
              <p>DETAILS: {this.state.item.remarks}</p>
              {this.state.item.files && this.state.item.files.length > 0 && <p className="">PHOTOS:</p>}
              <Row>
                {
                  this.state.item.files && this.state.item.files.map(file =>
                    <>
                      <Col>
                        <img className="img-fluid" alt={file.identifier || ""} src={file.url} style={{ height: 100 + 'px' }} /><br />
                        <span className="small">{file.identifier}</span>
                      </Col>
                    </>
                  )
                }
              </Row>
            </>
          )
          }
          {
            this.state.item && this.state.item.category === 'changeServiceRequestAcceptanceStatus' && (
              <>
                <h5 className="mb-0">{this.state.item.title}</h5>
              </>

            )
          }
          {
            this.state.item && this.state.item.category === 'createCostEstimate' && (
              <>
                <h5 className="">New Estimate created</h5>
                {/*<EstimatesComponent estimates={this.state.item.costEstimate} isTimelineComponent={true} />*/}
                <Row className="mb-2">
                  <Col xs={1}>{this.getEstStatusBadge(this.state.item.costEstimate.approvalStatus)}</Col>
                  <Col xs={7}>VENDOR: {this.state.item.costEstimate.vendorName}</Col>
                  <Col xs={4}>TOTAL: {this.state.item.costEstimate.totalAmount}</Col>
                </Row>
                <Row>
                  <Col xs={8}>PARTICULARS</Col>
                  <Col xs={4}>AMOUNT</Col>
                </Row>
                {this.state.item.costEstimate.costEstimateItems.map(item =>
                  <Row>
                    <Col xs={8}>{item.name}</Col>
                    <Col xs={4}>{item.amount}</Col>
                  </Row>
                )}
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'createSchedule' && (
              <>
                <h5 className="mb-0">New Schedule created</h5>
                <p className="mb-0">START: {formatDateTime(this.state.item.schedule.startDatetime)}</p>
                <p>END: {formatDateTime(this.state.item.schedule.startDatetime)}</p>
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'createAssignment' && (
              <>
                <h5 className="mb-0">{this.state.item.remarks}</h5>
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'createNote' && (
              <>
                <h5 className="mb-0">New Note created</h5>
                <p className="mb-0">NOTE: {this.state.item.note.content}</p>
                {/*<p className=''>Remarks: {this.state.item.remarks}</p>*/}
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'executeSchedule' && (
              <>
                <h5 className="mb-0">Schedule executed</h5>
                <p>ON: {formatDateTime(this.state.item.schedule.executedAt)}</p>
                <p className="mb-0">START: {formatDateTime(this.state.item.schedule.startDatetime)}</p>
                <p>END: {formatDateTime(this.state.item.schedule.startDatetime)}</p>
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'closeServiceRequest' && (
              <>
                <h5 className="mb-0">Service Request Closed</h5>
                <p className="">REMARKS: {this.state.item.remarks}</p>
                <p className="">PHOTOS:</p>
                <Row>
                  {
                    this.state.item.files && this.state.item.files.map(file =>
                      <>
                        <Col>
                          <img className="img-fluid" alt={file.identifier || ""} src={file.url} style={{ height: 100 + 'px' }} /><br />
                          <span className="small">{file.identifier}</span>
                        </Col>
                      </>
                    )
                  }
                </Row>
              </>
            )
          }
          {
            this.state.item && this.state.item.category === 'reopenServiceRequest' && (
              <>
                <h5 className="mb-0">Service Request Re-opened</h5>
                <p className="">REMARKS: {this.state.item.remarks}</p>
              </>
            )
          }
        </Card.Body>
      </Card>
    );
  }

}
