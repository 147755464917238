import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to list Rent Escalations
 * @param {Object} data 
 */
async function listRentEscalations(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/rentEscalationWorks`, { params: data });
        return result;
    } catch (err) {
        console.error('error in listRentEscalations ', err);
    }

}

/**
 * Method to get Rent Escalation by ID
 * @param {Object} data 
 */
async function getRentEscalationById(id) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/rentEscalationWorks/${id}`);
        return result;
    } catch (err) {
        console.error('error in getRentEscalationById ', err);
    }
}

/**
 * Method to mark Rent Escalation Work Terminal Status
 * @param {Object} data 
 */
async function markRentEscalationWorkTerminalStatus(id, data) {
    try {
        const result = await axiosInstance.patch(`${baseApiUrl}admin/rentEscalationWorks/${id}`, data);
        return result;
    } catch (err) {
        console.error('error in markRentEscalationWorkTerminalStatus ', err);
    }
}

/**
 * Method to create acknowledgement for Rent Escalation Work
 * @param {Object} data 
 */
async function createAcknowledgement(data) {
    try {
        const result = await axiosInstance.post(`${baseApiUrl}admin/acknowledgements`, data);
        return result;
    } catch (err) {
        console.error('error in createAcknowledgement ', err);
    }
}



export const rentEscalationsServices = {
    listRentEscalations,
    getRentEscalationById,
    markRentEscalationWorkTerminalStatus,
    createAcknowledgement
};