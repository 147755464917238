import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get Inspection Report by id
 * @param {Number} id 
 */
async function getInspectionReportById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/houseInspections/${id}/showReport`);
    return result;
  } catch (err) {
    console.error('error in getInspectionReportById ', err);
  }

}

/**
 * Method to publish report
 * @param {Number} id 
 */
async function publishReport(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseInspections/${id}/publish`);
    return result;
  } catch (err) {
    console.error('error in publishReport ', err);
  }
}


/**
 * Method to change execution details
 * @param {*} id 
 * @param {*} values 
 */

async function changeExecutionDetails(id, values) {
  try {
    const data = {
      'houseInspection':
        { ...values }
    }
    const result = await axiosInstance.patch(`${baseApiUrl}admin/houseInspections/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in changeExecutionDetails ', err);
  }
}

export const InspectionReportServices = {
  getInspectionReportById,
  publishReport,
  changeExecutionDetails
};