import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get Movein Reprt By Id
 * @param {Number} id 
 */
async function getMoveInReportById(id) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/leases/${id}/showMoveInMoveOutReport`);
    return result;
  } catch (err) {
    console.error('error in getMoveInReportById ', err);
  }

}

/**
 * Method to publish report
 * @param {Number} id 
 */
async function publishMoveInReport(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/publishMoveInReport`);
    return result;
  } catch (err) {
    console.error('error in publishMoveInReport ', err);
  }
}
async function publishMoveOutReport(id) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}/publishMoveOutReport`);
    return result;
  } catch (err) {
    console.error('error in publishMoveOutReport ', err);
  }
}
/**
 * Method to change execution details
 * @param {*} id 
 * @param {*} values 
 * TODO: correct the API link below
 */
async function changeExecutionDetails(id, values) {
  try {
    const data = {
      'lease':
        { ...values }
    }
    const result = await axiosInstance.patch(`${baseApiUrl}admin/leases/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in changeExecutionDetails ', err);
  }
}

export const MoveInReportServices = {
  getMoveInReportById,
  publishMoveInReport,
  publishMoveOutReport,
  changeExecutionDetails
};