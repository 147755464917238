import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { ScheduledJobsServices } from './ScheduledJobs.services';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { toAbsoluteUrl, formatDateTime, datePlusOneDay } from '../../../../_metronic/utils/utils';
import { Link } from 'react-router-dom';
import * as constants from '../../../constants';
import { debounce } from 'throttle-debounce';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { commonServices } from '../../../../app/commonServices/commonServices';
import * as firebase from 'firebase/app';

export default class MyProperties extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      state: ['OPEN'],
      requestedFilters: [
        'state',
      ]
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.getAllScheduledJobs();
    // await this.getCities();
    await this.getPropertySupervisors();
  }


  /**
   * component will mount method
   */
  componentWillUnmount() {
    this.getAllScheduledJobs = debounce(
      800,
      this.getAllScheduledJobs
    )
  }

  /**
   * Method to get all the payments data
   */
  getAllScheduledJobs = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, cityIds, state, assigneeId, propertyManagerId,
      minimumStartDatetime, maximumStartDatetime,
      // propertyManagementStatus, searchQuery, prevQuery, 
    } = this.state;
    let newMaximumStartDatetime = maximumStartDatetime && datePlusOneDay(maximumStartDatetime);
    const data = {
      // searchQuery,
      state: (state && state[0]) || undefined,
      cityIds,
      assigneeId: assigneeId !== '0' ? assigneeId : undefined,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      minimumStartDatetime: minimumStartDatetime || undefined,
      maximumStartDatetime: newMaximumStartDatetime || undefined,
      // propertySupervisorId: propertySupervisorId !== '0' ? propertySupervisorId : undefined,
      // propertyManagementStatus: (propertyManagementStatus && propertyManagementStatus[0]) || undefined,
      // propertyManagerAssigned: 'FALSE',
      // include: ['UNACKNOWLEDGED_JOBS_COUNT'],
      offset,
      limit,
      // scope: searchQuery ? 'ALL' : null,
    }
    const result = await ScheduledJobsServices.getAllScheduledJobs(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        scheduledJobs: result.data.jobs,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
      // (prevQuery !== searchQuery) && await this.resetPage();
      // await this.setState({ prevQuery: searchQuery })
    }
    this.setState({ isFetching: false });
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllScheduledJobs();
  }

  /**
   * Method to get the property status badge
   * @param {String} status 
   */
  getJobTypeBadge(jobType) {
    if (jobType === 'MOVE_IN_WORK') return <Badge size="sm" variant="info" title="Move-in Work">Move-in Work</Badge>;
    if (jobType === 'HOUSE_INSPECTION_WORK') return <Badge size="sm" variant="primary" title="House Inspection Work">House Inspection Work</Badge>;
    if (jobType === 'SERVICE_REQUEST') return <Badge size="sm" variant="warning" title="Service Request">Service Request</Badge>;
    if (jobType === 'MISCELLANEOUS') return <Badge size="sm" variant="info" title="Miscellaneous">Miscellaneous</Badge>;
  }

  /**
   * Method to get property supervisors
   */
  getPropertySupervisors = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_SUPERVISOR'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertySupervisors: result.data.users })
    }
  }

  /**
   * Method to get all the cities
   */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData })
      await this.resetPage();
      await this.getAllScheduledJobs();
      await this.getCities();
      await this.getPropertySupervisors();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'My Properties' });
    }
  }

  /**
   * Method to reset Pagination
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    })
  }

  render() {
    const { total, totalCount, page, scheduledJobs, isFetching, searchQuery, state,
      requestedFilters, propertySupervisors, assigneeId, formattedCities, preSelectedCities,
      propertyManagers, propertyManagerId, minimumStartDatetime, maximumStartDatetime,
      // propertyManagementStatus,
    } = this.state
    if (searchQuery) {

    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (

      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader title={`Scheduled Jobs (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <Card>
                <FilterComponent
                  requestedFilters={requestedFilters}
                  state={state}
                  formattedCities={formattedCities}
                  preSelectedCities={preSelectedCities}
                  propertySupervisors={propertySupervisors}
                  assigneeId={assigneeId}
                  propertyManagers={propertyManagers}
                  propertyManagerId={propertyManagerId}
                  minimumStartDatetime={minimumStartDatetime}
                  maximumStartDatetime={maximumStartDatetime}
                  recordsPerPage={constants.RECORDS_PER_PAGE}
                  selectedRecordValue={this.state.limit}
                  filterParentCallback={this.handleFilterChildData}
                  // propertyManagementStatus={propertyManagementStatus}
                  // isMyProperties = {true}
                  />
                <Card.Header>
                  <Row className="azuro-table-header">
                    <Col xs={6}><strong>Job</strong></Col>
                    <Col xs={3}><strong>Scheduled for</strong></Col>
                    <Col xs={3}><strong>Assigned to</strong></Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0">
                  {(isFetching || !scheduledJobs) && <p className="m-3">{constants.LOADING_DATA}</p>}
                  {!isFetching &&
                    <>
                      {scheduledJobs && scheduledJobs.length === 0 && <p>{constants.ZERO_DATA}</p>}
                      {scheduledJobs && scheduledJobs.length > 0 && scheduledJobs.map(job =>
                        <React.Fragment key={job.id}>
                          <Row key={job.id} className="azuro-table-rowd-flex align-items-center px-3 py-2">
                            <Col xs={6}>
                              {job.title}<br/>
                              <span className="small text-muted"><span className="pr-3">{this.getJobTypeBadge(job.jobType.code)}</span> <span>{job.house?.formattedHouseName}</span></span>
                            </Col>
                            <Col xs={3}>
                              {job.startDatetime !== null && <span className={job.isOverdue ? "text-danger" : ""} title={job.isOverdue ? "Overdue" : ""}>{formatDateTime(job.startDatetime)}<br/>{'to '+formatDateTime(job.endDatetime)}</span>}
                            </Col>
                            <Col xs={3} className="d-flex flex-row justify-content-between">
                              {/* {job.assignee.fullName} */}
                              <div className="kt-widget4">
                                <div className="kt-widget4__item ">
                                  <div className="kt-widget4__pic kt-widget4__pic--pic ">
                                    <img alt="" src={toAbsoluteUrl(job.assignee?.profilePicture || '/media/users/default.jpg')} />
                                  </div>
                                  <div className="kt-widget4__info ">
                                    <p className="mb-0 kt-widget4__username">
                                      {job.assignee?.fullName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <Link to={`/property-profile/${job.house?.id}`}>
                                <Button size='sm' variant='clean'><i className="flaticon-home-1 text-primary"></i></Button>
                              </Link>
                            </Col>
                            <Col xs={12}>
                              <hr className="my-1" />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      {/* Code for pagination */}
                      {!isFetching && showPagination && showPagination && <UltimatePagination
                        currentPage={page}
                        totalPages={total}
                        onChange={this.onPageChange}
                        boundaryPagesRange={0}
                        hidePreviousAndNextPageLinks={true}
                        hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                      />}
                    </>
                  }
                </Card.Body>
              </Card>
            </PortletBody>
          </Portlet>
        </Container>
      </>
    )
  }

}
