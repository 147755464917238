import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all rent receipts
 * @param {Object} data
 */
async function getAllRentReceipts(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/rentReceipts`, { params: data });
    return result;
  } catch (err) {
    console.error('error in getAllRentReceipts ', err);
  }

}

export const RentReceiptsServices = {
  getAllRentReceipts
};