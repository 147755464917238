import React, { Component } from 'react';
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar
} from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Badge, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { MyPropertiesServices } from './MyPropertes.services';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { formatMultipleUsers, formatAmount, formatDate } from '../../../../_metronic/utils/utils';
import { Link } from 'react-router-dom';
import * as constants from '../../../constants';
import { debounce } from 'throttle-debounce';
import FilterComponent from '../../../partials/layout/FilterComponent';
import { commonServices } from '../../../../app/commonServices/commonServices';
import * as firebase from 'firebase/app';

export default class MyProperties extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      requestedFilters: [
        'propertyManagementStatus', 'pmsSubscriptionType'
      ],
      propertyManagementStatus: ['YES']
    }
  }

  /**
   * component did mount method
   */
  async componentDidMount() {
    await this.getCities();
    await this.getAllProperties();
    await this.getPropertyManagers();
  }

  /**
   * Method to get property managers
   */
  getPropertyManagers = async () => {
    const data = {
      userRoleCodes: ['PROPERTY_MANAGER'],
    }
    const result = await commonServices.getUserListData(data);
    if (result && result.status === 200) {
      await this.setState({ propertyManagers: result.data.users })
    }
  }

  /**
   * Method to get all the cities
   */
  getCities = async () => {
    const { cityIds } = this.state;
    const data = {
      pmsOperationStatus: 'YES'
    }
    const result = await commonServices.getCities(data);
    if (result && result.status === 200) {
      const cities = result?.data?.cities;
      const formattedCities = commonServices.formatCities(cities);
      let preSelectedCities = null;
      if (this.state.cityIds?.length > 0) {
        preSelectedCities = commonServices.preSelectCities(formattedCities, cityIds);
      }
      this.setState({ formattedCities });
      preSelectedCities && this.setState({ preSelectedCities });
    }
  }

  /**
   * component will mount method
   */
  componentWillMount() {
    this.getAllProperties = debounce(
      800,
      this.getAllProperties
    )
  }

  /**
   * Method to get all the payments data
   */
  getAllProperties = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, searchQuery, propertyManagementStatus, prevQuery, cityIds,
      propertyManagerId, beatsListingIdentifier, pmsSubscriptionType } = this.state;
    const data = {
      searchQuery,
      cityIds,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      propertyManagementStatus: (!searchQuery && !beatsListingIdentifier) ? (propertyManagementStatus && propertyManagementStatus[0]) || undefined : undefined,
      // propertyManagerAssigned: 'FALSE',
      include: ['LATEST_LEASE_MOVE_IN_MANAGER', 'CURRENT_RENT', 'PROPERTY_MANAGER', 'OCCUPANCY_STATUS', 'CURRENT_TENANTS', 'LATEST_LEASE', 'OWNERS', 'PMS_SUBSCRIPTIONS'],
      offset,
      limit,
      scope: ( searchQuery || beatsListingIdentifier ) ? 'ALL' : null,
      beatsListingIdentifier,
      minimumExclusiveFeeRateQuantity: pmsSubscriptionType && pmsSubscriptionType.includes("Paid") ? 0 : undefined,
      maximumFeeRateQuantity: pmsSubscriptionType && pmsSubscriptionType.includes("Free") ? 0 : undefined
    }
    const result = await MyPropertiesServices.getAllProperties(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      await this.setState({
        myProperties: result.data.houses,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      });
      (prevQuery !== searchQuery) && await this.resetPage();
      await this.setState({ prevQuery: searchQuery })
    }
    this.setState({ isFetching: false });
  }

  /**
   * method to handle page change for pagination
   * @param {*} page
   */
  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllProperties();
  }

  /**
   * Method to get the property status badge
   * @param {String} status 
   */
  getPropertyStatusBadge(status) {
    return status === 'OCCUPIED' ? <Badge variant="success" title="Occupied">O</Badge> : <Badge variant="warning" title="Vacant">V</Badge>
  }

  /**
   * Method to handle data of add payment component
   * @param {String} childData
   */
  handleFilterChildData = async (childData) => {
    if (childData) {
      this.setState({ ...childData })
      await this.resetPage();
      await this.getAllProperties();
      await this.getCities();
      await this.getPropertyManagers();
      firebase.analytics().logEvent("apply_filter_button_click", { description: "Apply Filter Button Clicked", filterComponent: 'My Properties' });
    }
  }

  /**
   * Method to reset Pagination
   */
  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      // limit: 10,
    })
  }

  render() {
    const { total, totalCount, page, myProperties, isFetching, searchQuery,
      requestedFilters, propertyManagementStatus, formattedCities, preSelectedCities,
      propertyManagers, propertyManagerId, pmsSubscriptionType } = this.state;
    if (searchQuery) {

    }
    let showPagination = false;
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (

      <>
        <Container fluid>
          <Portlet fluidHeight={true}>
            <PortletHeader title={`My Properties (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  <Formik
                    initialValues={{
                      searchQuery: undefined
                    }}
                    onSubmit={
                      async (values, { setSubmitting }) => {
                        if (values.searchQuery && values.searchQuery.length > 2) {
                          await this.setState({ searchQuery: values.searchQuery })
                          setTimeout(() => {
                            this.getAllProperties();
                          });
                        } else if ((!values.searchQuery) || (values.searchQuery && values.searchQuery.length < 2)) {
                          await this.setState({ searchQuery: undefined })
                          setTimeout(() => {
                            this.getAllProperties();
                          });
                        }
                      }

                    }
                  >
                    {
                      ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                      (
                        <Form className="py-2">
                          <Form.Text id="searchHelpText" muted>Search by Property Name or by Customer's Name, Email or Phone Number or by TCF ID</Form.Text>
                          <Form.Control className="d-inline" type="text" name="searchQuery" placeholder="Type to search..." value={values.searchQuery}
                            onChange={
                              (e) => {
                                handleSubmit(e);
                                handleChange(e);
                              }
                            }
                          />
                        </Form>
                      )
                    }
                  </Formik>
                  <span className="px-2">OR</span>
                  <Formik
                    initialValues={{
                      beatsListingIdentifier: undefined
                    }}
                    onSubmit={
                      async (values, { setSubmitting }) => {
                        if (values.beatsListingIdentifier && values.beatsListingIdentifier.length > 2) {
                          await this.setState({ beatsListingIdentifier: values.beatsListingIdentifier })
                          setTimeout(() => {
                            this.getAllProperties();
                          });
                        } else if ((!values.beatsListingIdentifier) || (values.beatsListingIdentifier && values.beatsListingIdentifier.length < 2)) {
                          await this.setState({ beatsListingIdentifier: undefined })
                          setTimeout(() => {
                            this.getAllProperties();
                          });
                        }
                      }
                    }>
                    {
                      ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                      (
                        <Form className="py-2">
                          <Form.Text id="BeatsHelpText" muted>Search using Beats Listing ID.</Form.Text>
                          <Form.Control className="d-inline" type="text" name="beatsListingIdentifier" placeholder="Type to search..." value={values.beatsListingIdentifier}
                            onChange={
                              (e) => {
                                handleSubmit(e);
                                handleChange(e);
                              }
                            }
                          />
                        </Form>
                      )}
                  </Formik>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <Card>
                <FilterComponent
                  formattedCities={formattedCities}
                  preSelectedCities={preSelectedCities}
                  requestedFilters={requestedFilters}
                  propertyManagers={propertyManagers}
                  propertyManagerId={propertyManagerId}
                  recordsPerPage={constants.RECORDS_PER_PAGE}
                  selectedRecordValue={this.state.limit}
                  filterParentCallback={this.handleFilterChildData}
                  propertyManagementStatus={propertyManagementStatus}
                  pmsSubscriptionType={pmsSubscriptionType}
                  isMyProperties={true} />
                <Card.Header>
                  <Row className="azuro-table-header">
                    <Col md={3}><strong>Property</strong></Col>
                    <Col md={2}><strong>Owner</strong></Col>
                    <Col md={2}><strong>Tenant</strong></Col>
                    <Col md={2}><strong>Current Rent</strong></Col>
                    <Col md={2}><strong>Move-in Date</strong></Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0">
                  {(isFetching || !myProperties) && <p className="m-3">{constants.LOADING_DATA}</p>}
                  {!isFetching &&
                    <>
                      {myProperties && myProperties.length === 0 && <p>{constants.ZERO_DATA}</p>}
                      {myProperties && myProperties.length > 0 && myProperties.map(property =>
                        <React.Fragment key={property.id}>
                          <Row key={property.id} className="azuro-table-rowd-flex align-items-center px-3 py-2">
                            <Col md={3} className="d-flex flex-row"><span className="pr-3">{this.getPropertyStatusBadge(property.occupancyStatus || 'CLOSED')}</span>
                              <span>{property.formattedHouseName} <br />
                                {property.propertyManager ? <span className="small text-muted">PM: {property.propertyManager && property.propertyManager.fullName}</span> : <span className="small text-warning">PM: Not Assigned</span>}
                              </span>
                            </Col>
                            <Col md={2}>
                              {formatMultipleUsers((property.owners) || [])}
                            </Col>
                            <Col md={2}>
                              {formatMultipleUsers((property.currentTenants) || [])}
                            </Col>
                            <Col md={2}>{formatAmount(property.currentRent, property.currency)}</Col>
                            <Col md={2} className="" >
                              <span>{property.latestLease && property.latestLease.moveInDate ? formatDate(property.latestLease.moveInDate) : 'Not Set'}<br />
                                {property.propertyManagementStatus === 'YES' ? <span className="small text-success">Under Management</span> : property.propertyManagementStatus === 'NO' ? <span className="small text-danger">Not Under Management</span> : <span className="small text-muted">Status Not Set</span>}</span>
                            </Col>
                            <Col md={1}>
                              <Link to={`/property-profile/${property.id}`}>
                                <Button size='sm' variant='clean'><i className="flaticon-home-1 text-primary"></i></Button>
                              </Link>
                            </Col>
                            <Col md={12}>
                              <hr className="my-1" />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      {/* Code for pagination */}
                      {!isFetching && showPagination && showPagination && <UltimatePagination
                        currentPage={page}
                        totalPages={total}
                        onChange={this.onPageChange}
                        boundaryPagesRange={0}
                        hidePreviousAndNextPageLinks={true}
                        hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
                      />}
                    </>
                  }
                </Card.Body>
              </Card>
            </PortletBody>
          </Portlet>
        </Container>
      </>
    )
  }

}
