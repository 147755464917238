import React, { Component } from 'react';
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "../../../partials/content/Portlet";
import { Container, Row, Col, Card, Button, Accordion, Modal, Form, Badge } from 'react-bootstrap';
import NotesComponent from '../../../partials/layout/NotesComponent';
import { securityDepositBillsServices } from './SecurityDepositBills.services';
import { commonServices } from '../../../commonServices/commonServices';
import { connect } from 'react-redux';
import * as constants from '../../../constants';
import { Formik } from 'formik';
import { formatDate, formatDateTime, formatAmount } from '../../../../_metronic/utils/utils';
import UltimatePagination from '../../../partials/layout/PaginationComponent';
import { store } from 'react-notifications-component';
import * as firebase from 'firebase/app';
import AdditionalPaymentDetails from '../../../partials/layout/AdditionalPaymentDetails';
import { DatePickerField } from '../../../partials/layout/DatePickerField';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

const newSecurityDepositBillSchema = Yup.object().shape({
  paymentBill: Yup.object().shape({
    securityDepositLeaseId: Yup.number().required(),
    dueDate: Yup.date().required('Payment Due Date is a mandatory field'),
    amount: Yup.number().required('Amount is a mandatory field').moreThan(0, 'Amount cannot be zero or negative'),
    billAmount: Yup.number().required('Bill Amount is a mandadory field'),

  })
});

const editSecurityDepositBillSchema = Yup.object().shape({
  paymentBill: Yup.object().shape({
    dueDate: Yup.date().required('Payment Due Date is a mandatory field'),
    amount: Yup.number().required('Amount is a mandatory field').moreThan(0, 'Amount cannot be zero or negative'),
    billAmount: Yup.number().required('Bill Amount is a mandatory field'),
  })
});

class SecurityDepositBills extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      isFetching: false,
      showCreateSecurityDepositPaymentBillModal: false,
      editSecurityDepositPaymentBillModal: false,
      showDeleteSecurityDepositBillModal: false,
      page: 1,
      total: undefined,
      offset: 0,
      limit: 10,
      showChequeDetailsFields: false,
      showTransferModal: false,
      showMarkAsPaidSecurityDepositModal: false,
      state: (!props.houseIdFromPropertyProfile && !props.leaseIdFromLeaseProfile) ? ['OPEN'] : undefined,
      propertyManagementStatus: ['YES'],
      requestedFilters: ['state', 'propertyManagementStatus'],
      noteComponentId: undefined,
      originalSecurityDepositAmount: 0
    }
  }

  async componentDidMount() {
    await this.getAllSecurityDepositBills();
  }

  getAllSecurityDepositBills = async () => {
    this.setState({ isFetching: true });
    const { offset, limit, houseIdFromPropertyProfile, leaseIdFromLeaseProfile, cityIds,
      maximumPaymentDueDate, maximumLatestNoteCreatedAt, propertyManagerId, state, propertyManagementStatus } = this.state;
    const data = {
      state: (state && state[0]) || undefined,
      maximumPaymentDueDate, maximumLatestNoteCreatedAt,
      // propertyManagementStatus: (houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? undefined : 'YES',
      propertyManagementStatus: (!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile) ? (propertyManagementStatus && propertyManagementStatus[0]) : undefined,
      propertyManagerId: propertyManagerId !== '0' ? propertyManagerId : undefined,
      cityIds,
      houseId: houseIdFromPropertyProfile,
      securityDepositLeaseId: leaseIdFromLeaseProfile,
      include: houseIdFromPropertyProfile ? undefined : ['PROPERTY_MANAGER'],
      offset,
      limit,
    }
    const result = await securityDepositBillsServices.getAllSecurityDepositPaymentBills(data);
    if (result && result.status === 200) {
      const totalCount = result.headers['x-total-count'];
      const totalPageNo = Math.ceil(totalCount / limit);
      this.setState({
        securityDepositBills: result.data.securityDepositPaymentBills,
        total: totalPageNo === 0 ? 1 : totalPageNo,
        totalCount
      })
    }
    this.setState({ isFetching: false });
  }

  getSecurityDepositBillById = async (id) => {
    const result = await securityDepositBillsServices.getSecurityDepositBillById(id);
    if (result && result.status === 200) {
      if (result?.data?.paymentBill?.resultantPaymentBillAdjustments?.length > 0) {
        result.data.paymentBill.resultantPaymentBillAdjustments.forEach((rpa, index) => { rpa.id = index; rpa.isChecked = true; });
        result.data.paymentBill.initialFinalAmount = await this.calculateFinalAmountForBillAdjustments(null, result.data.paymentBill.resultantPaymentBillAdjustments, null);
      }
      await this.setState({
        accordionData: result.data.securityDepositPaymentBill,
        toBeEdited: result.data.securityDepositPaymentBill,
      });
    }
  }

  handleInitiateTransferButtonClick = async (sourcePaymentId = null) => {
    if (sourcePaymentId) {
      let data = { sourcePaymentId: sourcePaymentId }
      await this.getNewTransferInformation(data);
      this.setState(state => ({ transferData: data }));
    }
    this.setState(state => ({ showTransferModal: !state.showTransferModal }));
  }

  getNewTransferInformation = async (data) => {
    const result = await commonServices.getNewTransferBankAccountsAndPaymentAdjustments(data);
    if (result.status === 200) {
      this.setState({
        bankAccountsForTransfer: result.data.bankAccounts,
        sourcePaymentForTransfer: result.data.sourcePayment
      })
    }
  }

  handleNewSecurityDepositBillButtonClick = async (leaseIdFromLeaseProfile = null) => {
    if (leaseIdFromLeaseProfile !== null) {
      const result = await securityDepositBillsServices.getSecurityDepositBillAdjustments(leaseIdFromLeaseProfile);
      if (result.status === 200) {
        if (result.data.paymentBill.resultantPaymentBillAdjustments.length > 0) {
          result.data.paymentBill.resultantPaymentBillAdjustments.forEach((rpa, index) => { rpa.id = index; rpa.isChecked = true; });
          result.data.paymentBill.initialFinalAmount = await this.calculateFinalAmountForBillAdjustments(null, result.data.paymentBill.resultantPaymentBillAdjustments, null);
        }
        this.setState({
          suggestedPaymentBillAdjustments: result.data.paymentBill.resultantPaymentBillAdjustments,
          initialFinalAmount: result.data.paymentBill.initialFinalAmount
        });
      }
    }
    this.setState(state => ({ showCreateSecurityDepositPaymentBillModal: !state.showCreateSecurityDepositPaymentBillModal }))
  }

  calculateFinalAmountForBillAdjustments = async (e, rpas, setFieldValue) => {
    let finalAmount = Number(this.state.originalSecurityDepositAmount);
    rpas && rpas.forEach(rpa => {
      if (rpa.isChecked) {
        console.log('subtracting', Number(rpa.adjustedPaymentBill.amount));
        finalAmount = finalAmount - Number(rpa.adjustedPaymentBill.amount);
      }
    });
    if (setFieldValue) {
      setFieldValue(`paymentBill.amount`, finalAmount.toFixed(2));
      this.forceUpdate();
    } else {
      return finalAmount.toFixed(2);
    }
  }

  getNewTransferBankAccounts = async (data) => {
    const result = await commonServices.getNewTransferBankAccounts(data);
    if (result.status === 200) {
      this.setState({
        bankAccounts: result.data.bankAccounts
      })
    }
  }

  handleEditSecurityDepositBillButtonClick = async (id = null) => {
    this.setState(state => ({ editSecurityDepositPaymentBillModal: !state.editSecurityDepositPaymentBillModal }));
  }

  handleMarkAsPaidSecurityDepositBillButtonClick = async () => {
    await this.setState(state => ({ showMarkAsPaidSecurityDepositModal: !state.showMarkAsPaidSecurityDepositModal }));
    if (this.state.showMarkAsPaidSecurityDepositModal === true) {
      firebase.analytics().logEvent("mark_as_paid_button_click", { description: "Mark As Paid Button Clicked" });
    }
  }

  handleAccordionClick = async (id) => {
    const { prevId } = this.state;
    if (prevId !== id) {
      this.setState({ prevId: id });
      await this.getSecurityDepositBillById(id);
      firebase.analytics().logEvent("rent_payments_details_view_click", { description: "Rent Payment Bills Details View Clicked", rentPaymentId: id });
    }
  }

  checkIfTheBillHasDebitPayment = (payments) => {
    const debitPayment = payments?.find(payment => payment.direction === 'DEBIT');
    if (debitPayment) {
      return true;
    } else {
      return false;
    }
  }

  handleChildData = async (securityDepositId) => {
    this.getSecurityDepositBillById(securityDepositId);

  }

  onPageChange = async (page) => {
    const { limit } = this.state;
    const offset = (limit * (page - 1));
    await this.setState({ page, offset });
    await this.getAllSecurityDepositBills();
  }

  resetPage = async () => {
    await this.setState({
      page: 1,
      offset: 0,
      //limit: 10,
    });
  }

  handleDeleteSecurityDepositBillButtonClick = (id = null) => {
    if (id !== null) {
      this.setState({ idToBeDeleted: id })
    }
    this.setState(state => ({ showDeleteSecurityDepositBillModal: !state.showDeleteSecurityDepositBillModal }));
  }

  handleConfirmDeleteSecurityDepositBillButtonClick = async () => {
    if (this.state.idToBeDeleted) {
      const result = await securityDepositBillsServices.deleteSecurityDepositBill(this.state.idToBeDeleted);
      if (result && result.status === 200) {
        store.addNotification({
          title: "Success!",
          message: `Security Deposit Bill deleted!`,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            showIcon: true,
            pauseOnHover: true
          }
        });
        this.setState(state => ({ showDeleteSecurityDepositBillModal: !state.showDeleteSecurityDepositBillModal }));
        this.getAllSecurityDepositBills();
      }
    }
  }

  render() {
    const { securityDepositBills, accordionData, showCreateSecurityDepositPaymentBillModal, showMarkAsPaidSecurityDepositModal, editSecurityDepositPaymentBillModal, showTransferModal, showDeleteSecurityDepositBillModal,
      page, total, totalCount, bankAccountsForTransfer, sourcePaymentForTransfer, houseIdFromPropertyProfile, leaseIdFromLeaseProfile, isFetching, transferData, suggestedPaymentBillAdjustments
    } = this.state;
    let showPagination = false;
    let taskAssigneeData = [];
    taskAssigneeData.push(this.props.userDetails)
    if (isFetching) {
      return <p>{constants.LOADING_DATA}</p>
    }
    if (total) { showPagination = total !== 0 && total !== 1; }
    return (
      <>
        <Container fluid key="c1">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={`Security Deposit Bills (${totalCount || 0})`}
              toolbar={
                <PortletHeaderToolbar>
                  {leaseIdFromLeaseProfile && <Button className="mr-2" variant="link" size="sm" onClick={() => this.handleNewSecurityDepositBillButtonClick(leaseIdFromLeaseProfile)}>New Security Deposit Bill</Button>}
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <Accordion defaultActiveKey="0">
                <Card>
                  {securityDepositBills && securityDepositBills.length === 0 && <p>{constants.ZERO_DATA}</p>}
                  {securityDepositBills && securityDepositBills.length > 0 &&
                    <Card.Header>
                      <Row className="py-2 px-3 azuro-table-header">
                        {!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile && <Col xs={3}><strong>Property</strong></Col>}
                        <Col xs={1}><strong>Bill ID</strong></Col>
                        <Col xs={3}><strong>Payable Amount</strong></Col>
                        <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 4 : 2}><strong>Due Date</strong></Col>
                        <Col xs={3}><strong>Status</strong></Col>
                      </Row>
                    </Card.Header>}
                </Card>
                {securityDepositBills && securityDepositBills.map(securityDepostPaymentBill =>
                  <Card key={securityDepostPaymentBill.id}>
                    <Accordion.Toggle as={Card.Header} eventKey={securityDepostPaymentBill.id} className="row py-2 azuro-table-row d-flex align-items-center"
                      onClick={() => { this.handleAccordionClick(securityDepostPaymentBill.id) }} >
                      {!houseIdFromPropertyProfile && !leaseIdFromLeaseProfile && <Col xs={3}>
                        {securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house && <span>{securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house?.formattedHouseName} <br />
                          <span className="small text-muted">{securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house.propertyManager ? `PM: ${(securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house.propertyManager && securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house.propertyManager.fullName)}` : `PM: Not Assigned`}</span>
                        </span>}
                      </Col>}
                      <Col xs={1}>#{securityDepostPaymentBill.id}</Col>
                      <Col xs={3}>
                        {securityDepostPaymentBill.amount && formatAmount(securityDepostPaymentBill.amount, securityDepostPaymentBill.currency)}
                      </Col>
                      <Col xs={(houseIdFromPropertyProfile || leaseIdFromLeaseProfile) ? 4 : 2}>
                        {securityDepostPaymentBill.dueDate && formatDate(securityDepostPaymentBill.dueDate)}
                      </Col>
                      <Col xs={3} className="d-flex justify-content-between align-items-center pull-right">
                        <span className="flex-fill">
                          {securityDepostPaymentBill.status}
                        </span>
                        {securityDepostPaymentBill.securityDepositLease && securityDepostPaymentBill.securityDepositLease.houseOfferings && securityDepostPaymentBill.securityDepositLease.houseOfferings.house && <React.Fragment>
                          {!houseIdFromPropertyProfile && <Link to={`/property-profile/${securityDepostPaymentBill.securityDepositLease.houseOfferings.house.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-home-1"></i></Button>
                          </Link>}
                          {!leaseIdFromLeaseProfile && <Link to={`/lease-profile/${securityDepostPaymentBill.securityDepositLease.id}`}>
                            <Button size='sm' variant='clean'><i className="flaticon-edit-1"></i></Button>
                          </Link>}

                        </React.Fragment>}
                      </Col>
                    </Accordion.Toggle>

                    {
                      accordionData && (accordionData.id === securityDepostPaymentBill.id) &&
                      <Accordion.Collapse eventKey={securityDepostPaymentBill.id}>
                        <Card.Body>
                          <Row className="mb-4">
                            <Col>
                              <p><small>Created by {accordionData && accordionData.createdBy?.fullName ? accordionData.createdBy?.fullName : "Unknown"} at {formatDateTime(accordionData.createdAt)}</small></p>
                            </Col>
                            {accordionData.status === 'PENDING TENANT ACTION' && <Col xs={12}>
                              {<>
                                <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleEditSecurityDepositBillButtonClick(accordionData.id)}><i className="flaticon2-edit"></i></Button>
                                {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') && Math.floor((Math.abs(new Date() - new Date(accordionData.createdAt))) / (1000 * 60 * 60 * 24)) < 90) &&
                                  <Button className="pull-right" variant="clean" size="sm" onClick={() => this.handleDeleteSecurityDepositBillButtonClick(accordionData.id)}><i className="flaticon2-trash"></i></Button>}
                              </>}
                            </Col>}
                          </Row>

                          <NotesComponent notes={accordionData.notes || []} allowAddNotes={
                            (!accordionData.terminalStatus)
                          }
                            paymentBillId={accordionData.id}
                            parentCallback={this.handleChildData}
                          />

                          {accordionData.effectivePaymentPreBill && accordionData.effectivePaymentPreBill.id && <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Owner and Bank Account Details</h5>
                            </Col>
                            <Col>
                              <p className="mb-0"><strong>Owner's Name</strong>: {accordionData.effectivePaymentPreBill.payeeProperties.fullName}</p>
                              <p className="mb-0"><strong>Email</strong>: {accordionData.effectivePaymentPreBill.payeeProperties.email || 'Not Entered'}</p>
                              <p className="mb-0"><strong>Phone Number</strong>: +{accordionData.effectivePaymentPreBill.payeeProperties?.phoneCountryCode}-{accordionData.effectivePaymentPreBill.payeeProperties?.phoneNumber}</p>
                              <p className="mb-0"><strong>PAN</strong>: {accordionData.effectivePaymentPreBill.payeeProperties.pan || 'Not Entered'}</p>
                              {(accordionData.effectivePaymentPreBill.payeeBankAccountProperties?.vpa?.length > 0) ?
                                <p className="mb-0"><strong>VPA</strong>: {accordionData.effectivePaymentPreBill.payeeBankAccountProperties.vpa}</p> :
                                <span><p className="mb-0"><strong>Account Holder's Name</strong>: {accordionData.effectivePaymentPreBill.payeeBankAccountProperties.accountHolderName}</p>
                                  <p className="mb-0"><strong>Account Number</strong>: {accordionData.effectivePaymentPreBill.payeeBankAccountProperties.accountNumber}</p>
                                  <p className="mb-0"><strong>Account Type</strong>: {accordionData.effectivePaymentPreBill.payeeBankAccountProperties.accountType}</p>
                                  <p><strong>IFSC</strong>: {accordionData.effectivePaymentPreBill.payeeBankAccountProperties.ifsc}</p></span>
                              }
                            </Col>
                          </Row>
                          }
                          <Row className="mb-4">
                            <Col xs={12}>
                              <h5>Bill Adjustments</h5>
                            </Col>
                            <Col xs={12}>
                              <p className="mb-0 small text-muted">As per Lease Agreement</p>
                              <p>Bill Amount before Adjustments: {formatAmount(accordionData.billAmount)}</p>
                            </Col>
                            {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length === 0 &&
                              <Col xs={12}>
                                <p>No Bill Adjustments</p>
                              </Col>
                            }
                            {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length > 0 && accordionData.resultantPaymentBillAdjustments.map(billAdjust =>
                              <>
                                <Col xs={6}>
                                  <p className="mb-0 small text-muted">Payment Bill #{billAdjust.adjustedPaymentBill.id}</p>
                                  <p>Token Amount: <span className={billAdjust.effect === "NEGATIVE" ? "text-danger" : ""}>{(billAdjust.effect === "NEGATIVE") ? "-" : ""}{formatAmount(billAdjust.adjustedPaymentBill.amount)}</span></p>
                                </Col>
                              </>)
                            }
                          </Row>

                          <Row className="mb-4">
                            <Col xs={10}>
                              <h5>Payments</h5>
                            </Col>
                          </Row>
                          {accordionData.payments && accordionData.payments.length === 0 && <Row>
                            <Col xs={12} className="text-center">
                              <p>No Payments</p>
                            </Col>
                          </Row>}
                          {accordionData.payments && accordionData.payments.length > 0 && accordionData.payments.map(payment => <Row>
                            <Col xs={8}>
                              <p className="small text-muted mb-0">Payment ID #{payment.id} | Ref No: {payment.reference}</p>
                              <p className="mb-0"><span className={payment.direction === 'CREDIT' ? 'text-success' : 'text-danger'}>{payment.direction}</span> | {formatAmount(payment.amount, payment.currency)} via {payment.mode} | {payment.user && `${payment.direction === 'DEBIT' ? 'Paid to ' : 'Received from '} ${payment.user.fullName}`}</p>
                              <p className="small text-muted">on {formatDateTime(payment.datetime)}</p>
                            </Col>
                            <Col xs={2} className="text-right">
                              {payment.paymentGatewayProperties && <AdditionalPaymentDetails paymentDetails={payment.paymentGatewayProperties} />}
                            </Col>
                            <Col xs={2} className="text-right">
                              {(this.props.userDetails.userRoleCodes.includes('PROPERTY_MANAGEMENT_HEAD') || this.props.userDetails.userRoleCodes.includes('MOVE_IN_MANAGER')) && (payment?.direction === 'CREDIT') && (payment?.paymentGatewayName === 'CASHFREE') &&
                                (accordionData.terminalStatus === null) && payment?.currency === 'INR' && payment?.paymentGatewaySettlementStatus === 'YES' && accordionData.pendingTransferableAmount > 0 &&
                                <Button variant="link" size="sm" onClick={() => this.handleInitiateTransferButtonClick(payment.id)}>Initiate Transfer</Button>
                              }
                            </Col>
                            {payment.resultantPaymentAdjustments && payment.resultantPaymentAdjustments.length > 0 && <Col xs={12} className="pl-4">
                              <h6>Adjustments of this Payment:</h6>
                            </Col>}
                            {payment.resultantPaymentAdjustments && payment.resultantPaymentAdjustments.length > 0 && payment.resultantPaymentAdjustments.map((rpa, index) => <Col xs={12} className="pl-4 mb-3" key={"rpa" + index}>
                              <p className="small text-muted mb-0">Invoice #{rpa.invoice.invoiceNumber}</p>
                              {rpa.invoice.invoiceItems && rpa.invoice.invoiceItems.length > 0 && rpa.invoice.invoiceItems.map(item => <p className="mb-0">&gt; {item.name} : {formatAmount(item.amount)}</p>)}
                              <p className="mb-0">Total: {formatAmount(rpa.invoice.totalAmount)}</p>
                            </Col>)}
                          </Row>)}
                          {accordionData.pendingTransferableAmount > 0 ? <Row>
                            <Col xs={12}>
                              <p className='text-warning'><strong>Amount pending to be transferred: {formatAmount(accordionData.pendingTransferableAmount)}.</strong> You may close the bill after initiating transfer.</p>
                            </Col>
                          </Row> : <Row>
                            <Col xs={12}>
                              <p className='text-muted'>No amount pending for transfer for this bill. {accordionData.status === 'PAID' && !accordionData.terminalStatus && <strong className='text-warning'>You may close the bill now.</strong>}</p>
                            </Col>
                          </Row>}
                          <Row className="mb-4">
                            <Col xs={12} className="text-right">
                              {accordionData.terminalStatus === null && <Button className="ml-2" variant="outline-primary" size="sm" disabled={accordionData.pendingTransferableAmount > 9} onClick={this.handleMarkAsPaidSecurityDepositBillButtonClick}>Close Bill</Button>}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    }
                  </Card>
                )
                }
              </Accordion>
              {/* Code for pagination */}
              {showPagination && showPagination && <UltimatePagination
                currentPage={page}
                totalPages={total}
                onChange={this.onPageChange}
                boundaryPagesRange={0}
                hidePreviousAndNextPageLinks={true}
                hideFirstAndLastPageLinks={page !== 1 && page !== total ? false : true}
              />}
            </PortletBody>
          </Portlet>
        </Container>

        <Modal backdrop="static" keyboard={false} key="m1" show={showCreateSecurityDepositPaymentBillModal} onHide={() => this.handleNewSecurityDepositBillButtonClick(null)}>
          <Modal.Header closeButton>
            <Modal.Title>New Security Deposit Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                paymentBill: {
                  securityDepositLeaseId: leaseIdFromLeaseProfile,
                  dueDate: undefined,
                  amount: undefined,
                  billAmount: undefined,
                  resultantPaymentBillAdjustments: suggestedPaymentBillAdjustments,
                }
              }}
              validationSchema={newSecurityDepositBillSchema}
              onSubmit={
                async (values) => {
                  let sendingValues = JSON.parse(JSON.stringify(values));
                  //using sendingValues for manipulation to keep form values intact for retrying if it fails.
                  if (sendingValues.paymentBill?.resultantPaymentBillAdjustments && sendingValues.paymentBill?.resultantPaymentBillAdjustments.length > 0) {
                    sendingValues.paymentBill.resultantPaymentBillAdjustments = sendingValues.paymentBill.resultantPaymentBillAdjustments.filter(rpa => rpa.isChecked === true);
                    if (sendingValues.paymentBill.resultantPaymentBillAdjustments.length > 0) {
                      sendingValues.paymentBill.resultantPaymentBillAdjustments.forEach(rpa => {
                        delete rpa.id;
                        delete rpa.isChecked;
                        delete rpa.adjustedPaymentBill.tokenLeaseId;
                        delete rpa.adjustedPaymentBill.amount;
                      });
                    }
                  }
                  console.log("==== values manipulated before sending", JSON.stringify(sendingValues, null, 2));
                  const result = await securityDepositBillsServices.createSecurityDepositBill(sendingValues);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Security Deposit Bill created!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showCreateSecurityDepositPaymentBillModal: !state.showCreateSecurityDepositPaymentBillModal }));
                    this.getAllSecurityDepositBills();
                  }
                }
              }
            >
              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>1. Add Bill Details:</h5>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="paymentBill.dueDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payment Due Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='paymentBill.dueDate'
                            value={values.paymentBill.dueDate}
                            onChange={setFieldValue}
                          />
                          {errors.paymentBill && errors.paymentBill.dueDate && touched.paymentBill && touched.paymentBill.dueDate && <Form.Text className="text-danger">
                            {errors.paymentBill.dueDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="paymentBill.billAmount">
                          <Form.Label>Bill Amount before Adjustments <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" name="paymentBill.billAmount" onChange={
                            async (e) => {
                              handleChange(e);
                              await this.setState({ originalSecurityDepositAmount: e.target.value });
                              await this.calculateFinalAmountForBillAdjustments(e, values.paymentBill.resultantPaymentBillAdjustments, setFieldValue);
                            }
                          } onBlur={handleBlur} value={values.paymentBill.billAmount}
                          />
                          {errors.paymentBill && errors.paymentBill.billAmount && touched.paymentBill && touched.paymentBill.billAmount && <Form.Text className="text-danger">
                            {errors.paymentBill.billAmount}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      {suggestedPaymentBillAdjustments && suggestedPaymentBillAdjustments.length > 0 && <Col xs={12}>
                        <h5>2. Adjustments to include in this bill:</h5>
                        {/* <h5>2. Choose Suggested Adjustments to include in this bill:</h5> */}
                        {values.paymentBill.resultantPaymentBillAdjustments.map((adjustment, index) =>
                          <Form.Group className={adjustment.isChecked ? 'card border-success p-3' : 'card p-3'} key={`paymentBill.resultantPaymentBillAdjustments[${index}].isChecked`} controlId={`paymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}>
                            <Form.Check type="checkbox" key={`paymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}>
                              <Form.Check.Input type="checkbox" readOnly
                                name={`paymentBill.resultantPaymentBillAdjustments[${index}].isChecked`}
                                value={{ adjustment }}
                                checked={(adjustment.isChecked === true)}
                              // onClick={async (e) => {
                              //   adjustment.isChecked = !adjustment.isChecked;
                              //   await this.calculateFinalAmountForBillAdjustments(e, values.paymentBill.resultantPaymentBillAdjustments, setFieldValue);
                              // }}
                              />
                              <Form.Check.Label className="pl-3">Token Paid: {(adjustment.effect === 'NEGATIVE') ? '-' : ''}₹{adjustment.adjustedPaymentBill.amount}</Form.Check.Label>
                            </Form.Check>
                            <Form.Text className="text-muted">Note: This adjustment cannot be unchecked. Please create the Security Deposit Bill or Rent Bill where this is to be adjusted first.</Form.Text>
                          </Form.Group>
                        )}
                      </Col>}
                      {suggestedPaymentBillAdjustments && suggestedPaymentBillAdjustments.length === 0 && <Col xs={12} style={{ marginBottom: "2rem" }}>
                        <h5>2. Suggested Bill Adjustments:</h5>
                        <p className="small text-muted mb-0">No suggestions available at this time.</p>
                      </Col>}
                      <Col xs={12} md={6}>
                        <Form.Group controlId="paymentBill.amount">
                          <Form.Label>Final Payable Amount <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" min={1} readOnly name="paymentBill.amount" onChange={handleChange} onBlur={handleBlur} value={values.paymentBill.amount} />
                          {(errors.paymentBill && errors.paymentBill.amount && touched.paymentBill && touched.paymentBill.amount) ?
                            <Form.Text className="text-danger">{errors.paymentBill.amount}</Form.Text> : <Form.Text>Auto-calculated</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleNewSecurityDepositBillButtonClick(null)}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m2" show={editSecurityDepositPaymentBillModal} onHide={this.handleEditSecurityDepositBillButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Security Deposit Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                paymentBill: {
                  securityDepositPaymentBillId: (this.state.toBeEdited && this.state.toBeEdited.id) || undefined,
                  dueDate: (this.state.toBeEdited && this.state.toBeEdited.dueDate) || undefined,
                  amount: (this.state.toBeEdited && this.state.toBeEdited.amount) || undefined,
                  billAmount: (this.state.toBeEdited && this.state.toBeEdited.billAmount) || undefined,
                }
              }}
              validationSchema={editSecurityDepositBillSchema}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const data = {
                    'paymentBill': {
                      'dueDate': values.paymentBill.dueDate,
                      'amount': values.paymentBill.amount,
                      'billAmount': values.paymentBill.billAmount
                    }
                  }
                  let id = values.paymentBill.securityDepositPaymentBillId;
                  const result = await securityDepositBillsServices.updateSecurityDepositBill(id, data);
                  if (result.status === 200 || result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Security Deposit Bill Updated! Please refresh to see the updated bill.`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ editSecurityDepositPaymentBillModal: !state.editSecurityDepositPaymentBillModal }));
                    this.getSecurityDepositBillById(values.paymentBill.securityDepositPaymentBillId);
                  }
                }
              }
            >

              {
                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <h5>1. Edit Bill Details:</h5>
                        <p className="small text-muted">Security Deposit Payment Bill #{this.state.toBeEdited && this.state.toBeEdited.id}</p>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="paymentBill.dueDate">
                          <DatePickerField
                            className='form-control'
                            autoComplete='off'
                            label='Payment Due Date'
                            isMandatory={true}
                            dateFormat='MMMM d, yyyy'
                            name='paymentBill.dueDate'
                            value={values.paymentBill.dueDate}
                            onChange={setFieldValue}
                          />
                          {errors.paymentBill && errors.paymentBill.dueDate && touched.paymentBill && touched.paymentBill.dueDate && <Form.Text className="text-danger">
                            {errors.paymentBill.dueDate}
                          </Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="paymentBill.billAmount">
                          <Form.Label>Bill Amount before Adjustments <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="number" name="paymentBill.billAmount" onChange={
                            async (e) => {
                              handleChange(e);
                              await this.setState({ originalSecurityDepositAmount: e.target.value });
                              await this.calculateFinalAmountForBillAdjustments(e, accordionData.resultantPaymentBillAdjustments, setFieldValue);
                            }
                          } onBlur={handleBlur} value={values.paymentBill.billAmount} />
                          {(errors.paymentBill && errors.paymentBill.billAmount && touched.paymentBill && touched.paymentBill.billAmount) &&
                            <Form.Text className="text-danger">{errors.paymentBill.billAmount}</Form.Text>}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <h5>2. Applied Bill Adjustments:</h5>
                      </Col>
                      {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length > 0 &&
                        accordionData.resultantPaymentBillAdjustments.map((billAdjust, index) => <Col xs={12} key={"rpba" + index}>
                          <p className="mb-0 small text-muted">Payment Bill #{billAdjust.adjustedPaymentBill.id}</p>
                          <p>Token Amount: <span className={billAdjust.effect === "NEGATIVE" ? "text-danger" : ""}>{(billAdjust.effect === "NEGATIVE") ? "-" : ""}{formatAmount(billAdjust.adjustedPaymentBill.amount)}</span></p>
                        </Col>)}
                      {accordionData.resultantPaymentBillAdjustments && accordionData.resultantPaymentBillAdjustments.length === 0 && <Col xs={12} style={{ marginBottom: "2rem" }}>
                        <p className="small text-muted mb-0">No Payment Bill Adjustments applied.</p>
                      </Col>}
                      <Col xs={6}>
                        <Form.Group controlId="paymentBill.amount">
                          <Form.Label>Final Payable Amount <span className="text-danger">*</span></Form.Label>
                          <Form.Control type="text" name="paymentBill.amount" readOnly value={values.paymentBill.amount} />
                          {(errors.paymentBill && errors.paymentBill.amount && touched.paymentBill && touched.paymentBill.amount) ?
                            <Form.Text className="text-danger">{errors.paymentBill.amount}</Form.Text> : <Form.Text>Auto-calculated</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleEditSecurityDepositBillButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m4" show={showMarkAsPaidSecurityDepositModal} onHide={this.handleMarkAsPaidSecurityDepositBillButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Mark as Paid</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                paymentBill: {
                  terminalStatus: 'PAID'
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  const result = await securityDepositBillsServices.securityDepositBillsMarkTerminalStatus(accordionData && accordionData.id, values);
                  if (result && result.status === 200) {
                    store.addNotification({
                      title: "Success!",
                      message: `Security Deposit bill marked as paid!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    this.setState(state => ({ showMarkAsPaidSecurityDepositModal: !state.showMarkAsPaidSecurityDepositModal }));
                    await this.getAllSecurityDepositBills();
                    this.getSecurityDepositBillById(accordionData && accordionData.id);
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>

                    <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
                    <p><strong>Do you want to mark this Security Deposit as paid?</strong></p>

                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={this.handleMarkAsPaidSecurityDepositBillButtonClick}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Yes'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key="m5" size="lg" show={showTransferModal} onHide={() => this.handleInitiateTransferButtonClick()}>
          <Modal.Header closeButton>
            <Modal.Title>Initiate Transfer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bankAccountsForTransfer && bankAccountsForTransfer.length === 0 && <>
              <h4>Transfer cannot be initiated at this time because the settlement bank account does not exist.</h4>
            </>}
            {bankAccountsForTransfer && bankAccountsForTransfer.length > 0 && <Formik
              initialValues={{
                transfer: {
                  sourcePaymentId: (transferData && transferData.sourcePaymentId) || undefined,
                  securityDepositPaymentBillId: (transferData && transferData.securityDepositPaymentBillId) || undefined,
                  bankAccountId: undefined,
                  amount: accordionData.pendingTransferableAmount || (sourcePaymentForTransfer && Number(sourcePaymentForTransfer.amount).toFixed(2)) || undefined,
                  transferMode: 'imps',
                }
              }}
              onSubmit={
                async (values, { setSubmitting }) => {
                  if (values.transfer?.bankAccountId) {
                    values.transfer.bankAccountId = parseInt(values.transfer.bankAccountId, 10);
                  }
                  const result = await commonServices.createTransferToOwner(values);
                  if (result && result.status === 201) {
                    store.addNotification({
                      title: "Success!",
                      message: `Transfer Created Successfully!`,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animated", "fadeIn"],
                      animationOut: ["animated", "fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                        showIcon: true,
                        pauseOnHover: true
                      }
                    });
                    await this.getSecurityDepositBillById(accordionData && accordionData.id);
                    this.setState(state => ({ showTransferModal: !state.showTransferModal }));
                  }
                }
              }
            >
              {
                ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} className="mb-2">
                        <h5>1. Select the Bank Account to make the transfer to:</h5>
                        <Form.Group className="mb-2" key={`transfer.bankAccountId`} controlId={`transfer.bankAccountId`}>
                          {bankAccountsForTransfer && bankAccountsForTransfer.map((account, index) =>
                          (<Form.Check className={(values?.transfer?.bankAccountId === account.id.toString()) ? 'card border-success pl-2 pr-3 pt-2' : 'card pl-2 pr-3 pt-2'} type="radio" key={`transfer.bankAccountId${index}`}>
                            <Form.Check.Input className="ml-0" type="radio" name="transfer.bankAccountId" onChange={handleChange} value={account.id} disabled={!account.cashfreeBeneficiaryId} checked={(values?.transfer?.bankAccountId === account.id.toString())} />
                            {!account.cashfreeBeneficiaryId && <Form.Text className='text-danger ml-4'>This bank account does not have Cashfree Beneficiary ID</Form.Text>}
                            <Form.Check.Label className="ml-4 mt-1">
                              <Row>
                                <Col xs={12} md={8}>
                                  {account.vpa !== null && account.vpa !== "" ?
                                    <p>
                                      <strong>VPA:</strong> {account.vpa}<br />
                                      <strong>Cashfree Beneficiary ID:</strong> {account.cashfreeBeneficiaryId ? account.cashfreeBeneficiaryId : 'Not Set'}
                                    </p> :
                                    <p>
                                      <strong>Account No:</strong> {account.accountNumber} <small>{account.isPrimary && <Badge className="mr-1" variant="warning">Primary</Badge>}</small><br />
                                      Account Holder Name: {account.accountHolderName}<br />
                                      Bank: {account.currency === 'INR' && `${account.ifsc} | `}{account.currency === 'AED' && `${account.iban} | ${account.swiftCode} | `} {account.bankName} | {account.branch} <br />
                                      Cashfree Beneficiary ID: {account.cashfreeBeneficiaryId ? account.cashfreeBeneficiaryId : 'Not Set'}
                                      <p className='text-muted small'>Linked to {account.user.fullName} (Email: {account.user.email != null ? account.user.email : 'N/A'}) </p>
                                    </p>}
                                </Col>
                              </Row>
                            </Form.Check.Label>
                          </Form.Check>)
                          )
                          }
                          <Form.Text className="card pl-2 pr-3 py-2 text-warning small">Note: If you don't see an account you would like to transfer to, make sure it is added and a settlement account is created for it.</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mb-2">
                        <h5>2. Received Payment:</h5>
                        <p>Amount: {formatAmount(sourcePaymentForTransfer.amount, sourcePaymentForTransfer.currency)}</p>
                      </Col>
                      {accordionData && accordionData.pendingTransferableAmount > 0 && <Col xs={12} className="mb-2">
                        <h5> Amount pending to be transferred: {formatAmount(accordionData.pendingTransferableAmount)}</h5>
                      </Col>}
                      <Col xs={6}>
                        <Form.Group controlId="transfer.transferMode">
                          <Form.Label>Transfer Mode</Form.Label>
                          <Form.Control as="select" name="transfer.transferMode" onChange={handleChange} onBlur={handleBlur} value={values.transfer.transferMode} >
                            <option key={'viaIMPS'} value={'imps'}>IMPS</option>
                            <option key={'viaNEFT'} value={'neft'}>NEFT</option>
                          </Form.Control>
                          <Form.Text className="text-muted small">Change this to NEFT when the selected bank account does not support IMPS transfers.</Form.Text>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="transfer.amount">
                          <Form.Label>Amount to Transfer</Form.Label>
                          <Form.Control type="number" step="0.01" name="transfer.amount" min={1} max={accordionData && accordionData.pendingTransferableAmount} onChange={handleChange} onBlur={handleBlur}
                            value={values?.transfer?.amount} />
                          {(values.transfer.amount === "0.00" || values.transfer.amount < 9) && <Form.Text className="text-danger">Amount cannot be less than 9</Form.Text>}
                          {accordionData.pendingTransferableAmount > 0 && (values.transfer.amount > accordionData.pendingTransferableAmount) && <Form.Text className="text-muted">Amount cannot be greater than {formatAmount(accordionData.pendingTransferableAmount)}</Form.Text>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="text-right">
                        <Button variant="secondary" className="mr-2" onClick={() => this.handleInitiateTransferButtonClick()}>
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                          {isSubmitting ? 'Processing...' : 'Confirm'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }
            </Formik>}
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} key={"m5"} show={showDeleteSecurityDepositBillModal} onHide={this.handleDeleteSecurityDepositBillButtonClick}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Security Deposit Bill</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><i className="flaticon2-warning"></i> WARNING: This action is irreversible.</p>
            <p><strong>Confirm deletion of this Security Deposit Bill?</strong></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDeleteSecurityDepositBillButtonClick}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleConfirmDeleteSecurityDepositBillButtonClick}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth && state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(SecurityDepositBills);