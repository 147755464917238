import axiosInstance from "../../app/myAxiosInstance";
const baseApiUrl =
  process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

export async function login(email, password, userCategory) {
  try {
    const data = {
      email: email,
      password: password,
      userCategory: userCategory,
    };
    const result = await axiosInstance.post(`${baseApiUrl}users/login`, data);
    return result;
  } catch (err) {
    console.error("in login", err);
  }
}

export async function register(email, fullname, username, password) {
  try {
    const result = await axiosInstance.post(`api/auth/register`, {
      email,
      fullname,
      username,
      password,
    });
    return result;
  } catch (err) {
    console.error("error in register ", err);
  }
}

export async function requestPassword(email) {
  try {
    const result = await axiosInstance.post(
      `${baseApiUrl}users/requestPasswordReset`,
      { email }
    );
    return result;
  } catch (err) {
    console.error("error in requestPassword ", err);
  }
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  try {
    const result = await axiosInstance.get(`api/me`);
    return result;
  } catch (err) {
    console.error("error in getUserByToken ", err);
  }
}

export async function verifyToken(data) {
  // Authorization head should be fulfilled in interceptor.
  try {
    const result = await axiosInstance.get(`${baseApiUrl}users/verifyPrt`, {
      params: data,
    });
    return result;
  } catch (err) {
    console.error("error in verifyToken ", err);
  }
}

export async function resetPassword(data) {
  // Authorization head should be fulfilled in interceptor.
  try {
    const reqData = {
      prt: data.prt,
      newPassword: data.newPassword,
    };
    const result = await axiosInstance.post(
      `${baseApiUrl}users/resetPassword`,
      reqData
    );
    return result;
  } catch (err) {
    console.error("error in resetPassword ", err);
  }
}

export async function changePassword(data) {
  // Authorization head should be fulfilled in interceptor.
  try {
    const reqData = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    const result = await axiosInstance.post(
      `${baseApiUrl}users/changePassword`,
      reqData
    );
    return result;
  } catch (err) {
    console.error("error in changePassword ", err);
  }
}

/**
 * Method to get static data
 */
export async function getStaticData() {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/staticData`);
    return result;
  } catch (err) {
    console.error("error in getStaticData ", err);
  }
}
