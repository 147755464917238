import axiosInstance from "../../../myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to get all properties
 * @param {Object} data
 */
async function getAllProperties(data) {
    try {
        const result = await axiosInstance.get(`${baseApiUrl}admin/houses`, { params: data });
        return result;
    } catch (err) {
        console.error('error in getAllProperties ', err);
    }

}

export const MyPropertiesServices = {
    getAllProperties,
};