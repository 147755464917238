import React, { Component } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { commonServices } from '../../../app/commonServices/commonServices';
import * as constants from '../../constants';
import { Formik, FieldArray } from 'formik';
import { store } from 'react-notifications-component';

class AddUserComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props,
            showAddUserModal: false,
            selectedUserId:null,
            formStage: 'searchUser'
        };
    }
    handleAddUserButtonClick = (userType = null) => {
        this.setState(state => ({
            showAddUserModal: !state.showAddUserModal,
            userType: userType
        }));
    }

    /**
      * Method to get user list
      */
    getUserListData = async () => {
        const { searchQuery } = this.state;
        const data = {
            searchQuery,
            include: ['PHONE_NUMBERS']

        }
        const result = await commonServices.getUserListData(data);
        if (result && result.status === 200) {
            const data = result.data && result.data.users;
            this.setState({
                ownerOrTenant: data

            })
        }
    };

    /**
     * Method to handle Owner and Tenant
     */
    handleOwnerAndTenant = async () => {
        const { houseId, leaseId } = this.state;

        if (houseId) {
            await this.createHouseOwnerships();
        } else if (leaseId) {
            await this.createLeaseTenantParticipations();
        }
    }

    /**
      * Method to create house Onwerships
      */
    createHouseOwnerships = async () => {
        const { houseId, ownerOrTenant } = this.state;
        let id=this.state.selectedUserId
        const data = {
            houseOwnership: {
                houseId,
                userId: ownerOrTenant && ownerOrTenant.filter((data)=>data.id === id)[0].id
            }
        };
        const result = await commonServices.createHouseOwnerships(data);
        if (result && result.status === 201) {
            await this.handleSuccess();
        }
    };

    /**
      * Method to create lease tenant participations
      */
    createLeaseTenantParticipations = async () => {
        const { leaseId, ownerOrTenant } = this.state;
        let id=this.state.selectedUserId
        const data =
        {
            leaseTenantParticipation:
            {
                leaseId,
                userId: ownerOrTenant && ownerOrTenant.filter((data)=>data.id === id)[0].id
            }
        };
        const result = await commonServices.createLeaseTenantParticipations(data);
        if (result && result.status === 201) {
            this.handleSuccess();
        }
    };

    /**
     * Method to handle sucess cases
     */
    handleSuccess = () => {
        const { userType, leaseId, houseId, page,
            leaseProfilePageCallback, propertyProfilePageCallback } = this.state;
        store.addNotification({
            title: "Success!",
            message: userType + ` User Added!`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                showIcon: true,
                pauseOnHover: true
            }
        });
        this.setState(state => ({ showAddUserModal: !state.showAddUserModal }));
        if (page === 'LeaseProfile') {
            leaseProfilePageCallback(leaseId);
        } else if (page === 'PropertyProfile') {
            propertyProfilePageCallback(houseId);
        }
    }


    /**
     * Custom method to handle change for dropdowns in formik
     * @param {Object} e
     * @param {Object} setFieldValue
     */
    handleCustomChange = async (e, setFieldValue) => {
        e.persist();
        if (e.target.validity.valid) {
            if (e.target?.name === 'searchQuery') {
                this.setState({ searchQuery: e.target.value.trim() })
            }

            if (e.target?.name === 'initialSubmit') {
                await this.getUserListData();
                this.state.ownerOrTenant?.length === 0 && setFieldValue('formStage', 'regForm');
                if (this.state.ownerOrTenant?.length > 0) {
                    await setFieldValue("formStage", 'confirmUserToAdd');
                }
            }
            if (e.target?.name === 'addConfirmButton') {
                await this.handleOwnerAndTenant();
            }
            if (e.target?.name === 'addConfirmBack') {
                await setFieldValue('formStage', 'searchUser');
            }
        }
    };

    render() {
        const { searchQuery } = this.state;
        return (
            <>
                <Button variant="clean" size="sm" onClick={() => this.handleAddUserButtonClick(this.props.userType)}>Add {this.props.userType}</Button>

                <Modal backdrop="static" keyboard={false} key={'addUser' + 1} show={this.state.showAddUserModal} onHide={() => this.handleAddUserButtonClick(null)} >
                    <Modal.Header closeButton>
                        <Modal.Title>Add {this.state.userType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                file: undefined,
                                searchQuery: '',
                                email: undefined,
                                worksAs: undefined,
                            
                                worksAt: undefined,
                                fullName: undefined,
                                formStage: 'searchUser',
                                // isVendor: undefined,
                                phoneNumbers: [{
                                    countryCode: undefined,
                                    number: undefined,
                                    isPrimary: undefined
                                }],
                                leaseTenantParticipations: [
                                    {
                                        leaseId: this.props.leaseId || undefined,
                                        isPrimary: undefined
                                    }
                                ],
                                houseOwnerships: [
                                    {
                                        houseId: this.props.houseId || undefined,
                                        isPrimary: undefined
                                    }
                                ]
                            }}
                            validate={values => {
                                const errors = {};
                                // if (!values.preMoveInWorks.length === 0) {
                                //   errors.preMoveInWorks = 'Atlessast one Furnishing Name is required';
                                // }
                                return errors;
                            }}
                            onSubmit={
                                async (values, { setSubmitting }) => {

                                    let formData = new FormData();
                                    values.file && formData.append('file', values.file);
                                    values.email && formData.append('user[email]', values.email);
                                    values.worksAs && formData.append('user[worksAs]', values.worksAs);
                                    values.worksAt && formData.append('user[worksAt]', values.worksAt);
                                    values.fullName && formData.append('user[fullName]', values.fullName);
                                    // values.isVendor && formData.append('user[isVendor]', values.isVendor);
                                    values.phoneNumbers && (values.phoneNumbers).forEach((phone, index) => {
                                        formData.append(`user[phoneNumbers][${index}][countryCode]`, phone.countryCode);
                                        formData.append(`user[phoneNumbers][${index}][number]`, phone.number);
                                        formData.append(`user[phoneNumbers][${index}][isPrimary]`, phone.isPrimary === 'true');
                                    });
                                    if (this.state.userType === 'Tenant') {
                                        values.leaseTenantParticipations && (values.leaseTenantParticipations).forEach((leaseTenantParticipation, index) => {
                                            formData.append(`user[leaseTenantParticipations][${index}][leaseId]`, leaseTenantParticipation.leaseId);
                                            formData.append(`user[leaseTenantParticipations][${index}][isPrimary]`, leaseTenantParticipation.isPrimary === 'true');
                                        });
                                    } else if (this.state.userType === 'Owner') {
                                        values.houseOwnerships && (values.houseOwnerships).forEach((houseOwnership, index) => {
                                            formData.append(`user[houseOwnerships][${index}][houseId]`, houseOwnership.houseId);
                                            formData.append(`user[houseOwnerships][${index}][isPrimary]`, houseOwnership.isPrimary === 'true');
                                        });
                                    }
                                    const result = await commonServices.createUser(formData);
                                    if (result && result.status === 201) {
                                        this.handleSuccess();
                                    }
                                }
                            }
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) =>
                                (
                                    <Form onSubmit={handleSubmit}>
                                        {
                                            values.formStage === 'searchUser' && <Row>
                                                <Col xs={12} className='mt-2'>
                                                    <Form.Group controlId="searchQuery">
                                                        <Form.Label>Search using Email or Phone Number (without Country Code)</Form.Label>
                                                        <Form.Control type="text" name='searchQuery'
                                                            // onChange={handleChange}
                                                            onChange={
                                                                async (e) => {
                                                                    handleChange(e);
                                                                    await this.handleCustomChange(e, setFieldValue)
                                                                }
                                                            }
                                                            onBlur={handleBlur} value={values.searchQuery} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="text-left">
                                                    <Button
                                                        onClick={
                                                            async (e) => {
                                                                // handleChange(e);
                                                                await this.handleCustomChange(e, setFieldValue)
                                                            }
                                                        }
                                                        name='initialSubmit'
                                                        variant="primary"
                                                        disabled={searchQuery?.length < 6}
                                                    >
                                                        Search user
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            values.formStage === 'confirmUserToAdd' && <Row>
                                                <Col>
                                                    <Form.Group controlId="selectedUserId">
                                                        <Form.Label>The Search Text entered matches the following user(s).</Form.Label>
                                                        <p>{this.state.userType === 'Owner' ? 'Choose the one you want to add to this House as Owner.' :'Choose the one you want to add to this Lease as Tenant.'}</p>
                                                        {
                                                            this.state.ownerOrTenant && this.state.ownerOrTenant.map((ownOrTen) => (
                                                                
                                                                <Form.Check type="radio">
                                                                    <Form.Check.Input 
                                                                        type="radio" 
                                                                        name="selectedUserId"
                                                                        onChange={()=>this.setState({...this.state,selectedUserId:ownOrTen.id})}
                                                                        value={ownOrTen.id}
                                                                    />
                                                               
                                                                    <Form.Check.Label className="ml-3 mt-1">
                                                                        <strong>{ownOrTen.fullName}</strong>
                                                                        <p className="mb-0">{ownOrTen.email}</p>
                                                                        <p><span>+{ownOrTen.phoneNumbers[0].countryCode}-</span>{ownOrTen.phoneNumbers[0].number}</p>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            )
                                                            )
                                                        }
                                                        <p className="small text-muted">In case there seem to be duplicate users, please inform the same to the Tech Team to resolve the issue</p>
                                                        <Col xs={12} className="text-right">
                                                        <Button variant="secondary" name='addConfirmBack' className="mr-2" onClick={async (e) => await this.handleCustomChange(e, setFieldValue)}>
                                                        Go Back to Search
                                                        </Button>
                                                        <Button variant="primary" name='addConfirmButton' className="mr-2" onClick={async (e) => await this.handleCustomChange(e, setFieldValue)}>
                                                            Add
                                                        </Button>
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }

                                        {
                                            values.formStage === 'regForm' && <Row>
                                                <Col xs={12} className="mb-2">
                                                    <label>Select Profile Picture</label><br/>
                                                    <input id="file" name="file" type="file" onChange={(event) => {
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                    }} />
                                                </Col>

                                                <Col xs={12} className='mt-2'>
                                                    <Form.Group controlId="fullName">
                                                        <Form.Label>Full Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='fullName' onChange={handleChange} onBlur={handleBlur} value={values.fullName} pattern={"[a-zA-Z][a-zA-Z ]+[a-zA-Z]$"} required="required" />
                                                        <Form.Text className="text-muted">Only Alphabets with Spaces are allowed</Form.Text>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className='mt-2'>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="email" name='email' onChange={handleChange} onBlur={handleBlur} value={values.email} required="required" />
                                                    </Form.Group>
                                                </Col>

                                                {/* <Col xs={12}>
                                                        <Form.Group controlId={"isVendor"}>
                                                            <Form.Label>Is the user also a vendor?</Form.Label>
                                                            <Form.Control as="select" name={`isVendor`} onChange={handleChange} onBlur={handleBlur} value={values.isVendor}>
                                                                <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                <option value={true}>YES</option>
                                                                <option value={false}>NO</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col> */}

                                                <Col xs={6} className='mt-2'>
                                                    <Form.Group controlId="worksAs">
                                                        <Form.Label>Works As</Form.Label>
                                                        <Form.Control type="text" name='worksAs' onChange={handleChange} onBlur={handleBlur} value={values.worksAs} />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className='mt-2'>
                                                    <Form.Group controlId="worksAt">
                                                        <Form.Label>Works At</Form.Label>
                                                        <Form.Control type="text" name='worksAt' onChange={handleChange} onBlur={handleBlur} value={values.worksAt} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12}>
                                                    <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                                    <FieldArray
                                                        name="phoneNumbers"
                                                        render={arrayHelpers => (
                                                            <div>
                                                                {values.phoneNumbers.map((phoneNumber, index) =>
                                                                (<Row key={index}>
                                                                    <Col xs={3}>
                                                                        <Form.Group controlId={"countryCode" + index}>
                                                                            <Form.Label>Country Code <span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control type="text" name={`phoneNumbers[${index}].countryCode`} pattern={"[0-9]*"} required="required" onChange={handleChange} onBlur={handleBlur} value={phoneNumber.countryCode} />
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col xs={4}>
                                                                        <Form.Group controlId={"number" + index}>
                                                                            <Form.Label>Number <span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control type="text" name={`phoneNumbers[${index}].number`} pattern={"[0-9]*"} required="required" onChange={handleChange} onBlur={handleBlur} value={phoneNumber.number} />
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col xs={3}>
                                                                        <Form.Group controlId={"isPrimary" + index}>
                                                                            <Form.Label>Primary <span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control as="select" name={`phoneNumbers[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={phoneNumber.isPrimary}>
                                                                                <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                                <option value={true}>YES</option>
                                                                                <option value={false}>NO</option>
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col xs={2} className='d-flex align-self-center justify-content-right'>
                                                                        <Button variant="danger" size="sm" onClick={() => arrayHelpers.remove(index)}>X</Button>
                                                                    </Col>
                                                                </Row>)
                                                                )}
                                                                <Button variant="secondary" size="sm" onClick={() => arrayHelpers.push({
                                                                    countryCode: null,
                                                                    number: null,
                                                                    isPrimary: null
                                                                })}>Add Phone Number</Button>
                                                            </div>
                                                        )}
                                                    />
                                                    <hr style={{ borderTop: "1px dashed rgba(0, 0, 0, 0.1)" }} />
                                                </Col>

                                                {this.state.userType === 'Tenant' && <Col xs={12}>
                                                    {values.leaseTenantParticipations.map((leaseTenantParticipation, index) =>
                                                    (<Row key={index}>
                                                        <Col xs={6}>
                                                            <Form.Group controlId={"isPrimary" + index}>
                                                                <Form.Label>Is the Tenant primary? <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control as="select" name={`leaseTenantParticipations[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={leaseTenantParticipation.isPrimary}>
                                                                    <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                    <option value={true}>YES</option>
                                                                    <option value={false}>NO</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>)
                                                    )}
                                                </Col>}

                                                {this.state.userType === 'Owner' && <Col xs={12}>
                                                    {values.houseOwnerships.map((houseOwnership, index) =>
                                                    (<Row key={index}>
                                                        <Col xs={6}>
                                                            <Form.Group controlId={"isPrimary" + index}>
                                                                <Form.Label>Is the Owner primary? <span className="text-danger">*</span></Form.Label>
                                                                <Form.Control as="select" name={`houseOwnerships[${index}].isPrimary`} onChange={handleChange} onBlur={handleBlur} value={houseOwnership.isPrimary}>
                                                                    <option value={null}>{constants.DEFAULT_OPTION}</option>
                                                                    <option value={true}>YES</option>
                                                                    <option value={false}>NO</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>)
                                                    )}
                                                </Col>}
                                            </Row>
                                        }
                                        {values.formStage === 'regForm' && <Row>
                                            <Col xs={12} className="text-right">
                                                <Button variant="secondary" className="mr-2" onClick={() => this.handleAddUserButtonClick(null)}>
                                                    Cancel
                                                </Button>
                                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </Button>
                                            </Col>
                                        </Row>}
                                    </Form>
                                )
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

export default AddUserComponent;