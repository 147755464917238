import React, { Component } from "react";
import {
    Row, Col
} from 'react-bootstrap';

export default class BackgroundVerificationRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let result = {}
        if (props.requests !== state.requests) {
            result.requests = props.requests;
        }
        return Object.keys(result).length > 0 ? result : null;
    }

      /**
   * Method to get item status badge
   * @param {String} status 
   */
  getBackgroundVerificationStatusBadge = (status) => {
    if (status === 'red') return <i className="flaticon2-hexagonal text-danger" title="Red"></i>
    if (status === 'in_progress') return <i className="flaticon2-hexagonal text-warning" title="In-Progress"></i>
    if (status === 'green') return <i className="flaticon2-hexagonal text-success" title="Green"></i>
  }

    render() {
        const { requests } = this.state;
        return (
            <>
                {/* <Col xs={12}>
                    <h5>Background Verification Requests</h5>
                </Col> */}
                {(!requests || requests?.length === 0) && <Col className="text-center" xs={12}><p>No Background Verification Requests</p></Col>}
                <Col xs={12}>
                    {requests?.length > 0 && requests.map(backgroundVerificationRequest =>
                    (
                        <React.Fragment key={'bvr'+backgroundVerificationRequest.id}>
                            <Row className="mt-4">
                                <Col xs={12}>
                                    <h5>{backgroundVerificationRequest.user?.fullName}
                                        {backgroundVerificationRequest.reportFile && <a className="btn btn-sm btn-link d-inline-block ml-2" target="_blank" rel="noopener noreferrer" href={`${backgroundVerificationRequest.reportFile}`} title="Click to View">
                                            <span mr='2'>Report {backgroundVerificationRequest.status && this.getBackgroundVerificationStatusBadge(backgroundVerificationRequest.status)}</span>
                                        </a>}
                                    </h5>
                                </Col>
                            </Row>
                            {backgroundVerificationRequest?.backgroundVerificationTasks.map(backgroundVerificationTask => (<Row key={'bvt'+backgroundVerificationTask.id}>
                                <Col xs={3}>
                                    <span>
                                        {backgroundVerificationTask.name}
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    {backgroundVerificationTask.taskCompletedAt ? `(Completed)` : `(Not Completed)`}
                                </Col>

                                <Col xs={6}>
                                    <span>
                                        Status: {backgroundVerificationTask.status ? this.getBackgroundVerificationStatusBadge(backgroundVerificationTask.status) : `Unknown`}
                                        {backgroundVerificationTask.reason && ` (${backgroundVerificationTask.reason})`}
                                    </span>
                                </Col>
                            </Row>))}
                        </React.Fragment>
                    ))
                    }
                </Col>
            </>
        );
    }
}
