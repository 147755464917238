import axiosInstance from "../../../../app/myAxiosInstance";
const baseApiUrl = process.env.REACT_APP_HOST + process.env.REACT_APP_BASE_API_URL;

/**
 * Method to list PMS Subscriptions
 * @param {Object} data 
 */
async function listPMSSubscriptions(data) {
  try {
    const result = await axiosInstance.get(`${baseApiUrl}admin/pmsSubscriptions`, { params: data });
    return result;
  } catch (err) {
    console.error('error in listPMSSubscirptions ', err);
  }

}

/**
 * Method to create PMS Subscription
 * @param {Object} data 
 */
async function createPMSSubscription(data) {
  try {
    const result = await axiosInstance.post(`${baseApiUrl}admin/pmsSubscriptions`, data);
    return result;
  } catch (err) {
    console.error('error in createPMSSubscription ', err);
  }
}

/**
 * Method to update PMS Subscription
 * @param {Object} data 
 */
async function updatePMSSubscription(id, data) {
  try {
    const result = await axiosInstance.patch(`${baseApiUrl}admin/pmsSubscriptions/${id}`, data);
    return result;
  } catch (err) {
    console.error('error in updatePMSSubscription ', err);
  }
}



export const pmsSubscriptionsServices = {
  listPMSSubscriptions,
  createPMSSubscription,
  updatePMSSubscription
};